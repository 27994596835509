<template>
  <div class="cust-container">
    <menuBar></menuBar>
    <div>
      <div class="banner">
        <img src="@/assets/images/international_students/banner.jpg" />
        <div class="text" v-if="$i18n.locale === 'en'">
          INTERNATIONAL STUDENTS
        </div>
        <div class="text" v-if="$i18n.locale === 'zh'">国际学生</div>
      </div>

      <div class="content" v-if="$i18n.locale === 'en'">
        <div class="title">Find your SCAD family</div>
        <div class="desc">
          Life at SCAD is more than attending class. As an international
          student, you have abundant opportunities to connect with fellow
          students from across the globe while pursuing your academic,
          professional, and social interests.
        </div>

        <div class="title1">Student clubs</div>
        <div class="text1">
          SCAD offers more than 100 student organizations — from community
          service groups to leadership opportunities to intramural sports.
          Whether you're attending SCAD Savannah or SCAD Atlanta, join the club
          that's perfect for you.
        </div>

        <div class="title1">Events and trips</div>
        <div class="text1">
          Throughout the year, ISSO plans a wide variety of activities where you
          can meet new friends, discover potential hobbies, and make lifelong
          memories
        </div>
      </div>

      <div class="content" v-if="$i18n.locale === 'zh'">
        <div class="title">寻找您的 SCAD 大家庭</div>
        <div class="desc">
          在 SCAD 的生活不仅仅是上课。
          作为一名国际学生，在追求学业、专业程度和兴趣的同时，您有大量与来自世界各地的同学建立联系的机会。
        </div>

        <div class="title1">学生社团</div>
        <div class="text1">
          SCAD 提供 100
          多个学生社团组织——从社区服务社团到领导力社团，再到校内运动社团。
          无论在SCAD萨凡纳还是亚特兰大校区，都可以找到最适合您的社团。
        </div>

        <div class="title1">活动和旅行</div>
        <div class="text1">
          ISSO全年计划各种活动，让您结识新朋友、发现潜在爱好并留下终生难忘的回忆。
        </div>
      </div>
    </div>

    <goTop></goTop>
    <custFooter></custFooter>
  </div>
</template>

<script>
import menuBar from "../../components/menuBar/index.vue";
import custFooter from "@/components/footer/Footer";
export default {
  metaInfo: {
    title: "SCAD", // set a title
  },
  name: "International1",
  components: {
    menuBar,
    custFooter,
  },
  data: function () {
    return {};
  },
};
</script>

<style lang="less" scoped>
@import url(International.less);
</style>
