<template>
  <div>
    <menuBar></menuBar>
    <img class="banner" src="@/assets/images/events/banner.jpg" alt="" />
    <div class="cust-container">
      <div class="title" v-if="$i18n.locale === 'zh'">附近的招生活动</div>
      <div class="title" v-if="$i18n.locale === 'en'">
        Upcoming Events Schedule Released Soon
      </div>
      <div class="eventBox">
        <div class="eventList">
          <div
            class="eventItem"
            v-for="(item, index) in $i18n.locale == 'zh'
              ? eventList
              : eventENList"
            :key="index"
          >
            <el-row>
              <el-col :span="6" class="city">{{ item.city }}</el-col>
              <el-col :span="11" class="addr">
                <div class="addrInfo">
                  {{ item.addr }}
                </div>
                <div class="time">{{ item.time }}</div>
              </el-col>
              <el-col :span="3" class="statu">{{ item.desc }}</el-col>
              <el-col :span="4" class="btn1">
                <a :href="item.url">
                  <div
                    class="registerBtn"
                    v-if="$i18n.locale === 'zh'"
                    :style="item.url == '#' ? 'background: #80808073' : ''"
                  >
                    现在注册
                  </div>
                  <div
                    class="registerBtn"
                    v-if="$i18n.locale === 'en'"
                    :style="item.url == '#' ? 'background: #80808073' : ''"
                  >
                    Register Now
                  </div>
                </a>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
    </div>
    <goTop></goTop>
    <custFooter></custFooter>
  </div>
</template>

<script>
import menuBar from "../../components/menuBar/index.vue";
import custFooter from "@/components/footer/Footer";
export default {
  metaInfo: {
    title: "SCAD", // set a title
  },
  name: "MissonVisionValue",
  components: {
    menuBar,
    custFooter,
  },
  data: function () {
    return {
      eventList: [],
      eventENList: [],
    };
  },
  mounted() {
    var that = this;
    this.$api.get("AdmissionEvents/getEvents", {}, (r) => {
      console.log(r.data.data);
      that.eventList = r.data.data.cn;
      that.eventENList = r.data.data.en;
    });
  },
};
</script>

<style scoped lang="less">
@import "AdmissionEvents.less";
</style>
