const params = [
  { programs: "Advertising", value: "ADVE" },
  { programs: "Animation", value: "ANIM" },
  { programs: "Fashion", value: "FASH" },
  { programs: "Film and Television", value: "FILM" },
  { programs: "Game Development", value: "GAME" },
  { programs: "Graphic Dsgn/Visual Experience", value: "GDVX" },
  { programs: "Illustration", value: "ILLU" },
  { programs: "Interactive Design", value: "INTR" },
  { programs: "Luxury and Brand Management", value: "LXMT" },
  { programs: "Motion Media Design", value: "MOME" },
  { programs: "Photography", value: "PHOT" },
  { programs: "Sculpture", value: "SCPT" },
  { programs: "Sequential Art", value: "SEQA" },
  { programs: "Visual Effects", value: "VSFX" },
  { programs: "Writing", value: "WRIT" },
];

export default params;
