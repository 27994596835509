export default {
  "Interior Design": "室内设计",
  "Elevate your environment with SCAD interior design":
    "策划我们所爱的地方来提高生活质量",
  Fashion: "时尚",
  "Become the next big name in fashion": "成为时尚界的下一个标杆",
  Illustration: "插画",
  "Draw the future with SCAD illustration": "用插画艺术描绘未来",
  Atlanta: "亚特兰大",
  "A booming metropolitan hub": "蓬勃发展的大都市枢纽",
  Lacoste: "拉科斯特",
  "An idyllic medieval village": "田园诗般的中世纪村庄",
  Savannah: "萨凡纳",
  "The historic coastal south": "历史悠久，充满灵感的南部沿海地区",
  "Top Individual Program Rankings": "优秀专业排名",
  "Top Rankings": "其他高等排名",
  STEM: "STEM专业",

  public: {
    search: "搜索",
  },
  nav: {
    about_SCAD: "关于 SCAD",
    locations: "地点",
    mission_vision_values: "使命、愿景、价值观",
    Academics: "学位设置",
    academic_programs: "学术课程",
    faq: "FAQ",
    frequently_asked_questions: "常见问题",
    admission: "录取",
    admission_events: "招生活动",
    admissions_application: "招生申请",
    request_information: "索取资料",
    live_inquiry: "在线查询",
    "INTERNATIONAL STUDENTS": "国际学生",
    free: "学杂费",
  },
  faq: {
    faq_text_01: "国际学生常问问题",
    faq_text_02: "我如何申请？",
    faq_text_03:
      "您可以访问 <a href='https://www.scadedu.cn/application/'>www.scadedu.cn/application/</a> 进行在线申请。",
    faq_text_04: "我应该什么时候申请，申请截止日期是什么时候？",
    faq_text_05:
      "SCAD 实行滚动录取，全年接受所有季度学期的申请，因此没有确定的申请截止日期。 但是建议学生至少提前六个月申请，最迟不得晚于本季度开始前 30 天。 新生可以在任何季度开始时入学。 秋季学期是最受欢迎的学期； 它拥有最多的申请者和提供最多的课程。 如果您希望在秋季入学，我们鼓励您提前申请。 对于 SCAD萨凡纳校区和 SCAD亚特兰大校区的学生，只有在学生被录取并提供所需的财务文件证明后，才能签发 I-20 学生签证。",
    faq_text_06: "我有双重国籍。 我应该作为美国学生还是国际学生申请？",
    faq_text_07:
      "拥有双重国籍的学生被视为美国公民，无论他们住在哪里或他们的其他合法公民身份是什么，他们都应该以美国学生的身份申请。",
    faq_text_08: "你们有国际学生支持中心吗？",
    faq_text_09:
      "是的，国际学生服务办公室 (ISSO) 的工作人员会处理有关学术、大学生活、跨文化问题、就业、医疗保健、住房、安全、移民、保险、奖学金、税收、旅行和个人事务的问题。",
    faq_text_10: "国际学生可以申请证书课程吗？",
    faq_text_11:
      "国际学生只能申请通过 SCADnow（在线学习） 提供的证书。 由于证书不是一个完整的学位，所以学校将无法向他们签发学生签证。",
    faq_text_12: "我如何申请奖学金？",
    faq_text_13:
      "本科的新生，包括国际学生，在被 SCAD 录取后将自动获得学术奖学金的审查。 学术奖学金基于 GPA。 已提交简历、成就列表或作品集的录取学生也将获得成就奖学金。学校鼓励学生同时提交两项奖学金的申请。<br>研究生在录取时会自动获得基于成绩的奖学金。<br>如果您对列出的特定奖学金有疑问，请联系您的招生顾问。 我们还鼓励所有学生从大学以外的来源申请奖学金。 寻找机会的方法有很多。 SCAD 还会提供资源来帮助您寻找外部奖学金。",
    faq_text_14: "如果我没有美国信用卡/借记卡，如何支付申请费？",
    faq_text_15:
      "没有有效信用卡或借记卡的学生可以将 100 美元的支票、汇票或电汇邮寄到 SCAD 招生部，地址为：P.O. Box 2072, Savannah, GA, 31402-2072 USA.",
    faq_text_16: "我如何在 SCAD 获得转学分？",
    faq_text_17:
      "转学分可以授予已经完成大学水平课程的学生。 学分仅由经认可的机构或教育部或学生所在国家的同等机构认可的机构授予。<br> 大学向攻读四年制本科学士学位的学生授予最多两年的转学分。 学生必须提交所有先前大学的正式成绩单才能获得转学分审查。 为确保获得最多的转学分，建议学生提交他们希望转学分的所有课程的课程描述和教学大纲。 此外，可能需要进行作品集审查才能授予绘画或其他工作室课程的转学分。" +
      "",
    faq_text_18: "我如何获得有关托福、雅思或考试的信息？",
    faq_text_19:
      "有关这些考试的信息（包括考试日期和费用）可在线获取。 当您参加考试时，可要求将您的分数直接发送给 SCAD。 SCAD 的托福机构代码是 5631。<br>" +
      "托福<br>" +
      "雅思",
    faq_text_20: "我的托福分数最近需要多长时间？",
    faq_text_21:
      "托福有效成绩最长可达两年。 如果您的分数超过了这个时效，则需要重新参加考试或提供英语水平的替代证明。",
    faq_text_22: "国际学生是否有资格获得经济援助和奖学金？",
    faq_text_23:
      "由于美国政府的限制，国际学生没有资格以个人助学金、贷款或勤工俭学的形式获得美国的经济援助，但在某些地方可以提供大学资助的勤工俭学工作。 SCAD 为国际学生提供一些奖学金； 然而，这些并不能完全支付学费或生活费。 国际学生必须有足够的经济支持来完成整个课程的学习。",
    faq_text_24: "我在哪里可以了解更多关于 ESL 的信息？",
    faq_text_25:
      "SCAD Language Studio 提供适合不同学生需求、水平和熟练程度的英语作为第二语言的教学。",
    faq_text_26: "SCAD 的气候如何？",
    faq_text_27:
      "在萨凡纳，冬季平均气温为9摄氏度，夏季平均气温为27摄氏度，平均降雨量为1244毫米。 " +
      "<br>在亚特兰大，冬季平均气温为5摄氏度，夏季平均气温为26摄氏度，平均降雨量为1295毫米。",
    faq_text_28: "我申请的是本科生。 是否需要作品集？",
    faq_text_29:
      "本科录取不需要作品集，但鼓励您提交一份以供奖学金考虑。 它不必与您的预期专业相关，并且应该包含您最好的作品。",
    faq_text_30: "我如何提交我的本科作品集？",
    faq_text_31:
      "虽然本科录取不需要作品集，但成绩奖学金考虑需要作品集或简历和成就清单。 SCAD 接受多种类型的作品集，包括视觉艺术、时基媒体、写作、马术和表演艺术。 本科作品集不必特定于 SCAD 的预期学习课程； 学生可以选择他们认为可以展示最佳作品的作品集类型。<br>了解有关作品集类型、如何准备作品集和提交指南的提示的更多信息。 学生应通过 SlideRoom 在线提交他们的作品集。 SlideRoom 对提交的每个作品集收取 10 美元的费用。",
  },
  programs: {
    available_at: "所在地： ",
    Atlanta: "亚特兰大",
    Savannah: "萨凡纳",
    eLearning: "SCADnow 在线学习",
    Lacoste: "Lacoste",
  },
  footer: {
    "Request information": "获取更多信息",
    LEARN: "人才培养",
    Undergraduate: "本科生课程",
    Graduate: "研究生课程",
    Apply: "入学申请",
    EXPLORE: "发现更多",
    "SCAD Atlanta": "SCAD 亚特兰大",
    "SCAD Lacoste": "SCAD 拉科斯特",
    "SCAD Savannah": "SCAD 萨凡纳",
    eLearning: "SCADnow 线上学习",
    "Visit SCAD": "访问 SCAD",
    ADMISSION: "招生详情",
    "Admission Events": "招生活动",

    "The University for Creative Careers": "发展艺术与设计潜能的大学",
  },
  home: {
    "Highlighted Academic Programs": "教学设置",
    "Highlighted Locations": "校区分布",
    "Highlighted Awards": "荣誉成就",
    "WHY CHOOSE SCAD": "选择SCAD，成就超“艺”般的你",
    "SCAD is continually":
      "SCAD在国际艺术和设计领域拥有卓越的教育和专业水平，不断赢得认可的我们始终致力于取得最高标准的成就。",
  },
  locations: {
    Select_your_preferred_location: "选择您的首选位置 <br />了解更多信息",
  },
  Academic: {
    text1: "超过 100 门学位课程，满足你所有的艺术激情",
    text2:
      "为了开拓深入艺术创意之旅，SCAD 除了拥有普通大学的本科生和研究生课程，还专门创设了超过 75 个适合青少年和专业证书的课程。在世界各地，SCAD 都拥有学习中心，学生可以在不同国家和城市间进行不间断的学习。想象一下，从中国开始学习，然后在美国获得学位。",
  },
};
