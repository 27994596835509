<template>
  <div>
    <menuBar></menuBar>
    <div class="cust-container">
      <el-row>
        <el-col :span="6" class="locationMenu">
          <div class="grid-content">
            <div class="tips">
              <div
                class="text"
                v-html="$t('locations.Select_your_preferred_location')"
              >
                Select your preferred location <br />for more information
              </div>
            </div>
            <div class="locationMenuList">
              <div
                class="locationMenuItem"
                :class="selectedMenu === 'Atlanta' ? 'active' : ''"
                @click="locationMenuItemClick('Atlanta')"
              >
                <img src="@/assets/images/locations/Atlanta.jpg" alt="" />
              </div>
              <div
                class="locationMenuItem"
                :class="selectedMenu === 'Lacoste' ? 'active' : ''"
                @click="locationMenuItemClick('Lacoste')"
              >
                <img src="@/assets/images/locations/Lacoste.jpg" alt="" />
              </div>
              <div
                class="locationMenuItem"
                :class="selectedMenu === 'Savannah' ? 'active' : ''"
                @click="locationMenuItemClick('Savannah')"
              >
                <img src="@/assets/images/locations/Savannah.jpg" alt="" />
              </div>
              <div
                class="locationMenuItem"
                :class="selectedMenu === 'SCADnow' ? 'active' : ''"
                @click="locationMenuItemClick('SCADnow')"
              >
                <img src="@/assets/images/locations/scadnow2.jpg" alt="" />
              </div>
            </div>
          </div>
        </el-col>
        <el-col class="content" :span="18">
          <atlanta v-if="selectedMenu === 'Atlanta'" :mobile="mobile"></atlanta>
          <Lacoste v-if="selectedMenu === 'Lacoste'" :mobile="mobile"></Lacoste>
          <Savannah
            v-if="selectedMenu === 'Savannah'"
            :mobile="mobile"
          ></Savannah>
          <eLearning
            v-if="selectedMenu === 'SCADnow'"
            :mobile="mobile"
          ></eLearning>
        </el-col>
      </el-row>
    </div>
    <goTop></goTop>
    <custFooter></custFooter>
  </div>
</template>

<script>
import menuBar from "../../components/menuBar/index.vue";
import custFooter from "@/components/footer/Footer";
import atlanta from "@/views/locations/components/atlanta/Atlanta";
import Lacoste from "@/views/locations/components/lacoste/Lacoste";
import Savannah from "@/views/locations/components/savannah/Savannah";
import eLearning from "@/views/locations/components/eLearning/eLearning";
export default {
  // metaInfo: {
  //   title: "我是contact头", // set a title
  //   meta: [
  //     {
  //       // set meta
  //       name: "keyWords",
  //       content: "我是contact关键字",
  //     },
  //     {
  //       name: "description",
  //       content: "我是contact描述",
  //     },
  //   ],
  //   link: [
  //     {
  //       // set link
  //       rel: "asstes",
  //       href: "https://assets-cdn.github.com/",
  //     },
  //   ],
  // },
  metaInfo: {
    title: "SCAD", // set a title
  },
  name: "Locations",
  components: {
    menuBar,
    custFooter,
    atlanta,
    Lacoste,
    Savannah,
    eLearning,
  },
  data: function () {
    return {
      selectedMenu: "atlanta",
      mobile: this.$isMobile(800),
    };
  },
  created() {
    this.selectedMenu = this.$route.params["name"];
    const that = this;
    var query = this.$route.query;
    if (query.l) {
      this.selectedMenu = query.l;
    }
    window.addEventListener("resize", () => {
      //监听浏览器窗口大小改变
      that.mobile = that.$isMobile(800);
    });
  },
  methods: {
    locationMenuItemClick(menu) {
      // this.selectedMenu = menu;
      // this.goTop();
      window.location.href = menu;
    },
    goTop() {
      let timer = null;
      let _this = this;

      cancelAnimationFrame(timer);
      timer = requestAnimationFrame(function fn() {
        _this.scrollTop =
          document.body.scrollTop || document.documentElement.scrollTop;
        if (_this.scrollTop > 5000) {
          _this.scrollTop -= 200;
        } else if (_this.scrollTop > 1000 && _this.scrollTop <= 5000) {
          _this.scrollTop -= 100;
        } else if (_this.scrollTop > 200 && _this.scrollTop <= 1000) {
          _this.scrollTop -= 50;
        } else if (_this.scrollTop > 50 && _this.scrollTop <= 200) {
          _this.scrollTop -= 10;
        } else if (_this.scrollTop > 0 && _this.scrollTop <= 50) {
          _this.scrollTop -= 5;
        } else {
          cancelAnimationFrame(timer);
          return;
        }
        document.body.scrollTop = document.documentElement.scrollTop =
          _this.scrollTop;
        timer = requestAnimationFrame(fn);
      });
    },
  },
};
</script>

<style scoped lang="less">
@import "Locations.less";
</style>
