<template>
  <div>
    <menuBar />
    <banner :mobile="mobile" />
    <recommend :mobile="mobile" />
    <whychooseScad />
    <custFooter />
  </div>
</template>

<script>
import menuBar from "../../components/menuBar/index.vue";
import banner from "./components/Banner";
import recommend from "./components/recommend/Recommend";
import whychooseScad from "./components/whyChooseScad/WhyChooseScad";
import custFooter from "../../components/footer/Footer";

export default {
  metaInfo: {
    title: "SCAD", // set a title
  },
  name: "HomePage",
  components: {
    menuBar,
    banner,
    recommend,
    whychooseScad,
    custFooter,
  },
  data: function () {
    return {
      mobile: false,
    };
  },
  created() {
    window.addEventListener("resize", this.resize);
  },
  destroyed() {
    window.removeEventListener("resize", this.resize);
  },
  methods: {
    resize: function () {
      this.mobile = this.$isMobile(800);
    },
  },
};
</script>

<style scoped></style>
