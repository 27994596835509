<template>
  <div class="programs">
    <menuBar></menuBar>
    <div class="programs-header-container">
      <img
        class="banner"
        src="https://abcam.oss-cn-shanghai.aliyuncs.com/40b69202201191322236564.jpg"
      />
    </div>
    <div
      class="body-container"
      style="padding-bottom: 50px"
      v-if="$i18n.locale === 'zh'"
    >
      <div>
        <h1 class="title">优秀专业排名</h1>

        <div class="content">
          <ul>
            <li>
              <p>
                #1 本科和研究生室内设计课程全美排名第一（Design
                Intelligence设计智造，2020 年）
              </p>
            </li>
            <li><p>#1 塑造时尚未来最佳大学（福布斯，2021 年）</p></li>
            <li>
              <p>
                #1 美国最佳设计大学第一名，全球第二名（2021
                年连续五年红点设计排名）
              </p>
            </li>
            <li>
              <p>
                #1 美国排名前 25 的动画专业硕士（动画职业回顾，连续四年，2021
                年）
              </p>
            </li>
            <li>
              <p>#1 美国最佳制作卓越游戏设计学校排名第一（新秀奖，2020 年）</p>
            </li>
            <li>
              <p>#1 世界排名第一的动态媒体学校（2021 年连续六年新秀奖）</p>
            </li>
            <li>
              <p>
                #1 您应该了解的 5 门顶级声音设计课程中排名第 1（后台奖，2020
                年）
              </p>
            </li>
            <li><p>#1 卓越服务创新与创新影响力奖（ISSIP - 2021 年）</p></li>
            <li><p>#1 全球最佳产品设计学校（2021 年新秀奖）</p></li>
            <li>
              <p>
                SCAD是唯一一所自2018年以来，由好莱坞报道的五项顶尖大学学术排名中均名列前茅的大学，其中包括对服装设计、表演、影视、制作人和视觉效果项目的认可。
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div
      class="body-container"
      style="padding-bottom: 50px"
      v-if="$i18n.locale === 'en'"
    >
      <div>
        <h1 class="title">Top Individual Program Rankings</h1>

        <div class="content">
          <ul>
            <li>
              <p>
                #1 undergraduate and graduate Interior Design programs in the
                U.S. (DesignIntelligence, 2020)
              </p>
            </li>
            <li>
              <p>
                #1 Best Colleges Shaping the Future of Fashion (Forbes, 2021)
              </p>
            </li>
            <li>
              <p>
                #1 Best Design University in the U.S., #2 globally (Red Dot
                Design Rankings, five consecutive years, 2021)
              </p>
            </li>
            <li>
              <p>
                #1 Top 25 MA Animation Programs in the U.S. (Animation Career
                Review, four consecutive years, 2021)
              </p>
            </li>
            <li>
              <p>
                #1 Best Production Excellence Game Design Schools in the U.S.
                (The Rookies, 2020)
              </p>
            </li>
            <li>
              <p>
                #1 Motion Graphics Schools in the World (The Rookies, six
                consecutive years, 2021)
              </p>
            </li>
            <li>
              <p>
                #1in5 Top Sound Design Course You Should Know About(Backstage,
                2020)
              </p>
            </li>
            <li>
              <p>
                #1Excellence in Service Innovation with Impact to Innovation
              </p>
            </li>
            <li>
              <p>
                #1 Best Product Design School in the World(The Rookies, 2021)
              </p>
            </li>
            <li>
              <p>
                SCAD is the ONLY university that has been ranked in each of
                Hollywood Reporter’s FIVE academic rankings for top universities
                since 2018, including recognition for costume design, performing
                arts, film and television, production design, and visual effects
                programs.
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <goTop></goTop>
    <custFooter></custFooter>
  </div>
</template>

<script>
import menuBar from "../../components/menuBar/index.vue";
import custFooter from "@/components/footer/Footer";
import i18n from "@/i18n";
// import router from "@/router";
export default {
  name: "TopIndividualProgramRankings",
  metaInfo: {
    title: i18n.t("Top Individual Program Rankings"), // set a title
  },
  components: {
    menuBar,
    custFooter,
  },
  data: function () {
    return {
      obj: {},
      lang: this.$i18n.locale,
      id: null,
      name: null,
      areaList: [],
    };
  },
  mounted() {},
};
</script>
<style lang="less" scoped>
@import "Programs.less";
</style>

<style>
.content img {
  max-width: 100% !important;
}
</style>
