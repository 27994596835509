<template>
  <div>
    <TFUnderGrad2022EN v-if="$i18n.locale === 'en'"></TFUnderGrad2022EN>
    <TFUnderGrad2022CN v-if="$i18n.locale === 'zh'"></TFUnderGrad2022CN>
  </div>
</template>

<script>
import TFUnderGrad2022EN from "./T-F-Under-Grad-2022-EN.vue";
import TFUnderGrad2022CN from "./T-F-Under-Grad-2022-CN.vue";
export default {
  name: "T-F-Under-Grad-2022",
  components: { TFUnderGrad2022EN, TFUnderGrad2022CN },
};
</script>

<style scoped></style>
