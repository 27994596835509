const obj = [
  { name: "China", value: "China" },
  { name: "Abu Dhabi", value: "Abu Dhabi" },
  { name: "Afghanistan", value: "Afghanistan" },
  { name: "Albania", value: "Albania" },
  { name: "Algeria", value: "Algeria" },
  { name: "American Samoa", value: "American Samoa" },
  { name: "Andorra", value: "Andorra" },
  { name: "Angola", value: "Angola" },
  { name: "Anguilla", value: "Anguilla" },
  { name: "Antarctica", value: "Antarctica" },
  { name: "Antigua and Barbuda", value: "Antigua and Barbuda" },
  { name: "Argentina", value: "Argentina" },
  { name: "Armenia", value: "Armenia" },
  { name: "Aruba", value: "Aruba" },
  { name: "Ashmore and Cartier Islands", value: "Ashmore and Cartier Islands" },
  { name: "Australia", value: "Australia" },
  { name: "Austria", value: "Austria" },
  { name: "Azerbaijan", value: "Azerbaijan" },
  { name: "Azores", value: "Azores" },
  { name: "Bahamas", value: "Bahamas" },
  { name: "Bahrain", value: "Bahrain" },
  { name: "Baker Island", value: "Baker Island" },
  { name: "Balearic Islands", value: "Balearic Islands" },
  { name: "Bangladesh", value: "Bangladesh" },
  { name: "Barbados", value: "Barbados" },
  { name: "Bassa Da India", value: "Bassa Da India" },
  { name: "Belarus", value: "Belarus" },
  { name: "Belgium", value: "Belgium" },
  { name: "Belize", value: "Belize" },
  { name: "Benin", value: "Benin" },
  { name: "Bermuda", value: "Bermuda" },
  { name: "Bhutan", value: "Bhutan" },
  { name: "Bolivia", value: "Bolivia" },
  { name: "Bonaire", value: "Bonaire" },
  { name: "Bosnia-Herzegovina", value: "Bosnia-Herzegovina" },
  { name: "Botswana", value: "Botswana" },
  { name: "Bouvet Island", value: "Bouvet Island" },
  { name: "Brazil", value: "Brazil" },
  {
    name: "British Indian Ocean Territory",
    value: "British Indian Ocean Territory",
  },
  { name: "Brunei Darussalam", value: "Brunei Darussalam" },
  { name: "Bulgaria", value: "Bulgaria" },
  { name: "Burkina Faso", value: "Burkina Faso" },
  { name: "Burma (Myanmar)", value: "Burma (Myanmar)" },
  { name: "Burundi", value: "Burundi" },
  { name: "Cambodia", value: "Cambodia" },
  { name: "Cameroon", value: "Cameroon" },
  { name: "Canada", value: "Canada" },
  { name: "Canary Islands", value: "Canary Islands" },
  { name: "Cape Verde", value: "Cape Verde" },
  { name: "Cayman Islands", value: "Cayman Islands" },
  { name: "Central African Republic", value: "Central African Republic" },
  { name: "Chad", value: "Chad" },
  { name: "Chile", value: "Chile" },
  { name: "Christmas Island", value: "Christmas Island" },
  { name: "Clipperton Island", value: "Clipperton Island" },
  { name: "Cocos (Keeling) Islands", value: "Cocos (Keeling) Islands" },
  { name: "Colombia", value: "Colombia" },
  { name: "Comoros", value: "Comoros" },
  { name: "Congo", value: "Congo" },
  { name: "Cook Islands", value: "Cook Islands" },
  { name: "Coral Sea Islands Territory", value: "Coral Sea Islands Territory" },
  { name: "Corsica", value: "Corsica" },
  { name: "Costa Rica", value: "Costa Rica" },
  { name: "Cote D Ivoire", value: "Cote D Ivoire" },
  { name: "Croatia", value: "Croatia" },
  { name: "Cuba", value: "Cuba" },
  { name: "Curacao", value: "Curacao" },
  { name: "Cyprus", value: "Cyprus" },
  { name: "Czech Republic", value: "Czech Republic" },
  { name: "Denmark", value: "Denmark" },
  { name: "Djibouti", value: "Djibouti" },
  { name: "Dominica", value: "Dominica" },
  { name: "Dominican Republic", value: "Dominican Republic" },
  { name: "Dubai", value: "Dubai" },
  { name: "East Timor", value: "East Timor" },
  { name: "Ecuador", value: "Ecuador" },
  { name: "Egypt", value: "Egypt" },
  { name: "El Salvador", value: "El Salvador" },
  { name: "Eleuthera Island", value: "Eleuthera Island" },
  { name: "Equatorial Guinea", value: "Equatorial Guinea" },
  { name: "Ertrea", value: "Ertrea" },
  { name: "Estonia", value: "Estonia" },
  { name: "Ethiopia", value: "Ethiopia" },
  { name: "Europa Island", value: "Europa Island" },
  { name: "Falkland Islands", value: "Falkland Islands" },
  { name: "Faroe Islands", value: "Faroe Islands" },
  { name: "Fiji", value: "Fiji" },
  { name: "Finland", value: "Finland" },
  { name: "France", value: "France" },
  { name: "French Guiana", value: "French Guiana" },
  { name: "French Polynesia", value: "French Polynesia" },
  {
    name: "French Southern/Antartic Lands",
    value: "French Southern/Antartic Lands",
  },
  { name: "Gabon", value: "Gabon" },
  { name: "Gambia", value: "Gambia" },
  { name: "Gaza Strip", value: "Gaza Strip" },
  { name: "Georgia", value: "Georgia" },
  { name: "Germany", value: "Germany" },
  { name: "Ghana", value: "Ghana" },
  { name: "Gibraltar", value: "Gibraltar" },
  { name: "Glorioso Islnds", value: "Glorioso Islnds" },
  { name: "Greece", value: "Greece" },
  { name: "Greenland", value: "Greenland" },
  { name: "Grenada", value: "Grenada" },
  { name: "Guadeloupe", value: "Guadeloupe" },
  { name: "Guam", value: "Guam" },
  { name: "Guatemala", value: "Guatemala" },
  { name: "Guernsey", value: "Guernsey" },
  { name: "Guinea", value: "Guinea" },
  { name: "Guinea-Bissau", value: "Guinea-Bissau" },
  { name: "Guyana", value: "Guyana" },
  { name: "Haiti", value: "Haiti" },
  { name: "Heard/McDonald Islands", value: "Heard/McDonald Islands" },
  { name: "Honduras", value: "Honduras" },
  { name: "Hong Kong", value: "Hong Kong" },
  { name: "Howland Island", value: "Howland Island" },
  { name: "Hungary", value: "Hungary" },
  { name: "Iceland", value: "Iceland" },
  { name: "India", value: "India" },
  { name: "Indonesia", value: "Indonesia" },
  { name: "Iran (Islamic Republic of)", value: "Iran (Islamic Republic of)" },
  { name: "Iraq", value: "Iraq" },
  { name: "Ireland", value: "Ireland" },
  { name: "Isle of Man", value: "Isle of Man" },
  { name: "Israel", value: "Israel" },
  { name: "Italy", value: "Italy" },
  { name: "Jamaica", value: "Jamaica" },
  { name: "Jan Mayen", value: "Jan Mayen" },
  { name: "Japan", value: "Japan" },
  { name: "Jarvis Island", value: "Jarvis Island" },
  { name: "Jersey", value: "Jersey" },
  { name: "JohnstonAtoll", value: "JohnstonAtoll" },
  { name: "Jordan", value: "Jordan" },
  { name: "Juan de Nova Island", value: "Juan de Nova Island" },
  { name: "Kazakhstan", value: "Kazakhstan" },
  { name: "Kenya", value: "Kenya" },
  { name: "Kingman Reef", value: "Kingman Reef" },
  { name: "Kiribati", value: "Kiribati" },
  { name: "Kosovo", value: "Kosovo" },
  { name: "Kurile", value: "Kurile" },
  { name: "Kuwait", value: "Kuwait" },
  { name: "Kyrgyzstan", value: "Kyrgyzstan" },
  {
    name: "Lao People Democratic Republic",
    value: "Lao People Democratic Republic",
  },
  { name: "Latvia", value: "Latvia" },
  { name: "Lebanon", value: "Lebanon" },
  { name: "Lesotho", value: "Lesotho" },
  { name: "Liberia", value: "Liberia" },
  { name: "Libyan Arab Jamahiriya", value: "Libyan Arab Jamahiriya" },
  { name: "Liechtenstein", value: "Liechtenstein" },
  { name: "Lithuania", value: "Lithuania" },
  { name: "Luxembourg", value: "Luxembourg" },
  { name: "Macau", value: "Macau" },
  { name: "Macedonia", value: "Macedonia" },
  { name: "Madagascar", value: "Madagascar" },
  { name: "Malawi", value: "Malawi" },
  { name: "Malaysia", value: "Malaysia" },
  { name: "Maldives", value: "Maldives" },
  { name: "Mali", value: "Mali" },
  { name: "Malta", value: "Malta" },
  { name: "Marshall Islands", value: "Marshall Islands" },
  { name: "Martinique", value: "Martinique" },
  { name: "Mauritania", value: "Mauritania" },
  { name: "Mauritius", value: "Mauritius" },
  { name: "Mayotte", value: "Mayotte" },
  { name: "Mexico", value: "Mexico" },
  {
    name: "Micronesia,Federated States of",
    value: "Micronesia,Federated States of",
  },
  { name: "Midway Islands", value: "Midway Islands" },
  { name: "Moldova", value: "Moldova" },
  { name: "Monaco", value: "Monaco" },
  { name: "Mongolia", value: "Mongolia" },
  { name: "Montenegro", value: "Montenegro" },
  { name: "Montenegro (prior to 2001)", value: "Montenegro (prior to 2001)" },
  { name: "Montserrat", value: "Montserrat" },
  { name: "Morocco", value: "Morocco" },
  { name: "Mozambique", value: "Mozambique" },
  { name: "Namibia", value: "Namibia" },
  { name: "Nauru", value: "Nauru" },
  { name: "Navassa Island", value: "Navassa Island" },
  { name: "Nepal", value: "Nepal" },
  { name: "Netherlands", value: "Netherlands" },
  { name: "Netherlands Antilles", value: "Netherlands Antilles" },
  { name: "New Caledonia", value: "New Caledonia" },
  { name: "New Zealand", value: "New Zealand" },
  { name: "Nicaragua", value: "Nicaragua" },
  { name: "Niger", value: "Niger" },
  { name: "Nigeria", value: "Nigeria" },
  { name: "Niue", value: "Niue" },
  { name: "Norfolk Island", value: "Norfolk Island" },
  { name: "North Korea", value: "North Korea" },
  { name: "Northern Ireland", value: "Northern Ireland" },
  { name: "Norway", value: "Norway" },
  { name: "Oman", value: "Oman" },
  { name: "Pakistan", value: "Pakistan" },
  { name: "Palau", value: "Palau" },
  { name: "Palmyra Atoll", value: "Palmyra Atoll" },
  { name: "Panama", value: "Panama" },
  { name: "Papua New Guinea", value: "Papua New Guinea" },
  { name: "Paracel Islands", value: "Paracel Islands" },
  { name: "Paraguay", value: "Paraguay" },
  { name: "Peru", value: "Peru" },
  { name: "Philippines", value: "Philippines" },
  { name: "Pitcairn Island", value: "Pitcairn Island" },
  { name: "Poland", value: "Poland" },
  { name: "Portugal", value: "Portugal" },
  { name: "Puerto Rico", value: "Puerto Rico" },
  { name: "Qatar", value: "Qatar" },
  { name: "Redonda", value: "Redonda" },
  { name: "Reunion", value: "Reunion" },
  { name: "Romania", value: "Romania" },
  { name: "Russian Federation", value: "Russian Federation" },
  { name: "Rwanda", value: "Rwanda" },
  { name: "Ryukyu Islands", value: "Ryukyu Islands" },
  { name: "Saint Kitts and Nevis", value: "Saint Kitts and Nevis" },
  { name: "Saint Lucia", value: "Saint Lucia" },
  {
    name: "Saint Vincent and Grenadines",
    value: "Saint Vincent and Grenadines",
  },
  { name: "San Marino", value: "San Marino" },
  { name: "Sao Tome and Principe", value: "Sao Tome and Principe" },
  { name: "Sarawak", value: "Sarawak" },
  { name: "Saudi Arabia", value: "Saudi Arabia" },
  { name: "Senegal", value: "Senegal" },
  { name: "Serbia", value: "Serbia" },
  { name: "Serbia (prior to 2001)", value: "Serbia (prior to 2001)" },
  { name: "Serbia and Montenegro", value: "Serbia and Montenegro" },
  { name: "Seychelles", value: "Seychelles" },
  { name: "Sierra Leone", value: "Sierra Leone" },
  { name: "Singapore", value: "Singapore" },
  { name: "Slovakia", value: "Slovakia" },
  { name: "Slovenia", value: "Slovenia" },
  { name: "Solomon Islands", value: "Solomon Islands" },
  { name: "Somalia", value: "Somalia" },
  { name: "South Africa", value: "South Africa" },
  { name: "South Georgia", value: "South Georgia" },
  { name: "South Korea", value: "South Korea" },
  { name: "Spain", value: "Spain" },
  { name: "Spratly Islands", value: "Spratly Islands" },
  { name: "Sri Lanka", value: "Sri Lanka" },
  { name: "St. Helena", value: "St. Helena" },
  { name: "St.Pierre and Miquelon", value: "St.Pierre and Miquelon" },
  { name: "Sudan", value: "Sudan" },
  { name: "Suriname", value: "Suriname" },
  { name: "Svalbard (Spitsbergen)", value: "Svalbard (Spitsbergen)" },
  { name: "Swaziland", value: "Swaziland" },
  { name: "Sweden", value: "Sweden" },
  { name: "Switzerland", value: "Switzerland" },
  { name: "Syrian Arab Republic", value: "Syrian Arab Republic" },
  { name: "Tajikistan", value: "Tajikistan" },
  { name: "Tanzania", value: "Tanzania" },
  { name: "Thailand", value: "Thailand" },
  { name: "Togo", value: "Togo" },
  { name: "Tokelau", value: "Tokelau" },
  { name: "Tonga", value: "Tonga" },
  { name: "Tortola", value: "Tortola" },
  { name: "Trinidad and Tobago", value: "Trinidad and Tobago" },
  { name: "Tromelin Island", value: "Tromelin Island" },
  { name: "Tunisia", value: "Tunisia" },
  { name: "Turkey", value: "Turkey" },
  { name: "Turkmenistan", value: "Turkmenistan" },
  { name: "Turks and Caicos Island", value: "Turks and Caicos Island" },
  { name: "Tuvalu", value: "Tuvalu" },
  { name: "Uganda", value: "Uganda" },
  { name: "Ukraine", value: "Ukraine" },
  { name: "United Arab Emirates", value: "United Arab Emirates" },
  { name: "United Kingdom  selected=", value: "United Kingdom  selected=" },
  { name: "United States of America", value: "United States of America" },
  { name: "Uruguay", value: "Uruguay" },
  { name: "Uzbekistan", value: "Uzbekistan" },
  { name: "Vanuatu", value: "Vanuatu" },
  { name: "Vatican City", value: "Vatican City" },
  { name: "Venezuela", value: "Venezuela" },
  { name: "Viet Nam", value: "Viet Nam" },
  { name: "Virgin Islands (British)", value: "Virgin Islands (British)" },
  { name: "Virgin Islands (U.S.)", value: "Virgin Islands (U.S.)" },
  { name: "Wake Island", value: "Wake Island" },
  { name: "Wallis and Futuna", value: "Wallis and Futuna" },
  { name: "West Bank", value: "West Bank" },
  { name: "Western Sahara", value: "Western Sahara" },
  { name: "Western Samoa", value: "Western Samoa" },
  { name: "Windward Island", value: "Windward Island" },
  { name: "Yemen", value: "Yemen" },
  { name: "Yugoslavia", value: "Yugoslavia" },
  { name: "Zaire", value: "Zaire" },
  { name: "Zambia", value: "Zambia" },
  { name: "Zimbabwe", value: "Zimbabwe" },
];

export default obj;
