<template>
  <div class="cust-container section-life">
    <menuBar></menuBar>
    <div class="english-language-support-content">
      <div
        class="
          container-fluid
          margin-bottom-all-10
          nopadding-bottom nopadding-top
          hero-image
        "
        style="background: #0b7963"
      >
        <div
          id="Swarm-Full-Width-Panel"
          class="margin-bottom-all-10 nopadding-bottom nopadding-top hero-image"
        >
          <div class="row">
            <div class="outer-region panel-row">
              <div class="inner-region">
                <div
                  class="
                    panel-panel
                    row_1_left
                    col-sm-12 col-sm-offset-0 col-md-12 col-md-offset-0
                  "
                >
                  <div
                    class="
                      panel-pane
                      pane-entity-field pane-node-field-regional-images
                      region-hero-container
                    "
                  >
                    <div class="pane-content">
                      <div class="item">
                        <picture
                          class="img-responsive"
                          style="
                            position: relative;
                            margin: auto;
                            overflow: hidden;
                          "
                        >
                          <img
                            class="img-responsive"
                            src="@/assets/images/englishLanguageSupport/banner.jpg"
                            alt="SCAD Language Studio"
                            title=""
                            style="max-width: initial; width: 100%"
                          />

                          <!--<div style="height: 30vw; position: relative"></div>-->
                          <div class="text-overlay">
                            <div class="pane-content">
                              <h1 class="rtecenter main-resizer">
                                English language support
                              </h1>
                            </div>
                          </div>
                        </picture>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        id="Swarm-Full-Width-Panel"
        class="
          container
          margin-top-xs-30 margin-top-sm-50
          padding-top-sm-20
          margin-bottom-all-50
        "
      >
        <div class="row">
          <div class="outer-region panel-row">
            <div class="inner-region">
              <div
                class="
                  panel-panel
                  row_5_left
                  col-sm-3 col-sm-offset-1 col-md-3 col-md-offset-1
                "
              >
                <div class="panel-pane pane-custom pane-8">
                  <div class="pane-content">
                    <p class="rtecenter chat-icon" style="">
                      <svg
                        class="svg-icon-large svg-theme-section"
                        viewBox="0 2 40 40"
                      >
                        <use
                          src="@/assets/scad-icons.svg#SCAD-International-Chat-icon"
                          xlink:href="@/assets/scad-icons.svg#SCAD-International-Chat-icon"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                        ></use>
                      </svg>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="inner-region">
              <div
                class="
                  panel-panel
                  row_5_center_left
                  col-sm-7 col-sm-offset-0 col-md-7 col-md-offset-0
                "
              >
                <div
                  class="
                    panel-pane
                    pane-custom pane-7
                    margin-bottom-sm-50 margin-bottom-md-20
                  "
                >
                  <div class="pane-content">
                    <h2>
                      Become fluent through English as a Second Language (ESL)
                    </h2>
                    <p>
                      The SCAD Language Studio offers the English as a Second
                      Language (ESL) program to give you the confidence and
                      skills you need to pursue the academic programs at SCAD.
                      After taking an English proficiency test, you will be
                      placed in one of six ESL levels.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container-fluid" style="background: #f7f7f7">
        <div id="Swarm-Full-Width-Panel" class="container">
          <div class="row">
            <div class="outer-region panel-row">
              <div class="inner-region">
                <div
                  class="
                    panel-panel
                    row_7_left
                    col-sm-4 col-sm-offset-1 col-md-4 col-md-offset-1
                  "
                >
                  <div
                    class="
                      panel-pane
                      pane-entity-field pane-node-field-regional-images
                      overlay-box
                    "
                  >
                    <div class="pane-content">
                      <div class="item">
                        <picture class="img-responsive">
                          <img
                            class="img-responsive"
                            src="../../assets/images/englishLanguageSupport/ESL-Kenneth-Barnes-Savannah-Spring-2015-SK_3_SN.webp"
                            alt="ESL"
                            title=""
                          />
                        </picture>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="inner-region">
                <div
                  class="
                    panel-panel
                    row_7_center_left
                    col-sm-6 col-sm-offset-0 col-md-6 col-md-offset-0
                  "
                >
                  <div
                    class="
                      panel-pane
                      pane-custom pane-9
                      margin-top-sm-30 margin-top-md-50
                    "
                  >
                    <div class="pane-content">
                      <h2>Learn through real-world experiences</h2>
                      <p>
                        By enrolling in ESL classes, you can master the English
                        language through authentic studio experiences and
                        collaborative, engaging fieldwork.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="
          container-fluid
          padding-top-all-10 padding-bottom-all-30 padding-top-sm-10
        "
        style="background: #f7f7f7"
      >
        <div
          id="Swarm-Full-Width-Panel"
          class="
            container
            padding-top-all-10 padding-bottom-all-30 padding-top-sm-10
          "
        >
          <div class="row">
            <div class="outer-region panel-row">
              <div class="inner-region">
                <div
                  class="
                    panel-panel
                    row_9_left
                    col-sm-4 col-sm-offset-0 col-md-4 col-md-offset-0
                  "
                >
                  <div class="panel-pane pane-custom pane-12">
                    <div class="pane-content">
                      <p class="rtecenter" style="">
                        <span
                          aria-label="Books"
                          class="svg-circle-bg load-modal-window"
                          href="/life/international-student-life/english-language-support/english-assessment"
                          style="background: #ffffff !important"
                          ><svg
                            class="svg-icon svg-theme-section"
                            viewBox="0 0 40 30"
                          >
                            <use
                              src="@/assets/scad-icons.svg#SCAD-admission-Programs-icon"
                              xlink:href="@/assets/scad-icons.svg#SCAD-admission-Programs-icon"
                              xmlns:xlink="http://www.w3.org/1999/xlink"
                            ></use></svg
                        ></span>
                      </p>
                      <h3>English assessment</h3>
                      <p>
                        All non-native English-speaking students who are not
                        eligible for an ESL waiver are required to take a
                        placement test administered by the SCAD Language Studio.
                      </p>
                      <p>
                        <a
                          class="load-modal-window"
                          href="javascript:void()"
                          @click.stop="dialogEnglishAssessment = true"
                          >Learn about the English assessment</a
                        >
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="inner-region">
                <div
                  class="
                    panel-panel
                    row_9_center_left
                    col-sm-4 col-sm-offset-0 col-md-4 col-md-offset-0
                  "
                >
                  <div class="panel-pane pane-custom pane-10">
                    <div class="pane-content">
                      <p class="rtecenter" style="">
                        <span
                          aria-label="Books"
                          class="svg-circle-bg load-modal-window"
                          href="/life/international-student-life/english-language-support/esl-classes"
                          style="background: #ffffff !important"
                          ><svg
                            class="svg-icon svg-theme-section"
                            viewBox="0 0 40 40"
                          >
                            <use
                              src="@/assets/scad-icons.svg#SCAD-CAPP-review"
                              xlink:href="@/assets/scad-icons.svg#SCAD-CAPP-review"
                              xmlns:xlink="http://www.w3.org/1999/xlink"
                            ></use></svg
                        ></span>
                      </p>
                      <h3>Classes</h3>
                      <p>
                        The ESL program includes a variety of undergraduate and
                        graduate classes focused on reading, writing and grammar
                        proficiency as well as advanced presentation,
                        pronunciation and research skills necessary for a career
                        in art and design.
                      </p>
                      <p>
                        <a
                          class="load-modal-window"
                          href="javascript:void()"
                          @click.stop="dialogReviewclasses = true"
                          >Review classes</a
                        >
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="inner-region">
                <div
                  class="
                    panel-panel
                    row_9_center_right
                    col-sm-4 col-sm-offset-0 col-md-4 col-md-offset-0
                  "
                >
                  <div class="panel-pane pane-custom pane-11">
                    <div class="pane-content">
                      <p class="rtecenter" style="">
                        <span
                          aria-label="Books"
                          class="svg-circle-bg load-modal-window"
                          href="mailto:sls@scad.edu"
                          style="background: #ffffff !important"
                          ><svg
                            class="svg-icon svg-theme-section"
                            viewBox="0 0 40 40"
                          >
                            <use
                              src="@/assets/scad-icons.svg#SCAD-admission-ELP-icon"
                              xlink:href="@/assets/scad-icons.svg#SCAD-admission-ELP-icon"
                              xmlns:xlink="http://www.w3.org/1999/xlink"
                            ></use></svg
                        ></span>
                      </p>
                      <h3>SCAD English Language Program</h3>
                      <p>
                        Designed for non-degree seeking students, the SCAD
                        English Language Program is ideal for those interested
                        in developing their English to further professional and
                        personal goals.
                      </p>
                      <p>
                        <a href="mailto:sls@scad.edu"
                          >Email sls@scad.edu to learn more</a
                        >
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="Swarm-Full-Width-Panel "
        class="container chat-icon"
        style="height: 100px"
      ></div>
      <div
        class="container-fluid padding-top-all-30 padding-bottom-all-20"
        style="background: #f7f7f7"
      >
        <div
          id="Swarm-Full-Width-Panel"
          class="container padding-top-all-30 padding-bottom-all-20"
        >
          <div class="row">
            <div class="outer-region panel-row">
              <div class="inner-region">
                <div
                  class="
                    panel-panel
                    row_13_left
                    col-sm-6 col-sm-offset-0 col-md-5 col-md-offset-0
                  "
                >
                  <div class="panel-pane pane-custom pane-1">
                    <div class="pane-content">
                      <h2>
                        Master invaluable skills through SCAD Language Studio
                      </h2>
                      <p>
                        The SCAD Language Studio oversees every language program
                        at SCAD, including free individual tutoring based on
                        assessment results and instructor feedback. The SCAD
                        Language Studio helps students:
                      </p>
                      <ul>
                        <li>Write academic papers</li>
                        <li>Make formal presentations</li>
                        <li>Read university-level materials</li>
                        <li>Comprehend academic lectures</li>
                        <li>
                          Speak fluently in both formal and informal settings
                        </li>
                        <li>Understand U.S. culture</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div class="inner-region">
                <div
                  class="
                    panel-panel
                    row_13_center_left
                    col-sm-6 col-sm-offset-0 col-md-7 col-md-offset-0
                  "
                >
                  <div class="panel-pane pane-node">
                    <div class="pane-content">
                      <div class="borderless-block">
                        <div class="video-medium">
                          <div class="image">
                            <a
                              href="javascript:void(0)"
                              aria-label="Play Video"
                            >
                              <div
                                class="
                                  field
                                  field-name-field-thumbnail-image
                                  field-type-image
                                  field-label-hidden
                                "
                              >
                                <div class="field-items">
                                  <div class="field-item even">
                                    <picture class="img-responsive">
                                      <!--[if IE 9]><video style="display: none;"><![endif]-->
                                      <source
                                        srcset="
                                          https://www.scad.edu/sites/default/files/styles/swarm16x9_768/public/Video/Video%20Thumbnails/SCAD-Language-Studio-video.jpg?itok=5_up5YdH&amp;timestamp=1461247491 1x
                                        "
                                        media="(min-width: 1200px)"
                                      />
                                      <source
                                        srcset="
                                          https://www.scad.edu/sites/default/files/styles/swarm16x9_768/public/Video/Video%20Thumbnails/SCAD-Language-Studio-video.jpg?itok=5_up5YdH&amp;timestamp=1461247491 1x
                                        "
                                        media="(min-width: 992px)"
                                      />
                                      <source
                                        srcset="
                                          https://www.scad.edu/sites/default/files/styles/swarm16x9_768/public/Video/Video%20Thumbnails/SCAD-Language-Studio-video.jpg?itok=5_up5YdH&amp;timestamp=1461247491 1x
                                        "
                                        media="(min-width: 768px)"
                                      />
                                      <source
                                        srcset="
                                          https://www.scad.edu/sites/default/files/styles/swarm16x9_768/public/Video/Video%20Thumbnails/SCAD-Language-Studio-video.jpg?itok=5_up5YdH&amp;timestamp=1461247491 1x
                                        "
                                        media="(min-width: 0px)"
                                      />
                                      <!--[if IE 9]></video><![endif]-->
                                      <img
                                        class="img-responsive"
                                        src="https://www.scad.edu/sites/default/files/styles/swarm16x9_768/public/Video/Video%20Thumbnails/SCAD-Language-Studio-video.jpg?itok=5_up5YdH&amp;timestamp=1461247491"
                                        alt=""
                                        title=""
                                      />
                                    </picture>
                                  </div>
                                </div>
                              </div>
                              <span class="scadtv-icon scadtv-icon-play"></span>
                            </a>
                          </div>
                          <div class="video-element">
                            <div
                              class="embed-responsive embed-responsive-16by9"
                            >
                              <iframe
                                src="//www.youtube.com/embed/MNnLdqkTwbw?rel=0"
                                frameborder="0"
                                width="500px"
                                height="400px"
                                webkitallowfullscreen=""
                                mozallowfullscreen=""
                                allowfullscreen=""
                              ></iframe>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <el-dialog :visible.sync="dialogEnglishAssessment">
      <div>
        <div>
          <h1>English assessment</h1>
          <p>
            Evidence of English proficiency for students whose first language is
            not English.
          </p>
        </div>

        <div>
          <h2>English proficiency requirements</h2>
          <p>
            English is the language of instruction for all classes at SCAD.
            Students are required to demonstrate their current level of
            proficiency to receive an ESL waiver or take a placement test
            administered by the SCAD Language Studio during international
            orientation.
          </p>

          <h2>ESL waiver</h2>
          <p>
            ESL courses may be waived upon successful performance on the ESL
            proficiency test or if one of the following conditions is met and
            supporting documentation is provided:
          </p>
          <ul>
            <li>The student's first language is English.</li>
            <li>
              The undergraduate student has received a final credential diploma
              or degree from another college or university where English is the
              official language of instruction. Documentation of the official
              language of instruction is required.
            </li>
            <li>
              The graduate student has earned an undergraduate or graduate
              degree from a college or university where English is the official
              language of instruction. Documentation of the official language of
              instruction is required.
            </li>
            <li>
              The student has a Hong Kong Diploma of Secondary Education (HKDSE)
              score of at least 5 in the English language.
            </li>
            <li>
              The undergraduate student has passed the equivalent of English
              123/193 at an accredited/recognized college or university with a
              "C" (2.0/4.0 scale) or better; or qualifies for the equivalent
              transfer credit for English 123/193 through accepted IB, AP, CAPE,
              A-Level or CLEP test scores. Please refer to policies on transfer
              credits for required scores on the appropriate test.
            </li>
            <li>
              The student is a Fulbright/Humphrey exchange scholar or is a
              participant in the Taiwan Ministry of Education Scholarship
              Program for overseas study in art and design.
            </li>
            <li>
              The undergraduate student has completed first grade through high
              school graduation (1 to 12/13 years) at a recognized international
              school or other academic institution in which English is the
              language of instruction. Documentation of all 12/13 academic years
              is required.
            </li>
            <li>
              The student has a Scholastic Aptitude Test (SAT) score of at least
              550 on the critical reading portion or 22 on both the reading and
              writing sections of the ACT.
            </li>
            <li>
              The student has a Graduate Record Examination (GRE) verbal section
              score of at least 500 (for tests taken before Aug. 1, 2011) or 153
              (for tests taken after Aug. 1, 2011).
            </li>
            <li>
              The student has Test of English as a Foreign Language (TOEFL)
              minimum scores (SCAD code 5631):
            </li>
            <li style="list-style: circle; margin-left: 20px">
              550 on the paper-based test.
            </li>
            <li style="list-style: circle; margin-left: 20px">
              85 on the Internet-based test with a minimum score of 20 on each
              section.
            </li>
            <li>
              The student has an International English Language Testing System
              (IELTS) composite score of at least 6.5 with a score of at least
              6.5 in both reading and writing.
            </li>
            <li>
              The student has an American Council on the Teaching of Foreign
              Languages (ACTFL) score of at least "advanced high" on the writing
              proficiency portion of the test.
            </li>
            <li>
              The student has passed the Pearson's PTE Academic with an overall
              score of 58 with a 53 minimum in each category.
            </li>
          </ul>
          <p>
            Students who meet the minimum required composite score but who do
            not meet the minimum section scores are evaluated on a case-by-case
            basis for possible exemption. No exceptions are made if the minimum
            composite score is not met. All decisions are final.
          </p>
          <p>
            Any student who is provided an ESL waiver or exemption and fails any
            single course due to language deficiencies (as determined by the
            director of the SCAD Language Studio and the academic department
            chair) may be required to submit to an English language placement
            test. Results of the English language placement test may be used to
            determine what level or levels of ESL instruction if any, are
            required.
          </p>

          <h2>ESL placement test</h2>
          <p>
            Otherwise qualified applicants who do not meet the minimum scores
            for an ESL waiver may be considered for admission to SCAD. These
            students are required to take a placement test administered by the
            SCAD Language Studio, regardless of other test scores.
          </p>
          <p>
            Accuplacer ESL tests assess student skills and determine the
            necessity of ESL classes and placement into the appropriate level of
            ESL upon enrollment. The two-hour test consists of 20 reading skills
            questions, 20 usage and sentence structure questions, and a timed,
            written essay or listening diagnostic. Supporting documentation is
            provided two weeks before enrollment.
          </p>
          <p>
            Students must satisfactorily complete ESL coursework through
            completion of Level VI.
          </p>
        </div>
      </div>
    </el-dialog>

    <el-dialog :visible.sync="dialogReviewclasses">
      <div>
        <div>
          <h1>ESL classes</h1>
        </div>

        <div>
          <h2>English as a Second Language course offerings</h2>
          <p>
            ESL students at SCAD gain the skills and confidence that enable them
            to be successful at the university and in their careers. Through
            instruction appropriate to varied student needs, levels and
            proficiencies, students master reading, writing, speaking and
            pronunciation before advancing to academic programs at SCAD.
          </p>
          <p>
            Completion of ESL course lengthens the time degree-seeking students
            must commit to completing courses of study and increases overall
            student cost. For more information,
            <a
              target="_blank"
              href="https://www.scad.edu/admission/tuition-and-fees"
              >view our tuition and fees</a
            >.
          </p>
          <br />
          <h3>Undergraduate</h3>
          <h4>Level I</h4>
          <p>ESL 160 Foundational Reading, Writing and Grammar</p>
          <p>ESL 170 Foundational Listening, Speaking and Pronunciation</p>
          <h4>Level II</h4>
          <p>ESL 260 Intensive Reading, Writing and Grammar</p>
          <p>ESL 270 Intensive Listening, Speaking and Pronunciation</p>
          <h4>Level III</h4>
          <p>ESL 360 Academic Reading, Writing and Grammar</p>
          <p>ESL 370 Academic Listening, Speaking and Pronunciation</p>
          <h4>Level IV</h4>
          <p>ESL 460 Reading, Writing and Grammar in Art and Design</p>
          <p>ESL 470 Listening, Speaking and Pronunciation in Art and Design</p>
          <h4>Level V</h4>
          <p>
            ESL 560 Integrated Reading, Writing and Grammar in Art and Design
          </p>
          <p>
            ESL 570 Integrated Listening, Speaking and Pronunciation in Art and
            Design
          </p>
          <p>DRAW of DSGN course</p>
          <h4>Level VI</h4>
          <p>ESL 601 English for Academic Success</p>
          <p>ESL 670 Presentation and Pronuniation in Art and Design</p>
          <p>
            ESL 123 Ink to Ideas: Critical Concepts in Literature and Writing
          </p>
          <p>DRAW or DSGN course</p>
          <br />
          <h3>Graduate</h3>
          <h4>Level I</h4>
          <p>ESL 160 Foundational Reading, Writing and Grammar</p>
          <p>ESL 170 Foundational Listening, Speaking and Pronunciation</p>
          <h4>Level II</h4>
          <p>ESL 260 Intensive Reading, Writing and Grammar</p>
          <p>ESL 270 Intensive Listening, Speaking and Pronunciation</p>
          <h4>Level III</h4>
          <p>ESL 360 Academic Reading, Writing and Grammar</p>
          <p>ESL 370 Academic Listening, Speaking and Pronunciation</p>
          <h4>Level IV</h4>
          <p>ESL 460 Reading, Writing and Grammar in Art and Design</p>
          <p>ESL 470 Listening, Speaking and Pronunciation in Art and Design</p>
          <h4>Level V</h4>
          <p>
            ESL 570 Integrated Listening, Speaking and Pronunciation in Art and
            Design
          </p>
          <p>ESL 580 Graduate Reading, Writing and Grammar in Art and Design</p>
          <p>500- to 700-level studio course</p>
          <h4>Level VI</h4>
          <p>ESL 670 Presentation and Pronunciation in Art and Design</p>
          <p>ESL 680 Research, Reading and Writing in Art and Design</p>
          <p>500- to 700-level studio course</p>
        </div>
      </div>
    </el-dialog>
    <goTop></goTop>
    <custFooter></custFooter>
  </div>
</template>

<script>
import menuBar from "../../components/menuBar/index.vue";
import custFooter from "@/components/footer/Footer";
export default {
  metaInfo: {
    title: "SCAD", // set a title
  },
  name: "EnglishLanguageSupport",
  components: {
    menuBar,
    custFooter,
  },
  data: function () {
    return {
      dialogEnglishAssessment: false,
      dialogReviewclasses: false,
    };
  },
};
</script>

<style lang="css" src="./els-css/typography.gotham.css" scoped />
<style lang="less" scoped>
@import url(els-css/style.css);
//@import url(els-css/typography.gotham.css);
@import url(els-css/global.small.768.css);
@import url(els-css/swarm_fullwidth.css);
@import url(els-css/svg.css);
@import url(els-css/display-modes.css);
@import url(els-css/life.css);

/* Small screens */
@media (min-width: 768px) {
  .overlay-box {
    margin-top: -50px;
  }
  .image-box {
    margin-top: 200px;
  }
  .scad-card-nopadding {
    padding: 0px !important;
  }
  .scad-card-inset {
    padding-bottom: 5px !important;
    padding-left: 25px !important;
    padding-right: 25px !important;
  }
  .main-resizer {
    font-size: 42px;
  }
}
/* Medium screens */
@media (min-width: 992px) {
  .main-resizer {
    font-size: 54px;
  }
}

/* Large screens */
@media (min-width: 1200px) {
  .main-resizer {
    font-size: 60px;
  }
}

@media (max-width: 800px) {
  .chat-icon {
    display: none;
  }
}
</style>
