<template>
  <div class="cust-container">
    <menuBar></menuBar>
    <div>
      <div class="banner">
        <img src="@/assets/images/international_students/banner.jpg" />
        <div class="text" v-if="$i18n.locale === 'en'">
          INTERNATIONAL STUDENTS
        </div>
        <div class="text" v-if="$i18n.locale === 'zh'">国际学生</div>
      </div>

      <div class="content" v-if="$i18n.locale === 'en'">
        <div class="title">Take the first step in your journey to the U.S.</div>
        <div class="desc">
          Every day students from around the world come to SCAD. They all begin
          their journey by making sure they have the proper paperwork to travel
          to the U.S. Review the steps below to make sure you're on the right
          path to realizing your creative dreams.
        </div>

        <div class="title1">Apply and be accepted to SCAD</div>
        <div class="text1">
          To get a SEVIS I-20 from SCAD, submit a SCAD application and get
          accepted into a specific degree program.
        </div>
        <!--[START YOUR APPLICATION][开始您的申请]-->

        <div class="title1">Update passport</div>
        <div class="text1">
          Renew your passport at least six months before it is set to expire and
          make sure it will not expire again for at least six months after your
          stay in the U.S.
        </div>

        <div class="title1">Submit documents to SCAD</div>
        <div class="text1">
          To receive a SEVIS I-20 form, you must submit a certificate of
          financial support and sponsorship letter to your admission adviser.
        </div>
        <div class="title1">Ask a SCAD admission representative</div>
        <div class="text1">
          If you have questions about applying for a visa, an admission
          representative can guide you through the process.
        </div>

        <div class="title1">Differences between F-1</div>
        <div class="text1">
          F-1 visas are issued to international students enrolled in an academic
          or English language program at a U.S. college or university. You can
          get F-1 visas from a U.S. embassy or consulate outside of the U.S.
          with the SEVIS form provided by SCAD.
        </div>

        <div class="title1">I-20</div>
        <div class="text1">
          SCAD issues the SEVIS I-20 form to students applying for an F-1 visa.
        </div>
        <div class="title1">I-94</div>
        <div class="text1">
          U.S. Customs and Border Protection use Form I-94 to confer immigration
          status and monitor your arrival and departure dates from the U.S. This
          record is maintained digitally by the U.S. government. Learn more at
          <a href="http://cbp.gov/I94">cbp.gov/I94</a>. Students can retrieve
          their I-94 after U.S. arrival. It is not available or necessary prior
          to U.S. arrival.
        </div>
        <div class="title1">Arriving in the U.S.</div>
        <div class="text1">
          You must check in with the international student services office
          (ISSO) after arrival. ISSO will notify you of your designated check-in
          time prior to your arrival. You are required by U.S. federal law to be
          registered in SEVIS. To request an airport pickup, notify ISSO at
          least five business days before arriving.
        </div>
      </div>

      <div class="content" v-if="$i18n.locale === 'zh'">
        <div class="title">迈出美国之旅的第一步</div>
        <div class="desc">
          每天都有来自世界各地的学生来到 SCAD。
          开启美国之旅之前，所有人都需要准备他们所需要的文件，开启你的梦想之路吧！查看以下步骤以确保你做好准备。
        </div>

        <div class="title1">提交申请并被 SCAD 录取</div>
        <div class="text1">
          要获得美国学生签证，请提交 SCAD 申请书并获得选定学位的录取。
        </div>
        <!--[START YOUR APPLICATION][开始您的申请]-->

        <div class="title1">更新护照</div>
        <div class="text1">
          至少在护照到期六个月前更新您的护照，并确保在您在美国逗留后至少六个月内不会再次到期。
        </div>

        <div class="title1">向 SCAD 提交文件</div>
        <div class="text1">
          要获得SEVIS表格，您必须向您的招生顾问提交一份经济支持证明和赞助信。
        </div>
        <div class="title1">询问 SCAD 招生代表</div>
        <div class="text1">
          如果您对申请签证有任何疑问，招生代表可以指导您完成整个过程。
        </div>
        <!--Chat with an admission representative 与招生代表聊天-->
        <div class="title1">F-1签证和J-1签证的区别</div>
        <div class="text1">
          F-1签证是发给在美国学院或大学就读学术或英语语言课程的国际学生的签证。
          J-1 是签证发给在美国参加工作和学习交流和访问计划的国际学生的签证。
          您可以使用以下机构提供的 SEVIS 表格从美国境外的美国大使馆或领事馆获得
          SCAD提供的 F-1 和 J-1 签证。
        </div>

        <div class="title1">DS-2019</div>
        <div class="text1">
          SCAD 向申请 J-1 签证的学生发放 SEVIS DS-2019 表格。
        </div>
        <div class="title1">I-20</div>
        <div class="text1">
          SCAD 向申请 F-1 签证的学生发放 SEVIS I-20 表格。
        </div>
        <div class="title1">I-94</div>
        <div class="text1">
          美国海关和边境保护局使用 I-94
          表格来授予你的移民身份，并监控您从美国抵达和离开的日期。该记录由美国政府以电子数字方式维护。
          访问 <a href="http://cbp.gov/I94">cbp.gov/I94</a> 了解更多信息。
        </div>
        <div class="title1">抵达美国</div>
        <div class="text1">
          您必须在抵达美国后 48 小时内通知国际学生服务办公室
          (ISSO)。美国联邦法律要求您在注册SEVIS。
          如果要申请机场接机，请在抵达前至少五个工作日通知 ISSO。
        </div>
      </div>
    </div>

    <goTop></goTop>
    <custFooter></custFooter>
  </div>
</template>

<script>
import menuBar from "../../components/menuBar/index.vue";
import custFooter from "@/components/footer/Footer";
export default {
  metaInfo: {
    title: "SCAD", // set a title
  },
  name: "International1",
  components: {
    menuBar,
    custFooter,
  },
  data: function () {
    return {};
  },
};
</script>

<style lang="less" scoped>
@import url(International.less);
</style>
