<template>
  <div>
    <TFUnderGrad2024EN v-if="$i18n.locale === 'en'"></TFUnderGrad2024EN>
    <TFUnderGrad2024CN v-if="$i18n.locale === 'zh'"></TFUnderGrad2024CN>
  </div>
</template>

<script>
import TFUnderGrad2024EN from "./T-F-Under-Grad-2024-EN.vue";
import TFUnderGrad2024CN from "./T-F-Under-Grad-2024-CN.vue";
export default {
  name: "T-F-Under-Grad-2024",
  components: { TFUnderGrad2024EN, TFUnderGrad2024CN },
};
</script>

<style scoped></style>
