const params = [
  { programs: "Advertising", value: "ADVE" },
  { programs: "Animation", value: "ANIM" },
  { programs: "Fashion", value: "FASH" },
  { programs: "Film and Television", value: "FILM" },
  { programs: "Fragrance Marketing/Management", value: "FRAM" },
  { programs: "Graphic Design", value: "GRDS" },
  { programs: "Mustration", value: "mustration" },
  { programs: "Industrial Design", value: "IDUS" },
  { programs: "Illustration", value: "ILLU" },
  { programs: "Interior Design", value: "INDS" },
  { programs: "Interactive Design and Game Development", value: "ITGM" },
  { programs: "Motion Media Design", value: "MOME" },
  { programs: "Photography", value: "PHOT" },
  { programs: "Painting", value: "PNTG" },
  { programs: "Sculpture", value: "SCPT" },
  { programs: "Sequential Art", value: "SEQA" },
  { programs: "Undecided", value: "UNDE" },
  { programs: "User Experience (UX) Design", value: "UXDG" },
];

export default params;
