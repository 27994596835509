const params = [
  { programs: "Accessory Design", value: "ACCE" },
  { programs: "Advertising", value: "ADVE" },
  { programs: "Animation", value: "ANIM" },
  { programs: "Architecture", value: "ARCH" },
  { programs: "Architectural History", value: "ARLH" },
  { programs: "Art History", value: "ARTH" },
  { programs: "Creative Business Leadership", value: "CRBL" },
  { programs: "Design Management", value: "DMGT" },
  { programs: "Dramatic Writing", value: "DWRI" },
  { programs: "Fashion", value: "FASH" },
  { programs: "Fibers", value: "FIBR" },
  { programs: "Film and Television", value: "FILM" },
  { programs: "Furniture Design", value: "FURN" },
  { programs: "Graphic Design", value: "GRDS" },
  { programs: "Industrial Design", value: "IDUS" },
  { programs: "Illustration", value: "ILLU" },
  { programs: "Interior Design", value: "INDS" },
  { programs: "Interactive Design and Game Development", value: "ITGM" },
  { programs: "Jewelry", value: "JEWL" },
  { programs: "Luxury and Brand Management", value: "LXMT" },
  { programs: "Motion Media Design", value: "MOME" },
  { programs: "Painting", value: "PNTG" },
  { programs: "Design for Sustainability", value: "SUST" },
];

export default params;
