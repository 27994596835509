<template>
  <div>
    <EN v-if="$i18n.locale === 'en'"></EN>
    <CN v-if="$i18n.locale === 'zh'"></CN>
  </div>
</template>

<script>
import EN from "./T-F-Grad-2022-EN.vue";
import CN from "./T-F-Grad-2022-CN.vue";
export default {
  name: "T-F-Grad-2022",
  components: { EN, CN },
};
</script>

<style scoped></style>
