<template>
  <div>
    <!--<mo v-if="mobile"></mo>-->
    <pc></pc>
  </div>
</template>

<script>
// import mo from "./MO.vue";
import pc from "./PC.vue";
export default {
  name: "Atlanta",
  props: ["mobile"],
  components: {
    // mo,
    pc,
  },
  watch: {
    mobile: {
      // eslint-disable-next-line no-unused-vars
      handler(newValue, oldValue) {
        this.mobile = newValue;
      },
      deep: true,
      immediate: true,
    },
  },

  data: function () {
    return {};
  },
  mounted() {
    // this.mobile = this.$isMobile();
  },
};
</script>

<style scoped lang="less"></style>
