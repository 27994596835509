<template>
  <div>
    <div class="grid-content content">
      <div class="video">
        <video src=""></video>
      </div>
      <div class="title1">SCAD Atlanta — a booming metropolitan hub</div>
      <!--<img src="@/assets/images/locations/content_img_01.jpg" alt="" />-->

      <div style="padding: 0 10px">
        <div>
          <div class="title2">Immerse yourself in our diverse programs</div>
          <div class="text">
            SCAD offers more than 100 degree programs. With the flexibility of
            our programs, you can begin your journey at one location and
            continue it at another. It's an incredible opportunity to explore
            different cultures and absorb a variety of artistic perspectives
            without interrupting your progress in completing your degree.
          </div>
        </div>
        <div class="line"></div>

        <el-row>
          <el-col :span="12" style="padding-right: 30px">
            <!--<img src="@/assets/images/locations/content_img_02.jpg" />-->
            <!--<div-->
            <!--  class="img"-->
            <!--  style="height: 55vw"-->
            <!--  :style="-->
            <!--    ' background-image: url(' +-->
            <!--    require('@/assets/images/locations/content_img_02.jpg') +-->
            <!--    ');'-->
            <!--  "-->
            <!--&gt;</div>-->
          </el-col>
          <el-col :span="12">
            <div class="title3">Your space to grow</div>
            <div class="text">
              As a student at SCAD Atlanta, you will enjoy all the amenities of
              an art and design epicenter, anchored in the heart of Midtown. In
              addition to our library, dining hall, fitness center, galleries,
              and digital labs, students have access to a world-famous sculpture
              studio, a cultural arts and writing center, and an expansive
              digital media center. No matter where you are at SCAD — you will
              have all the tools you need.
            </div>
          </el-col>
        </el-row>
        <el-row class="margin-top">
          <el-col :span="24">
            <div class="title3">Hosting students from around the world</div>
            <div class="text">
              Atlanta is home to the world's busiest airport — and many SCAD
              students from across the globe have put it to good use. We support
              our diverse student population with a dedicated international
              student services office, offering everything from advising to
              orientation to fun events.
            </div>
          </el-col>
          <el-col :span="24" style="margin-top: 10px; width: 100%">
            <!--<img-->
            <!--  style="width: 100%"-->
            <!--  src="@/assets/images/locations/content_img_03.jpg"-->
            <!--/>-->
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped lang="less">
@import "Atlanta.less";
</style>
