const params = [
  { programs: "Advertising", value: "ADVE" },
  { programs: "Creative Business Leadership", value: "CRBL" },
  { programs: "Fashion", value: "FASH" },
  { programs: "Film and Television", value: "FILM" },
  { programs: "Graphic Design/Visual Experience", value: "GDVX" },
  { programs: "Illustration", value: "ILLU" },
  { programs: "Interactive Design", value: "INTR" },
  { programs: "Luxury and Brand Management", value: "LXMT" },
  { programs: "Painting", value: "PNTG" },
  { programs: "Sculpture", value: "SCPT" },
];

export default params;
