<template>
  <div class="cust-container">
    <menuBar></menuBar>
    <div>
      <div class="banner">
        <img src="@/assets/images/whyChooseSCAD/banner.jpg" />
      </div>

      <div class="content">
        <div class="title" v-if="$i18n.locale === 'en'">
          <h1 class="title1" style="font-weight: 800">WHY CHOOSE SCAD</h1>
          <h4 class="title2">
            At SCAD, we give you the choice of 100+ degree programs and
            customized career preparation.Star professors and pro-level
            technology. Real-world experiences and alumni connections. All so
            you can turn your dream into your reality. Make it here so you can
            make ti out there.
          </h4>
        </div>

        <div class="title" v-if="$i18n.locale === 'zh'">
          <h1 class="title1" style="font-weight: 800">
            选择SCAD，成就超“艺”般的你
          </h1>
          <h4 class="title2">
            SCAD不仅提供了超过 100
            门学位课程，更是拥有明星教授、专业级技术、真实经历和校友人脉网的加持，为在校学生们量身定制职业规划。一切只为了激发、鼓舞、启迪热爱艺术创意的你。加入
            SCAD，让梦想照进现实。
          </h4>
        </div>

        <div class="menu-list">
          <el-row :gutter="0" class="menu-row">
            <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
              <div class="menu-item">
                <div class="text1" style="color: #e13f8a">100+</div>
                <div class="text2" v-if="$i18n.locale === 'zh'">
                  学位项目 <br /><br />
                </div>
                <div class="text2" v-if="$i18n.locale === 'en'">
                  degree programs
                </div>

                <div class="readmore">
                  <a href="/Academic/" style="text-decoration: none">
                    <div class="readmore-btn">Read More</div>
                  </a>
                </div>
              </div>
            </el-col>
            <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
              <div class="menu-item">
                <div class="text1" style="color: #53acf0">17</div>

                <div class="text2" v-if="$i18n.locale === 'zh'">
                  STEM项目 <br />（科学、技术、工程、数学项目）
                </div>
                <div class="text2" v-if="$i18n.locale === 'en'">
                  stem programs
                </div>

                <div class="readmore">
                  <a href="/STEM/" style="text-decoration: none">
                    <div class="readmore-btn">Read More</div>
                  </a>
                </div>
              </div>
            </el-col>
          </el-row>

          <el-row :gutter="0" class="menu-row">
            <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
              <div class="menu-item">
                <div class="text1" style="color: #4aaf66">3</div>

                <div class="text2" v-if="$i18n.locale === 'zh'">
                  全球线下授课点
                </div>
                <div class="text2" v-if="$i18n.locale === 'en'">
                  global locations
                </div>
                <div class="readmore">
                  <a href="/locations/Atlanta" style="text-decoration: none">
                    <div class="readmore-btn">Read More</div>
                  </a>
                </div>
              </div>
            </el-col>
            <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
              <div class="menu-item">
                <div class="text1" style="color: #eda547">99%</div>
                <div class="text2" v-if="$i18n.locale === 'zh'">学生就业率</div>
                <div class="text2" v-if="$i18n.locale === 'en'">
                  alumni employment
                </div>

                <div class="readmore">
                  <div class="readmore-btn" @click="showVideo = true">
                    Read More
                  </div>
                </div>
              </div>
            </el-col>
          </el-row>

          <!--<el-row :gutter="0" class="menu-row">-->
          <!--  <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">-->
          <!--    <div class="menu-item">-->
          <!--      <div class="text1" style="color: #aa58a0">XX</div>-->
          <!--      <div class="text2">industry partners</div>-->
          <!--    </div>-->
          <!--  </el-col>-->
          <!--  <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">-->
          <!--    <div class="menu-item">-->
          <!--      <div class="text1" style="color: #fcf25f">XX</div>-->
          <!--      <div class="text2">awards</div>-->
          <!--    </div>-->
          <!--  </el-col>-->
          <!--</el-row>-->
        </div>
      </div>
    </div>

    <transition name="ani-video">
      <div class="videoPlane" v-if="showVideo == true">
        <div class="videoBg" @click="showVideo = false"></div>
        <video
          src="https://scad-minisite.oss-cn-beijing.aliyuncs.com/video/20220914/2022-23%20Creative%20Careers_CHINESE_subtitles-FNL.mp4"
          poster="https://scad-minisite.oss-cn-beijing.aliyuncs.com/video/20220914/2022-23%20Creative%20Careers_CHINESE_subtitles-FNL.mp4?x-oss-process=video/snapshot,t_1500,m_fast"
          controls
        ></video>
      </div>
    </transition>

    <goTop></goTop>
    <custFooter></custFooter>
  </div>
</template>

<script>
import menuBar from "../../components/menuBar/index.vue";
import custFooter from "@/components/footer/Footer";
export default {
  metaInfo: {
    title: "SCAD", // set a title
  },
  name: "WhyChooseSCAD",
  components: {
    menuBar,
    custFooter,
  },
  data: function () {
    return {
      showVideo: false,
    };
  },
};
</script>

<style lang="less" scoped>
@import url(WhyChooseSCAD.less);

.ani-video-enter-active {
  transform: scale(0);
}
.ani-video-enter {
  transform: scale(0);
}
.ani-video-leave-active {
  transform: scale(0);
}
.ani-video-leave-to {
  transform: scale(0);
}
</style>
