<template>
  <div class="cust-container">
    <menuBar></menuBar>
    <div class="content section-life">
      <div class="row" style="margin-top: 50px">
        <div
          class="col-sm-8 main-content"
          style="
            margin: 0 auto;
            display: block;
            float: inherit;
            max-width: 1000px;
          "
        >
          <div class="row">
            <div class="inner-region">
              <div
                class="
                  panel-panel
                  row_1_left
                  col-sm-4 col-xs-offset-0 col-md-5 col-md-offset-0
                "
              >
                <div
                  class="
                    panel-pane
                    pane-entity-field pane-node-field-regional-images
                  "
                >
                  <div class="pane-content">
                    <div class="item">
                      <picture class="img-responsive">
                        <img
                          class="img-responsive"
                          src="https://www.scad.edu/sites/default/files/styles/swarm1x1_550/public/Events/ISSO-Plaza-Fiesta-Atlanta-2018-summer-DP_3800_JJ%281%29.jpg?itok=xeKrZTXl&timestamp=1550080166 1x"
                          alt="International Student Services Office plaza fiesta Atlanta"
                          title=""
                        />
                      </picture>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="inner-region">
              <div
                class="
                  panel-panel
                  row_1_center
                  col-sm-8 col-sm-offset-0 col-md-7 col-md-offset-0
                "
              >
                <div class="panel-pane pane-custom pane-7">
                  <div class="pane-content">
                    <h1 style="margin-top: 0">
                      SCAD Cultural and Academic Preparation Program
                    </h1>
                    <p>
                      Find support on your journey to university life in the
                      U.S. with the SCAD Cultural and Academic Preparation
                      Program (CAPP), a four-week pre-orientation summer session
                      for new international students in Atlanta and Savannah
                      hosted by the SCAD International Student Services Office
                      and the SCAD Language Studio.
                    </p>
                    <p>
                      <strong>CAPP will take place Aug. 1-31, 2022. </strong>For
                      more information, email
                      <a href="mailto:isso@scad.edu">isso@scad.edu</a>.
                    </p>
                    <p>
                      <a
                        class="btn btn-stroke"
                        href="https://isso.scad.edu/index.cfm?FuseAction=Abroad.ViewLink&amp;Parent_ID=0&amp;Link_ID=F83876B7-EFC2-1731-B4F5F0A083B9611C"
                        target="_blank"
                        >Apply now</a
                      >
                    </p>
                    <p></p>
                    <p></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <div class="hr"></div>
            </div>
          </div>
          <div class="row">
            <div class="inner-region">
              <div
                class="
                  panel-panel
                  row_2_left
                  col-sm-12 col-xs-offset-0 col-md-12 col-md-offset-0
                "
              >
                <div class="panel-pane pane-node margin-top-sm-20">
                  <div class="pane-content">
                    <div class="borderless-block">
                      <div class="video-medium">
                        <div class="image">
                          <a href="javascript:void(0)" aria-label="Play Video">
                            <div
                              class="
                                field
                                field-name-field-thumbnail-image
                                field-type-image
                                field-label-hidden
                              "
                            >
                              <div class="field-items">
                                <div class="field-item even">
                                  <picture class="img-responsive">
                                    <img
                                      class="img-responsive"
                                      src="@/assets/images/internationalcapp/CAPP-2020-video.webp?itok=eQbV9vkq&amp;timestamp=1582039881"
                                      alt="Play SCAD CAPP video"
                                      title=""
                                    />
                                  </picture>
                                </div>
                              </div>
                            </div>
                            <span class="scadtv-icon scadtv-icon-play"></span>
                          </a>
                        </div>
                        <div class="video-element">
                          <div class="embed-responsive embed-responsive-16by9">
                            <iframe
                              src="//www.youtube.com/embed/bixEVUpW5iM?rel=0"
                              frameborder="0"
                              width="500px"
                              height="400px"
                              webkitallowfullscreen=""
                              mozallowfullscreen=""
                              allowfullscreen=""
                            ></iframe>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="panel-pane pane-custom pane-13">
                  <div class="pane-content" style="text-align: center">
                    <h2>Accelerate your success</h2>
                    <p>
                      CAPP is designed to prepare talented students for a
                      successful transition to academic, social, and cultural
                      life at SCAD before fall quarter begins.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="inner-region">
              <div
                class="
                  panel-panel
                  row_3_left
                  col-sm-6 col-sm-offset-0 col-md-4 col-md-offset-0
                "
              >
                <div
                  class="
                    panel-pane
                    pane-custom pane-15
                    margin-top-all-50 margin-bottom-xs-30 margin-bottom-sm-30
                  "
                >
                  <div class="pane-content">
                    <div
                      style="
                        background-color: #ffffff;
                        padding: 30px;
                        -webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
                        -moz-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
                        box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
                        border-radius: 3px;
                      "
                    >
                      <div
                        style="
                          border-top: 4px;
                          border-top-color: #cccccc;
                          border-top-style: solid;
                          padding-top: 30px;
                        "
                      >
                        <p
                          class="reduce"
                          style="
                            font-size: 28px;
                            line-height: 30px;
                            font-weight: 600;
                            margin-bottom: 0px;
                          "
                        >
                          Settle in to U.S. life
                        </p>
                      </div>
                    </div>
                    <p>&nbsp;</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="inner-region">
              <div
                class="
                  panel-panel
                  row_3_center
                  col-sm-6 col-sm-offset-0 col-md-4 col-md-offset-0
                "
              >
                <div
                  class="
                    panel-pane
                    pane-custom pane-14
                    margin-top-sm-50
                    margin-top-md-50
                    margin-top-lg-50
                    margin-bottom-xs-30
                    margin-bottom-sm-30
                  "
                >
                  <div class="pane-content">
                    <div
                      style="
                        background-color: #ffffff;
                        padding: 30px;
                        -webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
                        -moz-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
                        box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
                        border-radius: 3px;
                      "
                    >
                      <div
                        style="
                          border-top: 4px;
                          border-top-color: #cccccc;
                          border-top-style: solid;
                          padding-top: 30px;
                        "
                      >
                        <p
                          class="reduce"
                          style="
                            font-size: 28px;
                            line-height: 30px;
                            font-weight: 600;
                            margin-bottom: 0px;
                          "
                        >
                          Meet fellow new students
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="inner-region">
              <div
                class="
                  margin-top-sm-50 margin-top-md-50 margin-top-lg-50
                  panel-panel
                  row_3_right
                  col-sm-6 col-sm-offset-3 col-md-4 col-md-offset-0
                "
              >
                <div
                  class="
                    panel-pane
                    pane-custom pane-16
                    margin-top-md-50 margin-top-lg-50 margin-bottom-xs-30
                  "
                >
                  <div class="pane-content">
                    <div
                      style="
                        background-color: #ffffff;
                        padding: 30px;
                        -webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
                        -moz-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
                        box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
                        border-radius: 3px;
                      "
                    >
                      <div
                        style="
                          border-top: 4px;
                          border-top-color: #cccccc;
                          border-top-style: solid;
                          padding-top: 30px;
                        "
                      >
                        <p
                          class="reduce"
                          style="
                            font-size: 28px;
                            line-height: 30px;
                            font-weight: 600;
                            margin-bottom: 0px;
                          "
                        >
                          Prepare academically
                        </p>
                      </div>
                    </div>
                    <p>&nbsp;</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <div class="hr"></div>
            </div>
          </div>
          <div class="row">
            <div class="inner-region">
              <div
                class="
                  panel-panel
                  row_9_left
                  col-sm-12 col-sm-offset-0 col-md-12 col-md-offset-0
                "
              >
                <div class="panel-pane pane-custom pane-17 margin-top-all-20">
                  <div class="pane-content">
                    <h2 class="rtecenter">SCAD CAPP participants:</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="inner-region">
              <div
                class="
                  panel-panel
                  row_10_left
                  col-sm-4 col-sm-offset-0 col-md-4 col-md-offset-0
                "
              >
                <div
                  class="
                    panel-pane
                    pane-entity-field pane-node-field-regional-images
                    margin-bottom-all-20
                  "
                >
                  <div class="pane-content">
                    <div class="item">
                      <picture class="img-responsive">
                        <img
                          class="img-responsive"
                          src="@/assets/images/internationalcapp/ISSO-rafting-Atlanta-2018-summer-RP_27.webp?itok=xFbHJu3E&amp;timestamp=1549995354"
                          alt="International Student Services Office rafting"
                          title=""
                        />
                      </picture>
                    </div>
                  </div>
                </div>
                <div class="panel-pane pane-custom pane-2 margin-bottom-all-30">
                  <div class="pane-content">
                    <h3>Explore and travel the region</h3>
                    <p>
                      SCAD CAPP students take day trips around the southeastern
                      U.S., including beach days at Jekyll Island near Savannah
                      and tours of Atlanta attractions, among others.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="inner-region">
              <div
                class="
                  panel-panel
                  row_10_center
                  col-sm-4 col-sm-offset-0 col-md-4 col-md-offset-0
                "
              >
                <div
                  class="
                    panel-pane
                    pane-entity-field pane-node-field-regional-images
                    margin-bottom-all-20
                  "
                >
                  <div class="pane-content">
                    <div class="item">
                      <picture class="img-responsive">
                        <img
                          class="img-responsive"
                          src="@/assets/images/internationalcapp/Faculty-candids-Menjie-Di-Fall-2014-JM_40.webp?itok=0MOdEEaD&amp;timestamp=1549994894"
                          alt="Mengjie Di faculty"
                          title=""
                        />
                      </picture>
                    </div>
                  </div>
                </div>
                <div class="panel-pane pane-custom pane-1 margin-bottom-all-30">
                  <div class="pane-content">
                    <h3>Solve design challenges</h3>
                    <p>
                      In these team-based challenges, SCAD CAPP students work
                      together to design a new product, digital app, or brand
                      identity. Students learn the expectations for SCAD
                      classrooms at the university's U.S. locations and have
                      opportunities to practice their English presentation
                      skills.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="inner-region">
              <div
                class="
                  panel-panel
                  row_10_right
                  col-sm-4 col-sm-offset-0 col-md-4 col-md-offset-0
                "
              >
                <div
                  class="
                    panel-pane
                    pane-entity-field pane-node-field-regional-images
                    margin-bottom-all-20
                  "
                >
                  <div class="pane-content">
                    <div class="item">
                      <picture class="img-responsive">
                        <img
                          class="img-responsive"
                          src="@/assets/images/internationalcapp/Norris-Hall-Fall-2014-CC_07_SN.webp?itok=UzZ3LMDT&amp;timestamp=1549994623"
                          alt="Norris Hall interior"
                          title=""
                        />
                      </picture>
                    </div>
                  </div>
                </div>
                <div class="panel-pane pane-custom pane-3 margin-bottom-all-30">
                  <div class="pane-content">
                    <h3>Engage in foundation workshops</h3>
                    <p>
                      SCAD CAPP students are introduced to SCAD foundation
                      studies and general education courses, exploring the
                      conceptual, creative, and visual skills that are essential
                      to academic success. Workshops are tailored to expose
                      students to the studio environment and their programs of
                      study.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="inner-region">
              <div
                class="
                  panel-panel
                  row_11_left
                  col-sm-4 col-sm-offset-0 col-md-4 col-md-offset-0
                "
              >
                <div
                  class="
                    panel-pane
                    pane-entity-field pane-node-field-regional-images
                    margin-bottom-all-20
                  "
                >
                  <div class="pane-content">
                    <div class="item">
                      <picture
                        title="Rising Stars Arts Festival"
                        class="img-responsive"
                      >
                        <img
                          class="img-responsive"
                          src="@/assets/images/internationalcapp/RisingStarsArtsFestival_06-29-2014_jr_MG_9366_SN.webp?itok=45gOqyjk&amp;timestamp=1549993068"
                          alt="Rising Stars Arts Festival"
                          title="Rising Stars Arts Festival"
                        />
                      </picture>
                    </div>
                  </div>
                </div>
                <div class="panel-pane pane-custom pane-5 margin-bottom-xs-30">
                  <div class="pane-content">
                    <h3>Discover vibrant SCAD locations</h3>
                    <p>
                      SCAD CAPP students acclimate to the university and city
                      life in Atlanta or Savannah. In Atlanta, students explore
                      the university's Midtown home, surrounded by Fortune 500
                      companies, a lively cultural landscape, and a thriving
                      arts and media scene. In Savannah, students discover the
                      flourishing artistic community, blossoming squares, and
                      nearby beaches of the region.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="inner-region">
              <div
                class="
                  panel-panel
                  row_11_center
                  col-sm-4 col-sm-offset-0 col-md-4 col-md-offset-0
                "
              >
                <div
                  class="
                    panel-pane
                    pane-entity-field pane-node-field-regional-images
                    margin-bottom-all-20
                  "
                >
                  <div class="pane-content">
                    <div class="item">
                      <picture class="img-responsive">
                        <img
                          class="img-responsive"
                          src="@/assets/images/internationalcapp/Montgomery-House-interior-2013-230_CC_SN.webp"
                          alt="Montgomery House interior"
                          title=""
                        />
                      </picture>
                    </div>
                  </div>
                </div>
                <div class="panel-pane pane-custom pane-4 margin-bottom-xs-30">
                  <div class="pane-content">
                    <h3>Move into SCAD housing early</h3>
                    <p>
                      SCAD CAPP students get a head start on settling into SCAD
                      student life. In Atlanta, students reside in FORTY, SCAD
                      Atlanta's newest residence hall, featuring apartment and
                      suite-style housing, study space, event space, and dining
                      opportunities. In Savannah, students live at The Hive, a
                      complex of residence halls with a nearby dining hall,
                      fitness center, swimming pool, and more. Each residence
                      hall is fully furnished and has wireless internet.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <div class="hr"></div>
            </div>
          </div>
          <div class="row">
            <div class="inner-region">
              <div class="panel-panel">
                <div class="panel-pane pane-custom pane-12 margin-top-all-20">
                  <div class="pane-content" style="text-align: center">
                    <h2 class="rtecenter">
                      Take advantage of all CAPP has to offer
                    </h2>
                    <p class="rtecenter">
                      The total CAPP fee (US$3, 950) includes four weeks of
                      academic and English instruction, 18 meals per week,
                      weekend excursions, on-campus housing, and early access to
                      SCAD buildings and resources.
                    </p>
                    <!--<p class="rtecenter">-->
                    <!--  View this year's schedules.<br />-->
                    <!--  <a-->
                    <!--    class="load-modal-window"-->
                    <!--    href="/life/orientation/you-arrive/international-capp/atlanta-schedule"-->
                    <!--    >Atlanta</a-->
                    <!--  >-->
                    <!--  |-->
                    <!--  <a-->
                    <!--    class="load-modal-window"-->
                    <!--    href="/life/orientation/you-arrive/international-capp/savannah-schedule"-->
                    <!--    >Savannah</a-->
                    <!--  >-->
                    <!--</p>-->
                    <p class="rtecenter">
                      Enrollment is limited. For full consideration for the
                      program, complete the application and pay the US$850
                      deposit. The remaining balance of US$3, 100 to cover the
                      SCAD CAPP program fee is due Friday, July 1, 2022.
                    </p>

                    <p class="rtecenter">
                      To learn more, visit
                      <a href="https://scad.edu/scadcapp">scad.edu/scadcapp</a>.
                      If you have questions or would like more information about
                      how to enroll, email
                      <a href="mailto:isso@scad.edu">isso@scad.edu</a> or
                      <a href="mailto:issoatl@scad.edu">issoatl@scad.edu</a>.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <div class="hr"></div>
            </div>
          </div>
          <div class="row">
            <div class="inner-region">
              <div
                class="
                  panel-panel
                  row_23_left
                  col-sm-12 col-sm-offset-0 col-md-12 col-md-offset-0
                "
              >
                <div
                  class="
                    panel-pane
                    pane-custom pane-8
                    margin-top-sm-20 margin-bottom-all-40
                  "
                >
                  <div class="pane-content">
                    <h2>Frequently asked questions</h2>
                    <p>
                      Discover additional information about the program,
                      including payment due dates, class locations, and more.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="inner-region">
              <div
                class="
                  panel-panel
                  row_24_left
                  col-sm-12 col-sm-offset-0 col-md-12 col-md-offset-0
                "
              >
                <div class="panel-pane pane-custom pane-10">
                  <div class="pane-content">
                    <div style="padding-bottom: 60px">
                      <h4 style="margin-bottom: 0px; padding-bottom: 0px">
                        How long is SCAD CAPP?
                      </h4>
                      <div class="faq-line">&nbsp;</div>
                      <div class="faq-answer">
                        <p style="padding-top: 25px">
                          SCAD CAPP is a four-week program that takes place
                          before the start of university orientation and the
                          fall quarter.
                        </p>
                      </div>
                    </div>
                    <div style="padding-bottom: 20px">
                      <h4 style="margin-bottom: 0px; padding-bottom: 0px">
                        Am I eligible for SCAD CAPP?
                      </h4>
                      <div class="faq-line">&nbsp;</div>
                      <div class="faq-answer">
                        <p style="padding-top: 25px">
                          This program contains academic content for both
                          ESL-exempt and non-exempt students. All new students
                          are welcome to attend!
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-12">
              <div class="hr"></div>
            </div>
          </div>

          <div class="row">
            <div class="inner-region">
              <div
                class="
                  panel-panel
                  row_23_left
                  col-sm-12 col-sm-offset-0 col-md-12 col-md-offset-0
                "
              >
                <div
                  class="
                    panel-pane
                    pane-custom pane-8
                    margin-top-sm-20 margin-bottom-all-40
                  "
                >
                  <div class="pane-content">
                    <h2>Atlanta CAPP schedule</h2>
                    <div>
                      <p>Monday–Tuesday, Aug. 1-2, 2022</p>

                      <p>
                        SCAD CAPP participants arrive in Atlanta and check in
                        with residence life and housing. The SCAD International
                        Student Services Office will provide transportation on
                        request from the Atlanta airport between 9 a.m. and
                        10:30 p.m. ET. Students may check in to SCAD housing any
                        time between 9 a.m. and 10:30 p.m. ET. During these days
                        students will receive transportation and support in
                        opening bank accounts, setting up cell phones and
                        shopping.
                      </p>

                      <p>Aug. 3</p>

                      <p>Orientation and week 1 activities begin.</p>

                      <p>Aug. 4–30</p>

                      <p>
                        Students attend academic and/or English as a Second
                        Language classes, 9 a.m. to noon, Monday through
                        Thursday. Cultural activities, workshops, and local
                        trips will take place in the afternoon. Excursions are
                        scheduled for the following weekends:
                      </p>

                      <p>Aug. 5-7</p>
                      <p>
                        Weekend exploring the SCAD sister campus and surrounding
                        areas of Savannah, GA
                      </p>

                      <p>Aug. 12-14</p>
                      <p>
                        Weekend exploring Atlanta museums and a day-trip to
                        whitewater rafting in Tennessee
                      </p>

                      <p>
                        Aug. 18-21 Weekend in Orlando, FL with entry into
                        Universal Studios, FL
                      </p>

                      <p>Wednesday, Aug. 31</p>

                      <p>
                        SCAD CAPP ends. All students relocating to a new
                        residence hall or to off-campus housing will be provided
                        assistance.
                      </p>

                      <p>
                        More details will be announced closer to the program’s
                        dates. The program calendar is subject to change.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="inner-region">
              <div
                class="
                  panel-panel
                  row_23_left
                  col-sm-12 col-sm-offset-0 col-md-12 col-md-offset-0
                "
              >
                <div
                  class="
                    panel-pane
                    pane-custom pane-8
                    margin-top-sm-20 margin-bottom-all-40
                  "
                >
                  <div class="pane-content">
                    <h2>Savannah CAPP schedule</h2>
                    <div>
                      <p>Monday-Tuesday, Aug. 1–2, 2022</p>

                      <p>
                        SCAD CAPP participants arrive in Savannah and check in
                        with residence life and housing. The SCAD International
                        Student Services Office will provide transportation on
                        request from the Savannah airport between 9 a.m. and
                        10:30 p.m. ET. Students may check in to SCAD housing any
                        time between 9 a.m. and 10:30 p.m. ET. During these days
                        students will receive transportation and support in
                        opening bank accounts, setting up cell phones and
                        shopping.
                      </p>

                      <p>Aug. 3</p>

                      <p>Orientation and week 1 activities begin.</p>

                      <p>Aug. 4–30</p>

                      <p>
                        Students attend academic and/or English as a Second
                        Language classes, 9 a.m. to noon, Monday through
                        Thursday. Cultural activities, workshops, and local
                        trips will take place in the afternoon. Excursions are
                        scheduled for the following weekends:
                      </p>

                      <p>Aug. 5-7</p>
                      <p>
                        Weekend exploring the historic and coastal areas of
                        Savannah, GA and Tybee Island
                      </p>

                      <p>Aug. 12-14</p>
                      <p>
                        Weekend exploring the SCAD sister campus in Atlanta
                        along with local museums and a day-trip to whitewater
                        rafting in Tennessee
                      </p>

                      <p>
                        Aug. 18-21 Weekend in Orlando, FL with entry into
                        Universal Studios, FL
                      </p>

                      <p>Wednesday, Aug. 31</p>

                      <p>
                        SCAD CAPP ends. All students relocating to a new
                        residence hall or to off-campus housing will be provided
                        assistance.
                      </p>

                      <p>
                        More details will be announced closer to the program’s
                        dates. The program calendar is subject to change.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <goTop></goTop>
    <custFooter></custFooter>
  </div>
</template>

<script>
import menuBar from "../../components/menuBar/index.vue";
import custFooter from "@/components/footer/Footer";
export default {
  metaInfo: {
    title: "SCAD", // set a title
  },
  name: "InternationalCAPP",
  components: {
    menuBar,
    custFooter,
  },
  data: function () {
    return {};
  },
};
</script>

<style lang="css" src="./els-css/typography.gotham.css" scoped />
<style lang="less" scoped>
@import url(els-css/style.css);
@import url(els-css/global.small.768.css);
@import url(els-css/swarm_fullwidth.css);
@import url(els-css/svg.css);
@import url(els-css/display-modes.css);
@import url(els-css/life.css);

.faq-line {
  width: 70px;
  border-bottom: medium;
  border-bottom-color: #000000;
  border-bottom-style: solid;
}
.faq-answer {
  border-top: thin;
  border-top-color: #eaeaea;
  border-top-style: solid;
}

@media (min-width: 992px) {
  .reduce {
    font-size: 21px !important;
    line-height: 23px !important;
  }
}
/* Large screens */
@media (min-width: 1200px) {
  .reduce {
    font-size: 28px !important;
    line-height: 30px !important;
  }
}

@media (max-width: 800px) {
  .main-content {
    padding: 0;

    .row {
      margin: 0;
    }
  }
}
</style>
