<template>
  <div>
    <div v-if="$i18n.locale === 'en'">
      <div class="grid-content content">
        <div class="title1">SCAD Lacoste — an idyllic medieval village</div>
        <div class="video">
          <!--<video-->
          <!--  src="https://scad-minisite.oss-cn-beijing.aliyuncs.com/video/Lacoste/2021-22%20SCADLacoste.mp4"-->
          <!--  controls-->
          <!--  poster="https://scad-minisite.oss-cn-beijing.aliyuncs.com/video/Lacoste/2021-22%20SCADLacoste.mp4?x-oss-process=video/snapshot,t_1500,m_fast"-->
          <!--&gt;</video>-->

          <video
            src="https://scad-minisite.oss-cn-beijing.aliyuncs.com/video/20231110/2023_24_SCAD_Lacoste_Mandarin_Subs_V7.mp4"
            controls
            poster="https://scad-minisite.oss-cn-beijing.aliyuncs.com/video/20231110/2023_24_SCAD_Lacoste_Mandarin_Subs_V7.mp4?x-oss-process=video/snapshot,t_1500,m_fast"
          ></video>
        </div>

        <div class="tips text">
          For more than 20 years, SCAD Lacoste has welcomed celebrated creative
          luminaries and culture-curious students to explore the extraordinary
          landscapes, illustrious local attractions, and exquisitely conserved
          architectural treasures — some dating to the ninth century — that
          comprise the university’s international locale. At this historic and
          magical destination in southern France, newly enrolled and first-year
          students, returning students, and alumni alike add an international
          perspective to their studies and artistic practices and launch
          globetrotting careers.
        </div>
        <div class="line" style="margin-top: 1%"></div>
        <div class="padding-both">
          <img
            src="@/assets/images/locations/lacoste_content_img_01.jpg?v=1"
            alt=""
          />
          <div class="title2">
            Earn credits along with a world of experience
          </div>
          <div class="text text-center">
            As the university’s study abroad location, Lacoste invites students
            of all years to seamlessly continue their SCAD degrees as they make
            treasured lifelong memories. Courses vary each quarter, with
            offerings across programs such as advertising, animation, art
            history, fashion, film and television, interior design, painting,
            and photography. Students also participate in Lacoste-based SCADpro
            assignments and connect with companies throughout Europe.
          </div>
        </div>
        <div class="line"></div>

        <el-row :gutter="30" class="padding-left">
          <el-col :xs="24" :md="12">
            <img
              src="@/assets/images/locations/lacoste_content_img_02.jpg?v=1"
            />
          </el-col>
          <el-col :xs="24" :md="12">
            <div class="title3">Go on the trip of a lifetime</div>
            <div class="text">
              Students at SCAD Lacoste gain even more cultural experience and
              worldly perspective through excursions to nearby cities and
              villages. Previous destinations include:

              <div style="margin-top: 15px">
                <div>● Aix-en-Provence</div>
                <div>● Marseilles</div>
                <div>● Nîmes and Pont du Gard</div>
                <div>● Saint-Rémy-de-Provence</div>
                <div>● And more …</div>
              </div>
            </div>
          </el-col>
        </el-row>
        <el-row :gutter="30" class="padding-left margin-top">
          <el-col :xs="24" :md="12">
            <img
              src="@/assets/images/locations/lacoste_content_img_03.jpg?v=1"
            />
          </el-col>
          <el-col :xs="24" :md="12">
            <div class="title3">Gain insight from visiting artists</div>
            <div class="text">
              As with all of SCAD's locations around the globe, visiting
              artists, designers and professionals come to SCAD Lacoste to
              inspire — and be inspired by — our students. Recent visitors
              include:

              <div style="margin-top: 15px">
                <div>● GLOBAL GUESTS Chioma Nnadi Vogue editor</div>
                <div>● Lynn Yaeger Vogue contributing editor</div>
                <div>
                  ● Shane Gabier and Christopher Peters CFDA/Vogue Fashion Award
                  recipients
                </div>
                <div>● Julien Fournié Fashion designer</div>
                <div>● Ilse Crawford Interior and furniture designer</div>
                <div>● Ghislaine Viñas Interior designer</div>
              </div>

              <!--              <div style="margin-top: 15px">-->
              <!--                <div>● Contemporary artist Hugo Dalton</div>-->
              <!--                <div>● London-based interior stylist Hannah Cork</div>-->
              <!--                <div>-->
              <!--                  ● London-based illustrator and printmaker Christopher Brown-->
              <!--                </div>-->
              <!--                <div>-->
              <!--                  ● Academy Award-winning screenwriter Geoffery Fletcher-->
              <!--                </div>-->
              <!--                <div>-->
              <!--                  ● CFDA Award-winning fashion designers Creatures of the Wind-->
              <!--                </div>-->
              <!--              </div>-->
            </div>
          </el-col>
        </el-row>

        <div class="line"></div>

        <el-row :gutter="30" class="padding-left margin-top">
          <el-col :xs="24" :md="12">
            <div class="title3">
              Inhabit a piece of history with cutting-edge modern amenities
            </div>
            <div class="text">
              While the Luberon Valley and its rolling fields of lavender form a
              resplendent, old-world backdrop to the time-honored village, SCAD
              Lacoste’s digitally connected, tech-savvy spaces ensure students
              and alumni stay tapped into their studies or their work as they
              enjoy this once-in-a-lifetime experience.
            </div>
          </el-col>

          <el-col :xs="24" :md="12">
            <img
              src="@/assets/images/locations/lacoste_content_img_04.jpg?v=1"
            />
          </el-col>
        </el-row>

        <el-row :gutter="30" class="padding-left margin-top">
          <el-col :xs="24" :md="12">
            <div class="title3">
              Explore the indelible history of Maison Basse
            </div>
            <div class="text">
              La Maison Basse has led many lives during the past eight
              centuries, including tenure as a farmhouse, inn, way station for
              bear tamers and, perhaps most notoriously, a carriage
              house-cum-gambling den for the infamous Marquis de Sade. This
              centerpiece of the Luberon valley fell into disrepair after
              40-plus years of neglect, until a generous donation helped SCAD
              bring it back to life.
            </div>
          </el-col>
          <el-col :xs="24" :md="12">
            <img
              src="@/assets/images/locations/lacoste_content_img_05.jpg?v=1"
            />
          </el-col>
        </el-row>

        <div class="line"></div>
        <el-row :gutter="30" class="padding-left margin-top">
          <el-col :xs="24" :md="12">
            <img
              src="@/assets/images/locations/lacoste_content_img_06.jpg?v=1"
            />
          </el-col>
          <el-col :xs="24" :md="12">
            <div class="title3">Connect with us</div>
            <div class="text">
              Request more information today, and we'll send you all the details
              you need to begin your exciting journey at SCAD.
              <br /><br />
              <a
                target="_blank"
                href="https://app.jingsocial.com/microFrontend/forms/jsf-forms/details/qFjikR3MCzTGfMEbp4bRqh?lead_source_type=survey&lead_source_value=qFjikR3MCzTGfMEbp4bRqh&promotion_code=survey_qrcode&promotion_value=cdw9Dvn5cUBUn6GxM44mja&promotion_channel_id=tiVt8LccayrF2kbKxd9jja&global_promotion_channel_id=3ZH8PjSow9oxzhbzfrcXAX&global_promotion_sub_channel_id=n9RQk7YyKX6he32EtHzFce&appId=wxe14b2fd0105ac9dc"
                >Request Information
              </a>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <div v-if="$i18n.locale === 'zh'">
      <div class="grid-content content">
        <div class="title1">SCAD拉科斯特校区——充满田园诗意的中世纪村庄</div>
        <div class="video">
          <!--<video-->
          <!--  src="https://scad-minisite.oss-cn-beijing.aliyuncs.com/video/Lacoste/2021-22%20SCADLacoste.mp4"-->
          <!--  controls-->
          <!--  poster="https://scad-minisite.oss-cn-beijing.aliyuncs.com/video/Lacoste/2021-22%20SCADLacoste.mp4?x-oss-process=video/snapshot,t_1500,m_fast"-->
          <!--&gt;</video>-->

          <video
            src="https://scad-minisite.oss-cn-beijing.aliyuncs.com/video/20231110/2023_24_SCAD_Lacoste_Mandarin_Subs_V7.mp4"
            controls
            poster="https://scad-minisite.oss-cn-beijing.aliyuncs.com/video/20231110/2023_24_SCAD_Lacoste_Mandarin_Subs_V7.mp4?x-oss-process=video/snapshot,t_1500,m_fast"
          ></video>
        </div>
        <div class="tips text">
          自 15
          世纪以来，艺术家们前往法国拉科斯特朝圣，只是为了见证其纯净的光芒。今天，艺术家们由
          SCAD 赞助，仍然涌向这个保存完好的中世纪村庄。作为一名在
          拉科斯特留学的学生，您将陶醉于田园之美，同时摆脱日常生活的干扰，更好的专注于您的手艺。
        </div>
        <div class="line" style="margin-top: 1%"></div>
        <div class="padding-both">
          <img
            src="@/assets/images/locations/lacoste_content_img_01.jpg?v=1"
            alt=""
          />
          <div class="title2">同时获得学分以及丰富经验</div>
          <div class="text text-center">
            SCAD拉科斯特校区的课程设置根据每个季度都会有所不同，让你有机会在体验普罗旺斯壮丽文化的同时获得我们学位课程的学分。你也可以在拉科斯特校区学习一部分基础课程、通识教育或选修课程（如果该时段提供）。
          </div>
        </div>
        <div class="line"></div>

        <el-row :gutter="30" class="padding-left">
          <el-col :xs="24" :md="12">
            <img
              src="@/assets/images/locations/lacoste_content_img_02.jpg?v=1"
            />
          </el-col>
          <el-col :xs="24" :md="12">
            <div class="title3">踏上持续一生的旅程</div>
            <div class="text">
              SCAD拉科斯特校区的学生通过游览附近的城市和村庄获得更多的文化体验和世界观，其中包括：

              <div style="margin-top: 15px">
                <div>● 普罗旺斯艾克斯</div>
                <div>● 马赛</div>
                <div>● 尼姆和加德桥</div>
                <div>● 圣雷米普罗旺斯</div>
                <div>● 和更多 ...</div>
              </div>
            </div>
          </el-col>
        </el-row>
        <el-row :gutter="30" class="padding-left margin-top">
          <el-col :xs="24" :md="12">
            <img
              src="@/assets/images/locations/lacoste_content_img_03.jpg?v=1"
            />
          </el-col>
          <el-col :xs="24" :md="12">
            <div class="title3">从来访的艺术家那里获得慧眼经验</div>
            <div class="text">
              与 SCAD其他校区一样，来访的艺术家、设计师和专业人士来到
              SCAD拉科斯特是为了激发我们学生的灵感，同时受到他们的启发。最近的访客包括：

              <div style="margin-top: 15px">
                <div>● 当代艺术家Hugo Dalton</div>
                <div>● 伦敦室内设计师 Hannah Cork</div>
                <div>● 伦敦插画家和版画家Christopher Brown</div>
                <div>● 奥斯卡获奖编剧Geoffery Fletcher</div>
                <div>
                  ● CFDA 获奖时装设计师 Creatures of the Wind 品牌主理人
                </div>
              </div>
            </div>
          </el-col>
        </el-row>

        <div class="line"></div>

        <el-row :gutter="30" class="padding-left margin-top">
          <el-col :xs="24" :md="12">
            <div class="title3">在历史熏陶氛围中享受现代科技</div>
            <div class="text">
              在 SCAD拉科斯特校区，您将接触到历史悠久的 9 至 19
              世纪的设施，在具有悠久历史的环境中生活和学习。这些古色古香的空间经过精心翻修，包含您需要的所有高端资源，例如数字连接图书馆、版画和摄影实验室、工作室和讲座教室等。
            </div>
          </el-col>

          <el-col :xs="24" :md="12">
            <img
              src="@/assets/images/locations/lacoste_content_img_04.jpg?v=1"
            />
          </el-col>
        </el-row>

        <el-row :gutter="30" class="padding-left margin-top">
          <el-col :xs="24" :md="12">
            <div class="title3">探索巴斯之家不可磨灭的历史</div>
            <div class="text">
              在过去的 8
              个世纪里，巴斯之家经历了许多变迁，包括担任农舍、旅馆、驯熊者的中转站，以及臭名昭著的萨德侯爵
              (Marquis de Sade) 的马车房兼赌场。经过 40
              多年的疏忽管理，鲁巴隆山谷的这个核心部分年久失修，直到 SCAD
              一笔慷慨的捐赠帮助使其重新恢复生机。
            </div>
          </el-col>
          <el-col :xs="24" :md="12">
            <img
              src="@/assets/images/locations/lacoste_content_img_05.jpg?v=1"
            />
          </el-col>
        </el-row>

        <div class="line"></div>
        <el-row :gutter="30" class="padding-left margin-top">
          <el-col :xs="24" :md="12">
            <img
              src="@/assets/images/locations/lacoste_content_img_06.jpg?v=1"
            />
          </el-col>
          <el-col :xs="24" :md="12">
            <div class="title3">联系我们</div>
            <div class="text">
              立即索取更多信息，我们将向你发送开始SCAD激动人心的旅程所需的详细信息。
              <br /><br />
              <a
                target="_blank"
                href="https://app.jingsocial.com/microFrontend/forms/jsf-forms/details/fjbXnGDs4SQNrs7uL6dph7?lead_source_type=survey&lead_source_value=fjbXnGDs4SQNrs7uL6dph7&promotion_code=survey_qrcode&promotion_value=ifzDy6ceWJuk3qCYFxrEzD&promotion_channel_id=EuQLxTKCfzLJgdmf8Z2DzD&global_promotion_channel_id=3ZH8PjSow9oxzhbzfrcXAX&global_promotion_sub_channel_id=n9RQk7YyKX6he32EtHzFce&appId=wxe14b2fd0105ac9dc"
                style="color: #000000"
                >了解更多
              </a>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped lang="less">
@import "../Atlanta.less";
</style>
