<template>
  <div class="body">
    <formMenuBar></formMenuBar>
    <div class="header-container">
      <div class="title">Request for information</div>
      <div class="tips">
        We can’t wait to show you everything SCAD has to offer! First, let’s get
        to know you.
      </div>
    </div>

    <div class="content-container" id="content-container">
      <div class="form-nav-container">
        <div class="form-nav-list" v-if="formIndex == 0">
          <div class="form-nav-item active"><span>1</span>I am a...</div>
        </div>
        <div
          class="form-nav-list"
          v-if="formData.company == 'Prospective Student' && formIndex != 0"
        >
          <div
            class="form-nav-item"
            @click="formNavClick('Personal Information', 1)"
            :class="formIndex == 1 ? 'active' : ''"
          >
            <span>1</span>Tell us about yourself
          </div>
          <div
            class="form-nav-item"
            @click="formNavClick('Campus / Curriculum Information', 2)"
            :class="formIndex == 2 ? 'active' : ''"
          >
            <span>2</span>Where are you from?
          </div>
          <div
            class="form-nav-item"
            @click="formNavClick('Education History', 3)"
            :class="formIndex == 3 ? 'active' : ''"
          >
            <span>3</span>Which best describes you?
          </div>
          <div
            class="form-nav-item"
            @click="formNavClick('Guardian Information', 4)"
            :class="formIndex == 4 ? 'active' : ''"
          >
            <span>4</span>Tell us about your interests
          </div>
          <div
            class="form-nav-item"
            @click="formNavClick('Additional Information', 5)"
            :class="formIndex == 5 ? 'active' : ''"
          >
            <span>5</span>Last step
          </div>
        </div>

        <div
          class="form-nav-list"
          v-if="formData.company == 'Parent or Guardian' && formIndex != 0"
        >
          <div
            class="form-nav-item"
            @click="formNavClick('Personal Information', 1)"
            :class="formIndex == 1 ? 'active' : ''"
          >
            <span>1</span>Tell us about yourself
          </div>

          <div
            class="form-nav-item"
            @click="formNavClick('Campus / Curriculum Information', 2)"
            :class="formIndex == 2 ? 'active' : ''"
          >
            <span>2</span>Tell us about your student
          </div>

          <div
            class="form-nav-item"
            @click="formNavClick('Campus / Curriculum Information', 2)"
            :class="formIndex == 3 ? 'active' : ''"
          >
            <span>3</span>Where are your student from?
          </div>
          <div
            class="form-nav-item"
            @click="formNavClick('Education History', 3)"
            :class="formIndex == 4 ? 'active' : ''"
          >
            <span>4</span>Which best describes your student?
          </div>
          <div
            class="form-nav-item"
            @click="formNavClick('Guardian Information', 4)"
            :class="formIndex == 5 ? 'active' : ''"
          >
            <span>5</span>Tell us about your student's interests
          </div>
          <div
            class="form-nav-item"
            @click="formNavClick('Additional Information', 5)"
            :class="formIndex == 6 ? 'active' : ''"
          >
            <span>6</span>Last step
          </div>
        </div>
      </div>
      <div class="form-container">
        <div class="form-header-container" v-if="formIndex !== 0">
          <div class="form-title">{{ formTitle }}</div>
          <div class="form-title-tips">Fields marked with * are required</div>
        </div>

        <el-form
          :rules="rules"
          ref="ruleForm0"
          :model="formData"
          v-if="formIndex === 0"
          class="form-list"
        >
          <div class="form-row form-row-col-1" style="margin-top: 0">
            <el-form-item class="form-item" prop="company">
              <div class="form-label">I am a... *</div>
              <el-radio-group v-model="formData.company">
                <el-radio
                  label="Prospective Student"
                  value="Prospective Student"
                  >Prospective Student</el-radio
                >
                <el-radio label="Parent or Guardian" value="Parent or Guardian"
                  >Parent or Guardian</el-radio
                >
              </el-radio-group>
            </el-form-item>
          </div>
        </el-form>

        <div v-if="formData.company == 'Prospective Student'">
          <el-form
            v-if="formIndex === 1"
            :rules="rules"
            ref="ruleForm1"
            :model="formData"
            class="form-list"
          >
            <div class="form-row form-row-col-2">
              <el-form-item class="form-item" prop="firstname">
                <div class="form-label">First name (Given name) *</div>
                <el-input v-model="formData.firstname"></el-input>
              </el-form-item>

              <el-form-item class="form-item">
                <div class="form-label">Preferred English name</div>
                <el-input v-model="formData.preferredenglishname"></el-input>
              </el-form-item>
            </div>

            <div class="form-row form-row-col-2">
              <el-form-item class="form-item" prop="familyname">
                <div class="form-label">Last name (Family name) *</div>
                <el-input v-model="formData.familyname"></el-input>
              </el-form-item>
              <el-form-item class="form-item" prop="email">
                <div class="form-label">Email address *</div>
                <el-input v-model="formData.email"></el-input>
              </el-form-item>
            </div>

            <div class="form-row form-row-col-1">
              <el-form-item class="form-item" prop="dateofbirth">
                <div class="form-label">Date of birth *</div>
                <el-date-picker
                  :editable="false"
                  value-format="yyyy-MM-dd"
                  v-model="formData.dateofbirth"
                  style="width: 100%"
                  type="date"
                  placeholder="Choose..."
                >
                </el-date-picker>
              </el-form-item>
            </div>

            <div class="form-row form-row-col-1">
              <el-form-item class="form-item" prop="mobile">
                <div class="form-label">Phone number *</div>
                <el-input v-model="formData.mobile"></el-input>
              </el-form-item>
            </div>
          </el-form>

          <el-form
            v-if="formIndex === 2"
            :rules="rules"
            ref="ruleForm2"
            :model="formData"
            class="form-list"
          >
            <div class="form-row form-row-col-1">
              <el-form-item class="form-item" prop="countryc">
                <div class="form-label">Country *</div>
                <el-select
                  v-model="formData.countryc"
                  class="w-100"
                  placeholder="Choose..."
                >
                  <el-option
                    v-for="item in countrys"
                    :label="item.name"
                    :value="item.code"
                    :key="item.code"
                  ></el-option>
                </el-select>
              </el-form-item>
            </div>

            <div class="form-row form-row-col-1">
              <el-form-item class="form-item" prop="streetaddress">
                <div class="form-label">Street address *</div>
                <el-input
                  v-model="formData.streetaddress"
                  placeholder="Lane, road name, apartment, city"
                ></el-input>
              </el-form-item>
            </div>

            <div class="form-row form-row-col-1">
              <el-form-item class="form-item" prop="areyouauscitizen">
                <div class="form-label">Are you a U.S. citizen? *</div>
                <el-radio-group v-model="formData.areyouauscitizen">
                  <el-radio label="TRUE" value="TRUE">Yes</el-radio>
                  <el-radio label="FALSE" value="FALSE">No</el-radio>
                </el-radio-group>
              </el-form-item>
            </div>
          </el-form>

          <el-form
            v-if="formIndex === 3"
            :rules="rules"
            ref="ruleForm3"
            :model="formData"
            class="form-list"
          >
            <div class="form-row form-row-col-1">
              <el-form-item class="form-item" prop="rfiiama">
                <div class="form-label">I am a... *</div>
                <el-radio-group v-model="formData.rfiiama">
                  <el-radio
                    label="High school student"
                    value="High school student"
                    >High school student</el-radio
                  >
                  <el-radio
                    label="High school graduate"
                    value="High school graduate"
                    >High school graduate</el-radio
                  >
                  <el-radio label="College student" value="College student"
                    >College student</el-radio
                  >
                  <el-radio label="College graduate" value="College graduate"
                    >College graduate</el-radio
                  >
                </el-radio-group>
              </el-form-item>
            </div>

            <div
              class="form-row form-row-col-1"
              v-if="
                formData.rfiiama === 'High school student' ||
                formData.rfiiama === 'High school graduate'
              "
            >
              <el-form-item class="form-item" prop="rfinameofhighschool">
                <div class="form-label">Name of high school *</div>
                <el-input v-model="formData.rfinameofhighschool"></el-input>
              </el-form-item>
            </div>

            <div
              class="form-row form-row-col-1"
              v-if="formData.rfiiama === 'College graduate'"
            >
              <el-form-item
                class="form-item"
                prop="rfiwheredidyougraduatefromcollege"
              >
                <div class="form-label">
                  Where did you graduate from college? *
                </div>
                <el-input
                  v-model="formData.rfiwheredidyougraduatefromcollege"
                ></el-input>
              </el-form-item>
            </div>

            <div
              class="form-row form-row-col-1"
              v-if="formData.rfiiama === 'College student'"
            >
              <el-form-item
                class="form-item"
                prop="rfiwheredoyoucurrentlyattendcollege"
              >
                <div class="form-label">
                  Where do you currently attend college? *
                </div>
                <el-input
                  v-model="formData.rfiwheredoyoucurrentlyattendcollege"
                ></el-input>
              </el-form-item>
            </div>
          </el-form>

          <el-form
            v-if="formIndex === 4"
            :rules="rules"
            ref="ruleForm4"
            :model="formData"
            class="form-list"
          >
            <div class="form-row form-row-col-1">
              <el-form-item
                class="form-item"
                prop="rfiwhatscadlocationinterestsyouthemost"
              >
                <div class="form-label">
                  What SCAD location interests you the most?
                </div>
                <el-radio-group
                  v-model="formData.rfiwhatscadlocationinterestsyouthemost"
                >
                  <el-radio label="Atlanta"></el-radio>
                  <el-radio label="Savannah"></el-radio>
                  <el-radio label="SCADnow"></el-radio>
                </el-radio-group>
              </el-form-item>
            </div>

            <div class="form-row form-row-col-1">
              <el-form-item
                class="form-item"
                prop="rfiwhenwouldyouliketoenroll"
              >
                <div class="form-label">When would you like to enroll? *</div>
                <el-select
                  v-model="formData.rfiwhenwouldyouliketoenroll"
                  class="w-100"
                  placeholder="Choose..."
                >
                  <el-option
                    label="Winter (January) 2022"
                    value="202220"
                  ></el-option>
                  <el-option
                    label="Spring (March) 2022"
                    value="202230"
                  ></el-option>
                  <el-option
                    label="Summer (June) 2022"
                    value="202240"
                  ></el-option>
                  <el-option
                    label="Fall (September) 2022"
                    value="202310"
                  ></el-option>
                  <el-option
                    label="Winter (January) 2023"
                    value="202320"
                  ></el-option>
                  <el-option
                    label="Spring (March) 2023"
                    value="202330"
                  ></el-option>
                  <el-option
                    label="Summer (June) 2023"
                    value="202340"
                  ></el-option>
                  <el-option
                    label="Fall (September) 2023"
                    value="202410"
                  ></el-option>
                  <el-option
                    label="Winter (January) 2024"
                    value="202420"
                  ></el-option>
                </el-select>
              </el-form-item>
            </div>

            <div class="form-row form-row-col-1">
              <el-form-item
                class="form-item"
                prop="rfiwhatsyourintendedlevelofstudy"
              >
                <div class="form-label">
                  What's your intended level of study? *
                </div>
                <el-radio-group
                  v-model="formData.rfiwhatsyourintendedlevelofstudy"
                >
                  <el-radio label="Undergraduate"></el-radio>
                  <el-radio label="Graduate"></el-radio>
                </el-radio-group>
              </el-form-item>
            </div>

            <div
              class="form-row form-row-col-1"
              v-if="
                formData.rfiwhatscadlocationinterestsyouthemost != '' &&
                formData.rfiwhatsyourintendedlevelofstudy != ''
              "
            >
              <el-form-item
                class="form-item"
                prop="rfiwhatareyouinterestedinstudying"
              >
                <div class="form-label">
                  What are you interested in studying? *
                </div>
                <!--Atlanta-->
                <el-select
                  v-model="formData.rfiwhatareyouinterestedinstudying"
                  class="w-100"
                  placeholder="Choose..."
                  v-if="
                    formData.rfiwhatscadlocationinterestsyouthemost ===
                      'Atlanta' &&
                    formData.rfiwhatsyourintendedlevelofstudy ===
                      'Undergraduate'
                  "
                >
                  <el-option
                    v-for="item in Programs_RFI_Undergraduate_1"
                    :label="item.programs"
                    :value="item.value"
                    :key="item.value"
                  ></el-option>
                </el-select>

                <!--Savannah-->
                <el-select
                  v-model="formData.rfiwhatareyouinterestedinstudying"
                  class="w-100"
                  placeholder="Choose..."
                  v-if="
                    formData.rfiwhatscadlocationinterestsyouthemost ===
                      'Savannah' &&
                    formData.rfiwhatsyourintendedlevelofstudy ===
                      'Undergraduate'
                  "
                >
                  <el-option
                    v-for="item in Programs_RFI_Undergraduate_2"
                    :label="item.programs"
                    :value="item.value"
                    :key="item.value"
                  ></el-option>
                </el-select>

                <!--eLearning-->
                <el-select
                  v-model="formData.rfiwhatareyouinterestedinstudying"
                  class="w-100"
                  placeholder="Choose..."
                  v-if="
                    formData.rfiwhatscadlocationinterestsyouthemost ===
                      'eLearning' &&
                    formData.rfiwhatsyourintendedlevelofstudy ===
                      'Undergraduate'
                  "
                >
                  <el-option
                    v-for="item in Programs_RFI_Undergraduate_3"
                    :label="item.programs"
                    :value="item.value"
                    :key="item.value"
                  ></el-option>
                </el-select>

                <!--Atlanta-->
                <el-select
                  v-model="formData.rfiwhatareyouinterestedinstudying"
                  class="w-100"
                  placeholder="Choose..."
                  v-if="
                    formData.rfiwhatscadlocationinterestsyouthemost ===
                      'Atlanta' &&
                    formData.rfiwhatsyourintendedlevelofstudy === 'Graduate'
                  "
                >
                  <el-option
                    v-for="item in Programs_RFI_Graduate_1"
                    :label="item.programs"
                    :value="item.value"
                    :key="item.value"
                  ></el-option>
                </el-select>

                <!--Savannah-->
                <el-select
                  v-model="formData.rfiwhatareyouinterestedinstudying"
                  class="w-100"
                  placeholder="Choose..."
                  v-if="
                    formData.rfiwhatscadlocationinterestsyouthemost ===
                      'Savannah' &&
                    formData.rfiwhatsyourintendedlevelofstudy === 'Graduate'
                  "
                >
                  <el-option
                    v-for="item in Programs_RFI_Graduate_2"
                    :label="item.programs"
                    :value="item.value"
                    :key="item.value"
                  ></el-option>
                </el-select>

                <!--eLearning-->
                <el-select
                  v-model="formData.rfiwhatareyouinterestedinstudying"
                  class="w-100"
                  placeholder="Choose..."
                  v-if="
                    formData.rfiwhatscadlocationinterestsyouthemost ===
                      'eLearning' &&
                    formData.rfiwhatsyourintendedlevelofstudy === 'Graduate'
                  "
                >
                  <el-option
                    v-for="item in Programs_RFI_Graduate_3"
                    :label="item.programs"
                    :value="item.value"
                    :key="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </div>
          </el-form>

          <el-form
            v-if="formIndex === 5"
            :rules="rules"
            ref="ruleForm5"
            :model="formData"
            class="form-list"
          >
            <div class="form-row form-row-col-1">
              <el-form-item
                class="form-item"
                prop="rfiwouldyoulikeustosendinformation"
              >
                <div class="form-label">
                  Would you like us to send information to a parent or guardian,
                  too? *
                </div>
                <el-radio-group
                  v-model="formData.rfiwouldyoulikeustosendinformation"
                >
                  <el-radio label="TRUE">Yes</el-radio>
                  <el-radio label="FALSE">No</el-radio>
                </el-radio-group>
              </el-form-item>
            </div>
            <div class="form-row form-row-col-1">
              <el-form-item class="form-item" prop="rfiparentfirstname">
                <div class="form-label">Parent first name *</div>
                <el-input v-model="formData.rfiparentfirstname"></el-input>
              </el-form-item>
            </div>

            <div class="form-row form-row-col-1">
              <el-form-item class="form-item" prop="rfiparentlastname">
                <div class="form-label">Parent last name *</div>
                <el-input v-model="formData.rfiparentlastname"></el-input>
              </el-form-item>
            </div>

            <div class="form-row form-row-col-1">
              <el-form-item class="form-item" prop="rfiparentemail">
                <div class="form-label">Parent email *</div>
                <el-input v-model="formData.rfiparentemail"></el-input>
              </el-form-item>
            </div>

            <div class="form-row form-row-col-1">
              <el-form-item class="form-item" prop="rfiparentphonenumber">
                <div class="form-label">Parent phone number *</div>
                <el-input v-model="formData.rfiparentphonenumber"></el-input>
              </el-form-item>
            </div>

            <div class="form-row form-row-col-1">
              <el-form-item class="form-item" prop="contactconsent">
                <div class="form-label">
                  I would also like to stay up to date on SCAD events,
                  exhibitions, opportunities, and exclusive content. Contact me
                  by:
                </div>
                <el-radio-group v-model="formData.contactconsent">
                  <el-radio label="email">Email</el-radio>
                  <el-radio label="phone">Phone</el-radio>
                  <el-radio label="text"
                    >SMS/text message (Standard texting rates may
                    apply)</el-radio
                  >
                </el-radio-group>
              </el-form-item>
            </div>

            <div class="description">
              <div>
                By clicking “Submit”, you acknowledge that you have read and
                understand the
                <a href="https://www.scad.edu/privacy">privacy policy</a> and
                that SCAD may contact you via the information you have provided.
              </div>
            </div>
          </el-form>
        </div>

        <div v-if="formData.company == 'Parent or Guardian'">
          <el-form
            v-if="formIndex === 1"
            :rules="rules"
            ref="ruleForm1"
            :model="formData"
            class="form-list"
          >
            <div class="form-row form-row-col-2">
              <el-form-item class="form-item" prop="guardianfirstname">
                <div class="form-label">First name (Given name) *</div>
                <el-input v-model="formData.guardianfirstname"></el-input>
              </el-form-item>
              <el-form-item class="form-item" prop="guardianlastname">
                <div class="form-label">Last name (Family name) *</div>
                <el-input v-model="formData.guardianlastname"></el-input>
              </el-form-item>
            </div>

            <div class="form-row form-row-col-2">
              <el-form-item class="form-item" prop="guardianemail">
                <div class="form-label">Email address *</div>
                <el-input v-model="formData.guardianemail"></el-input>
              </el-form-item>
            </div>

            <div class="form-row form-row-col-1">
              <el-form-item class="form-item" prop="guardianphone">
                <div class="form-label">Phone number *</div>
                <el-input v-model="formData.guardianphone"></el-input>
              </el-form-item>
            </div>
          </el-form>

          <el-form
            v-if="formIndex === 2"
            :rules="rules"
            ref="ruleForm2"
            :model="formData"
            class="form-list"
          >
            <div class="form-row form-row-col-2">
              <el-form-item class="form-item" prop="firstname">
                <div class="form-label">First name (Given name) *</div>
                <el-input v-model="formData.firstname"></el-input>
              </el-form-item>

              <el-form-item class="form-item">
                <div class="form-label">Preferred English name</div>
                <el-input v-model="formData.preferredenglishname"></el-input>
              </el-form-item>
            </div>

            <div class="form-row form-row-col-2">
              <el-form-item class="form-item" prop="familyname">
                <div class="form-label">Last name (Family name) *</div>
                <el-input v-model="formData.familyname"></el-input>
              </el-form-item>
              <el-form-item class="form-item" prop="email">
                <div class="form-label">Email address *</div>
                <el-input v-model="formData.email"></el-input>
              </el-form-item>
            </div>

            <div class="form-row form-row-col-1">
              <el-form-item class="form-item" prop="dateofbirth">
                <div class="form-label">Date of birth *</div>
                <el-date-picker
                  :editable="false"
                  value-format="yyyy-MM-dd"
                  v-model="formData.dateofbirth"
                  style="width: 100%"
                  type="date"
                  placeholder="Choose..."
                >
                </el-date-picker>
              </el-form-item>
            </div>

            <div class="form-row form-row-col-1">
              <el-form-item class="form-item" prop="mobile">
                <div class="form-label">Phone number *</div>
                <el-input v-model="formData.mobile"></el-input>
              </el-form-item>
            </div>
          </el-form>

          <el-form
            v-if="formIndex === 3"
            :rules="rules"
            ref="ruleForm3"
            :model="formData"
            class="form-list"
          >
            <div class="form-row form-row-col-1">
              <el-form-item class="form-item" prop="countryc">
                <div class="form-label">Country *</div>
                <el-select
                  v-model="formData.countryc"
                  class="w-100"
                  placeholder="Choose..."
                >
                  <el-option
                    v-for="item in countrys"
                    :label="item.name"
                    :value="item.code"
                    :key="item.code"
                  ></el-option>
                </el-select>
              </el-form-item>
            </div>

            <div class="form-row form-row-col-1">
              <el-form-item class="form-item" prop="streetaddress">
                <div class="form-label">Street address *</div>
                <el-input
                  v-model="formData.streetaddress"
                  placeholder="Lane, road name, apartment, city"
                ></el-input>
              </el-form-item>
            </div>

            <div class="form-row form-row-col-1">
              <el-form-item class="form-item" prop="areyouauscitizen">
                <div class="form-label">Is your student a U.S. citizen? *</div>
                <el-radio-group v-model="formData.areyouauscitizen">
                  <el-radio label="TRUE" value="TRUE">Yes</el-radio>
                  <el-radio label="FALSE" value="FALSE">No</el-radio>
                </el-radio-group>
              </el-form-item>
            </div>
          </el-form>

          <el-form
            v-if="formIndex === 4"
            :rules="rules"
            ref="ruleForm4"
            :model="formData"
            class="form-list"
          >
            <div class="form-row form-row-col-1">
              <el-form-item class="form-item" prop="rfiiama">
                <div class="form-label">My student is a... *</div>
                <el-radio-group v-model="formData.rfiiama">
                  <el-radio
                    label="High school student"
                    value="High school student"
                    >High school student</el-radio
                  >
                  <el-radio
                    label="High school graduate"
                    value="High school graduate"
                    >High school graduate</el-radio
                  >
                  <el-radio label="College student" value="College student"
                    >College student</el-radio
                  >
                  <el-radio label="College graduate" value="College graduate"
                    >College graduate</el-radio
                  >
                </el-radio-group>
              </el-form-item>
            </div>

            <div
              class="form-row form-row-col-1"
              v-if="
                formData.rfiiama === 'High school student' ||
                formData.rfiiama === 'High school graduate'
              "
            >
              <el-form-item class="form-item" prop="rfinameofhighschool">
                <div class="form-label">Name of high school *</div>
                <el-input v-model="formData.rfinameofhighschool"></el-input>
              </el-form-item>
            </div>

            <div
              class="form-row form-row-col-1"
              v-if="formData.rfiiama === 'College graduate'"
            >
              <el-form-item
                class="form-item"
                prop="rfiwheredidyougraduatefromcollege"
              >
                <div class="form-label">
                  Where did your student graduate from college? *
                </div>
                <el-input
                  v-model="formData.rfiwheredidyougraduatefromcollege"
                ></el-input>
              </el-form-item>
            </div>

            <div
              class="form-row form-row-col-1"
              v-if="formData.rfiiama === 'College student'"
            >
              <el-form-item
                class="form-item"
                prop="rfiwheredoyoucurrentlyattendcollege"
              >
                <div class="form-label">
                  Where does your student currently attend college? *
                </div>
                <el-input
                  v-model="formData.rfiwheredoyoucurrentlyattendcollege"
                ></el-input>
              </el-form-item>
            </div>
          </el-form>

          <el-form
            v-if="formIndex === 5"
            :rules="rules"
            ref="ruleForm5"
            :model="formData"
            class="form-list"
          >
            <div class="form-row form-row-col-1">
              <el-form-item
                class="form-item"
                prop="rfiwhatscadlocationinterestsyouthemost"
              >
                <div class="form-label">
                  What SCAD location interests your student most?
                </div>
                <el-radio-group
                  v-model="formData.rfiwhatscadlocationinterestsyouthemost"
                >
                  <el-radio label="Atlanta"></el-radio>
                  <el-radio label="Savannah"></el-radio>
                  <el-radio label="SCADnow"></el-radio>
                </el-radio-group>
              </el-form-item>
            </div>

            <div class="form-row form-row-col-1">
              <el-form-item
                class="form-item"
                prop="rfiwhenwouldyouliketoenroll"
              >
                <div class="form-label">
                  When would your student intend to enroll? *
                </div>
                <el-select
                  v-model="formData.rfiwhenwouldyouliketoenroll"
                  class="w-100"
                  placeholder="Choose..."
                >
                  <el-option
                    label="Winter (January) 2022"
                    value="202220"
                  ></el-option>
                  <el-option
                    label="Spring (March) 2022"
                    value="202230"
                  ></el-option>
                  <el-option
                    label="Summer (June) 2022"
                    value="202240"
                  ></el-option>
                  <el-option
                    label="Fall (September) 2022"
                    value="202310"
                  ></el-option>
                  <el-option
                    label="Winter (January) 2023"
                    value="202320"
                  ></el-option>
                  <el-option
                    label="Spring (March) 2023"
                    value="202330"
                  ></el-option>
                  <el-option
                    label="Summer (June) 2023"
                    value="202340"
                  ></el-option>
                  <el-option
                    label="Fall (September) 2023"
                    value="202410"
                  ></el-option>
                  <el-option
                    label="Winter (January) 2024"
                    value="202420"
                  ></el-option>
                </el-select>
              </el-form-item>
            </div>

            <div class="form-row form-row-col-1">
              <el-form-item
                class="form-item"
                prop="rfiwhatsyourintendedlevelofstudy"
              >
                <div class="form-label">
                  What is your student's intended level of study? *
                </div>
                <el-radio-group
                  v-model="formData.rfiwhatsyourintendedlevelofstudy"
                >
                  <el-radio label="Undergraduate"></el-radio>
                  <el-radio label="Graduate"></el-radio>
                </el-radio-group>
              </el-form-item>
            </div>

            <div
              class="form-row form-row-col-1"
              v-if="
                formData.rfiwhatscadlocationinterestsyouthemost != '' &&
                formData.rfiwhatsyourintendedlevelofstudy != ''
              "
            >
              <el-form-item
                class="form-item"
                prop="rfiwhatareyouinterestedinstudying"
              >
                <div class="form-label">
                  What is your student interested in studying? *
                </div>
                <!--<el-select-->
                <!--  v-model="formData.rfiwhatareyouinterestedinstudying"-->
                <!--  class="w-100"-->
                <!--  placeholder="Choose..."-->
                <!--&gt;-->
                <!--  <el-option-->
                <!--    v-for="item in intendedProgram"-->
                <!--    :label="item.programs"-->
                <!--    :value="item.value"-->
                <!--    :key="item.value"-->
                <!--  ></el-option>-->
                <!--</el-select>-->

                <!--Atlanta-->
                <el-select
                  v-model="formData.rfiwhatareyouinterestedinstudying"
                  class="w-100"
                  placeholder="Choose..."
                  v-if="
                    formData.rfiwhatscadlocationinterestsyouthemost ===
                      'Atlanta' &&
                    formData.rfiwhatsyourintendedlevelofstudy ===
                      'Undergraduate'
                  "
                >
                  <el-option
                    v-for="item in Programs_RFI_Undergraduate_1"
                    :label="item.programs"
                    :value="item.value"
                    :key="item.value"
                  ></el-option>
                </el-select>

                <!--Savannah-->
                <el-select
                  v-model="formData.rfiwhatareyouinterestedinstudying"
                  class="w-100"
                  placeholder="Choose..."
                  v-if="
                    formData.rfiwhatscadlocationinterestsyouthemost ===
                      'Savannah' &&
                    formData.rfiwhatsyourintendedlevelofstudy ===
                      'Undergraduate'
                  "
                >
                  <el-option
                    v-for="item in Programs_RFI_Undergraduate_2"
                    :label="item.programs"
                    :value="item.value"
                    :key="item.value"
                  ></el-option>
                </el-select>

                <!--eLearning-->
                <el-select
                  v-model="formData.rfiwhatareyouinterestedinstudying"
                  class="w-100"
                  placeholder="Choose..."
                  v-if="
                    formData.rfiwhatscadlocationinterestsyouthemost ===
                      'eLearning' &&
                    formData.rfiwhatsyourintendedlevelofstudy ===
                      'Undergraduate'
                  "
                >
                  <el-option
                    v-for="item in Programs_RFI_Undergraduate_3"
                    :label="item.programs"
                    :value="item.value"
                    :key="item.value"
                  ></el-option>
                </el-select>

                <!--Atlanta-->
                <el-select
                  v-model="formData.rfiwhatareyouinterestedinstudying"
                  class="w-100"
                  placeholder="Choose..."
                  v-if="
                    formData.rfiwhatscadlocationinterestsyouthemost ===
                      'Atlanta' &&
                    formData.rfiwhatsyourintendedlevelofstudy === 'Graduate'
                  "
                >
                  <el-option
                    v-for="item in Programs_RFI_Graduate_1"
                    :label="item.programs"
                    :value="item.value"
                    :key="item.value"
                  ></el-option>
                </el-select>

                <!--Savannah-->
                <el-select
                  v-model="formData.rfiwhatareyouinterestedinstudying"
                  class="w-100"
                  placeholder="Choose..."
                  v-if="
                    formData.rfiwhatscadlocationinterestsyouthemost ===
                      'Savannah' &&
                    formData.rfiwhatsyourintendedlevelofstudy === 'Graduate'
                  "
                >
                  <el-option
                    v-for="item in Programs_RFI_Graduate_2"
                    :label="item.programs"
                    :value="item.value"
                    :key="item.value"
                  ></el-option>
                </el-select>

                <!--eLearning-->
                <el-select
                  v-model="formData.rfiwhatareyouinterestedinstudying"
                  class="w-100"
                  placeholder="Choose..."
                  v-if="
                    formData.rfiwhatscadlocationinterestsyouthemost ===
                      'eLearning' &&
                    formData.rfiwhatsyourintendedlevelofstudy === 'Graduate'
                  "
                >
                  <el-option
                    v-for="item in Programs_RFI_Graduate_3"
                    :label="item.programs"
                    :value="item.value"
                    :key="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </div>
          </el-form>

          <el-form
            v-if="formIndex === 6"
            :rules="rules"
            ref="ruleForm6"
            :model="formData"
            class="form-list"
          >
            <div class="form-row form-row-col-1">
              <el-form-item
                class="form-item"
                prop="rfiwouldyoulikeustosendinformation"
              >
                <div class="form-label">
                  Would you like us to send information to a parent or guardian,
                  too? *
                </div>
                <el-radio-group
                  v-model="formData.rfiwouldyoulikeustosendinformation"
                >
                  <el-radio label="TRUE">Yes</el-radio>
                  <el-radio label="FALSE">No</el-radio>
                </el-radio-group>
              </el-form-item>
            </div>
            <div class="form-row form-row-col-1">
              <el-form-item class="form-item" prop="rfiparentfirstname">
                <div class="form-label">Parent first name *</div>
                <el-input v-model="formData.rfiparentfirstname"></el-input>
              </el-form-item>
            </div>

            <div class="form-row form-row-col-1">
              <el-form-item class="form-item" prop="rfiparentlastname">
                <div class="form-label">Parent last name *</div>
                <el-input v-model="formData.rfiparentlastname"></el-input>
              </el-form-item>
            </div>

            <div class="form-row form-row-col-1">
              <el-form-item class="form-item" prop="rfiparentemail">
                <div class="form-label">Parent email *</div>
                <el-input v-model="formData.rfiparentemail"></el-input>
              </el-form-item>
            </div>

            <div class="form-row form-row-col-1">
              <el-form-item class="form-item" prop="rfiparentphonenumber">
                <div class="form-label">Parent phone number *</div>
                <el-input v-model="formData.rfiparentphonenumber"></el-input>
              </el-form-item>
            </div>

            <div class="form-row form-row-col-1">
              <el-form-item class="form-item" prop="contactconsent">
                <div class="form-label">
                  I would also like to stay up to date on SCAD events,
                  exhibitions, opportunities, and exclusive content. Contact me
                  by:
                </div>
                <el-radio-group v-model="formData.contactconsent">
                  <el-radio label="email">Email</el-radio>
                  <el-radio label="phone">Phone</el-radio>
                  <el-radio label="text"
                    >SMS/text message (Standard texting rates may
                    apply)</el-radio
                  >
                </el-radio-group>
              </el-form-item>
            </div>

            <div class="description">
              <div>
                By clicking “Submit”, you acknowledge that you have read and
                understand the
                <a href="https://www.scad.edu/privacy">privacy policy</a> and
                that SCAD may contact you via the information you have provided.
              </div>
            </div>
          </el-form>
        </div>

        <div class="form-btns">
          <el-button
            type="primary"
            v-if="formIndex != 0"
            :round="false"
            @click="previousClick()"
            >Previous</el-button
          >
          <el-button
            type="primary"
            v-if="formData.company == 'Prospective Student' && formIndex != 5"
            :round="false"
            @click="nextClick()"
            >Next</el-button
          >

          <el-button
            type="primary"
            v-if="formData.company == 'Prospective Student' && formIndex == 5"
            :round="false"
            @click="submitClick()"
            :loading="saveStatus"
            >Submit</el-button
          >

          <el-button
            type="primary"
            v-if="formData.company == 'Parent or Guardian' && formIndex != 6"
            :round="false"
            @click="nextClick()"
            >Next</el-button
          >

          <el-button
            type="primary"
            v-if="formData.company == 'Parent or Guardian' && formIndex == 6"
            :round="false"
            @click="submitClick()"
            :loading="saveStatus"
            >Submit</el-button
          >
        </div>
      </div>
    </div>

    <el-dialog
      title="Prompt"
      :visible.sync="dialogVisible"
      width="30%"
      @close="closeFun"
    >
      <span>Submitted successfully</span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false">OK</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import formMenuBar from "@/views/form/formMenuBar";
import Programs from "@/data/Programs";
import Country from "@/data/Country";
import GraduateApplicationRules from "@/views/form/GraduateApplicationRules";
// import jingTrack from "@/utils/jingTrack";
import Citizenship from "@/data/Citizenship";
import Programs_RFI_Undergraduate_1 from "@/data/RFI/Programs_RFI_Undergraduate_1";
import Programs_RFI_Undergraduate_2 from "@/data/RFI/Programs_RFI_Undergraduate_2";
import Programs_RFI_Undergraduate_3 from "@/data/RFI/Programs_RFI_Undergraduate_3";
import Programs_RFI_Graduate_1 from "@/data/RFI/Programs_RFI_Graduate_1";
import Programs_RFI_Graduate_2 from "@/data/RFI/Programs_RFI_Graduate_2";
import Programs_RFI_Graduate_3 from "@/data/RFI/Programs_RFI_Graduate_3";

var formatNum = function (num) {
  return num < 10 ? "0" + num : num;
};

export default {
  name: "RFI",
  metaInfo: {
    title: "Request for information", // set a title
  },
  components: {
    formMenuBar,
  },
  data() {
    return {
      dialogVisible: false,
      formTitle: "",
      formIndex: 0,
      name: "",
      saveStatus: false,
      intendedProgram: Programs,
      Programs_RFI_Undergraduate_1: Programs_RFI_Undergraduate_1,
      Programs_RFI_Undergraduate_2: Programs_RFI_Undergraduate_2,
      Programs_RFI_Undergraduate_3: Programs_RFI_Undergraduate_3,
      Programs_RFI_Graduate_1: Programs_RFI_Graduate_1,
      Programs_RFI_Graduate_2: Programs_RFI_Graduate_2,
      Programs_RFI_Graduate_3: Programs_RFI_Graduate_3,
      countrys: Country,
      rules: GraduateApplicationRules,
      countryOfCitizenship: Citizenship,
      formData: {
        rfisource: "",
        applicationidentity: "Request for information",
        // i_am_a: "Prospective Student",
        firstname: "",
        familyname: "",
        email: "",
        confirmemail: "",
        company: "",
        leadstatus: "Open",
        dateofbirth: "",
        countryc: "",
        streetaddress: "",
        mobile: "",
        areyouauscitizen: null,
        // countryofcitizenship: "",
        // intendedlocation: "",
        // termofentry: "",
        // enrollmentstatus: "",
        // intendedprogramofstudentfirstchoice: "",
        // intendedprogramofstudentsecondchoice: "",
        // intendedprogramofstudentthirdchoice: "",
        // campusdegree: "",
        // nameofuniversityattended: "",
        // universitycity: "",
        // dateattendedfromuniversityonly: "",
        // dateattendedtouniversityonly: "",
        // degreeuniversityonly: "",
        // dateofgraduationuniversityonly: "",
        probationandsuspendedexpelled: "",
        convicted: "",
        guardianrelationship: "",
        guardianemail: "",
        guardianfirstname: "",
        guardianlastname: "",
        guardianaddressline1: "",
        guardianphone: "",
        contactconsent: "",
        scadrulesagreementyesorno: "",
        // xxxxxx: "分割",
        rfiiama: "",
        rfinameofhighschool: "",
        rfiwheredidyougraduatefromcollege: "",
        rfiwheredoyoucurrentlyattendcollege: "",
        rfiwhatscadlocationinterestsyouthemost: "",
        rfiwhenwouldyouliketoenroll: "",
        rfiwhatsyourintendedlevelofstudy: "",
        rfiwhatareyouinterestedinstudying: "",
        rfiwouldyoulikeustosendinformation: "",
        rfiparentfirstname: "",
        rfiparentlastname: "",
        rfiparentemail: "",
        rfiparentphonenumber: "",
      },
    };
  },
  watch: {
    formIndex() {
      if (this.formData.company == "Prospective Student") {
        if (this.formIndex == 1) {
          this.formTitle = "Tell us about yourself";
        } else if (this.formIndex == 2) {
          this.formTitle = "Where are you from?";
        } else if (this.formIndex == 3) {
          this.formTitle = "Which best describes you?";
        } else if (this.formIndex == 4) {
          this.formTitle = "Tell us about your interests";
        } else if (this.formIndex == 5) {
          this.formTitle = "Last step";
        }
      } else if (this.formData.company == "Parent or Guardian") {
        if (this.formIndex == 1) {
          this.formTitle = "Tell us about yourself";
        } else if (this.formIndex == 2) {
          this.formTitle = "Tell us about your student";
        } else if (this.formIndex == 3) {
          this.formTitle = "Where are your student from?";
        } else if (this.formIndex == 4) {
          this.formTitle = "Which best describes your student?";
        } else if (this.formIndex == 5) {
          this.formTitle = "Tell us about your student's interests";
        } else if (this.formIndex == 6) {
          this.formTitle = "Last step";
        }
      }
    },
  },

  created() {
    // console.log(this.formatDate(new Date()));
    if (this.$route.query["source"]) {
      this.formData.rfisource = this.$route.query["source"];
      console.log("当前渠道：" + this.formData.rfisource);
    }
  },
  methods: {
    formatDate(value) {
      var year = value.getFullYear();
      var month = formatNum(value.getMonth() + 1);
      var day = formatNum(value.getDate());
      var hour = formatNum(value.getHours());
      var min = formatNum(value.getMinutes());
      var sec = formatNum(value.getSeconds());
      return (
        year + "-" + month + "-" + day + " " + hour + ":" + min + ":" + sec
      );
    },
    // eslint-disable-next-line no-unused-vars
    formNavClick(name, index) {
      // this.formTitle = name;
      // this.formIndex = index;
    },
    previousClick() {
      if (this.formIndex <= 0) {
        this.formIndex = 0;
        return;
      }
      this.formIndex--;
      document.getElementById("content-container").scrollIntoView();
    },
    nextClick() {
      let formName = "ruleForm" + this.formIndex;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.formData.company == "Prospective Student") {
            if (this.formIndex >= 5) {
              this.formIndex = 5;
              return;
            }
          } else if (this.formData.company == "Parent or Guardian") {
            if (this.formIndex >= 6) {
              this.formIndex = 6;
              return;
            }
          }

          this.formIndex++;
          document.getElementById("content-container").scrollIntoView();
          return false;
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    submitClick() {
      let formName = "ruleForm" + this.formIndex;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.sumit();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    sumit() {
      var that = this;
      this.saveStatus = true;

      this.formData["mobile"] = "+86 " + this.formData["mobile"];
      this.formData["guardianphone"] = "+86 " + this.formData["guardianphone"];
      this.formData["formsubmittime"] = this.formatDate(new Date());
      // jingTrack.trackToUser(this.formData);
      // setTimeout(function () {
      //   that.saveStatus = false;
      //   that.dialogVisible = true;
      // }, 1000);

      this.$api.post("AcademicsPrograms/postJING", this.formData, (r) => {
        console.log(r);
        // that.obj = r.data.data;
        // that.areaList = r.data.data.area.split(";");
        that.saveStatus = false;
        that.dialogVisible = true;
      });
    },
    closeFun() {
      window.location.reload();
    },
  },
};
</script>

<style lang="less">
.el-dialog__wrapper {
  .el-dialog {
    @media (max-width: 600px) {
      width: 85% !important;
    }
  }
}
</style>

<style scoped lang="less">
@import "GraduateApplication.less";
.body {
  padding: 20px 30px 0 30px;
  background: #fcf9f6;

  @media (max-width: 800px) {
    padding: 10px 10px 0 10px;
  }
}
</style>
