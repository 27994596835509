<template>
  <div class="cust-container">
    <menuBar></menuBar>
    <div>
      <div class="banner">
        <img src="@/assets/images/international_students/banner.jpg" />
        <div class="text" v-if="$i18n.locale === 'en'">
          INTERNATIONAL STUDENTS
        </div>
        <div class="text" v-if="$i18n.locale === 'zh'">国际学生</div>
      </div>

      <div class="content" v-if="$i18n.locale === 'en'">
        <div class="title">
          Getting started at SCAD as an international student
        </div>
        <div class="desc">
          <ul>
            <li>Apply</li>
            <li>Login to MyAdmission</li>
            <li>Submit your documents</li>
          </ul>
          <div>
            Once you have submitted your online application, you will receive an
            invitation to
            <a href="https://www.scad.edu/psp/login">log in to MyAdmission</a>.
            An admission adviser will be assigned to you to help guide you
            through the remaining steps.
          </div>
        </div>

        <div class="title1">
          Admission requirements for a first-year freshman international student
        </div>
        <div class="text1">
          Below is the list of materials you will need to submit to finalize
          your application to SCAD as a first-year undergraduate applicant.
          These materials can be submitted at any time to your adviser via
          email, standard mail, and in some cases, online.
        </div>
        <table border="1" cellspacing="0" cellpadding="0">
          <tr>
            <td>Official High School Diploma</td>
          </tr>
          <tr>
            <td>Evidence of English proficiency</td>
          </tr>
          <tr>
            <td>Supplementary materials</td>
          </tr>
          <tr>
            <td>Documents for visa application</td>
          </tr>
          <tr>
            <td>Additional materials</td>
          </tr>
          <tr>
            <td>
              <a
                href="https://scad-minisite.oss-cn-beijing.aliyuncs.com/files/220120_ADM-Info-freshman-international-2022-SCN-Translation-14pages.docx"
                >Download the entire list</a
              >
            </td>
          </tr>
        </table>

        <div class="title1">
          Admission requirements for an international transfer student
        </div>
        <div class="text1">
          Below is the list of documents you will need to submit to finalize
          your application to SCAD as an undergraduate transfer applicant. These
          documents can be submitted at any time to your adviser via email,
          standard mail, and in some cases, online.
        </div>
        <table border="1" cellspacing="0" cellpadding="0">
          <tr>
            <td>Official transcripts from colleges or universities attended</td>
          </tr>
          <tr>
            <td>Evidence of English proficiency</td>
          </tr>
          <tr>
            <td>Supplementary materials</td>
          </tr>
          <tr>
            <td>Documents for visa application</td>
          </tr>
          <tr>
            <td>Additional materials</td>
          </tr>
          <tr>
            <td>
              <a
                href="https://scad-minisite.oss-cn-beijing.aliyuncs.com/files/220120_ADM-Info-transfer-international-2022-SCN-Translation-12pages.docx"
                >Download the entire list</a
              >
            </td>
          </tr>
        </table>

        <div class="title1">
          Admission requirements for an international graduate student
        </div>
        <div class="text1">
          Below is the list of documents you will need to submit to finalize
          your application to SCAD as an international graduate applicant. These
          documents can be submitted at any time to your adviser via email,
          standard mail, and in some cases, online.
        </div>

        <table border="1" cellspacing="0" cellpadding="0">
          <tr>
            <td>Bachelor’s degree or its equivalent</td>
          </tr>
          <tr>
            <td>Portfolio</td>
          </tr>
          <tr>
            <td>Recommendations</td>
          </tr>
          <tr>
            <td>Resume</td>
          </tr>
          <tr>
            <td>Statement of purpose</td>
          </tr>
          <tr>
            <td>Evidence of English proficiency</td>
          </tr>
          <tr>
            <td>Interview (if applicable)</td>
          </tr>
          <tr>
            <td>GRE scores (if applicable)</td>
          </tr>
          <tr>
            <td>Documents for visa application</td>
          </tr>
          <tr>
            <td>Additional materials</td>
          </tr>
          <tr>
            <td>
              <a
                href="https://scad-minisite.oss-cn-beijing.aliyuncs.com/files/220120_ADM-Info-graduate-international-2022-SCN-Translation-18pages.docx"
                >Download the entire list</a
              >
            </td>
          </tr>
        </table>

        <div class="title1">The application process</div>
        <div class="text1">
          SCAD accepts new applications throughout the year. There is no
          deadline to apply. Admission decisions typically take between two and
          four weeks, and applicants should apply at least 30 days before their
          intended term of entry.
        </div>

        <div class="text1">
          New students may enroll:

          <ul>
            <li>Fall quarter (begins in September)</li>
            <li>Winter quarter (begins in January)</li>
            <li>Spring quarter (begins in March)</li>
            <li>Summer quarter (begins in June)</li>
          </ul>
        </div>

        <div class="title1">Financial aid and scholarship opportunities</div>
        <div class="text1">
          Although international students are not eligible to receive U.S.
          federal or state financial aid, all admitted students are
          automatically considered for institutional scholarships. There is no
          need to apply for these scholarships. These funds are limited and are
          awarded to the earliest qualifiers.
        </div>

        <div class="title1">Portfolios</div>
        <div class="text1">
          Portfolios and auditions are not required for undergraduate admission.
          However, freshman and transfer applicants are strongly encouraged to
          submit one if possible to be considered for scholarships.
        </div>

        <div class="text1">
          Graduate-level international applicants are required to submit a
          portfolio or audition with the added benefit of also being considered
          for scholarships.
        </div>

        <div class="title1">Preparing your visa for travel</div>
        <div class="text1">
          New students who are not U.S. citizens or permanent residents will
          need to obtain visas to enter the country. Generally, you obtain a
          visa through a U.S. consulate in your area, so you will need to
          identify which consulate you will be working with. Your admission
          adviser, assigned after submitting your application, will be able to
          provide assistance and answer any questions you may have.
        </div>

        <div class="title1">Proof of financial support</div>
        <div class="text1">
          Specific financial documentation is required to apply for a U.S.
          student visa, including a sponsorship letter addressed to SCAD and a
          Certificate of Financial Support that officially documents a sponsor's
          adequate bank funds.that shows a student and/or their sponsor have
          adequate bank funds to support at least one year of tuition and U.S.
          living expenses.
        </div>
      </div>

      <div class="content" v-if="$i18n.locale === 'zh'">
        <div class="title">国际学生如何开启在 SCAD的生活</div>
        <div class="desc">
          <ul>
            <li>申请</li>
            <li>登录 MyAdmission</li>
            <li>提交您的文件</li>
          </ul>
          <div>
            提交在线申请后，您将收到<a href="https://www.scad.edu/psp/login"
              >登录 MyAdmission</a
            >
            的邀请。 我们将分配给您一名招生顾问，以帮助指导您完成剩余的步骤。
          </div>
        </div>

        <div class="title1">
          Admission requirements for a first-year freshman international student
          国际大一新生入学要求
        </div>
        <div class="text1">
          以下是作为本科一年级申请人向 SCAD 申请所需提交的材料清单。
          这些材料可以随时通过电子邮件、纸质邮件以及在某些情况下在线提交给您的顾问。
        </div>
        <table border="1" cellspacing="0" cellpadding="0">
          <tr>
            <td>官方高中文凭</td>
          </tr>
          <tr>
            <td>英语能力证明</td>
          </tr>
          <tr>
            <td>补充材料</td>
          </tr>
          <tr>
            <td>签证申请文件</td>
          </tr>
          <tr>
            <td>附加材料</td>
          </tr>
          <tr>
            <td>
              <a
                href="https://scad-minisite.oss-cn-beijing.aliyuncs.com/files/220120_ADM-Info-freshman-international-2022-SCN-Translation-14pages.docx"
                target="_blank"
                >下载整个列表</a
              >
            </td>
          </tr>
        </table>

        <div class="title1">国际转学生的入学要求</div>
        <div class="text1">
          以下是您作为本科转学申请人需要提交的文件清单，以完成您向 SCAD 的申请。
          这些文件可以随时通过电子邮件、纸质邮件以及在某些情况下在线提交给您的顾问。
        </div>
        <table border="1" cellspacing="0" cellpadding="0">
          <tr>
            <td>参加过的学院或大学的正式成绩单</td>
          </tr>
          <tr>
            <td>英语能力证明</td>
          </tr>
          <tr>
            <td>补充材料</td>
          </tr>
          <tr>
            <td>签证申请文件</td>
          </tr>
          <tr>
            <td>附加材料</td>
          </tr>
          <tr>
            <td>
              <a
                href="https://scad-minisite.oss-cn-beijing.aliyuncs.com/files/220120_ADM-Info-transfer-international-2022-SCN-Translation-12pages.docx"
                target="_blank"
                >下载整个列表</a
              >
            </td>
          </tr>
        </table>

        <div class="title1">国际研究生入学要求</div>
        <div class="text1">
          以下是您作为国际研究生需要提交的文件清单，以完成向 SCAD 的申请。
          这些文件可以随时通过电子邮件、纸质邮件以及在某些情况下在线提交给您的顾问。
        </div>

        <table border="1" cellspacing="0" cellpadding="0">
          <tr>
            <td>学士学位证或同等学历</td>
          </tr>
          <tr>
            <td>作品集</td>
          </tr>
          <tr>
            <td>推荐信</td>
          </tr>
          <tr>
            <td>简历</td>
          </tr>
          <tr>
            <td>个人陈述</td>
          </tr>
          <tr>
            <td>英语能力证明</td>
          </tr>
          <tr>
            <td>面试（如有）</td>
          </tr>
          <tr>
            <td>GRE 成绩（如有）</td>
          </tr>
          <tr>
            <td>签证申请文件</td>
          </tr>
          <tr>
            <td>附加材料</td>
          </tr>
          <tr>
            <td>
              <a
                href="https://scad-minisite.oss-cn-beijing.aliyuncs.com/files/220120_ADM-Info-graduate-international-2022-SCN-Translation-18pages.docx"
                target="_blank"
                >下载整个列表</a
              >
            </td>
          </tr>
        </table>

        <div class="title1">申请流程</div>
        <div class="text1">
          SCAD 全年都接受新的申请。 申请没有截止日期。 录取通常需要 2 到 4
          周时间得到最终结果，申请人应在预期入学期限前至少 30 天提出申请。
        </div>

        <div class="text1">
          新生可以报名开学的时间：

          <ul>
            <li>秋季学期（九月开始）</li>
            <li>冬季学期（一月开始）</li>
            <li>春季学期（三月开始）</li>
            <li>夏季学期（从六月开始）</li>
          </ul>
        </div>

        <div class="title1">经济援助和奖学金机会</div>
        <div class="text1">
          尽管国际学生没有资格获得美国联邦或州的经济援助，但所有被录取的学生都会被自动考虑获得学校奖学金。
          你无需申请这些奖学金。
          这些资金是有限的，将被授予最早申请，且满足要求的学生。
        </div>

        <div class="title1">Portfolios作品集</div>
        <div class="text1">
          本科录取不需要作品集和面试。
          但是，如果可能的话，强烈建议新生和转学申请者提交一份申请奖学金。
        </div>

        <div class="text1">
          研究生水平的国际申请者必须提交作品集或面试，并且会被考虑获得奖学金的机会。
        </div>

        <div class="title1">准备旅行签证</div>
        <div class="text1">
          不是美国公民或永久居民的新生将需要获得签证才能进入美国。
          通常，您通过您所在地区的美国领事馆获得签证，因此您需要提前确定您将与哪个领事馆申请签证。
          提交申请后分配的招生顾问将能够提供帮助并回答您可能遇到的任何问题。
        </div>

        <div class="title1">经济支持证明</div>
        <div class="text1">
          申请美国学生签证需要特定的财务文件，包括一封寄给 SCAD
          的赞助信和一份正式记录学生资助者充足银行资金的财务支持证明。
        </div>
      </div>
    </div>

    <goTop></goTop>
    <custFooter></custFooter>
  </div>
</template>

<script>
import menuBar from "../../components/menuBar/index.vue";
import custFooter from "@/components/footer/Footer";
export default {
  metaInfo: {
    title: "SCAD", // set a title
  },
  name: "International1",
  components: {
    menuBar,
    custFooter,
  },
  data: function () {
    return {};
  },
};
</script>

<style lang="less" scoped>
@import url(International.less);
</style>
