<template>
  <div class="cust-container section-life">
    <menuBar></menuBar>
    <div class="english-language-support-content">
      <div
        class="
          container-fluid
          margin-bottom-all-10
          nopadding-bottom nopadding-top
          hero-image
        "
        style="background: #0b7963"
      >
        <div
          id="Swarm-Full-Width-Panel"
          class="margin-bottom-all-10 nopadding-bottom nopadding-top hero-image"
        >
          <div class="row">
            <div class="outer-region panel-row">
              <div class="inner-region">
                <div
                  class="
                    panel-panel
                    row_1_left
                    col-sm-12 col-sm-offset-0 col-md-12 col-md-offset-0
                  "
                >
                  <div
                    class="
                      panel-pane
                      pane-entity-field pane-node-field-regional-images
                      region-hero-container
                    "
                  >
                    <div class="pane-content">
                      <div class="item">
                        <picture
                          class="img-responsive"
                          style="
                            position: relative;
                            margin: auto;
                            overflow: hidden;
                          "
                        >
                          <img
                            class="img-responsive"
                            src="@/assets/images/englishLanguageSupport/banner.jpg"
                            alt="SCAD Language Studio"
                            title=""
                            style="max-width: initial; width: 100%"
                          />

                          <!--<div style="height: 30vw; position: relative"></div>-->
                          <div class="text-overlay">
                            <div class="pane-content">
                              <h1 class="rtecenter main-resizer">
                                英语学习帮助
                              </h1>
                            </div>
                          </div>
                        </picture>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        id="Swarm-Full-Width-Panel"
        class="
          container
          margin-top-xs-30 margin-top-sm-50
          padding-top-sm-20
          margin-bottom-all-50
        "
      >
        <div class="row">
          <div class="outer-region panel-row">
            <div class="inner-region">
              <div
                class="
                  panel-panel
                  row_5_left
                  col-sm-3 col-sm-offset-1 col-md-3 col-md-offset-1
                "
              >
                <div class="panel-pane pane-custom pane-8">
                  <div class="pane-content">
                    <p class="rtecenter chat-icon" style="">
                      <svg
                        class="svg-icon-large svg-theme-section"
                        viewBox="0 2 40 40"
                      >
                        <use
                          src="@/assets/scad-icons.svg#SCAD-International-Chat-icon"
                          xlink:href="@/assets/scad-icons.svg#SCAD-International-Chat-icon"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                        ></use>
                      </svg>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="inner-region">
              <div
                class="
                  panel-panel
                  row_5_center_left
                  col-sm-7 col-sm-offset-0 col-md-7 col-md-offset-0
                "
              >
                <div
                  class="
                    panel-pane
                    pane-custom pane-7
                    margin-bottom-sm-50 margin-bottom-md-20
                  "
                >
                  <div class="pane-content">
                    <h2>流利运用英语这门第二语言 (ESL)</h2>
                    <p>
                      SCAD 语言工作室提供英语作为第二语言 (ESL) 课程，为您提供在
                      SCAD
                      攻读学术课程所需的信心和技能。参加英语水平测试后，会将您安排在六个
                      ESL 级别之一。
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container-fluid" style="background: #f7f7f7">
        <div id="Swarm-Full-Width-Panel" class="container">
          <div class="row">
            <div class="outer-region panel-row">
              <div class="inner-region">
                <div
                  class="
                    panel-panel
                    row_7_left
                    col-sm-4 col-sm-offset-1 col-md-4 col-md-offset-1
                  "
                >
                  <div
                    class="
                      panel-pane
                      pane-entity-field pane-node-field-regional-images
                      overlay-box
                    "
                  >
                    <div class="pane-content">
                      <div class="item">
                        <picture class="img-responsive">
                          <img
                            class="img-responsive"
                            src="../../assets/images/englishLanguageSupport/ESL-Kenneth-Barnes-Savannah-Spring-2015-SK_3_SN.webp"
                            alt="ESL"
                            title=""
                          />
                        </picture>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="inner-region">
                <div
                  class="
                    panel-panel
                    row_7_center_left
                    col-sm-6 col-sm-offset-0 col-md-6 col-md-offset-0
                  "
                >
                  <div
                    class="
                      panel-pane
                      pane-custom pane-9
                      margin-top-sm-30 margin-top-md-50
                    "
                  >
                    <div class="pane-content">
                      <h2>通过现实经验学习</h2>
                      <p>
                        通过参加 ESL
                        课程，您可以通过真实的工作室体验和协作、引人入胜的实地考察来掌握英语。
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="
          container-fluid
          padding-top-all-10 padding-bottom-all-30 padding-top-sm-10
        "
        style="background: #f7f7f7"
      >
        <div
          id="Swarm-Full-Width-Panel"
          class="
            container
            padding-top-all-10 padding-bottom-all-30 padding-top-sm-10
          "
        >
          <div class="row">
            <div class="outer-region panel-row">
              <div class="inner-region">
                <div
                  class="
                    panel-panel
                    row_9_left
                    col-sm-4 col-sm-offset-0 col-md-4 col-md-offset-0
                  "
                >
                  <div class="panel-pane pane-custom pane-12">
                    <div class="pane-content">
                      <p class="rtecenter" style="">
                        <span
                          aria-label="Books"
                          class="svg-circle-bg load-modal-window"
                          href="/life/international-student-life/english-language-support/english-assessment"
                          style="background: #ffffff !important"
                          ><svg
                            class="svg-icon svg-theme-section"
                            viewBox="0 0 40 30"
                          >
                            <use
                              src="@/assets/scad-icons.svg#SCAD-admission-Programs-icon"
                              xlink:href="@/assets/scad-icons.svg#SCAD-admission-Programs-icon"
                              xmlns:xlink="http://www.w3.org/1999/xlink"
                            ></use></svg
                        ></span>
                      </p>
                      <h3>英语测验</h3>
                      <p>
                        所有无法取得 ESL 豁免资格的非英语母语学生都需要参加 SCAD
                        语言工作室管理的分班考试。
                      </p>
                      <p>
                        <a
                          class="load-modal-window"
                          href="javascript:void()"
                          @click.stop="dialogEnglishAssessment = true"
                          >了解英语测验</a
                        >
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="inner-region">
                <div
                  class="
                    panel-panel
                    row_9_center_left
                    col-sm-4 col-sm-offset-0 col-md-4 col-md-offset-0
                  "
                >
                  <div class="panel-pane pane-custom pane-10">
                    <div class="pane-content">
                      <p class="rtecenter" style="">
                        <span
                          aria-label="Books"
                          class="svg-circle-bg load-modal-window"
                          href="/life/international-student-life/english-language-support/esl-classes"
                          style="background: #ffffff !important"
                          ><svg
                            class="svg-icon svg-theme-section"
                            viewBox="0 0 40 40"
                          >
                            <use
                              src="@/assets/scad-icons.svg#SCAD-CAPP-review"
                              xlink:href="@/assets/scad-icons.svg#SCAD-CAPP-review"
                              xmlns:xlink="http://www.w3.org/1999/xlink"
                            ></use></svg
                        ></span>
                      </p>
                      <h3>课程</h3>
                      <p>
                        ESL
                        课程包含各种本科和研究生课程，重点培养阅读、写作和语法能力，以及艺术设计职业所需的高级演讲、发音和研究技能。
                      </p>
                      <p>
                        <a
                          class="load-modal-window"
                          href="javascript:void()"
                          @click.stop="dialogReviewclasses = true"
                          >查看课程</a
                        >
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="inner-region">
                <div
                  class="
                    panel-panel
                    row_9_center_right
                    col-sm-4 col-sm-offset-0 col-md-4 col-md-offset-0
                  "
                >
                  <div class="panel-pane pane-custom pane-11">
                    <div class="pane-content">
                      <p class="rtecenter" style="">
                        <span
                          aria-label="Books"
                          class="svg-circle-bg load-modal-window"
                          href="mailto:sls@scad.edu"
                          style="background: #ffffff !important"
                          ><svg
                            class="svg-icon svg-theme-section"
                            viewBox="0 0 40 40"
                          >
                            <use
                              src="@/assets/scad-icons.svg#SCAD-admission-ELP-icon"
                              xlink:href="@/assets/scad-icons.svg#SCAD-admission-ELP-icon"
                              xmlns:xlink="http://www.w3.org/1999/xlink"
                            ></use></svg
                        ></span>
                      </p>
                      <h3>SCAD 英语语言课程</h3>
                      <p>
                        SCAD
                        英语语言课程专为非学位生而设计，非常适合那些有兴趣发展英语以进一步实现专业和个人目标的学生。
                      </p>
                      <p>
                        <a href="mailto:sls@scad.edu"
                          >发送电子邮件至 sls@scad.edu 了解更多详情</a
                        >
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="Swarm-Full-Width-Panel "
        class="container chat-icon"
        style="height: 100px"
      ></div>
      <div
        class="container-fluid padding-top-all-30 padding-bottom-all-20"
        style="background: #f7f7f7"
      >
        <div
          id="Swarm-Full-Width-Panel"
          class="container padding-top-all-30 padding-bottom-all-20"
        >
          <div class="row">
            <div class="outer-region panel-row">
              <div class="inner-region">
                <div
                  class="
                    panel-panel
                    row_13_left
                    col-sm-6 col-sm-offset-0 col-md-5 col-md-offset-0
                  "
                >
                  <div class="panel-pane pane-custom pane-1">
                    <div class="pane-content">
                      <h2>通过 SCAD 语言工作室掌握宝贵的技能</h2>
                      <p>
                        SCAD 语言工作室负责监督 SCAD
                        的所有语言课程，包括基于测验结果和讲师反馈的免费个人辅导。SCAD
                        语言工作室帮助学生实现以下技能：
                      </p>
                      <ul>
                        <li>撰写学术论文</li>
                        <li>发表正式的演讲</li>
                        <li>阅读大学材料</li>
                        <li>理解学术讲座</li>
                        <li>在正式和非正式场合都能流利地运用英语交流</li>
                        <li>了解美国文化</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div class="inner-region">
                <div
                  class="
                    panel-panel
                    row_13_center_left
                    col-sm-6 col-sm-offset-0 col-md-7 col-md-offset-0
                  "
                >
                  <div class="panel-pane pane-node">
                    <div class="pane-content">
                      <div class="borderless-block">
                        <div class="video-medium">
                          <div class="image">
                            <a
                              href="javascript:void(0)"
                              aria-label="Play Video"
                            >
                              <div
                                class="
                                  field
                                  field-name-field-thumbnail-image
                                  field-type-image
                                  field-label-hidden
                                "
                              >
                                <div class="field-items">
                                  <div class="field-item even">
                                    <picture class="img-responsive">
                                      <!--[if IE 9]><video style="display: none;"><![endif]-->
                                      <source
                                        srcset="
                                          https://www.scad.edu/sites/default/files/styles/swarm16x9_768/public/Video/Video%20Thumbnails/SCAD-Language-Studio-video.jpg?itok=5_up5YdH&amp;timestamp=1461247491 1x
                                        "
                                        media="(min-width: 1200px)"
                                      />
                                      <source
                                        srcset="
                                          https://www.scad.edu/sites/default/files/styles/swarm16x9_768/public/Video/Video%20Thumbnails/SCAD-Language-Studio-video.jpg?itok=5_up5YdH&amp;timestamp=1461247491 1x
                                        "
                                        media="(min-width: 992px)"
                                      />
                                      <source
                                        srcset="
                                          https://www.scad.edu/sites/default/files/styles/swarm16x9_768/public/Video/Video%20Thumbnails/SCAD-Language-Studio-video.jpg?itok=5_up5YdH&amp;timestamp=1461247491 1x
                                        "
                                        media="(min-width: 768px)"
                                      />
                                      <source
                                        srcset="
                                          https://www.scad.edu/sites/default/files/styles/swarm16x9_768/public/Video/Video%20Thumbnails/SCAD-Language-Studio-video.jpg?itok=5_up5YdH&amp;timestamp=1461247491 1x
                                        "
                                        media="(min-width: 0px)"
                                      />
                                      <!--[if IE 9]></video><![endif]-->
                                      <img
                                        class="img-responsive"
                                        src="https://www.scad.edu/sites/default/files/styles/swarm16x9_768/public/Video/Video%20Thumbnails/SCAD-Language-Studio-video.jpg?itok=5_up5YdH&amp;timestamp=1461247491"
                                        alt=""
                                        title=""
                                      />
                                    </picture>
                                  </div>
                                </div>
                              </div>
                              <span class="scadtv-icon scadtv-icon-play"></span>
                            </a>
                          </div>
                          <div class="video-element">
                            <div
                              class="embed-responsive embed-responsive-16by9"
                            >
                              <iframe
                                src="//www.youtube.com/embed/MNnLdqkTwbw?rel=0"
                                frameborder="0"
                                width="500px"
                                height="400px"
                                webkitallowfullscreen=""
                                mozallowfullscreen=""
                                allowfullscreen=""
                              ></iframe>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <el-dialog :visible.sync="dialogEnglishAssessment">
      <div>
        <div>
          <h1>英语测验</h1>
          <p>母语为非英语的学生，须提供英语水平证明。</p>
        </div>

        <div>
          <h2>英语水平要求</h2>
          <p>
            英语是 SCAD
            所有课程的教学语言。学生需要证明他们目前的英语水平，才能获得 ESL
            豁免，或在国际入学期间参加 SCAD 语言工作室管理的分班考试。
          </p>
          <h2>ESL 豁免</h2>
          <p>
            如果成功通过 ESL
            水平测试或满足以下条件之一并提供证明文件，则可以免除 ESL 课程：
          </p>
          <li>学生的第一语言为英语。</li>
          <li>
            本科生已从另一所以英语为官方教学语言的学院或大学获得毕业证书文凭或学位。需要提供官方教学语言的文件。
          </li>
          <li>
            研究生已从一所以英语为官方教学语言的学院或大学获得本科学位或研究生学位。需要提供官方教学语言的文件。
          </li>
          <li>该生已通过香港中学文凭考试 (HKDSE)，英语成绩不低于 5 分。</li>
          <li>
            本科生在经国家认证/认可的学院或大学以“C”（2.0/4.0
            分）或更高成绩通过了英语 123/193 同等考试；或者通过认可的
            IB、AP、CAPE、A-Level（英国中学高级水平考试）或 CLEP
            考试成绩，获得英语 123/193
            的同等转移学分。有关相应考试的要求分数，请参阅转移学分政策。
          </li>
          <li>
            该生是富布莱特/汉弗莱交换学者，或是中国台湾教育部海外艺术设计研究奖学金项目的参与者。
          </li>
          <li>
            本科生在以英语为教学语言的公认国际学校或其他学术机构完成一年级至高中毕业（1
            至 12/13 年）的学业。需要所有 12/13 学年的文件。
          </li>
          <li>
            该生在批判性阅读部分的学术能力倾向测试 (SAT) 得分至少为 550
            分，在美国大学入学考试 (ACT) 的阅读和写作部分的得分至少为 22 分。
          </li>
          <li>
            该生的研究生入学考试 (GRE) 语言部分成绩至少为 500 分（2011 年 8 月 1
            日之前的考试）或 153 分（2011 年 8 月 1 日之后的考试）。
          </li>
          <li>该生的英语作为外语（托福）考试的最低分数（SCAD 代码 5631）：</li>
          <li style="list-style: circle; margin-left: 20px">
            纸质测试的分数为 550 分。
          </li>
          <li style="list-style: circle; margin-left: 20px">
            网上测试的分数为 85 分，每个部分的得分不低于 20 分。
          </li>
          <li>该生雅思综合成绩不低于 6.5 分，阅读和写作成绩不低于 6.5 分。</li>
          <li>
            该生在美国外语教学委员会 (ACTFL)
            考试的写作能力部分的得分至少为“高级”。
          </li>
          <li>
            该生已通过培生学术英语考试 (PTE Academic)，总分为 58
            分，每个类别的最低分数不低于 53 分。
          </li>
          <p>
            符合综合分数最低要求但不符合部分分数最低要求的学生将根据具体情况进行评估，以获得可能的豁免。如果未达到最低综合分数，则不豁免。所有决定均为最终决定。
          </p>
          <p>
            任何获得 ESL 豁免并且由于语言缺陷（由 SCAD
            语言工作室主任和学术系主任确定）而未能通过任何单门课程的学生可能需要参加英语语言分班考试。英语语言分班考试的结果可以用来确定需要什么水平的
            ESL 教学（如有）。
          </p>
          <h2>ESL 分班考试</h2>
          <p>
            其他未达到 ESL 豁免最低分数要求的合格申请者可能会被 SCAD
            招收。无论其他考试成绩如何，这些学生都必须参加由 SCAD
            语言工作室管理的分班考试。
          </p>
          <p>
            Accuplacer ESL 测试测验学生的技能，并确定 ESL
            课程的必要性，并在入学时将其安排到适当的 ESL
            水平。测试时长为两小时，包括 20 道阅读技巧题、20
            道用法和句子结构问题，以及一篇定时的书面文章或听力诊断。在注册前两周提供证明文件。
          </p>
          <p>学生必须通过六级，圆满完成 ESL 课程。</p>
        </div>
      </div>
    </el-dialog>

    <el-dialog :visible.sync="dialogReviewclasses">
      <div>
        <div>
          <h1>ESL 课程</h1>
        </div>

        <div>
          <h2>英语作为第二语言课程</h2>
          <p>
            SCAD 的 ESL
            学生获得了使他们能够在大学和职业生涯中取得成功的技能和信心。教学适合不同学生的需求、水平和熟练程度，学生在升读
            SCAD 的学术项目之前便掌握了阅读、写作、口语和发音。
          </p>
          <p>
            完成 ESL
            课程会延长攻读学位的学生必须潜心完成学习课程的时间，并增加学生的总体成本。如需更多信息，<a
              target="_blank"
              href="https://www.scad.edu/admission/tuition-and-fees"
              >请查看我们的学杂费</a
            >。
          </p>
          <h3>本科生</h3>
          <h4>I 级</h4>
          <p>ESL 160 基础阅读、写作和语言</p>
          <p>ESL 170 基础听力、口语和发音</p>
          <h4>II 级</h4>
          <p>ESL 260 精读、写作和语法</p>
          <p>ESL 270 精听、口语和发音</p>
          <h4>III 级</h4>
          <p>ESL 360 学术阅读、写作和语法</p>
          <p>ESL 370 学术听力、口语和发音</p>
          <h4>IV 级</h4>
          <p>ESL 460 艺术设计阅读、写作和语法</p>
          <p>ESL 470 艺术设计听力、口语和发音</p>
          <h4>V 级</h4>
          <p>ESL 560 艺术设计综合阅读、写作和语法</p>
          <p>ESL 570 艺术设计综合听力、口语和发音</p>
          <p>DRAW 或 DSGN 课程</p>
          <h4>VI 级</h4>
          <p>ESL 601 有助于学术成功的英语</p>
          <p>ESL 670 艺术设计演讲与发音</p>
          <p>ESL 123 从笔尖到思想：文学与写作中的批判概念</p>
          <p>DRAW 或 DSGN 课程</p>
          <br />
          <h3>研究生</h3>
          <h4>I 级</h4>
          <p>ESL 160 基础阅读、写作和语言</p>
          <p>ESL 170 基础听力、口语和发音</p>
          <h4>II 级</h4>
          <p>ESL 260 精读、写作和语法</p>
          <p>ESL 270 精听、口语和发音</p>
          <h4>III 级</h4>
          <p>ESL 360 学术阅读、写作和语法</p>
          <p>ESL 370 学术听力、口语和发音</p>
          <h4>IV 级</h4>
          <p>ESL 460 艺术设计阅读、写作和语法</p>
          <p>ESL 470 艺术设计听力、口语和发音</p>
          <h4>V 级</h4>
          <p>ESL 570 艺术设计综合听力、口语和发音</p>
          <p>ESL 580 艺术设计研究生阅读、写作和语法</p>
          <p>500 至 700 级工作室课程</p>
          <h4>VI 级</h4>
          <p>ESL 670 艺术设计演讲与发音</p>
          <p>ESL 680 艺术设计研究、阅读和写作</p>
          <p>500 至 700 级工作室课程</p>
        </div>
      </div>
    </el-dialog>
    <goTop></goTop>
    <custFooter></custFooter>
  </div>
</template>

<script>
import menuBar from "../../components/menuBar/index.vue";
import custFooter from "@/components/footer/Footer";
export default {
  metaInfo: {
    title: "SCAD", // set a title
  },
  name: "EnglishLanguageSupport",
  components: {
    menuBar,
    custFooter,
  },
  data: function () {
    return {
      dialogEnglishAssessment: false,
      dialogReviewclasses: false,
    };
  },
};
</script>

<style lang="css" src="./els-css/typography.gotham.css" scoped />
<style lang="less" scoped>
@import url(els-css/style.css);
//@import url(els-css/typography.gotham.css);
@import url(els-css/global.small.768.css);
@import url(els-css/swarm_fullwidth.css);
@import url(els-css/svg.css);
@import url(els-css/display-modes.css);
@import url(els-css/life.css);

/* Small screens */
@media (min-width: 768px) {
  .overlay-box {
    margin-top: -50px;
  }
  .image-box {
    margin-top: 200px;
  }
  .scad-card-nopadding {
    padding: 0px !important;
  }
  .scad-card-inset {
    padding-bottom: 5px !important;
    padding-left: 25px !important;
    padding-right: 25px !important;
  }
  .main-resizer {
    font-size: 42px;
  }
}
/* Medium screens */
@media (min-width: 992px) {
  .main-resizer {
    font-size: 54px;
  }
}

/* Large screens */
@media (min-width: 1200px) {
  .main-resizer {
    font-size: 60px;
  }
}

@media (max-width: 800px) {
  .chat-icon {
    display: none;
  }
}
</style>
