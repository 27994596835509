const params = [
  { programs: "Advertising Copywriting", value: "ADCO" },
  { programs: "2D Animation", value: "ANI2" },
  { programs: "3D Character Animation", value: "ANI3" },
  { programs: "Animation", value: "ANIM" },
  { programs: "Storytelling/Concept Develop", value: "ANSC" },
  { programs: "Technical Animation", value: "ANTA" },
  { programs: "Advertising Art Direction", value: "ARTD" },
  { programs: "Creative Technology", value: "ATEC" },
  { programs: "Commercial Photography", value: "COMP" },
  { programs: "Documentary Photography", value: "DOCP" },
  { programs: "Fashion", value: "FASH" },
  { programs: "Fashion Marketing & Management", value: "FASM" },
  { programs: "Film and Television", value: "FILM" },
  { programs: "Fine Art Photography", value: "FINE" },
  { programs: "Graphic Design", value: "GRDS" },
  { programs: "Industrial Design", value: "IDUS" },
  { programs: "Animated Illu/Publication Dsgn", value: "ILLA" },
  { programs: "Concept Dsgn/Animation & Games", value: "ILLC" },
  { programs: "Illustration for Entertainment", value: "ILLE" },
  { programs: "Illustration for Surface Dsgn", value: "ILLS" },
  { programs: "Interior Design", value: "INDS" },
  { programs: "Interactive Dsgn/Game Developm", value: "ITGM" },
  { programs: "Motion Media Design", value: "MOME" },
  { programs: "Painting", value: "PNTG" },
  { programs: "Sculpture", value: "SCPT" },
  { programs: "Sequential Art", value: "SEQA" },
  { programs: "User Experience (UX) Design", value: "UXDG" },
  { programs: "Visual Effects", value: "VSFX" },
  { programs: "Writing", value: "WRIT" },
];

export default params;
