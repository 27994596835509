<template>
  <div>
    <menuBar></menuBar>
    <div class="cust-container">
      <div class="title">{{ $t("faq.faq_text_01") }}</div>
      <div class="faqList">
        <div class="faqItem">
          <h4 class="faqTitle">{{ $t("faq.faq_text_02") }}</h4>
          <p class="faqText" v-html="$t('faq.faq_text_03')">
            {{ $t("faq.faq_text_03") }}
          </p>
        </div>

        <div class="faqItem">
          <h4 class="faqTitle" v-html="$t('faq.faq_text_04')">
            {{ $t("faq.faq_text_04") }}
          </h4>
          <p class="faqText" v-html="$t('faq.faq_text_05')">
            {{ $t("faq.faq_text_05") }}
          </p>
        </div>

        <div class="faqItem">
          <h4 class="faqTitle" v-html="$t('faq.faq_text_06')">
            {{ $t("faq.faq_text_06") }}
          </h4>
          <p class="faqText" v-html="$t('faq.faq_text_07')">
            {{ $t("faq.faq_text_07") }}
          </p>
        </div>

        <div class="faqItem">
          <h4 class="faqTitle" v-html="$t('faq.faq_text_08')">
            {{ $t("faq.faq_text_08") }}
          </h4>
          <p class="faqText" v-html="$t('faq.faq_text_09')">
            {{ $t("faq.faq_text_09") }}
          </p>
        </div>

        <div class="faqItem">
          <h4 class="faqTitle" v-html="$t('faq.faq_text_10')">
            {{ $t("faq.faq_text_10") }}
          </h4>
          <p class="faqText" v-html="$t('faq.faq_text_11')">
            {{ $t("faq.faq_text_11") }}
          </p>
        </div>

        <div class="faqItem">
          <h4 class="faqTitle" v-html="$t('faq.faq_text_12')">
            {{ $t("faq.faq_text_12") }}
          </h4>
          <p class="faqText" v-html="$t('faq.faq_text_13')">
            {{ $t("faq.faq_text_13") }}
          </p>
        </div>

        <div class="faqItem">
          <h4 class="faqTitle" v-html="$t('faq.faq_text_14')">
            {{ $t("faq.faq_text_14") }}
          </h4>
          <p class="faqText" v-html="$t('faq.faq_text_15')">
            {{ $t("faq.faq_text_15") }}
          </p>
        </div>

        <div class="faqItem">
          <h4 class="faqTitle" v-html="$t('faq.faq_text_16')">
            {{ $t("faq.faq_text_16") }}
          </h4>
          <p class="faqText" v-html="$t('faq.faq_text_17')">
            {{ $t("faq.faq_text_17") }}
          </p>
        </div>

        <div class="faqItem">
          <h4 class="faqTitle" v-html="$t('faq.faq_text_18')">
            {{ $t("faq.faq_text_18") }}
          </h4>
          <p class="faqText" v-html="$t('faq.faq_text_19')">
            {{ $t("faq.faq_text_19") }}
          </p>
        </div>

        <div class="faqItem">
          <h4 class="faqTitle" v-html="$t('faq.faq_text_20')">
            {{ $t("faq.faq_text_20") }}
          </h4>
          <p class="faqText" v-html="$t('faq.faq_text_21')">
            {{ $t("faq.faq_text_21") }}
          </p>
        </div>

        <div class="faqItem">
          <h4 class="faqTitle" v-html="$t('faq.faq_text_22')">
            {{ $t("faq.faq_text_22") }}
          </h4>
          <p class="faqText" v-html="$t('faq.faq_text_23')">
            {{ $t("faq.faq_text_23") }}
          </p>
        </div>
        <div class="faqItem">
          <h4 class="faqTitle" v-html="$t('faq.faq_text_24')">
            {{ $t("faq.faq_text_24") }}
          </h4>
          <p class="faqText" v-html="$t('faq.faq_text_25')">
            {{ $t("faq.faq_text_25") }}
          </p>
        </div>
        <div class="faqItem">
          <h4 class="faqTitle" v-html="$t('faq.faq_text_26')">
            {{ $t("faq.faq_text_26") }}
          </h4>
          <p class="faqText" v-html="$t('faq.faq_text_27')">
            {{ $t("faq.faq_text_27") }}
          </p>
        </div>
        <div class="faqItem">
          <h4 class="faqTitle" v-html="$t('faq.faq_text_28')">
            {{ $t("faq.faq_text_28") }}
          </h4>
          <p class="faqText" v-html="$t('faq.faq_text_29')">
            {{ $t("faq.faq_text_29") }}
          </p>
        </div>
        <div class="faqItem">
          <h4 class="faqTitle" v-html="$t('faq.faq_text_30')">
            {{ $t("faq.faq_text_30") }}
          </h4>
          <p class="faqText" v-html="$t('faq.faq_text_31')">
            <!--{{ $t("faq.faq_text_31") }}-->
          </p>
        </div>
      </div>
    </div>
    <goTop></goTop>
    <custFooter></custFooter>
  </div>
</template>

<script>
import menuBar from "../../components/menuBar/index.vue";
import custFooter from "@/components/footer/Footer";
export default {
  metaInfo: {
    title: "SCAD", // set a title
  },
  name: "MissonVisionValue",
  components: {
    menuBar,
    custFooter,
  },
};
</script>

<style scoped lang="less">
@import "Faq.less";
</style>
