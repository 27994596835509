<template>
  <div>
    <!--v-show="!mobile"-->
    <div class="banner-swiper-container swiper-container">
      <div class="swiper-wrapper">
        <div
          class="swiper-slide"
          v-for="(banner, index) in $i18n.locale === 'zh'
            ? cnBanners
            : enBanners"
          :key="index"
        >
          <div class="slide-inner">
            <img :src="banner.src" alt="" />
          </div>
        </div>
      </div>

      <div class="swiper-pagination"></div>
    </div>

    <!--<div v-show="mobile" class="mo-banner-swiper-container swiper-container">-->
    <!--  <div class="swiper-wrapper">-->
    <!--    <div-->
    <!--      class="swiper-slide"-->
    <!--      v-for="(banner, index) in moBanners"-->
    <!--      :key="index"-->
    <!--    >-->
    <!--      <div class="slide-inner">-->
    <!--        <img :src="banner.src" alt="" />-->
    <!--      </div>-->
    <!--    </div>-->
    <!--  </div>-->

    <!--  <div class="swiper-pagination"></div>-->
    <!--</div>-->
  </div>
</template>

<script>
// Import Swiper Vue.js components
import Swiper from "swiper/swiper-bundle.min.js";
import "swiper/swiper-bundle.min.css";

export default {
  components: {},
  props: ["mobile"],
  watch: {
    mobile: {
      // eslint-disable-next-line no-unused-vars
      handler(newValue, oldValue) {
        this.mobile = newValue;
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      mySwiper: null,
      myMoSwiper: null,

      banners: [],
      cnBanners: [
        // {
        //   src: require("@/assets/images/scad_03_cn.jpg"),
        //   url: "",
        // },
        {
          src: require("@/assets/images/banner/index/202411/cn_1.jpg"),
          url: "",
        },
        {
          src: require("@/assets/images/banner/index/202411/cn_2.jpg"),
          url: "",
        },
        {
          src: require("@/assets/images/banner/index/202411/cn_3.jpg"),
          url: "",
        },
        {
          src: require("@/assets/images/banner/index/202411/cn_4.jpg"),
          url: "",
        },
        {
          src: require("@/assets/images/banner/index/202411/cn_5.jpg"),
          url: "",
        },
      ],
      enBanners: [
        // {
        //   src: require("@/assets/images/scad_03.jpg"),
        //   url: "",
        // },
        {
          src: require("@/assets/images/banner/index/202411/en_1.jpg"),
          url: "",
        },
        {
          src: require("@/assets/images/banner/index/202411/en_2.jpg"),
          url: "",
        },
        {
          src: require("@/assets/images/banner/index/202411/en_3.jpg"),
          url: "",
        },
        {
          src: require("@/assets/images/banner/index/202411/en_4.jpg"),
          url: "",
        },
        {
          src: require("@/assets/images/banner/index/202411/en_5.jpg"),
          url: "",
        },
      ],
      moBanners: [
        {
          src: require("@/assets/images/index/mo/1.20210923SCAD_Mobile_02.jpg"),
          url: "",
        },
        {
          src: require("@/assets/images/index/mo/1.20210923SCAD_Mobile_02.jpg"),
          url: "",
        },
        {
          src: require("@/assets/images/index/mo/1.20210923SCAD_Mobile_02.jpg"),
          url: "",
        },
      ],
      screenWidth: document.body.clientWidth, // 屏幕尺寸
    };
  },
  mounted() {
    const that = this;
    window.onresize = () => {
      return (() => {
        window.screenWidth = document.body.clientWidth;
        that.screenWidth = window.screenWidth;
      })();
    };

    this.mySwiper = this.initSwiper(".banner-swiper-container");
    this.myMoSwiper = this.initSwiper(".mo-banner-swiper-container");
  },
  methods: {
    initSwiper: function (clazz) {
      var interleaveOffset = 0.5; //视差比值
      var swiperOptions = {
        loop: true,
        speed: 1000,
        autoplay: true,
        grabCursor: true,
        autoHeight: true,
        watchSlidesProgress: true,
        mousewheelControl: true,
        keyboardControl: true,
        observer: true,

        pagination: {
          el: ".swiper-pagination",
        },

        on: {
          progress: function (swiper) {
            for (var i = 0; i < swiper.slides.length; i++) {
              var slideProgress = swiper.slides[i].progress;
              var innerOffset = swiper.width * interleaveOffset;
              var innerTranslate = slideProgress * innerOffset;
              swiper.slides[i].querySelector(".slide-inner").style.transform =
                "translate3d(" + innerTranslate + "px, 0, 0)";
            }
          },
          touchStart: function (swiper) {
            for (var i = 0; i < swiper.slides.length; i++) {
              swiper.slides[i].style.transition = "";
            }
          },
          setTransition: function (swiper, speed) {
            for (var i = 0; i < swiper.slides.length; i++) {
              swiper.slides[i].style.transition = speed + "ms";
              swiper.slides[i].querySelector(".slide-inner").style.transition =
                speed + "ms";
            }
          },
        },
      };

      return new Swiper(clazz, swiperOptions);
    },
  },
};
</script>

<style lang="less" scoped>
.el-carousel__button {
  height: 3px !important;
}
</style>

<style lang="less">
.banner {
  .banner-item {
    .banner-item-img {
      display: block;
      width: 100%;
    }
  }
}

.swiper-container {
  width: 100%;
  height: auto;
}

//.banner-swiper-container {
//  display: block;
//  @media (max-width: 600px) {
//    display: none;
//  }
//}
//.mo-banner-swiper-container {
//  display: none;
//
//  @media (max-width: 600px) {
//    display: block;
//  }
//}
.banner-swiper-container,
.mo-banner-swiper-container {
  .swiper-pagination-bullet {
    width: 30px;
    height: 4px;
    border-radius: 2px;
    background: white;
    transition: 0.5s opacity;
  }
  .swiper-pagination-bullet-active {
    background: white;
  }

  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;

    overflow: hidden;
    position: relative;
  }

  .slide-inner {
    width: 100%;
    left: 0;
    top: 0;
    background-size: cover;
    background-position: center;

    img {
      width: 100%;
      display: block;
    }
  }
}
</style>
