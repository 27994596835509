<template>
  <div>
    <menuBar></menuBar>
    <div class="head-container">
      <img class="banner" src="@/assets/images/banner/application.jpg" />
    </div>

    <div class="body-container" v-if="$i18n.locale === 'en'">
      <div class="title">
        Let's start your journey toward your creative career
      </div>

      <div class="text1">
        Select your enrollment type and apply. SCAD is committed to the pursuit
        of excellence and welcomes applicants who have the same high standards.
        The application takes about 20 minutes to complete.
      </div>
      <div class="text2">
        Applications are accepted at any time throughout the year. There are no
        deadlines to apply, although students are encouraged apply at least six
        months in advance to allow time to arrange for financial aid and no
        later than 30 days prior to the start of their first quarter. Admission
        results normally take between two and four weeks.
      </div>

      <div class="application-list">
        <el-row :gutter="30">
          <!--xs、sm、md、lg 和 xl-->
          <el-col :span="8" :xs="24">
            <div class="application-item">
              <div class="application-title">Freshman</div>
              <div class="application-desc">
                High school/secondary school juniors and seniors, or high
                school/secondary school graduates who have not attended a
                college or university. Includes home-schooled students.
              </div>
              <a href="/UndergraduateApplication" target="_blank"
                ><div class="application-btn">Freshman application</div></a
              >
            </div>
          </el-col>
          <el-col :span="8" :xs="24">
            <div class="application-item">
              <div class="application-title">Transfer</div>
              <div class="application-desc">
                Students who have attended a college or university after
                completing high school/secondary school. Includes undergraduate
                certificate, transient, English Language Program and non-degree
                seeking applicants.
              </div>
              <a href="/TransferApplication" target="_blank"
                ><div class="application-btn">Transfer application</div></a
              >
            </div>
          </el-col>
          <el-col :span="8" :xs="24">
            <div class="application-item">
              <div class="application-title">Graduate</div>
              <div class="application-desc">
                Students who have graduated or anticipate graduating from a
                four-year college or university. This also includes graduate
                certificates.
              </div>
              <a href="/GraduateApplication" target="_blank"
                ><div class="application-btn">Graduate application</div></a
              >
            </div>
          </el-col>
        </el-row>
        <div class="tips">
          The form needs to be filled in with English only. If you are a senior
          <span class="high" style="color: #000">high school student</span>,
          please
          <span class="high" style="color: #000"
            >ask your parents to fill it in</span
          >.
        </div>
      </div>
    </div>

    <div class="body-container" v-if="$i18n.locale === 'zh'">
      <div class="title">由此启航你的艺术创意之旅</div>

      <div class="text1">
        请选择你心仪的课程并完成填写申请表。SCAD欢迎和我们一样拥有着高标准并追求极致卓越的学生。<br />完成填写课程申请表约需要
        20 分钟。
      </div>
      <div class="text2">
        我们全年招生，因此没有招生截止日期，但晚于课程开始前 30
        天的申请将错过这一季度的课程学习。<br />我们真挚地建议申请者提前至少 6
        个月完成填写课程申请表，以便于协调经济安排。
        申请者通常在两到四周收到录取结果通知。
      </div>

      <div class="application-list">
        <el-row :gutter="30">
          <!--xs、sm、md、lg 和 xl-->
          <el-col :span="8" :xs="24">
            <div class="application-item">
              <div class="application-title">本科生课程</div>
              <div class="application-desc">
                适合高中（及同等学历）在校学生，或已毕业但未参加大学（及同等学历）课程的学生，包括在家上学（自学）的学生。
              </div>
              <a href="/UndergraduateApplication" target="_blank"
                ><div class="application-btn">本科生课程申请表</div></a
              >
            </div>
          </el-col>
          <el-col :span="8" :xs="24">
            <div class="application-item">
              <div class="application-title">转校生课程</div>
              <div class="application-desc">
                适合已完成高中（及同等学历）并正在大学（及同等学历）的在校生，以及持有本科毕业证，需要英语语言课程和非学位需求的学生。
              </div>
              <a href="/TransferApplication" target="_blank"
                ><div class="application-btn">转校生课程申请表</div></a
              >
            </div>
          </el-col>
          <el-col :span="8" :xs="24">
            <div class="application-item">
              <div class="application-title">研究生课程</div>
              <div class="application-desc">
                适合全日制大学（及同等学历）的应届生和往届生，以及持有研究生毕业证的学生。
              </div>
              <a href="/GraduateApplication" target="_blank"
                ><div class="application-btn">研究生课程申请表</div></a
              >
            </div>
          </el-col>
        </el-row>
        <div class="tips">
          此申请表必须全部用英语填写，如果你是
          <span class="high" style="color: #000">高中生</span>, 请
          <span class="high" style="color: #000">交由父母完成填写</span>。
        </div>
      </div>
    </div>

    <goTop></goTop>
    <custFooter></custFooter>
  </div>
</template>

<script>
import menuBar from "@/components/menuBar";
import custFooter from "@/components/footer/Footer";

export default {
  name: "Application",
  metaInfo: {
    title: "SCAD", // set a title
  },
  components: {
    menuBar,
    custFooter,
  },
};
</script>

<style scoped lang="less">
.head-container {
  .banner {
    width: 100%;
  }
}

.body-container {
  width: 1200px;
  margin: 0 auto;

  @media (max-width: 800px) {
    width: 100%;
    padding: 0 20px;
    box-sizing: border-box;
  }

  .title {
    font-size: 38px;
    font-weight: 800;
    text-align: center;
    margin-top: 50px;
    margin-bottom: 30px;

    @media (max-width: 800px) {
      font-size: 20px;
    }
  }

  .text1 {
    font-size: 14px;
    text-align: center;
    line-height: 26px;

    @media (max-width: 800px) {
      font-size: 12px;
    }
  }

  .text2 {
    font-size: 14px;
    text-align: center;
    margin-top: 20px;
    line-height: 26px;

    @media (max-width: 800px) {
      font-size: 12px;
    }
  }

  .application-list {
    margin-top: 70px;
    padding-bottom: 50px;
    .application-item {
      @media (max-width: 800px) {
        margin-bottom: 50px;
      }

      .application-title {
        font-size: 30px;
        font-weight: 800;

        @media (max-width: 800px) {
          font-size: 20px;
        }
      }
      .application-desc {
        margin-top: 5px;
        font-size: 14px;
        line-height: 26px;

        @media (max-width: 800px) {
          font-size: 11px;
          line-height: 22px;
        }
      }

      .application-btn {
        margin-top: 10px;
        display: inline-block;
        border: 2px solid #3c45b8;
        width: 230px;
        text-align: center;
        padding: 12px 0;
        font-size: 14px;
        color: #3c45b8;
        transition: 0.5s all;

        @media (max-width: 800px) {
          font-size: 11px;
        }

        &:hover {
          background: #3c45b8;
          color: white;
        }
      }
    }
  }

  .tips {
    font-size: 14px;
    font-style: italic;
    text-align: center;
    margin-top: 50px;

    @media (max-width: 800px) {
      margin-top: 0px;
      font-size: 11px;
    }

    .high {
      font-weight: 800;
      color: #3c45b8;
    }
  }
}
</style>
