<template>
  <div class="programs">
    <menuBar></menuBar>
    <div class="programs-header-container">
      <img
        class="banner"
        src="https://abcam.oss-cn-shanghai.aliyuncs.com/40b69202201191322236564.jpg"
      />
    </div>
    <div
      class="body-container"
      style="padding-bottom: 50px"
      v-if="$i18n.locale === 'zh'"
    >
      <div>
        <h1 class="title">STEM专业</h1>

        <div class="content">
          <h2>沉浸式媒体设计:</h2>
          <ul>
            <li><p>#8 美国顶级 AR/VR 私立学校，</p></li>
            <li><p>#13 美国前 50 名 AR/VR 学校（2021 年动画职业评论）</p></li>
          </ul>
          <br />

          <h2>工业设计和用户体验设计：</h2>
          <ul>
            <li><p>#1 世界上最好的产品设计学校（2021年新秀奖）</p></li>
            <li>
              <p>
                美国最佳设计大学排名第一，全球排名第二（2021
                年已连续五年获得此红点设计排名）
              </p>
            </li>
            <li><p>#1 最佳全国产品设计课程（本科）（2021 年Plexuss奖）</p></li>
          </ul>
          <br />

          <h2>服务设计：</h2>
          <ul>
            <li><p>卓越服务创新与创新影响力奖（ISSIP - 2021 年）</p></li>
          </ul>
          <br />

          <h2>奢侈品和品牌管理：</h2>
          <ul>
            <li><p>#1 塑造时尚未来的最佳大学（福布斯，2021 年）</p></li>
            <li>
              <p>#1 南方最佳时装营销学校（Fashion-Schools.org，2021 年）</p>
            </li>
            <li>
              <p>#1 南方最佳时装设计学校（Fashion-Schools.org，2021 年）</p>
            </li>
            <li>
              <p>#1 美国最佳私立时装设计学校（Fashion-Schools.org，2021 年）</p>
            </li>
          </ul>
          <br />

          <h2>动画设计：</h2>
          <ul>
            <li>
              <p>#1 美国排名前 25 的动画硕士课程（动画职业评论，2021 年）</p>
            </li>
            <li><p>#2 美国最好的 2D 动画学校（2021 年新秀奖）</p></li>
          </ul>
          <br />

          <h2>平面设计：</h2>
          <ul>
            <li><p>南方顶级平面设计学校（动画职业评论，2021 年）</p></li>
            <li>
              <p>
                #1 最好的平面设计硕士在线课程（TheBestSchools.org，2021 年）
              </p>
            </li>
            <li><p>#1 最佳平面设计在线硕士学位（Study.com，2021 年）</p></li>
          </ul>
          <br />

          <h2>交互设计和游戏开发：</h2>
          <ul>
            <li>
              <p>#1 乔治亚州游戏设计学校前 10 （动画职业评论，2021 年）</p>
            </li>
            <li>
              <p>#1 美国最佳制作卓越游戏设计学校（The Rookies，2020 年）</p>
            </li>
            <li>
              <p>
                #22 顶尖游戏设计本科院校，#16 顶尖游戏设计研究生学校（Princeton
                Review 和 PC Gamer，2021 年）
              </p>
            </li>
            <li>
              <p>
                #1 顶级在线视频游戏设计学士学位课程（TheBestSchools.org，2021
                年）
              </p>
            </li>
          </ul>
          <br />

          <h2>视觉效果：</h2>

          <ul>
            <li><p>视觉特效学校前 10 （好莱坞记者，2018 年）</p></li>
            <li><p>#2 美国最佳视觉特效学校（新秀，2021 年）</p></li>
          </ul>
          <br />

          <h2>建筑学：</h2>
          <ul>
            <li>
              <p>
                美国最受推崇的建筑学院（Design Intelligence设计智造，2016
                年、2018 年、2019 年、2020 年前 25 名）
              </p>
            </li>
            <li><p>#2 美国最佳建筑可视化学校（新秀，2021 年）</p></li>
          </ul>
          <br />
        </div>
      </div>
    </div>

    <div
      class="body-container"
      style="padding-bottom: 50px"
      v-if="$i18n.locale === 'en'"
    >
      <div>
        <h1 class="title">STEM</h1>

        <div class="content">
          <h2>Immersive Media:</h2>
          <ul>
            <li>
              <p>
                #8 Top AR/VR Private Schools in the U.S., #13 Top 50 AR/VR
                Schools in the U.S. (Animation Career Review, 2021)
              </p>
            </li>
          </ul>
          <br />

          <h2>Industrial Design and User Experience Design:</h2>
          <ul>
            <li>
              <p>
                #1 Best Product Design School in the World(The Rookies, 2021)
              </p>
            </li>
            <li>
              <p>
                #1 Best Design University in the U.S., #2 globally (Red Dot
                Design Rankings, five consecutive years, 2021)
              </p>
            </li>
            <li>
              <p>
                #1 Best Nationwide Offering Product Design Programs
                (Undergraduate) (Plexuss, 2021)
              </p>
            </li>
          </ul>
          <br />

          <h2>Service Design:</h2>
          <ul>
            <li>
              <p>
                #1Excellence in Service Innovation with Impact to Innovation
                (ISSIP - 2021)
              </p>
            </li>
          </ul>
          <br />

          <h2>Luxury and Brand Management:</h2>
          <ul>
            <li>
              <p>
                #1Best Colleges Shaping the Future of Fashion (Forbes, 2021)
              </p>
            </li>
            <li>
              <p>
                #1 Best Fashion Merchandising Schools in the South
                (Fashion-Schools.org, 2021)
              </p>
            </li>
            <li>
              <p>
                #1 Best Fashion Design Schools in the South
                (Fashion-Schools.org, 2021)
              </p>
            </li>
            <li>
              <p>
                #1 Best Private Fashion Design Schools in the U.S.
                (Fashion-Schools.org, 2021)
              </p>
            </li>
          </ul>
          <br />

          <h2>Animation:</h2>
          <ul>
            <li>
              <p>
                #1 Top 25 MA Animation Programs in the U.S. (Animation Career
                Review, 2021)
              </p>
            </li>
            <li>
              <p>
                #2 Best Schools for 2D Animation in the U.S. (The Rookies, 2021
              </p>
            </li>
          </ul>
          <br />

          <h2>Graphic Design:</h2>
          <ul>
            <li>
              <p>
                #1 Top Graphic Design Schools in the South (Animation Career
                Review, 2021)
              </p>
            </li>
            <li>
              <p>
                #1 The Best Online in Master’s in Graphic Design Programs
                (TheBestSchools.org, 2021)
              </p>
            </li>
            <li>
              <p>
                #1 Best Online Master’s Degree in Graphic Design (Study.com,
                2021)
              </p>
            </li>
          </ul>
          <br />

          <h2>Interactive Design and Game Development:</h2>
          <ul>
            <li>
              <p>
                #1 Top 10 Game Design Schools in Georgia (Animation Career
                Review, 2021)
              </p>
            </li>
            <li>
              <p>
                #1 Best Production Excellence Game Design Schools in the U.S.
                (The Rookies, 2020)
              </p>
            </li>
            <li>
              <p>
                #22 Top Undergraduate Game Design Schools, #16 Top Graduate Game
                Design Schools (Princeton Review and PC Gamer, 2021)
              </p>
            </li>
            <li>
              <p>
                #1 Top Online Video Game Design Bachelor’s Degree Programs
                (TheBestSchools.org, 2021)
              </p>
            </li>
          </ul>
          <br />

          <h2>Visual Effects:</h2>

          <ul>
            <li>
              <p>Top 10 Visual Effects Schools (Hollywood Reporter, 2018)</p>
            </li>
            <li>
              <p>
                #2 Best Visual Effects Schools in the U.S. (The Rookies, 2021)
              </p>
            </li>
          </ul>
          <br />

          <h2>Architecture:</h2>
          <ul>
            <li>
              <p>
                America’s Most Admired Architecture Schools (Design
                Intelligence, Top 25 2016, 2018, 2019, 2020)
              </p>
            </li>
            <li>
              <p>
                #2 Best Architectural Visualization Schools in the U.S. (The
                Rookies, 2021)
              </p>
            </li>
          </ul>
          <br />
        </div>
      </div>
    </div>

    <goTop></goTop>
    <custFooter></custFooter>
  </div>
</template>

<script>
import menuBar from "../../components/menuBar/index.vue";
import custFooter from "@/components/footer/Footer";
import i18n from "@/i18n";
// import router from "@/router";
export default {
  name: "AwardsSTEM",
  metaInfo: {
    title: i18n.t("STEM"), // set a title
  },
  components: {
    menuBar,
    custFooter,
  },
  data: function () {
    return {
      obj: {},
      lang: this.$i18n.locale,
      id: null,
      name: null,
      areaList: [],
    };
  },
  mounted() {},
};
</script>
<style lang="less" scoped>
@import "Programs.less";
</style>

<style>
.content img {
  max-width: 100% !important;
}
</style>
