<template>
  <div class="header">
    <div class="formMenuBar">
      <router-link to="/" class="logo">
        <div class="logo-svg"></div>
        <!--<img class="logo" src="../../assets/logo-black.svg" alt="" />-->
      </router-link>
      <div class="tips">The University for Creative Careers</div>

      <transition name="left1">
        <!--<div class="menuBox" :class="moMenuStatu ? 'open' : ''">-->
        <div
          class="menuBox"
          style="display: none"
          v-if="!mobile || moMenuStatu"
        >
          <div class="moOperate">
            <div @click="changeMoMenu(false)" class="closeButton">
              <img src="@/assets/images/closeBtn.png" alt="" />
            </div>
            <div class="switchLanguage">
              <div
                v-if="lang === 'en'"
                class="switchLanguageItem"
                @click="changeLanguage('zh')"
              >
                中文
              </div>
              <div
                v-if="lang === 'zh'"
                class="switchLanguageItem"
                @click="changeLanguage('en')"
              >
                English
              </div>
            </div>
          </div>
        </div>
      </transition>

      <div class="operate" style="display: none">
        <div class="switchLanguage">
          <div
            v-if="lang === 'en'"
            class="switchLanguageItem"
            @click="changeLanguage('zh')"
          >
            中文
          </div>
          <div
            v-if="lang === 'zh'"
            class="switchLanguageItem"
            @click="changeLanguage('en')"
          >
            English
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "formMenuBar",

  data() {
    return {
      activeIndex: "1",
      lang: "",
      mobile: false,
      memuMode: "horizontal", //vertical
      moMenuStatu: false,
    };
  },
  created() {
    var query = this.$route.query;
    console.log(localStorage.getItem("lang"));

    if (query.lang) {
      if (query.lang === "en") {
        this.$i18n.locale = "en";
        this.lang = "en";
      } else {
        this.$i18n.locale = "zh";
        this.lang = "zh";
      }
    } else {
      var lang = localStorage.getItem("lang");
      if (lang) {
        this.$i18n.locale = lang;
        this.lang = lang;
      } else {
        this.$i18n.locale = "zh";
        this.lang = "zh";
      }
    }

    this.changeMenu();
    window.addEventListener("resize", () => {
      //监听浏览器窗口大小改变
      this.changeMenu();
    });
  },
  methods: {
    changeMoMenu(status) {
      this.moMenuStatu = status;
    },
    closeMenuClick() {},
    changeMenu() {
      if (document.body.clientWidth <= 800) {
        this.mobile = true;
        this.memuMode = "vertical";
      } else {
        this.mobile = false;
        this.memuMode = "horizontal";
      }
    },
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
    },
    changeLanguage(lang) {
      if (lang === "en") {
        this.$i18n.locale = "en";
        this.lang = "en";
      } else {
        this.$i18n.locale = "zh";
        this.lang = "zh";
      }
      localStorage.setItem("lang", lang); // 存到localStorage中
      window.location.reload();
    },
    liveInquiryClick() {
      // eslint-disable-next-line no-undef
      ZM.open();
    },
  },
};
</script>

<style scoped lang="less">
@import "formMenuBar.less";
</style>
