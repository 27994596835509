const params = [
  { programs: "Accessory Design", value: "ACCE" },
  { programs: "Advertising", value: "ADVE" },
  { programs: "Animation", value: "ANIM" },
  { programs: "Architectural History", value: "ARLH" },
  { programs: "Design Management", value: "DMGT" },
  { programs: "Dramatic Writing", value: "DWRI" },
  { programs: "Fashion", value: "FASH" },
  { programs: "Fibers", value: "FIBR" },
  { programs: "Film and Television", value: "FILM" },
  { programs: "Furniture Design", value: "FURN" },
  { programs: "Graphic Dsgn/Visual Experience", value: "GDVX" },
  { programs: "Industrial Design", value: "IDUS" },
  { programs: "Illustration", value: "ILLU" },
  { programs: "Interior Design", value: "INDS" },
  { programs: "Jewelry", value: "JEWL" },
  { programs: "Luxury and Brand Management", value: "LXMT" },
  { programs: "Motion Media Design", value: "MOME" },
  { programs: "Performing Arts", value: "MPRA" },
  { programs: "Photography", value: "PHOT" },
  { programs: "Painting", value: "PNTG" },
  { programs: "Preservation Design", value: "PRES" },
  { programs: "Production Design", value: "PROD" },
  { programs: "Sequential Art", value: "SEQA" },
  { programs: "Service Design", value: "SERV" },
  { programs: "Design for Sustainability", value: "SUST" },
  { programs: "Themed Entertainment Design", value: "THED" },
  { programs: "Visual Effects", value: "VSFX" },
  { programs: "Writing", value: "WRIT" },
];

export default params;
