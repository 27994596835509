<template>
  <div>
    <div v-if="$i18n.locale === 'en'">
      <div class="grid-content content">
        <div class="title1">
          Access preeminent online education anywhere in the world through
          SCADnow
        </div>
        <div class="video">
          <!--<video-->
          <!--  src="https://scad-minisite.oss-cn-beijing.aliyuncs.com/video/SCADnow/2021-22SCADeLearning.mp4"-->
          <!--  controls-->
          <!--  poster="https://scad-minisite.oss-cn-beijing.aliyuncs.com/video/SCADnow/2021-22SCADeLearning.mp4?x-oss-process=video/snapshot,t_2000,m_fast"-->
          <!--&gt;</video>-->

          <video
            src="https://scad-minisite.oss-cn-beijing.aliyuncs.com/video/20231110/2023_24_SCADnow_Mandarin_subs_V7.mp4"
            controls
            poster="https://scad-minisite.oss-cn-beijing.aliyuncs.com/video/20231110/2023_24_SCADnow_Mandarin_subs_V7.mp4?x-oss-process=video/snapshot,t_2000,m_fast"
          ></video>
        </div>
        <div class="tips text">
          SCADnow is a signature online learning platform that offers on-the-go
          artists, designers, and professionals the best of both worlds in
          distance education — real-time engagement combined with on-demand
          access. For maximum flexibility, SCADnow courses stream in real-time
          and are accessible anytime, allowing students to digitally connect to
          award winning undergraduate and graduate degree programs according to
          their own distinct schedules.
        </div>
        <div class="line" style="margin-top: 1%"></div>
        <div class="padding-both">
          <img
            src="@/assets/images/locations/eLearning_content_img_01.jpg?v=1"
            alt=""
          />
          <div class="title2">Unparalleled extended learning</div>
          <div class="text text-center">
            SCADnow offers students a robust array of extended learning
            opportunities, such as Guests and Gusto, SCADextra workshops, and
            SCADamp, the university’s professional presentation studio. SCADnow
            virtual learning opportunities also extend to the university’s
            professionally driven initiatives, like digital collaborations with
            top companies via SCADpro, the university's in-house design studio.
          </div>
        </div>
        <div class="line"></div>

        <el-row :gutter="30" class="padding-left">
          <el-col :xs="24" :md="12">
            <img
              src="@/assets/images/locations/eLearning_content_img_02.jpg?v=1"
            />
          </el-col>
          <el-col :xs="24" :md="12">
            <div class="title3">The SCAD experience at your fingertips</div>
            <div class="text">
              Through SCADnow online, students are just a click away from the
              resources and support services offered at SCAD locations around
              the world, including peer tutoring, the Writers’ Studio, Bee Well,
              career and alumni success, and access to the university’s
              extensive library collections. Online resources and textbook
              rental through the SCAD bookstore Ex Libris ensure students have
              the materials to succeed from wherever they log in.
            </div>
          </el-col>
        </el-row>
        <el-row :gutter="30" class="padding-left margin-top">
          <el-col :xs="24" :md="12">
            <img
              src="@/assets/images/locations/eLearning_content_img_03.jpg?v=1"
            />
          </el-col>
          <el-col :xs="24" :md="12">
            <div class="title3">Plug into an exclusive network</div>
            <div class="text">
              SCADnow online courses are led by the same accomplished faculty
              who teach at university locations around the globe. Wielding
              real-world experience, these extraordinary leaders will help you
              develop your skills and prepare you to excel in your own creative
              career.
            </div>
          </el-col>
        </el-row>

        <div class="line"></div>

        <el-row :gutter="30" class="padding-left margin-top">
          <el-col :xs="24" :md="12">
            <div class="title3">A history of excellence</div>
            <div class="text">
              SCAD has been offering online degrees for nearly 20 years. We are
              the first art and design university to receive the Instructional
              Technology Council’s award for Outstanding Distance Education
              Program, and the rank of Excellence in Institution-Wide Online
              Teaching and Learning from the Online Learning Consortium.
            </div>
          </el-col>

          <el-col :xs="24" :md="12">
            <img
              src="@/assets/images/locations/eLearning_content_img_04.jpg?v=1"
            />
          </el-col>
        </el-row>

        <el-row :gutter="30" class="padding-left margin-top">
          <el-col :span="24">
            <!--<img-->
            <!--  src="@/assets/images/locations/eLearning_content_img_01.jpg?v=1"-->
            <!--  alt=""-->
            <!--/>-->
            <div class="title3">Providing you with the tools for success</div>
            <div class="text">
              SCAD's mission includes providing students with the tools and
              technologies they need to be successful academically and in their
              professional lives.

              <div style="margin-top: 15px">
                <div class="tool-list">
                  <div>● Autodesk Maya</div>
                  <div>● Autodesk 3ds Max</div>
                  <div>● Autodesk Mudbox</div>
                  <div>● Unity 3D</div>
                  <div>● Adobe Creative Cloud</div>
                  <div>● Remote conferencing with Zoom</div>
                  <div>● Nuke Personal Learning Edition</div>
                  <div>● Microsoft Windows 8</div>
                  <div>● Microsoft Windows 7</div>
                  <div>● Microsoft Visual Studio</div>
                  <div>● Access to on-ground 3D printers and more</div>
                  <div>● LinkedIn Learning</div>
                  <div>● Microsoft XNA Game Studio</div>
                  <div>● Microsoft Kinect for Windows SDK</div>
                  <div>● Microsoft Expression Studio</div>
                </div>
              </div>
            </div>
          </el-col>

          <!--<el-col :xs="24" :md="12" >-->
          <!--  <img src="@/assets/images/locations/eLearning_content_img_04.jpg?v=1" />-->
          <!--</el-col>-->
        </el-row>

        <div class="line"></div>

        <el-row :gutter="30" class="padding-left margin-top">
          <el-col :xs="24" :md="12">
            <div class="title3">Connect with us</div>
            <div class="text">
              Request more information today, and we'll send you all the details
              you need to begin your exciting journey at SCAD.
              <br /><br />
              <a
                target="_blank"
                href="https://app.jingsocial.com/microFrontend/forms/jsf-forms/details/qFjikR3MCzTGfMEbp4bRqh?lead_source_type=survey&lead_source_value=qFjikR3MCzTGfMEbp4bRqh&promotion_code=survey_qrcode&promotion_value=cdw9Dvn5cUBUn6GxM44mja&promotion_channel_id=tiVt8LccayrF2kbKxd9jja&global_promotion_channel_id=3ZH8PjSow9oxzhbzfrcXAX&global_promotion_sub_channel_id=n9RQk7YyKX6he32EtHzFce&appId=wxe14b2fd0105ac9dc"
                >Request Information
              </a>
            </div>
          </el-col>

          <el-col :xs="24" :md="12">
            <img
              src="@/assets/images/locations/eLearning_content_img_06.jpg?v=1"
            />
          </el-col>
        </el-row>
      </div>
    </div>
    <div v-if="$i18n.locale === 'zh'">
      <div class="grid-content content">
        <div class="title1">通过 SCADnow，在世界各地获得卓越的在线教育</div>
        <div class="video">
          <video
            src="https://scad-minisite.oss-cn-beijing.aliyuncs.com/video/20231110/2023_24_SCADnow_Mandarin_subs_V7.mp4"
            controls
            poster="https://scad-minisite.oss-cn-beijing.aliyuncs.com/video/20231110/2023_24_SCADnow_Mandarin_subs_V7.mp4?x-oss-process=video/snapshot,t_2000,m_fast"
          ></video>
        </div>
        <div class="tips text">
          SCADnow
          是一个标志性的在线学习平台，它为行踪不定的艺术家、设计师和专业人士提供两全其美的远程教育，把实时参与课程与按需访问相结合。为获得最大的灵活性，SCADnow
          课程实时传输并可随时回访课程内容，允许学生根据自己独特的时间表，以线上模式学习屡获殊荣的本科和研究生学位课程。
        </div>
        <div class="line" style="margin-top: 1%"></div>
        <div class="padding-both">
          <img
            src="@/assets/images/locations/eLearning_content_img_01.jpg?v=1"
            alt=""
          />
          <div class="title2">无与伦比的扩展学习机会</div>
          <div class="text text-center">
            SCADnow 为学生提供一系列强大的扩展学习机会，例如 Guest and
            Gusto、SCADextra 研讨会和大学内部专业演讲培训工作室 SCADamp。
            SCADnow
            线上学习机会还扩展到大学的专业驱动计划，例如通过大学内部设计工作室
            SCADpro 与顶级公司进行数字合作。
          </div>
        </div>
        <div class="line"></div>

        <el-row :gutter="30" class="padding-left">
          <el-col :xs="24" :md="12">
            <img
              src="@/assets/images/locations/eLearning_content_img_02.jpg?v=1"
            />
          </el-col>
          <el-col :xs="24" :md="12">
            <div class="title3">触手可及的 SCAD 体验</div>
            <div class="text">
              通过 SCADnow线上服务，学生只需点击一下即可获得世界各地 SCAD
              地点提供的资源和支持服务，包括同伴辅导、作家工作室、Bee
              Well、职业和校友成功网络，以及访问大学图书馆馆藏。通过 SCAD 图书馆
              Ex Libris
              提供在线资源和教科书租赁，确保学生无论在何处登录都能获得成功所需的材料。
            </div>
          </el-col>
        </el-row>
        <el-row :gutter="30" class="padding-left margin-top">
          <el-col :xs="24" :md="12">
            <img
              src="@/assets/images/locations/eLearning_content_img_03.jpg?v=1"
            />
          </el-col>
          <el-col :xs="24" :md="12">
            <div class="title3">连接专属关系网</div>
            <div class="text">
              SCADnow
              在线课程由在全球各校区任教的知名教师领导授课。这些非凡的行业领导者运用在社会实践的经验，将帮助你发展自己的技能，并为你在自己的创意事业中脱颖而出做好准备。
            </div>
          </el-col>
        </el-row>

        <div class="line"></div>

        <el-row :gutter="30" class="padding-left margin-top">
          <el-col :xs="24" :md="12">
            <div class="title3">卓越的历史</div>
            <div class="text">
              SCAD 提供在线学位已有近 20
              年的历史。我们是第一所获得教学技术委员会颁发的杰出远程教育项目奖以及在线学习联盟颁发的全机构在线教学卓越奖的艺术与设计大学。
            </div>
          </el-col>

          <el-col :xs="24" :md="12">
            <img
              src="@/assets/images/locations/eLearning_content_img_04.jpg?v=1"
            />
          </el-col>
        </el-row>

        <el-row :gutter="30" class="padding-left margin-top">
          <el-col :span="24">
            <!--<img-->
            <!--  src="@/assets/images/locations/eLearning_content_img_01.jpg?v=1"-->
            <!--  alt=""-->
            <!--/>-->
            <div class="title3">准备和提供通往成功的工具</div>
            <div class="text">
              SCAD
              的使命包括为学生提供在学业和职业生涯中取得成功所需的工具和技术。

              <div style="margin-top: 15px">
                <div class="tool-list">
                  <div>● Autodesk Maya</div>
                  <div>● Autodesk 3ds Max</div>
                  <div>● Autodesk Mudbox</div>
                  <div>● Unity 3D</div>
                  <div>● Adobe Creative Cloud</div>
                  <div>● Remote conferencing with Zoom</div>
                  <div>● Nuke Personal Learning Edition</div>
                  <div>● Microsoft Windows 8</div>
                  <div>● Microsoft Windows 7</div>
                  <div>● Microsoft Visual Studio</div>
                  <div>● Access to on-ground 3D printers and more</div>
                  <div>● LinkedIn Learning</div>
                  <div>● Microsoft XNA Game Studio</div>
                  <div>● Microsoft Kinect for Windows SDK</div>
                  <div>● Microsoft Expression Studio</div>
                </div>
              </div>
            </div>
          </el-col>

          <!--<el-col :xs="24" :md="12" >-->
          <!--  <img src="@/assets/images/locations/eLearning_content_img_04.jpg?v=1" />-->
          <!--</el-col>-->
        </el-row>

        <div class="line"></div>

        <el-row :gutter="30" class="padding-left margin-top">
          <el-col :xs="24" :md="12">
            <div class="title3">联系我们</div>
            <div class="text">
              立即索取更多信息，我们将向你发送开始SCAD激动人心的旅程所需的详细信息。
              <br /><br />
              <a
                target="_blank"
                href="https://app.jingsocial.com/microFrontend/forms/jsf-forms/details/fjbXnGDs4SQNrs7uL6dph7?lead_source_type=survey&lead_source_value=fjbXnGDs4SQNrs7uL6dph7&promotion_code=survey_qrcode&promotion_value=ifzDy6ceWJuk3qCYFxrEzD&promotion_channel_id=EuQLxTKCfzLJgdmf8Z2DzD&global_promotion_channel_id=3ZH8PjSow9oxzhbzfrcXAX&global_promotion_sub_channel_id=n9RQk7YyKX6he32EtHzFce&appId=wxe14b2fd0105ac9dc"
                style="color: #000000"
                >了解更多
              </a>
            </div>
          </el-col>

          <el-col :xs="24" :md="12">
            <img
              src="@/assets/images/locations/eLearning_content_img_06.jpg?v=1"
            />
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped lang="less">
@import "../Atlanta.less";
</style>
