const params = [
  { programs: "Creative Business Leadership", value: "CRBL" },
  { programs: "Fashion", value: "FASH" },
  { programs: "Film and Television", value: "FILM" },
  { programs: "Graphic Design", value: "GRDS" },
  { programs: "Illustration", value: "ILLU" },
  { programs: "Interior Design", value: "INDS" },
  { programs: "Luxury and Brand Management", value: "LXMT" },
  { programs: "Motion Media Design", value: "MOME" },
  { programs: "Photography", value: "PHOT" },
  { programs: "Painting", value: "PNTG" },
  { programs: "Sculpture", value: "SCPT" },
  { programs: "Sequential Art", value: "SEQA" },
  { programs: "Undecided", value: "UNDE" },
  { programs: "Visual Effects", value: "VSFX" },
  { programs: "Writing", value: "WRIT" },
];

export default params;
