<template>
  <div class="cust-container">
    <menuBar></menuBar>
    <div>
      <div class="banner">
        <img src="@/assets/images/international_students/banner.jpg" />
        <div class="text" v-if="$i18n.locale === 'en'">
          INTERNATIONAL STUDENTS
        </div>
        <div class="text" v-if="$i18n.locale === 'zh'">国际学生</div>
      </div>

      <div class="content" v-if="$i18n.locale === 'en'">
        <div class="title">Student clubs</div>
        <div class="desc">
          SCAD student clubs provide social, recreational, and professional
          activities for every member of the student body. Explore an interest,
          meet new people, or take on a leadership role.
        </div>

        <div class="title1">Atlanta clubs</div>
        <div class="text1">
          The information below is indicative of the most common student clubs
          active on the SCAD Atlanta location. For more information about
          current clubs and student organizations, visit the
          <a href="https://blog.scad.edu/atlinvolvement/"
            >SCAD Atlanta student involvement blog</a
          >.
        </div>
        <div class="title1">Academic</div>
        <div class="text1">
          <ul>
            <li>AIGA Atlanta AIGA</li>
            <li>Digital Media Network</li>
            <li>Fashion Luxe (FLX)</li>
            <li>Film Club</li>
            <li>Game Club</li>
            <li>Hivemind</li>
            <li>iDo (Interior Design Organization)</li>
            <li>The Marksmen – A Drawing Club</li>
            <li>Momentum</li>
            <li>Music Production Club</li>
            <li>SCAD Advertising Atlanta</li>
            <li>SCAD à la mode</li>
            <li>SCAD Atlanta Animation Society (SAAS)</li>
            <li>SCAD Atlanta Illustration Club</li>
            <li>SCAD Rigging Inc.</li>
            <li>SCAD SIGGRAPH Club</li>
            <li>SCAD Stop Motion Club</li>
            <li>Sequential Art Club</li>
          </ul>
        </div>
        <div class="title1">Community service</div>
        <div class="text1">
          <ul>
            <li>SERVE</li>
          </ul>
        </div>
        <div class="title1">Cultural</div>
        <div class="text1">
          <ul>
            <li>African Student Association</li>
            <li>Black Student Association</li>
            <li>Chinese Students Association (CSA)</li>
            <li>Island Vybz</li>
            <li>Korean Undergraduate Student Association (KUSA)</li>
            <li>Latino Club</li>
            <li>SISA- SCAD Indian Student Association</li>
            <li>Intercultural Club of SCAD Atlanta</li>
          </ul>
        </div>
        <div class="title1">Religious</div>
        <div class="text1">
          <ul>
            <li>Believers' LoveWorld location Ministry</li>
            <li>Kings and Queens</li>
            <li>SCAD InterVarsity Christian Fellowship</li>
          </ul>
        </div>
        <div class="title1">Special interest</div>
        <div class="text1">
          <ul>
            <li>Business of SCAD Students (BOSS)</li>
            <li>Dance Club</li>
            <li>ECO (Environmentally Concerned Organization)</li>
            <li>Fountain</li>
            <li>Improvisation Club</li>
            <li>LGBT + Community</li>
            <li>Makeup Club</li>
            <li>Musician’s Club</li>
            <li>Networking Club</li>
            <li>Rhythm and Movement (RAM)</li>
            <li>SCAD Animu</li>
            <li>SCAD Atlanta Esports</li>
            <li>SCAD B(ee) (em)power(ed)</li>
            <li>SCAD Costume Club</li>
            <li>SCAD for Africa</li>
            <li>SCAD Furs</li>
            <li>SCAD Writers' Circle</li>
            <li>SCOPE Peer Educators</li>
            <li>Theater Club</li>
            <li>Vegan Club</li>
            <li>Women in Technology</li>
            <li>Writer's Womb</li>
          </ul>
        </div>
        <div class="title1">Student leadership</div>
        <div class="text1">
          <ul>
            <li>Student Activities Council</li>
            <li>United Student Forum (USF)</li>
          </ul>
        </div>

        <div style="margin-top: 50px">
          <hr />
        </div>
        <div class="title1">Savannah clubs</div>
        <div class="text1">
          The information below is indicative of the most common student clubs
          active on the SCAD Savannah campus. For more information about current
          clubs and student organizations, visit the
          <a href="https://blog.scad.edu/icc/"
            >SCAD Savannah student involvement blog</a
          >.
        </div>

        <div class="title1">Academic</div>
        <div class="text1">
          <ul>
            <li>AIGA SCAD AIGA</li>
            <li>Alpha Psi Omega</li>
            <li>American Institute of Architecture Students (AIAS)</li>
            <li>Animation Organization</li>
            <li>ASHRAE</li>
            <li>Digital Media Club部</li>
            <li>Drawing Inc.</li>
            <li>Fibers Force</li>
            <li>FLUX-UX Club</li>
            <li>Furniture Friends</li>
            <li>Game Audio Experience</li>
            <li>Game Development Network</li>
            <li>Graduate Illustration Club</li>
            <li>Inter-Net</li>
            <li>Interior Design Organization</li>
            <li>MOME Love</li>
            <li>
              National Organization of Minority Architecture Students (NOMAS)
            </li>
            <li>National Residence Hall Honorary</li>
            <li>The Oglethorpe Chapter</li>
            <li>Radical Architectural Discourse (RAD)</li>
            <li>SCAD Art History Society</li>
            <li>SCAD Ceramics Club</li>
            <li>SCAD Concept Art Club</li>
            <li>SCAD Geodesign Student Chapter (SGTSC)</li>
            <li>SCAD Motion Capture Club</li>
            <li>SCAD Rigging Union</li>
            <li>SCAD Sequential Art Society</li>
            <li>Scripted</li>
            <li>STITCH</li>
            <li>Stop Motion Club</li>
            <li>Student Arts Managers (SAM)</li>
            <li>Student Preservation Association (SPA)</li>
            <li>Tau Sigma Delta</li>
            <li>TEA @ SCAD</li>
            <li>USITT Student Chapter</li>
          </ul>
        </div>

        <div class="title1">Community service</div>
        <div class="text1">
          <ul>
            <li>Breaking the Cycle</li>
            <li>Freedom by Design</li>
            <li>PositiviBees</li>
            <li>SCAD SERVE</li>
          </ul>
        </div>

        <div class="title1">Cultural</div>
        <div class="text1">
          <ul>
            <li>African Student Union</li>
            <li>Black Student Association</li>
            <li>Chinese Student Association</li>
            <li>Association of Korean International Students</li>
            <li>SCAD Bollywood Dance Club</li>
            <li>SCAD Indian Student Association</li>
            <li>United Reaction</li>
          </ul>
        </div>

        <div class="title1">Religious</div>
        <div class="text1">
          <ul>
            <li>CREED</li>
            <li>Hillel</li>
            <li>The Muslim Student Association (MSA)</li>
            <li>Reformed University Fellowship (RUF)</li>
            <li>Young Life 年轻的生命俱乐部</li>
          </ul>
        </div>

        <div class="title1">Special interest</div>
        <div class="text1">
          <ul>
            <li>3rd Act Production</li>
            <li>After Dark Sketch Comedy</li>
            <li>Anime Club</li>
            <li>The BeeSharps Acapella Group BeeSharps</li>
            <li>Climate Reality</li>
            <li>CosClub</li>
            <li>Humans vs. Humans</li>
            <li>Multicultural Alliance Theatre (MAT)</li>
            <li>Savannah Nerf Underground (SNUG)</li>
            <li>SCAD Science Club</li>
            <li>SCAD Furs</li>
            <li>SCAD Gamers' Guild</li>
            <li>SCAD Gardening Club</li>
            <li>SCAD Humans vs. Zombies</li>
            <li>SCAD Improv</li>
            <li>SCAD Overwatch League</li>
            <li>SCAD Pokemon Peach League</li>
            <li>SCAD Queers and Allies</li>
            <li>SCAD Sequential Art Society</li>
            <li>SCAD Stand Up Comedy Club</li>
            <li>SCAD Super Smash Bros.</li>
            <li>SIGGRAPH</li>
            <li>Women's Empowerment Club (WEC)</li>
          </ul>
        </div>

        <div class="title1">Student leadership</div>
        <div class="text1">
          <ul>
            <li>Student Activities Council (SAC)</li>
            <li>United Student Forum (USF)</li>
          </ul>
        </div>
      </div>

      <div class="content" v-if="$i18n.locale === 'zh'">
        <div class="title">学生社团</div>
        <div class="desc">
          SCAD
          学生社团为每一位学生提供社交、娱乐和专业相关的获得。你可以在这里探索兴趣，结识新朋友或担任领导角色。
        </div>

        <div class="title1">亚特兰大社团</div>
        <div class="text1">
          以下信息代表了 SCAD 亚特兰大校区最常见的学生俱乐部。
          有关当前俱乐部和学生组织的更多信息，请访问
          <a href="https://blog.scad.edu/atlinvolvement/"
            >SCAD 亚特兰大学生参与博客</a
          >。
        </div>
        <div class="title1">学术社团</div>
        <div class="text1">
          <ul>
            <li>亚特兰大社团</li>
            <li>数字媒体网络社团</li>
            <li>时尚奢华社团 (FLX)</li>
            <li>电影社团</li>
            <li>游戏社团</li>
            <li>蜂群思维社团</li>
            <li>iDo（室内设计组织）</li>
            <li>射手——绘画社团</li>
            <li>动量社团</li>
            <li>音乐制作社</li>
            <li>SCAD亚特兰大广告社团</li>
            <li>SCAD à la 模式亚特兰大</li>
            <li>SCAD亚特兰大动画协会（SAAS）</li>
            <li>SCAD亚特兰大插画社</li>
            <li>SCAD索具公司</li>
            <li>SCAD SIGGRAPH 社团</li>
            <li>SCAD定格动画社</li>
            <li>图形叙事社团</li>
          </ul>
        </div>
        <div class="title1">社区服务</div>
        <div class="text1">SERVE 服务</div>
        <div class="title1">文化</div>
        <div class="text1">
          <ul>
            <li>非洲学生协会</li>
            <li>黑人学生会</li>
            <li>维布兹岛协会</li>
            <li>韩国本科生协会（KUSA）</li>
            <li>拉丁裔协会</li>
            <li>SCAD亚特兰大印度学生会</li>
            <li>SCAD亚特兰大跨文化俱乐部</li>
          </ul>
        </div>
        <div class="title1">宗教</div>
        <div class="text1">
          <ul>
            <li>信徒爱心世界校园事工协会</li>
            <li>国王和王后协会</li>
            <li>SCAD校际基督徒教徒协会</li>
          </ul>
        </div>
        <div class="title1">兴趣社团</div>
        <div class="text1">
          <ul>
            <li>SCAD学生业务协会（BOSS）</li>
            <li>舞蹈俱乐部</li>
            <li>ECO（环保组织）</li>
            <li>喷泉协会</li>
            <li>即兴俱乐部</li>
            <li>性别平等LGBT + 社区</li>
            <li>化妆俱乐部</li>
            <li>音乐家俱乐部</li>
            <li>社交俱乐部</li>
            <li>节奏和运动协会(RAM)</li>
            <li>SCAD动画俱乐部</li>
            <li>SCAD亚特兰大电子竞技俱乐部</li>
            <li>SCAD学生赋能俱乐部</li>
            <li>SCAD 服装俱乐部</li>
            <li>SCAD非洲俱乐部</li>
            <li>SCAD动物装扮俱乐部</li>
            <li>SCAD作家协会</li>
            <li>SCOPE同伴教育协会</li>
            <li>戏剧俱乐部</li>
            <li>素食俱乐部</li>
            <li>科技女性俱乐部</li>
            <li>作家的摇篮俱乐部</li>
          </ul>
        </div>
        <div class="title1">学生领导力</div>
        <div class="text1">
          <ul>
            <li>学生活动委员会</li>
            <li>联合学生论坛协会（USF）</li>
          </ul>
        </div>

        <div style="margin-top: 50px">
          <hr />
        </div>
        <div class="title1">萨凡纳的社团</div>
        <div class="text1">
          下面的信息是 SCAD 萨凡纳校区最常见的学生俱乐部的名单。
          有关当前俱乐部和学生组织的更多信息，请访问
          <a href="https://blog.scad.edu/icc/">SCAD Savannah学生参与博客</a>。
        </div>

        <div class="title1">学术社团</div>
        <div class="text1">
          <ul>
            <li>SCAD俱乐部</li>
            <li>阿尔法欧米茄戏剧社</li>
            <li>美国建筑学院学生 (AIAS)</li>
            <li>动画组织协会</li>
            <li>ASHRAE协会</li>
            <li>数字媒体俱乐部</li>
            <li>绘图公司</li>
            <li>纤维的力量俱乐部</li>
            <li>FLUX-用户体验俱乐部</li>
            <li>家具设计之友俱乐部</li>
            <li>游戏音效体验俱乐部</li>
            <li>游戏开发网俱乐部</li>
            <li>研究生插画俱乐部</li>
            <li>互联网协会</li>
            <li>室内设计机构</li>
            <li>动效爱社团</li>
            <li>全国少数民族建筑学生组织 (NOMAS)</li>
            <li>国家宿舍荣誉协会</li>
            <li>奥格尔索普章协会</li>
            <li>激进建筑讨论协会（RAD）</li>
            <li>SCAD艺术史学会</li>
            <li>SCAD陶瓷俱乐部</li>
            <li>SCAD概念艺术俱乐部</li>
            <li>SCAD 地理设计学生分会 (SGTSC)</li>
            <li>SCAD 动作捕捉俱乐部</li>
            <li>SCAD 索具联盟</li>
            <li>SCAD图像序列艺术协会</li>
            <li>剧本俱乐部</li>
            <li>缝纫俱乐部</li>
            <li>定格动画俱乐部</li>
            <li>学生艺术经理协会（SAM）</li>
            <li>学生保护协会（SPA）</li>
            <li>Tau Sigma 三角洲协会</li>
            <li>茶@SCAD俱乐部</li>
            <li>USITT学生分会</li>
          </ul>
        </div>

        <div class="title1">社区服务社团</div>
        <div class="text1">
          <ul>
            <li>打破循环社团</li>
            <li>设计自由社团</li>
            <li>积极蜜蜂社团</li>
            <li>SCAD 服务协会</li>
          </ul>
        </div>

        <div class="title1">文化社团</div>
        <div class="text1">
          <ul>
            <li>非洲学生会</li>
            <li>黑人学生会</li>
            <li>中国学生会</li>
            <li>韩国学生会</li>
            <li>SCAD宝莱坞舞蹈俱乐部</li>
            <li>SCAD 印度学生协会</li>
            <li>联合反应社团</li>
          </ul>
        </div>

        <div class="title1">宗教社团</div>
        <div class="text1">
          <ul>
            <li>信条俱乐部</li>
            <li>希勒尔俱乐部</li>
            <li>穆斯林学生协会（MSA）</li>
            <li>改革宗大学奖学金 (RUF)</li>
            <li>年轻的生命俱乐部</li>
          </ul>
        </div>

        <div class="title1">特别兴趣俱乐部</div>
        <div class="text1">
          <ul>
            <li>第三幕制作舍</li>
            <li>天黑之后小品喜剧俱乐部</li>
            <li>动漫俱乐部</li>
            <li>BeeSharps 无伴奏合唱团</li>
            <li>气候现实俱乐部</li>
            <li>COS俱乐部</li>
            <li>人类与人类俱乐部</li>
            <li>多元文化联盟剧院（MAT）</li>
            <li>萨凡纳 Nerf 地下俱乐部 (SNUG)</li>
            <li>SCAD 科学社团</li>
            <li>SCAD动物扮演俱乐部</li>
            <li>SCAD 游戏玩家俱乐部</li>
            <li>SCAD园艺俱乐部</li>
            <li>SCAD 人类大战僵尸俱乐部</li>
            <li>SCAD 即兴表演俱乐部</li>
            <li>SCAD守望先锋联赛</li>
            <li>SCAD 口袋妖怪桃子联盟</li>
            <li>SCAD 酷儿和盟友俱乐部</li>
            <li>SCAD图像序列艺术协会</li>
            <li>SCAD 脱口秀喜剧俱乐部</li>
            <li>SCAD Super Smash Bros. 俱乐部</li>
            <li>计算机图像视觉协会</li>
            <li>妇女赋权俱乐部（WEC）</li>
          </ul>
        </div>

        <div class="title1">学生领导</div>
        <div class="text1">
          <ul>
            <li>学生活动委员会（SAC）</li>
            <li>联合学生论坛（USF）</li>
          </ul>
        </div>
      </div>
    </div>

    <goTop></goTop>
    <custFooter></custFooter>
  </div>
</template>

<script>
import menuBar from "../../components/menuBar/index.vue";
import custFooter from "@/components/footer/Footer";
export default {
  metaInfo: {
    title: "SCAD", // set a title
  },
  name: "International1",
  components: {
    menuBar,
    custFooter,
  },
  data: function () {
    return {};
  },
};
</script>

<style lang="less" scoped>
@import url(International.less);
</style>
