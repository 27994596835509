<template>
  <div id="app">
    <!--v-wechat-title="$route.meta.title"-->
    <router-view />
  </div>
</template>

<script type="text/javascript">
// eslint-disable-next-line
(function (z, h, i, m, a, j, s) {
  z[i] =
    z[m] ||
    function () {
      (z[m].a = z[m].a || []).push(arguments);
    };
  // eslint-disable-next-line
  j = h.createElement(i), s = h.getElementsByTagName(i)[0];
  j.async = true;
  j.charset = "UTF-8";
  j.setAttribute(
    "data-json",
    JSON.stringify({
      id: 1534,
      token: "d8b890790334a9287b71d03f0f8ac4c0",
      image: "https://xiaokefu.com.cn/static/home/img/logo_x120.png",
      customer: 1, // 控制是否显示客服图标，0为显示，1为不显示
      language: "ZH", // 控制显示语言，ZH为中文，EN为英文
    })
  );
  j.id = "xiaokefu_js";
  j.src =
    "https://pcjs.xiaokefu.com.cn/static/pc_Access/dist/pcAccess.js?version=" +
    parseInt(new Date().getTime() / 3600000);
  s.parentNode.insertBefore(j, s);
})(window, document, "script", "_xiaokefu");
</script>

<style lang="less">
//body {
//  font-family: pingfang SC, helvetica neue, arial, hiragino sans gb,
//    microsoft yahei ui, microsoft yahei, simsun, sans-serif !important;
//}
body {
  font-family: "Gotham SSm A", "Gotham SSm B", "Century Gothic", "Helvetica",
    "Arial", pingfang SC, helvetica neue, arial, hiragino sans gb,
    microsoft yahei ui, microsoft yahei, simsun, sans-serif !important;
  font-style: normal;
  color: #333;
  font-weight: 400;
  font-size: 16px;
  font-kerning: normal;
  -webkit-font-smoothing: antialiased;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
p,
ul,
li {
  font-family: "Gotham SSm A", "Gotham SSm B", "Century Gothic", "Helvetica",
    "Arial", pingfang SC, helvetica neue, arial, hiragino sans gb,
    microsoft yahei ui, microsoft yahei, simsun, sans-serif !important;
  line-height: 1.7;
}

h1,
h2,
h3 {
  margin-top: 10px;
  margin-bottom: 10px;
}

@media (max-width: 800px) {
  h1 {
    font-size: 20px;
  }
  h2 {
    font-size: 18px;
  }

  h3 {
    font-size: 16px;
  }

  h4 {
    font-size: 14px;
  }

  h5 {
    font-size: 12px;
  }

  p {
    font-size: 13px !important;
  }
  ul {
    padding-left: 30px;
    /deep/ li {
    }
  }
}

hr {
  margin-top: 10px;
  margin-bottom: 10px;
}

h1 {
  font-size: 36px;
}
* {
  outline: none;
}
.w-100 {
  width: 100%;
}
.text-center {
  text-align: center;
}

#app {
  @media (max-width: 800px) {
    //width: 800px;
  }
}

a {
  &:active {
    background: none;
  }
}
.pc {
  display: block;
  @media (max-width: 800px) {
    display: none;
  }
}

.mo {
  display: none;
  @media (max-width: 800px) {
    display: block;
  }
}

.color_pink {
  background: #e81e8c;
}
.color_green {
  background: #03a687;
}
.color_blue {
  background: #213d86;
}
</style>
