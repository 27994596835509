<template>
  <div>
    <TFUnderGrad2023EN v-if="$i18n.locale === 'en'"></TFUnderGrad2023EN>
    <TFUnderGrad2023CN v-if="$i18n.locale === 'zh'"></TFUnderGrad2023CN>
  </div>
</template>

<script>
import TFUnderGrad2023EN from "./T-F-Under-Grad-2023-EN.vue";
import TFUnderGrad2023CN from "./T-F-Under-Grad-2023-CN.vue";
export default {
  name: "T-F-Under-Grad-2023",
  components: { TFUnderGrad2023EN, TFUnderGrad2023CN },
};
</script>

<style scoped></style>
