const params = [
  { programs: "Advertising and Branding", value: "ADBR" },
  { programs: "Game Development", value: "GAME" },
  { programs: "Graphic Design", value: "GRDS" },
  { programs: "Interactive Design", value: "INTR" },
  { programs: "Photography", value: "PHOT" },
  { programs: "Sequential Art", value: "SEQA" },
];

export default params;
