export default {
  public: {
    search: "Search",
  },
  nav: {
    about_SCAD: "About SCAD",
    locations: "Locations",
    mission_vision_values: "Mission, Vision, Values",
    Academics: "Academics",
    academic_programs: "Academic Programs",
    faq: "FAQ",
    frequently_asked_questions: "Frequently Asked Questions",
    admission: "Admission",
    admission_events: "Admission Events",
    admissions_application: "Admission Application",
    request_information: "Request Information",
    live_inquiry: "Inquire now",
    "INTERNATIONAL STUDENTS": "International Students",
    free: "Tuition and fees",
  },
  faq: {
    faq_text_01: "International FAQs",
    faq_text_02: "How do I apply?",
    faq_text_03:
      "You can access the online application at <a href='https://www.scadedu.cn/application/'>www.scadedu.cn/application/</a>.",
    faq_text_04: "When should I apply, and when are the application deadlines?",
    faq_text_05:
      "SCAD is on rolling admission and accepts applications year-round for" +
      "            all quarters, so there are no firm deadlines to apply. It is" +
      "            recommended, however, that students apply at least six months in" +
      "            advance and no later than 30 days prior to the start of the quarter." +
      "            New students may enter at the beginning of any quarter. Fall quarter" +
      "            is the most popular quarter; it has the most applications and the" +
      "            most course offerings. Early application is encouraged if you wish" +
      "            to enroll in fall quarter. For SCAD Savannah and SCAD Atlanta" +
      "            students, an I-20 application for a student visa may be issued only" +
      "            after a student has been accepted and has provided the required" +
      "            certification of financial documentation.",
    faq_text_06:
      "I have dual citizenship. Should I apply as a domestic or" +
      "            international student?",
    faq_text_07:
      "Students who have dual citizenship are considered U.S. citizens" +
      "            regardless of where they live or what their other legal citizenship" +
      "            is. They should apply as domestic students.",
    faq_text_08: "Do you have a support center for international students?",
    faq_text_09:
      "Yes, the International Student Services Office (ISSO) staff" +
      "            addresses questions concerning academics, university life, " +
      "            cross-cultural issues, employment, health care, housing, safety, " +
      "            immigration, insurance, scholarships, taxes, travel, and personal" +
      "            matters.",
    faq_text_10: "Can international students apply for certificate programs?",
    faq_text_11:
      "International students can only apply for certificates offered" +
      "            through SCADnow. Since a certificate is not a full degree, we" +
      "            would not be able to issue them a student visa.",
    faq_text_12: "How do I apply for a scholarship?",
    faq_text_13:
      "Incoming undergraduate students, including international students, " +
      "            are automatically reviewed for academic scholarships after they have" +
      "            been accepted to SCAD. Academic scholarships are based upon GPA." +
      "            Admitted students who have submitted a résumé/list of achievements" +
      "            and/or a portfolio are also reviewed for achievement scholarships." +
      "            Students are encouraged to submit both. <br />" +
      "            Graduate students are automatically reviewed for merit-based" +
      "            scholarships at the time of admission. <br />" +
      "            If you have questions about a specific scholarship listed, please" +
      "            contact your admission adviser. We also encourage all students to" +
      "            apply for scholarships from sources outside the university. There" +
      "            are many ways to find opportunities; SCAD also provides resources to" +
      "            help you in your search for outside scholarships.",
    faq_text_14:
      "How can I pay the application fee if I do not have an American" +
      "            credit/debit card?",
    faq_text_15:
      "Students without a valid credit or debit card may mail a check, " +
      "            money order or wire transfer for USD $100 to SCAD Admission" +
      "            Department, P.O. Box 2072, Savannah, GA, 31402-2072 USA.",
    faq_text_16: "How do I get transfer credit at SCAD?",
    faq_text_17:
      "Transfer credit may be awarded to students who have already" +
      "            completed college-level courses. Credit is awarded only from" +
      "            accredited institutions or institutions recognized by the ministry" +
      "            of education or equivalent agency in the student's home country." +
      "            <br />" +
      "            The university awards a maximum of two years’ worth of transfer" +
      "            credits to a student pursuing an undergraduate four-year Bachelor" +
      "            degree. Students must submit official transcripts from all prior" +
      "            universities in order to receive a transfer credit review. To ensure" +
      "            that the maximum amount is awarded, it is recommended that students" +
      "            include course descriptions and syllabi from all classes they wish" +
      "            to transfer. Additionally, a portfolio, review may be required to" +
      "            award transfer credit for drawing, painting, or other studio" +
      "            coursework.",
    faq_text_18:
      "How can I obtain information about the TOEFL, IELTS or" +
      "            examination?",
    faq_text_19:
      "Information regarding these examinations, including testing dates" +
      "            and fees, is available online. When you take the test, request that" +
      "            your score be sent directly to SCAD. SCAD's TOEFL institution code" +
      "            is 5631." +
      "            <br />" +
      "            TOEFL<br />" +
      "            IELTS",
    faq_text_20:
      "How recent do my Test of English as a Foreign Language (TOEFL)" +
      "            scores need to be?",
    faq_text_21:
      "TOEFL scores can be up to two years old. If you have scores older" +
      "            than that, you would need to retake the test or provide alternate" +
      "            proof of English proficiency.",
    faq_text_22:
      "Are international students eligible for financial aid and" +
      "            scholarships?",
    faq_text_23:
      "Because of U.S. government restrictions, international students are" +
      "            not eligible for U.S. financial aid in the form of personal grants, " +
      "            loans, or work-study funds, but university-funded work-study jobs are" +
      "            available at some locations. SCAD offers some scholarships for" +
      "            international students, however, these do not fully cover the cost" +
      "            of tuition or living expenses. International students must have" +
      "            sufficient financial resources for the full course of study.",
    faq_text_24: "Where can I learn more about ESL?",
    faq_text_25:
      "The SCAD Language Studio provides English as a second language" +
      "            instruction appropriate to varied student needs, levels, and" +
      "            proficiencies.",
    faq_text_26: "What is the climate like at SCAD?",
    faq_text_27:
      "In Savannah, winter temperatures average 49 degrees Fahrenheit, " +
      "            summer temperatures average 82 degrees and average rainfall is 49" +
      "            inches. In Atlanta, winter temperatures average 41 degrees" +
      "            Fahrenheit, summer temperatures average 79 degrees and average" +
      "            rainfall is 51 inches.",
    faq_text_28:
      "I am applying as an undergraduate student. Is a portfolio required?",
    faq_text_29:
      "A portfolio isn't required for undergraduate admission, but you are" +
      "            encouraged to submit one for scholarship consideration. It does not" +
      "            have to be related to your intended major and should consist of your" +
      "            very best work.",
    faq_text_30: "How do I submit my undergraduate portfolio?",
    faq_text_31:
      "Although a portfolio is not required for undergraduate admission, a" +
      "            portfolio and/or a résumé/list of achievements is required for" +
      "            achievement scholarship consideration. SCAD accepts several types of" +
      "            portfolios, including visual art, time-based media, writing, " +
      "            equestrian, and performing arts. Undergraduate portfolios do not have" +
      "            to be specific to an intended program of study at SCAD; students may" +
      "            select the type of portfolio that they feel will allow them to" +
      "            present their best work." +
      "            <br />" +
      "            Learn more about portfolio types, tips on how to prepare a portfolio" +
      "            and submission guidelines. Students should submit their portfolios" +
      "            online via SlideRoom. A fee of US$10 is charged by SlideRoom for" +
      "            each portfolio submission.",
  },
  programs: {
    available_at: "Available at:",
    Atlanta: "Atlanta",
    Savannah: "Savannah",
    eLearning: "SCADnow",
    Lacoste: "Lacoste",
  },
  footer: {
    "Request information": "Request information",
    LEARN: "LEARN",
    Undergraduate: "Undergraduate",
    Graduate: "Graduate",
    Apply: "Apply",
    EXPLORE: "EXPLORE",
    "SCAD Atlanta": "SCAD Atlanta",
    "SCAD Lacoste": "SCAD Lacoste",
    "SCAD Savannah": "SCAD Savannah",
    eLearning: "SCADnow",
    "Visit SCAD": "Visit SCAD",
    ADMISSION: "ADMISSION",
    "Admission Events": "Admission Events",
    "The University for Creative Careers":
      "The University for Creative Careers",
  },
  home: {
    "Highlighted Academic Programs": "Highlighted Academic Programs",
    "Highlighted Locations": "Highlighted Locations",
    "Highlighted Awards": "Highlighted Awards",
    "WHY CHOOSE SCAD": "WHY CHOOSE SCAD",
    "SCAD is continually":
      "SCAD is continually recognized for educational and professional excellence, affirming our commitment to the highest standards of achievement in art and design.",
  },
  locations: {
    Select_your_preferred_location:
      "Select your preferred location <br />for more information",
  },
  Academic: {
    text1: "More than 100 degree programs to match your passion",
    text2:
      "In addition to our graduate and undergraduate programs, SCAD offers more than 75 minors and certificate programs, with access to learning centers around the world. Most degree programs are offered in multiple locations, so you can start in one place and finish in another.",
  },
};
