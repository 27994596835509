<template>
  <div class="recommend">
    <el-collapse value="1" class="moRecommend" accordion>
      <el-collapse-item name="1">
        <template slot="title">
          <div class="recomandTitle">
            {{ $t("home.Highlighted Academic Programs") }}
          </div>
        </template>
        <div>
          <articleList ref="first" :articleList="academicProgramsList" />
        </div>
      </el-collapse-item>

      <el-collapse-item>
        <template slot="title">
          <div class="recomandTitle">
            {{ $t("home.Highlighted Locations") }}
          </div>
        </template>
        <div>
          <articleList ref="first" :articleList="locationsList" />
        </div>
      </el-collapse-item>

      <el-collapse-item>
        <template slot="title">
          <div class="recomandTitle">
            {{ $t("home.Highlighted Awards") }}
          </div>
        </template>
        <div>
          <articleList ref="first" :articleList="academicProgramsList" />
        </div>
      </el-collapse-item>
    </el-collapse>

    <el-tabs class="pcRecommend" v-model="activeName" @tab-click="handleClick">
      <el-tab-pane
        :label="$t('home.Highlighted Academic Programs')"
        name="first"
      >
        <articleList ref="first" :articleList="academicProgramsList" />
      </el-tab-pane>
      <el-tab-pane :label="$t('home.Highlighted Locations')" name="second">
        <articleList ref="second" :articleList="locationsList" />
      </el-tab-pane>
      <el-tab-pane :label="$t('home.Highlighted Awards')" name="third">
        <articleList ref="third" :articleList="highlightedAwardsList" />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import articleList from "./components/ArticleList";
import i18n from "@/i18n";
export default {
  name: "Recommend",
  props: ["mobile"],
  components: {
    articleList,
  },
  data() {
    return {
      activeName: "first",
      academicProgramsList: [
        {
          img_url: "/img/academicPrograms/549_Interior_design_1x1.jpeg",
          name: i18n.t("Interior Design"),
          desc: i18n.t("Elevate your environment with SCAD interior design"),
          url: "/Programs/58/M.A.%20in%20interior%20design.html",
        },
        {
          img_url: "/img/academicPrograms/799_Fashion_1x1.jpeg",
          name: i18n.t("Fashion"),
          desc: i18n.t("Become the next big name in fashion"),
          url: "/Programs/30/M.A.%20in%20fashion.html",
        },
        {
          img_url: "/img/academicPrograms/556_Illustration_1x1.jpeg",
          name: i18n.t("Illustration"),
          desc: i18n.t("Draw the future with SCAD illustration"),
          url: "/Programs/46/B.A.%20in%20illustration.html",
        },
      ],
      locationsList: [
        {
          img_url: "/img/location/1.jpeg",
          name: i18n.t("Atlanta"),
          desc: i18n.t("A booming metropolitan hub"),
          url: "/locations/Atlanta",
        },
        {
          img_url: "/img/location/2.jpeg",
          name: i18n.t("Lacoste"),
          desc: i18n.t("An idyllic medieval village"),
          url: "/locations/Lacoste",
        },
        {
          img_url: "/img/location/3.jpeg",
          name: i18n.t("Savannah"),
          desc: i18n.t("The historic coastal south"),
          url: "/locations/Savannah",
        },
      ],
      highlightedAwardsList: [
        {
          img_url: "/img/awards/1.jpg",
          name: i18n.t("Top Individual Program Rankings"),
          desc: "",
          url: "/topIndividualProgramRankings",
        },
        {
          img_url: "/img/awards/2.jpg",
          name: i18n.t("Top Rankings"),
          desc: "",
          url: "/topRankings",
        },
        {
          img_url: "/img/awards/3.jpg",
          name: i18n.t("STEM"),
          desc: "",
          url: "/awardsSTEM",
        },
      ],
    };
  },
  watch: {
    mobile: {
      // eslint-disable-next-line no-unused-vars
      handler(newValue, oldValue) {
        this.mobile = newValue;
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    handleClick(tab, event) {
      // console.log(this.$refs[this.activeName].update());
    },
  },
};
</script>

<style lang="less">
.recommend {
  padding: 3vw 3vw;

  max-width: 1600px;
  margin: 0 auto;
  //@media (min-width: 1600px) {
  //  font-size:39px;
  //}

  @media (max-width: 800px) {
    padding: 3vw 3vw;
  }

  @media (max-width: 600px) {
    padding: 0;
  }

  .moRecommend {
    display: none;
    border-top: 0px solid #ebeef5;
    border-bottom: 0px solid #ebeef5;
    @media (max-width: 600px) {
      display: block;
    }

    .recomandTitle {
      background: white;
      color: black;
      border: 0;
      width: 100%;
      display: block;
      text-align: center;
      //font-size: 20px;
    }

    .is-active {
      &.el-collapse-item__header {
        border: 0;
      }
      .recomandTitle {
        background: black;
        color: white;
      }
    }
  }

  .pcRecommend {
    display: block;
    @media (max-width: 600px) {
      display: none;
    }
  }
  .el-tabs__nav-wrap::after {
    height: 0 !important;
  }
  .el-tabs__active-bar {
    display: none;
  }

  .el-tabs__nav {
    display: flex;
    width: 100%;
    #tab-second {
      margin: 0 6px;

      width: calc(33.3333% - 12px);
    }
    .el-tabs__item {
      flex-shrink: 0;
      width: 33.3333%;
      text-align: center;
      border: 1px solid #e3e3e3;
      //margin: 0 3px;
      line-height: 4.5vw;
      font-size: 16px;
      height: auto;

      @media (min-width: 1600px) {
        //font-size:39px;
        line-height: 80px;
      }

      //&:first-child {
      //  margin-left: 0;
      //}

      background: #ffffff;
      transition: background 0.3s;

      @media (max-width: 800px) {
        font-size: 12px;
      }

      &.is-active {
        color: white;
        background: #000000;
      }

      &:hover {
        color: #000000;
      }

      &.is-active {
        color: white;
        background: #000000;
      }
    }
  }
}

.el-collapse-item__arrow {
  display: none;
}
</style>
