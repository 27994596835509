import { render, staticRenderFns } from "./InternationalCAPP-CN.vue?vue&type=template&id=37d42f2d&scoped=true&"
import script from "./InternationalCAPP-CN.vue?vue&type=script&lang=js&"
export * from "./InternationalCAPP-CN.vue?vue&type=script&lang=js&"
import style0 from "./els-css/typography.gotham.css?vue&type=style&index=0&id=37d42f2d&lang=css&scoped=true&"
import style1 from "./InternationalCAPP-CN.vue?vue&type=style&index=1&id=37d42f2d&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "37d42f2d",
  null
  
)

export default component.exports