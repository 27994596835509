<template>
  <div class="cust-container">
    <menuBar></menuBar>
    <div v-if="$i18n.locale === 'en'">
      <div class="banner">
        <img src="@/assets/images/international_students/banner.jpg" />
        <div class="text">INTERNATIONAL STUDENTS</div>
      </div>

      <div class="content">
        <div class="menu-list">
          <a class="menu-item" href="/International-1" style="color: #4ab066">
            Take the first step in your journey to the U.S.
          </a>
          <a
            class="menu-item"
            href="/Getting-started-at-SCAD"
            style="color: #de408a"
          >
            Getting started at SCAD as an international student
          </a>

          <a class="menu-item" href="/International-3" style="color: #53acf0">
            Working in the U.S.
          </a>

          <a class="menu-item" href="/International-4" style="color: #53acf0">
            Find your SCAD family
          </a>

          <a class="menu-item" href="/International-5" style="color: #eea346">
            Student clubs
          </a>

          <a
            class="menu-item"
            href="/EnglishLanguageSupport"
            style="color: #0b7963"
          >
            English as a Second Language
          </a>

          <a
            class="menu-item"
            href="/international-capp"
            style="color: #0b7963"
          >
            SCAD Cultural and Academic Preparation Program
          </a>
        </div>

        <!--<ul class="tips">-->
        <!--  <li>-->
        <!--    Each of these sections will have a lot of text explaining the-->
        <!--    requirements to study in SCAD as an international student-->
        <!--  </li>-->
        <!--</ul>-->
      </div>
    </div>

    <div v-if="$i18n.locale === 'zh'">
      <div class="banner">
        <img src="@/assets/images/international_students/banner.jpg" />
        <div class="text">国际学生</div>
      </div>

      <div class="content">
        <div class="menu-list">
          <a class="menu-item" href="/International-1" style="color: #4ab066">
            跨出美国之旅的第一步
          </a>
          <a
            class="menu-item"
            href="/Getting-started-at-SCAD"
            style="color: #de408a"
          >
            国际学生如何开启在SCAD的生活
          </a>

          <a class="menu-item" href="/International-3" style="color: #53acf0"
            >在美国工作</a
          >

          <a class="menu-item" href="/International-4" style="color: #53acf0"
            >寻找您的 SCAD 大家庭</a
          >

          <a class="menu-item" href="/International-5" style="color: #eea346"
            >学生社团</a
          >

          <a
            class="menu-item"
            href="/EnglishLanguageSupport"
            style="color: #0b7963"
          >
            流利运用英语这门第二语言 (ESL)
          </a>

          <a
            class="menu-item"
            href="/international-capp"
            style="color: #0b7963"
          >
            SCAD 文化和学术准备计划
          </a>
        </div>

        <!--<ul class="tips">-->
        <!--  <li>每个部分都将详细阐明国际学生在 SCAD 学习的具体要求</li>-->
        <!--</ul>-->
      </div>
    </div>
    <goTop></goTop>
    <custFooter></custFooter>
  </div>
</template>

<script>
import menuBar from "../../components/menuBar/index.vue";
import custFooter from "@/components/footer/Footer";
export default {
  metaInfo: {
    title: "SCAD", // set a title
  },
  name: "InternationalStudents",
  components: {
    menuBar,
    custFooter,
  },
  data: function () {
    return {};
  },
};
</script>

<style lang="less" scoped>
@import url(InternationalStudents.less);
</style>
