<template>
  <div>
    <TFEN v-if="$i18n.locale === 'en'"></TFEN>
    <TFCN v-if="$i18n.locale === 'zh'"></TFCN>
  </div>
</template>

<script>
import TFEN from "./EN.vue";
import TFCN from "./CN.vue";
export default {
  name: "T-F-Under-Grad-2025",
  components: { TFEN, TFCN },
};
</script>

<style scoped></style>
