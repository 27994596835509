<template>
  <div>
    <menuBar></menuBar>
    <div class="cust-container">
      <img
        class="banner"
        src="@/assets/images/academicPrograms/banner.jpg"
        alt=""
      />
      <div class="content">
        <div class="head">
          <div class="title">
            {{ $t("Academic.text1") }}
          </div>
          <div class="desc">
            {{ $t("Academic.text2") }}
          </div>
        </div>

        <div class="searchBox">
          <!--<div class="input">-->
          <!--  <input-->
          <!--    type="text"-->
          <!--    :placeholder="$t('public.search')"-->
          <!--    maxlength="50"-->
          <!--  />-->
          <!--</div>-->
          <!--<div class="searchIcon" @click="searchIconClick">-->
          <!--  <img src="@/assets/images/academicPrograms/searchIcon_01.jpg" />-->
          <!--</div>-->
        </div>

        <div class="degreeNav">
          <div class="degreeItem" @click="degreeItemClick('B.A.')">B.A.</div>
          <div class="degreeItem" @click="degreeItemClick('B.F.A.')">
            B.F.A.
          </div>
          <div class="degreeItem" @click="degreeItemClick('M.A.')">M.A.</div>
          <div class="degreeItem" @click="degreeItemClick('M.F.A.')">
            M.F.A.
          </div>
        </div>

        <img
          class="footImg"
          src="@/assets/images/academicPrograms/img_1209.jpg"
          alt=""
        />
      </div>
    </div>

    <goTop></goTop>
    <custFooter></custFooter>
  </div>
</template>

<script>
import menuBar from "../../components/menuBar/index.vue";
import custFooter from "@/components/footer/Footer";
import router from "@/router";
import i18n from "@/i18n";
export default {
  name: "MissonVisionValue",
  metaInfo: {
    title: i18n.t("nav.academic_programs"), // set a title
  },
  components: {
    menuBar,
    custFooter,
  },
  data: function () {
    return {};
  },
  methods: {
    degreeItemClick(name) {
      router.push({ path: "/AcademicPrograms", query: { type: name } });
    },
    searchIconClick() {
      alert("暂未开放");
    },
  },
};
</script>
<style scoped lang="less">
@import "Academic.less";
</style>
