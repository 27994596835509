const params = [
  { programs: "Advertising and Branding", value: "ADBR" },
  { programs: "Advertising Photography", value: "ADVP" },
  { programs: "Equestrian Studies", value: "EQST" },
  { programs: "Game Development", value: "GAME" },
  { programs: "Graphic Design", value: "GRDS" },
  { programs: "Illustration", value: "ILLU" },
  { programs: "Interactive Design", value: "INTR" },
  { programs: "Sequential Art", value: "SEQA" },
  { programs: "Undecided", value: "UNDE" },
];

export default params;
