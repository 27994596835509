<template>
  <div>
    <menuBar></menuBar>
    <div class="cust-container tuition-and-fees">
      <img src="@/assets/images/tuitionandFees/banner.jpg" class="banner" />

      <div class="header-container">
        <h1>学杂费</h1>
        <h4>
          在每个级别，SCAD 学杂费涵盖了 SCAD
          教育在课堂、社交活动中的资源，以及与您选择的相关职业领域与行业领导者合作的成本。
        </h4>
      </div>

      <div class="nav-container">
        <div class="nav-list">
          <div class="nav-item">
            <a href="/T-F-Under-Grad-2025">本科生</a>
            <a class="active" href="/T-F-Grad-2025">研究生</a>
          </div>

          <div class="nav-item">
            <a class="active" href="/T-F-Grad-2025">2024-25</a>
            <!--            <a class="" href="/T-F-Grad-2023">2022-23</a>-->
            <!--<a href="/T-F-Grad-2022">2021-22</a>-->
            <a
              href="https://scad-minisite.oss-cn-beijing.aliyuncs.com/pdf/Mandarin%20version%20of%20the%20Meal%20Plans.pdf"
              class="active"
              target="_blank"
              style="
                color: #387cb0;
                background: white;
                border: 1.5px solid #387cb0;
              "
              >膳食套餐</a
            >
          </div>

          <!--          <div class="nav-item" v-if="mealPlans">-->
          <!--            <a-->
          <!--              href="https://scad-minisite.oss-cn-beijing.aliyuncs.com/pdf/Mandarin%20version%20of%20the%20Meal%20Plans.pdf"-->
          <!--              class="active"-->
          <!--              target="_blank"-->
          <!--              style="-->
          <!--                color: #387cb0;-->
          <!--                background: white;-->
          <!--                border: 1.5px solid #387cb0;-->
          <!--              "-->
          <!--              >膳食套餐</a-->
          <!--            ><a href="javascript:void(0);" style="border: 0"></a>-->
          <!--          </div>-->
        </div>
      </div>

      <div class="tuitionand-fees-container">
        <div class="tuitionand-fees-tips">
          亚特兰大，萨凡纳，SCADnow线上学习，拉科斯特
        </div>
        <div class="tuitionand-fees-list">
          <div>
            <div class="tuitionand-fees-item bg-gary">
              <div class="tuitionand-fees-item-title">年度学费</div>
              <div class="tuitionand-fees-item-desc italic">
                基于每季度三门课程入学，为期三个季度
              </div>
            </div>

            <div class="tuitionand-fees-item-list">
              <div class="tuitionand-fees-item bg-white">
                <div class="tuitionand-fees-item-name">
                  总数，一学年全日制学生
                </div>

                <div class="tuitionand-fees-number">US $42,120</div>
              </div>
            </div>
          </div>

          <div>
            <div class="tuitionand-fees-item bg-gary">
              <div class="tuitionand-fees-item-title">学季学费</div>
            </div>

            <div class="tuitionand-fees-item-list">
              <div class="tuitionand-fees-item bg-white">
                <div class="tuitionand-fees-item-name">
                  全日制学生，每季度三门课程
                </div>
                <div class="tuitionand-fees-item-name-desc">
                  15 小时学分，150 小时教学
                </div>

                <div class="tuitionand-fees-number">US $14,040</div>
              </div>

              <div class="tuitionand-fees-item bg-white">
                <div class="tuitionand-fees-item-name">
                  兼职学生，每季度两门课程
                </div>
                <div class="tuitionand-fees-item-name-desc">
                  10 小时学分，100 小时教学
                </div>

                <div class="tuitionand-fees-number">US $9,360</div>
              </div>

              <div class="tuitionand-fees-item bg-white">
                <div class="tuitionand-fees-item-name">
                  少于非全日制学生，每季度一门课程
                </div>
                <div class="tuitionand-fees-item-name-desc">
                  5 小时学分，50 小时教学
                </div>

                <div class="tuitionand-fees-number">US $4,680</div>
              </div>

              <div class="tuitionand-fees-item bg-white">
                <div class="tuitionand-fees-item-name">一学分</div>
                <div class="tuitionand-fees-number">US $936</div>
              </div>
            </div>
          </div>

          <div>
            <div class="tuitionand-fees-item bg-gary">
              <div class="tuitionand-fees-item-title">新学生申请和入学费用</div>
            </div>

            <div class="tuitionand-fees-item-list">
              <div class="tuitionand-fees-item bg-white">
                <div class="tuitionand-fees-item-name">网上申请费</div>
                <div class="tuitionand-fees-number">US $100</div>
              </div>

              <div class="tuitionand-fees-item bg-white">
                <div class="tuitionand-fees-item-name">一次性报名费</div>
                <div class="tuitionand-fees-number">US $500</div>
              </div>
            </div>
          </div>

          <div>
            <div class="tuitionand-fees-item bg-gary">
              <div class="tuitionand-fees-item-title">一学年的住宿费*</div>
            </div>

            <div class="tuitionand-fees-item-list">
              <div class="tuitionand-fees-item bg-white">
                <div class="tuitionand-fees-item-name">新学生宿舍预订费</div>
                <div class="tuitionand-fees-number">US $750</div>
              </div>

              <div class="tuitionand-fees-item bg-white">
                <div class="tuitionand-fees-item-name">在校生住宿预订费</div>
                <div class="tuitionand-fees-number">US $750</div>
              </div>
            </div>
          </div>

          <div>
            <div class="tuitionand-fees-item bg-gary">
              <div class="tuitionand-fees-item-title">一学年的宿舍余额*</div>
              <div class="tuitionand-fees-item-desc italic">
                价格包括 US $750 美元的学生宿舍预订费。
              </div>
            </div>

            <div class="tuitionand-fees-item-list">
              <div class="tuitionand-fees-item bg-white">
                <div class="tuitionand-fees-item-name weight">萨凡纳</div>
              </div>

              <div class="tuitionand-fees-item bg-white">
                <div class="tuitionand-fees-item-name">
                  Chatham之家，双人间（两卫浴）
                </div>
                <div class="tuitionand-fees-number">US $11,727</div>
              </div>
              <div class="tuitionand-fees-item bg-white">
                <div class="tuitionand-fees-item-name">
                  Chatham之家，双人间（一卫浴）
                </div>
                <div class="tuitionand-fees-number">US $11,412</div>
              </div>

              <div class="tuitionand-fees-item bg-white">
                <div class="tuitionand-fees-item-name">The Hive，双人间</div>
                <div class="tuitionand-fees-number">US $11,412</div>
              </div>

              <div class="tuitionand-fees-item bg-white">
                <div class="tuitionand-fees-item-name">
                  Oglethorpe 之家，（2 名学生），没有厨房和双人卧室
                </div>
                <div class="tuitionand-fees-number">US $10,614</div>
              </div>

              <div class="tuitionand-fees-item bg-white">
                <div class="tuitionand-fees-item-name">
                  Montgomery 之家，（4 名学生），没有厨房和双人卧室
                </div>
                <div class="tuitionand-fees-number">US $11,310</div>
              </div>

              <div class="tuitionand-fees-item bg-white">
                <div class="tuitionand-fees-item-name">Turner之家，双人间</div>
                <div class="tuitionand-fees-number">US $10,662</div>
              </div>

              <div class="tuitionand-fees-item bg-white">
                <div class="tuitionand-fees-item-name">Turner之家，三人间</div>
                <div class="tuitionand-fees-number">US $9,867</div>
              </div>

              <div class="tuitionand-fees-item bg-white">
                <div class="tuitionand-fees-item-name">
                  Boundary村，(4 名学生），包括厨房和私人卧室
                </div>
                <div class="tuitionand-fees-number">US $12,258</div>
              </div>

              <div class="tuitionand-fees-item bg-white">
                <div class="tuitionand-fees-item-name">
                  Barnard村，(4 名学生），包括厨房和私人卧室
                </div>
                <div class="tuitionand-fees-number">US $12,258</div>
              </div>

              <div class="tuitionand-fees-item bg-white">
                <div class="tuitionand-fees-item-name">
                  Victory 村的Sand（4 名学生），包括厨房和私人卧室
                </div>
                <div class="tuitionand-fees-number">US $13,143</div>
              </div>

              <div class="tuitionand-fees-item bg-white">
                <div class="tuitionand-fees-item-name">
                  Victory 村的Sand（2 名学生），包括厨房和私人卧室
                </div>
                <div class="tuitionand-fees-number">US $15,372</div>
              </div>

              <div class="tuitionand-fees-item bg-white">
                <div class="tuitionand-fees-item-name">
                  Victory 村的Sand（1 名学生），包括厨房和私人卧室
                </div>
                <div class="tuitionand-fees-number">US $17,682</div>
              </div>

              <div class="tuitionand-fees-item bg-white">
                <div class="tuitionand-fees-item-name">
                  Victory村的Sail和Surf （4 名学生），两室一卫浴，两人一间
                </div>
                <div class="tuitionand-fees-number">US $11,412</div>
              </div>

              <div class="tuitionand-fees-item bg-white">
                <div class="tuitionand-fees-item-name">
                  Victory村的Sail和Surf （4 名学生），两室两卫浴，两人一间
                </div>
                <div class="tuitionand-fees-number">US $11,727</div>
              </div>

              <div class="tuitionand-fees-item bg-white">
                <div class="tuitionand-fees-item-name">
                  Victory村的Sail和Surf （2 名学生），一室双人间
                </div>
                <div class="tuitionand-fees-number">US $12,483</div>
              </div>

              <div class="tuitionand-fees-item bg-white">
                <div class="tuitionand-fees-item-name">
                  Victory村Sail和Surf（1 名学生），工作室，无厨房
                </div>
                <div class="tuitionand-fees-number">US $13,143</div>
              </div>

              <div class="tuitionand-fees-item bg-white">
                <div class="tuitionand-fees-item-name">
                  River 四人套房 (双人间，每间套房有四位住客)
                </div>
                <div class="tuitionand-fees-number">US $11,412</div>
              </div>

              <div class="tuitionand-fees-item bg-white">
                <div class="tuitionand-fees-item-name">
                  River 公寓式，私人卧室
                </div>
                <div class="tuitionand-fees-number">US $13,143</div>
              </div>

              <div class="tuitionand-fees-item bg-white">
                <div class="tuitionand-fees-item-name">Ann街，一室双人间</div>
                <div class="tuitionand-fees-number">US $13,143</div>
              </div>

              <div class="tuitionand-fees-item bg-white">
                <div class="tuitionand-fees-item-name weight">亚特兰大</div>
              </div>

              <div class="tuitionand-fees-item bg-white">
                <div class="tuitionand-fees-item-name">
                  SCAD之家, 公寓（双人间，每间公寓有四位住户，并包括厨房）
                </div>
                <div class="tuitionand-fees-number">US $12,258</div>
              </div>

              <div class="tuitionand-fees-item bg-white">
                <div class="tuitionand-fees-item-name">
                  FORTY，两室公寓 （每个房间两人 ，共四人）
                </div>
                <div class="tuitionand-fees-number">US $13,143</div>
              </div>

              <div class="tuitionand-fees-item bg-white">
                <div class="tuitionand-fees-item-name">
                  FORTY，两室公寓 （每个房间两人 ，共四人）
                </div>
                <div class="tuitionand-fees-number">US $11,412</div>
              </div>

              <div class="tuitionand-fees-item bg-white">
                <div class="tuitionand-fees-item-name">
                  FORTY，四室套间 （每个房间两人 ，共八人）
                </div>
                <div class="tuitionand-fees-number">US $11,412</div>
              </div>

              <!--              <div class="tuitionand-fees-item bg-white">-->
              <!--                <div class="tuitionand-fees-item-name">-->
              <!--                  FORTY, 公寓（双人间，每间公寓有四位住户，并包括厨房）-->
              <!--                </div>-->
              <!--                <div class="tuitionand-fees-number">US $12,555</div>-->
              <!--              </div>-->

              <!--              <div class="tuitionand-fees-item bg-white">-->
              <!--                <div class="tuitionand-fees-item-name">-->
              <!--                  FORTY, 四人套房 (双人间，每间套房有四位住客)-->
              <!--                </div>-->
              <!--                <div class="tuitionand-fees-number">US $10,905</div>-->
              <!--              </div>-->

              <div class="tuitionand-fees-item bg-white">
                <div class="tuitionand-fees-item-name">
                  FORTY FOUR（4 名学生），两室公寓，两人一间
                </div>
                <div class="tuitionand-fees-number">US $13,143</div>
              </div>

              <div class="tuitionand-fees-item bg-white">
                <div class="tuitionand-fees-item-name">
                  FORTY FOUR（4 名学生），两室套间，两卫浴，两人一间
                </div>
                <div class="tuitionand-fees-number">US $11,727</div>
              </div>

              <div class="tuitionand-fees-item bg-white">
                <div class="tuitionand-fees-item-name">
                  FORTY FOUR（4 名学生）, 两室套房，一卫浴，两人一间
                </div>
                <div class="tuitionand-fees-number">US $11,412</div>
              </div>

              <div class="tuitionand-fees-item bg-white">
                <div class="tuitionand-fees-item-name">
                  FORTY FOUR, 双人套房(双人间)
                </div>
                <div class="tuitionand-fees-number">US $10,854</div>
              </div>

              <div class="tuitionand-fees-item bg-white">
                <div class="tuitionand-fees-item-name">
                  FORTY FOUR, 私人工作室
                </div>
                <div class="tuitionand-fees-number">US $13,134</div>
              </div>

              <div class="tuitionand-fees-item bg-white">
                <div class="tuitionand-fees-item-name">
                  FORTY FIVE（4 名学生），两室套间，一卫浴，两人一间
                </div>
                <div class="tuitionand-fees-number">US $11,412</div>
              </div>

              <div class="tuitionand-fees-item bg-white">
                <div class="tuitionand-fees-item-name">
                  FORTY FIVE, 双人套房(双人间)
                </div>
                <div class="tuitionand-fees-number">US $10,854</div>
              </div>
            </div>
          </div>

          <div class="tuitionand-fees-item-tips bg-white">
            <div class="">
              *包括住宿、家具、水电费以及本地电话、有线电视和互联网服务。宿舍住宿存在一些变化。具体信息会提供给必须要求住宿的学生。对于特定的住房要求/分配，我们不作任何保证。住房是有限的，从每年
              7 月 1 日开始，随着收到季度付款和签订的住房合同，确认分配。
            </div>
          </div>

          <div>
            <div class="tuitionand-fees-item bg-gary">
              <div class="tuitionand-fees-item-title">夏季住房</div>
              <div class="tuitionand-fees-item-desc italic">
                包括不可退还的 250 美元夏季住房预订费
              </div>
            </div>

            <div class="tuitionand-fees-item-list">
              <div class="tuitionand-fees-item bg-white">
                <div class="tuitionand-fees-item-name">传统风格，双人间</div>
                <div class="tuitionand-fees-number">US $3,878</div>
              </div>

              <div class="tuitionand-fees-item bg-white">
                <div class="tuitionand-fees-item-name">公寓式，双人间</div>
                <div class="tuitionand-fees-number">US $4,451</div>
              </div>

              <div class="tuitionand-fees-item bg-white">
                <div class="tuitionand-fees-item-name">公寓式，私人卧室</div>
                <div class="tuitionand-fees-number">US $4,232</div>
              </div>
            </div>
          </div>

          <div>
            <div class="tuitionand-fees-item bg-gary">
              <div class="tuitionand-fees-item-title">膳食计划（季度）</div>
            </div>

            <div class="tuitionand-fees-item-list">
              <div class="tuitionand-fees-item bg-white">
                <div class="tuitionand-fees-item-name">
                  18 餐/周，$75 餐饮美元
                </div>
                <div class="tuitionand-fees-number">US $2,069</div>
              </div>

              <div class="tuitionand-fees-item bg-white">
                <div class="tuitionand-fees-item-name">
                  每周 14 餐，$150 餐饮美元
                </div>
                <div class="tuitionand-fees-number">US $2,090</div>
              </div>

              <div class="tuitionand-fees-item bg-white">
                <div class="tuitionand-fees-item-name">
                  135 餐/季度，$125 餐饮美元
                </div>
                <div class="tuitionand-fees-number">US $2,031</div>
              </div>

              <div class="tuitionand-fees-item bg-white">
                <div class="tuitionand-fees-item-name">
                  85 餐/季度，$75 餐饮美元
                </div>
                <div class="tuitionand-fees-number">US $1,056</div>
              </div>

              <div class="tuitionand-fees-item bg-white">
                <div class="tuitionand-fees-item-name">
                  50 通勤费，100 美元餐饮费
                </div>
                <div class="tuitionand-fees-number">US $705</div>
              </div>

              <div class="tuitionand-fees-item bg-white">
                <div class="tuitionand-fees-item-name">
                  25 通勤费，125 美元餐饮费
                </div>
                <div class="tuitionand-fees-number">US $480</div>
              </div>
            </div>
          </div>

          <div>
            <div class="tuitionand-fees-item bg-gary">
              <div class="tuitionand-fees-item-title">英语作为第二语言</div>
              <div class="tuitionand-fees-item-desc italic">10周常规课程</div>
            </div>

            <div class="tuitionand-fees-item-list">
              <div class="tuitionand-fees-item bg-white">
                <div class="tuitionand-fees-item-name">
                  一级、二级、三级和四级
                </div>
                <div class="tuitionand-fees-item-name-desc">20 个ESL学时</div>

                <div class="tuitionand-fees-number">US $3,416</div>
              </div>

              <div class="tuitionand-fees-item bg-white">
                <div class="tuitionand-fees-item-name">五级</div>
                <div class="tuitionand-fees-item-name-desc">
                  15 个 ESL 学时*
                </div>

                <div class="tuitionand-fees-number">US $2,562</div>
              </div>

              <div class="tuitionand-fees-item bg-white">
                <div class="tuitionand-fees-item-name">六级</div>
                <div class="tuitionand-fees-item-name-desc">
                  15 个 ESL 课时**
                </div>

                <div class="tuitionand-fees-number">US $2,562</div>
              </div>
            </div>

            <div class="tuitionand-fees-item-tips bg-white">
              <div class="">
                *仅限 ESL
                课程费用（学生还必须参加学术工作室课程，并且必须支付学术课程的学费）

                <br />
                **仅限 ESL 课程费用（学生还需要参加英语 123
                和学术工作室课程，并且必须支付学术课程的学费）
              </div>
            </div>
          </div>

          <div>
            <div class="tuitionand-fees-item bg-gary">
              <div class="tuitionand-fees-item-title">
                英语作为第二语言 LEAP 课程
              </div>
              <div class="tuitionand-fees-item-desc italic">五周在线课程</div>
            </div>

            <div class="tuitionand-fees-item-list">
              <div class="tuitionand-fees-item bg-white">
                <div class="tuitionand-fees-item-name">为学术目的学习英语</div>
                <div class="tuitionand-fees-item-name-desc">五个 ESL 学时</div>

                <div class="tuitionand-fees-number">US $854</div>
              </div>
            </div>
          </div>

          <div>
            <div class="tuitionand-fees-item bg-gary">
              <div class="tuitionand-fees-item-title">拉科斯特项目费用</div>
            </div>

            <div class="tuitionand-fees-item-list">
              <div class="tuitionand-fees-item bg-white">
                <div class="tuitionand-fees-item-name">
                  房费、餐费、实地考察费
                </div>
                <div class="tuitionand-fees-item-name-desc">
                  参与者负责所有个人开支，包括在拉科斯特以外的用餐
                </div>

                <div class="tuitionand-fees-number">US $8,200</div>
              </div>

              <div class="tuitionand-fees-item bg-white">
                <div class="tuitionand-fees-item-name">保险费</div>
                <div class="tuitionand-fees-item-name-desc">
                  所有开始留学项目的学生将自动加入 SCAD 学生保险计划。<br />
                  此保险的费用将每季度添加到您的账单中。<br />
                  请注意，此保单并非您的主要保险；是留学保险有关该政策的更多信息，请发送电子邮件至
                  studyabroad@scad.edu。
                </div>

                <div class="tuitionand-fees-number">US $75</div>
              </div>

              <div class="tuitionand-fees-item bg-white">
                <div class="tuitionand-fees-item-name">
                  <b>拉科斯特费用详情</b>
                </div>
                <div class="tuitionand-fees-item-name-desc2">
                  参与者负责周末短途旅行期间的学费、课程费用、差旅费和所有个人开支，包括伙食费。由于必须在计划开始之前代表学生安排好航空旅行，因此参与者遵守录取电子邮件中的截止日期至关重要。所有押金均不可退还且不可转让；取消和取款必须通过发送电子邮件至
                  <a href="mailto:studyabroad@scad.edu">studyabroad@scad.edu</a>
                  以书面形式进行。计划方面如有更改，恕不另行通知。
                  <br />
                  被录取参加 SCAD
                  出国留学项目的合格学生将获得不同数额的旅行奖学金。如需更多信息，请发送电子邮件至
                  <a href="mailto:studyabroad@scad.edu">studyabroad@scad.edu</a>
                  或致电 912.525.5806。
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <goTop></goTop>
    <custFooter></custFooter>
  </div>
</template>

<script>
import menuBar from "../../../../components/menuBar/index.vue";
import custFooter from "@/components/footer/Footer";
export default {
  metaInfo: {
    title: "SCAD", // set a title
  },
  name: "TuitionandFees",
  components: {
    menuBar,
    custFooter,
  },
  data() {
    return {
      mealPlans: true,
    };
  },
  created() {
    if (this.$route.query.t === "preview") {
      this.mealPlans = true;
    }
  },
};
</script>

<style scoped lang="less">
@import "../TuitionandFees.less";
</style>
