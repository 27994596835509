<template>
  <div class="menuBar">
    <!--<router-link to="/">-->
    <!--  <img class="logo" src="../../assets/images/scad_01.jpg" alt="" />-->
    <!--</router-link>-->
    <router-link to="/" class="logo">
      <div class="logo-svg"></div>
      <!--<img class="logo" src="../../assets/logo-black.svg" alt="" />-->
    </router-link>

    <transition name="left1">
      <!--<div class="menuBox" :class="moMenuStatu ? 'open' : ''">-->
      <div class="menuBox" v-if="!mobile || moMenuStatu">
        <div class="moOperate">
          <div @click="changeMoMenu(false)" class="closeButton">
            <img src="@/assets/images/closeBtn.png" alt="" />
          </div>
          <div class="switchLanguage">
            <div
              v-if="lang === 'en'"
              class="switchLanguageItem"
              @click="changeLanguage('zh')"
            >
              Chinese 中文
            </div>
            <div
              v-if="lang === 'zh'"
              class="switchLanguageItem"
              @click="changeLanguage('en')"
            >
              English
            </div>
          </div>
        </div>
        <el-menu
          :default-active="activeIndex"
          class="menu"
          :mode="memuMode"
          @select="handleSelect"
          background-color="#000000"
          text-color="#ffffff"
          active-text-color="#ffffff"
        >
          <el-submenu index="1" :popper-append-to-body="true">
            <template slot="title">{{ $t("nav.about_SCAD") }}</template>
            <router-link to="/locations/Atlanta">
              <el-menu-item index="1-1">{{ $t("nav.locations") }}</el-menu-item>
            </router-link>
            <router-link to="/missonVisionValue/">
              <el-menu-item index="1-2">
                {{ $t("nav.mission_vision_values") }}
              </el-menu-item>
            </router-link>
          </el-submenu>

          <el-submenu index="2" :popper-append-to-body="true">
            <template slot="title">{{ $t("nav.Academics") }}</template>
            <router-link to="/Academic/">
              <el-menu-item index="2-1">
                {{ $t("nav.academic_programs") }}
              </el-menu-item>
            </router-link>
          </el-submenu>

          <el-submenu index="4">
            <template slot="title">{{ $t("nav.admission") }}</template>
            <a href="/Getting-started-at-SCAD" style="display: block">
              <el-menu-item index="4-5">
                {{ $t("nav.INTERNATIONAL STUDENTS") }}
              </el-menu-item>
            </a>
            <router-link to="/AdmissionEvents/">
              <el-menu-item index="4-1">
                {{ $t("nav.admission_events") }}
              </el-menu-item>
            </router-link>

            <a href="/application">
              <el-menu-item index="4-2">{{
                $t("nav.admissions_application")
              }}</el-menu-item>
            </a>
            <a
              v-if="$i18n.locale === 'zh'"
              href="https://app.jingsocial.com/microFrontend/forms/jsf-forms/details/9mCMdCR8rRm6azsPvpQUVG?lead_source_type=survey&lead_source_value=9mCMdCR8rRm6azsPvpQUVG&promotion_code=survey_qrcode&promotion_value=Bid2Ba65aV5hR3YuijC65M&promotion_channel_id=2g98UHBQfWQyiWziJ9rx4M&global_promotion_channel_id=3ZH8PjSow9oxzhbzfrcXAX&global_promotion_sub_channel_id=n9RQk7YyKX6he32EtHzFce&appId=wxe14b2fd0105ac9dc"
              target="_blank"
            >
              <el-menu-item index="4-3">{{
                $t("nav.request_information")
              }}</el-menu-item>
            </a>
            <a
              v-if="$i18n.locale === 'en'"
              href="https://app.jingsocial.com/microFrontend/forms/jsf-forms/details/Bt5QSchiSoHkQvKoxd5gPc?lead_source_type=survey&lead_source_value=Bt5QSchiSoHkQvKoxd5gPc&promotion_code=survey_qrcode&promotion_value=iSkCfBR9RxKDdTLp69eSxG&promotion_channel_id=zew4sYBauP98hApmFcJPxG&global_promotion_channel_id=3ZH8PjSow9oxzhbzfrcXAX&global_promotion_sub_channel_id=n9RQk7YyKX6he32EtHzFce&appId=wxe14b2fd0105ac9dc"
              target="_blank"
            >
              <el-menu-item index="4-3">{{
                $t("nav.request_information")
              }}</el-menu-item>
            </a>

            <el-menu-item index="4-4" @click="liveInquiryClick">{{
              $t("nav.live_inquiry")
            }}</el-menu-item>
          </el-submenu>

          <el-submenu index="3">
            <template slot="title">{{ $t("nav.faq") }}</template>
            <router-link to="/FAQ/">
              <el-menu-item index="3-1">
                {{ $t("nav.frequently_asked_questions") }}
              </el-menu-item>
            </router-link>

            <router-link to="/T-F-under-Grad-2025/">
              <el-menu-item index="3-2">
                {{ $t("nav.free") }}
              </el-menu-item>
            </router-link>
          </el-submenu>

          <!--<el-submenu index="5" class="noopen">-->
          <!--  <template slot="title"-->
          <!--    ><a href="#">International Students</a></template-->
          <!--  >-->
          <!--</el-submenu>-->

          <!--<el-menu-item index="5">-->
          <!--  <div>-->
          <!--    <a href="/InternationalStudents" style="display: block">-->
          <!--      {{ $t("nav.INTERNATIONAL STUDENTS") }}-->
          <!--    </a>-->
          <!--  </div>-->
          <!--</el-menu-item>-->
        </el-menu>
      </div>
    </transition>

    <div class="operate">
      <div class="switchLanguage">
        <div
          v-if="lang === 'en'"
          class="switchLanguageItem"
          @click="changeLanguage('zh')"
        >
          Chinese 中文
        </div>
        <div
          v-if="lang === 'zh'"
          class="switchLanguageItem"
          @click="changeLanguage('en')"
        >
          English
        </div>
      </div>
      <div @click="changeMoMenu(true)" class="moMenuIcon">
        <img style="display: block" src="@/assets/images/menuIcon.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeIndex: "1",
      lang: "",
      mobile: false,
      memuMode: "horizontal", //vertical
      moMenuStatu: false,
    };
  },
  created() {
    var query = this.$route.query;
    console.log(localStorage.getItem("lang"));

    if (query.lang) {
      if (query.lang === "en") {
        this.$i18n.locale = "en";
        this.lang = "en";
      } else {
        this.$i18n.locale = "zh";
        this.lang = "zh";
      }
    } else {
      var lang = localStorage.getItem("lang");
      if (lang) {
        this.$i18n.locale = lang;
        this.lang = lang;
      } else {
        this.$i18n.locale = "zh";
        this.lang = "zh";
      }
    }

    this.changeMenu();
    window.addEventListener("resize", () => {
      //监听浏览器窗口大小改变
      this.changeMenu();
    });
  },
  methods: {
    changeMoMenu(status) {
      this.moMenuStatu = status;
    },
    closeMenuClick() {},
    changeMenu() {
      if (document.body.clientWidth <= 800) {
        this.mobile = true;
        this.memuMode = "vertical";
      } else {
        this.mobile = false;
        this.memuMode = "horizontal";
      }
    },
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
    },
    changeLanguage(lang) {
      if (lang === "en") {
        this.$i18n.locale = "en";
        this.lang = "en";
      } else {
        this.$i18n.locale = "zh";
        this.lang = "zh";
      }
      localStorage.setItem("lang", lang); // 存到localStorage中
      window.location.reload();
    },
    liveInquiryClick() {
      // eslint-disable-next-line no-undef
      ZM.open();
    },
  },
};
</script>

<style lang="less">
@import "MenuBar.less";
@import "MenuBarTransition.less";

.noopen.el-submenu {
}

.menuBar .menu .el-menu-item {
  line-height: 80px;
}
</style>
