<template>
  <div>
    <menuBar></menuBar>
    <div class="cust-container">
      <img
        class="banner"
        src="@/assets/images/academicPrograms/banner.jpg"
        alt=""
      />
      <div class="content">
        <div class="programs" style="width: 100%">
          <div class="searchBox">
            <div class="studyLevel" @click="studyLevelClick">
              {{ type }}
              <div class="studyLevelList" :class="studyLevelListStyle">
                <div
                  class="studyLevelItem"
                  @click="studyLevelItemClick('B.A.')"
                  v-if="type != 'B.A.'"
                >
                  B.A.
                </div>
                <div
                  class="studyLevelItem"
                  @click="studyLevelItemClick('B.F.A.')"
                  v-if="type != 'B.F.A.'"
                >
                  B.F.A.
                </div>
                <div
                  class="studyLevelItem"
                  @click="studyLevelItemClick('M.A.')"
                  v-if="type != 'M.A.'"
                >
                  M.A.
                </div>
                <div
                  class="studyLevelItem"
                  @click="studyLevelItemClick('M.F.A.')"
                  v-if="type != 'M.F.A.'"
                >
                  M.F.A.
                </div>
              </div>
            </div>
            <div class="location">
              <div
                class="locationItem"
                @click="locationItemClick('Atlanta')"
                :class="locationItemName === 'Atlanta' ? 'active' : ''"
              >
                <i class="circle color_pink"></i>- {{ $t("programs.Atlanta") }}
              </div>
              <div
                class="locationItem"
                @click="locationItemClick('Savannah')"
                :class="locationItemName === 'Savannah' ? 'active' : ''"
              >
                <i class="circle color_green"></i>-
                {{ $t("programs.Savannah") }}
              </div>
              <div
                class="locationItem"
                @click="locationItemClick('eLearning')"
                :class="locationItemName === 'eLearning' ? 'active' : ''"
              >
                <i class="circle color_blue"></i>-
                {{ $t("programs.eLearning") }}
              </div>
            </div>
          </div>
          <div class="programsBox">
            <div class="programList">
              <el-row>
                <el-col
                  :span="12"
                  v-for="(item, index) in objList"
                  :key="index"
                  class="programItemBox"
                >
                  <a
                    :href="
                      '/Programs/' +
                      item.id +
                      '/' +
                      (lang === 'en' ? item.name_en : item.name) +
                      '.html'
                    "
                    style="text-decoration: none"
                  >
                    <div class="programItem">
                      <div class="programContent">
                        <div class="name">
                          {{ lang === "en" ? item.name_en : item.name }}
                        </div>
                        <div class="tags">
                          <i
                            class="circle color_pink"
                            :class="item2"
                            v-for="(item2, index2) in item.areaList"
                            :key="index2"
                          ></i>
                          <!--<i class="circle color_green"></i>-->
                          <!--<i class="circle color_blue"></i>-->
                        </div>
                      </div>
                    </div>
                  </a>
                </el-col>
              </el-row>
            </div>
          </div>
        </div>
        <div class="stem">
          <div class="title">STEM Programs</div>
          <div class="STEMPrograms">
            <div
              class="STEMProgramItem"
              :style="'background-image: url(' + item.cover_img + ')'"
              v-for="(item, index) in stemList"
              :key="index"
            >
              <a
                :href="
                  '/Programs/' +
                  item.id +
                  '/' +
                  (lang === 'en' ? item.name_en : item.name) +
                  '.html'
                "
              >
                <div class="text">
                  <h2 class="name">
                    {{ lang === "en" ? item.name_en : item.name }}
                  </h2>
                  <!--<h5 class="tags">-->
                  <!--  <span>B.A.</span>-->
                  <!--  <span>B.F.A.</span>-->
                  <!--  <span>M.A.</span>-->
                  <!--  <span>M.F.A.</span>-->
                  <!--</h5>-->
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <goTop></goTop>
    <custFooter></custFooter>
  </div>
</template>

<script>
import menuBar from "../../components/menuBar/index.vue";
import custFooter from "@/components/footer/Footer";
import i18n from "@/i18n";
// import router from "@/router";
export default {
  name: "MissonVisionValue",
  metaInfo: {
    title: i18n.t("nav.academic_programs"), // set a title
  },
  components: {
    menuBar,
    custFooter,
  },
  data: function () {
    return {
      type: "B.A",
      locationItemName: "",
      objList: [],
      stemList: [],
      oldObjList: [],
      lang: this.$i18n.locale,
      studyLevelListStyle: "",
    };
  },
  mounted() {
    this.type = this.$route.query.type;

    var that = this;
    this.$api.get(
      "AcademicsPrograms/getPrograms",
      {
        type: this.type,
      },
      (r) => {
        console.log(r);
        that.stemList = r.data.data.stem;
        that.objList = r.data.data.list;
        that.oldObjList = r.data.data.list;
        for (let i = 0; i < that.objList.length; i++) {
          let obj = that.objList[i];
          obj.areaList = obj.area.split(";");
        }

        for (let i = 0; i < that.oldObjList.length; i++) {
          let obj = that.oldObjList[i];
          obj.areaList = obj.area.split(";");
        }

        for (let i = 0; i < that.stemList.length; i++) {
          let obj = that.stemList[i];
          obj.areaList = obj.area.split(";");
        }
      }
    );
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    locationItemClick(_area) {
      if (this.locationItemName == _area) {
        this.locationItemName = "";
        this.objList = this.oldObjList;
        return;
      }

      this.locationItemName = _area;

      // this.oldObjList = this.objList;
      var temp = [];
      for (const objKey in this.oldObjList) {
        var obj = this.oldObjList[objKey];
        if (obj.areaList.indexOf(_area) >= 0) {
          temp.push(obj);
        }
        // var area = obj.area;
        // var area_arr = area.split(";");
        // area_arr.has;
        // if (area_arr.indexOf(_area) >= 0) {
        //   temp.push(obj);
        // }
        //
        // this.objList = temp;
      }
      this.objList = temp;
      console.log(temp);
    },
    studyLevelClick() {
      if (this.studyLevelListStyle == "in") {
        this.studyLevelListStyle = "";
      } else {
        this.studyLevelListStyle = "in";
      }
    },
    studyLevelItemClick(temp) {
      window.location.href = "/AcademicPrograms?type=" + temp;
    },
  },
};
</script>
<style scoped lang="less">
@import "AcademicPrograms.less";
</style>
