<template>
  <div class="footer">
    <div class="scadInfoContainer">
      <div class="title">SCAD</div>
      <div class="desc ani-left">
        {{ $t("footer.The University for Creative Careers") }}
      </div>
      <a class="email ani-left" href="mailto:contact@scad.edu"
        >contact@scad.edu</a
      >
      <div class="line"></div>
      <a
        v-if="$i18n.locale === 'zh'"
        target="_blank"
        href="https://app.jingsocial.com/microFrontend/forms/jsf-forms/details/9mCMdCR8rRm6azsPvpQUVG?lead_source_type=survey&lead_source_value=9mCMdCR8rRm6azsPvpQUVG&promotion_code=survey_qrcode&promotion_value=Bid2Ba65aV5hR3YuijC65M&promotion_channel_id=2g98UHBQfWQyiWziJ9rx4M&global_promotion_channel_id=3ZH8PjSow9oxzhbzfrcXAX&global_promotion_sub_channel_id=n9RQk7YyKX6he32EtHzFce&appId=wxe14b2fd0105ac9dc"
        class="information ani-left"
      >
        {{ $t("footer.Request information") }} <span class="icon">></span>
      </a>

      <a
        v-if="$i18n.locale === 'en'"
        target="_blank"
        href="https://app.jingsocial.com/microFrontend/forms/jsf-forms/details/Bt5QSchiSoHkQvKoxd5gPc?lead_source_type=survey&lead_source_value=Bt5QSchiSoHkQvKoxd5gPc&promotion_code=survey_qrcode&promotion_value=iSkCfBR9RxKDdTLp69eSxG&promotion_channel_id=zew4sYBauP98hApmFcJPxG&global_promotion_channel_id=3ZH8PjSow9oxzhbzfrcXAX&global_promotion_sub_channel_id=n9RQk7YyKX6he32EtHzFce&appId=wxe14b2fd0105ac9dc"
        class="information ani-left"
      >
        {{ $t("footer.Request information") }} <span class="icon">></span>
      </a>

      <div class="line"></div>
      <a href="https://beian.miit.gov.cn/" class="information ani-left">
        沪ICP备17031828号-2
      </a>
    </div>
    <div class="footerMenuContainer">
      <div class="footerMenuColumn">
        <div class="footerMenuItem learn">
          <div class="menuTitle">{{ $t("footer.LEARN") }}</div>
          <a href="/Academic/" class="menuItem">{{
            $t("footer.Undergraduate")
          }}</a>
          <a href="/Academic/" class="menuItem">{{ $t("footer.Graduate") }}</a>
          <div class="line"></div>
          <a href="/application" class="menuItem">{{ $t("footer.Apply") }}</a>
        </div>
      </div>
      <div class="footerMenuColumn">
        <div class="footerMenuItem explore">
          <div class="menuTitle">{{ $t("footer.EXPLORE") }}</div>
          <a href="/locations/Atlanta" class="menuItem">{{
            $t("footer.SCAD Atlanta")
          }}</a>
          <a href="/locations/Lacoste" class="menuItem">{{
            $t("footer.SCAD Lacoste")
          }}</a>
          <a href="/locations/Savannah" class="menuItem">{{
            $t("footer.SCAD Savannah")
          }}</a>
          <a href="/locations/SCADnow" class="menuItem">{{
            $t("footer.eLearning")
          }}</a>
          <div class="line"></div>
          <a
            href="https://www.scad.edu/admission/visit-scad"
            target="_blank"
            class="menuItem"
            >{{ $t("footer.Visit SCAD") }}</a
          >
        </div>
      </div>
      <div class="footerMenuColumn">
        <div class="footerMenuItem admission">
          <div class="menuTitle">{{ $t("footer.ADMISSION") }}</div>
          <!--<a href="/AdmissionEvents/" class="menuItem">{{-->
          <!--  $t("footer.Admission Events")-->
          <!--}}</a>-->

          <a
            v-if="$i18n.locale === 'zh'"
            href="https://app.jingsocial.com/microFrontend/forms/jsf-forms/details/fjbXnGDs4SQNrs7uL6dph7?lead_source_type=survey&lead_source_value=fjbXnGDs4SQNrs7uL6dph7&promotion_code=survey_qrcode&promotion_value=ifzDy6ceWJuk3qCYFxrEzD&promotion_channel_id=EuQLxTKCfzLJgdmf8Z2DzD&global_promotion_channel_id=3ZH8PjSow9oxzhbzfrcXAX&global_promotion_sub_channel_id=n9RQk7YyKX6he32EtHzFce&appId=wxe14b2fd0105ac9dc"
            target="_blank"
            class="menuItem"
          >
            {{ $t("footer.Request information") }}
          </a>

          <a
            v-if="$i18n.locale === 'en'"
            href="https://app.jingsocial.com/microFrontend/forms/jsf-forms/details/qFjikR3MCzTGfMEbp4bRqh?lead_source_type=survey&lead_source_value=qFjikR3MCzTGfMEbp4bRqh&promotion_code=survey_qrcode&promotion_value=cdw9Dvn5cUBUn6GxM44mja&promotion_channel_id=tiVt8LccayrF2kbKxd9jja&global_promotion_channel_id=3ZH8PjSow9oxzhbzfrcXAX&global_promotion_sub_channel_id=n9RQk7YyKX6he32EtHzFce&appId=wxe14b2fd0105ac9dc"
            target="_blank"
            class="menuItem"
          >
            {{ $t("footer.Request information") }}
          </a>
        </div>
      </div>
      <div class="qrcodeItem">
        <img class="img" src="@/assets/images/Website.jpg" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style scoped lang="less">
@import "Footer.less";
</style>
