<template>
  <div>
    <menuBar></menuBar>
    <div class="cust-container tuition-and-fees">
      <img src="@/assets/images/tuitionandFees/banner.jpg" class="banner" />

      <div class="header-container">
        <h1>Tuition and fees</h1>
        <h4>
          At each level, SCAD tuition covers the cost of the extraordinary
          opportunity a SCAD education provides in the classroom, at networking
          events and in collaboration with leaders in your chosen career field.
        </h4>
      </div>

      <div class="nav-container">
        <div class="nav-list">
          <div class="nav-item">
            <a href="/T-F-Under-Grad-2024">Undergraduate</a>
            <a class="active" href="/T-F-Grad-2024">Graduate</a>
          </div>

          <div class="nav-item">
            <a class="active" href="/T-F-Grad-2024">2023-24</a>
            <a class="" href="/T-F-Grad-2023">2022-23</a>
            <!--<a href="/T-F-Grad-2022">2021-22</a>-->
          </div>
          <div class="nav-item" v-if="mealPlans">
            <a
              href="https://scad-minisite.oss-cn-beijing.aliyuncs.com/pdf/2023_24_SCAD_Meal_Plans_ENGLISH_LG.pdf"
              class="active"
              target="_blank"
              style="
                color: #387cb0;
                background: white;
                border: 1.5px solid #387cb0;
              "
              >Meal Plans</a
            ><a href="javascript:void(0);" style="border: 0"></a>
          </div>
        </div>
      </div>

      <div class="tuitionand-fees-container">
        <div class="tuitionand-fees-tips">
          Atlanta, Savannah, SCADnow online, Lacoste
        </div>
        <div class="tuitionand-fees-list">
          <div>
            <div class="tuitionand-fees-item bg-gary">
              <div class="tuitionand-fees-item-title">Annual tuition</div>
              <div class="tuitionand-fees-item-desc italic">
                Based on three courses per quarter enrollment for three
                quarters.
              </div>
            </div>

            <div class="tuitionand-fees-item-list">
              <div class="tuitionand-fees-item bg-white">
                <div class="tuitionand-fees-item-name">
                  Total, full-time student for one academic year
                </div>

                <div class="tuitionand-fees-number">US $40,095</div>
              </div>
            </div>
          </div>

          <div>
            <div class="tuitionand-fees-item bg-gary">
              <div class="tuitionand-fees-item-title">Quarterly tuition</div>
            </div>

            <div class="tuitionand-fees-item-list">
              <div class="tuitionand-fees-item bg-white">
                <div class="tuitionand-fees-item-name">
                  Full-time student, three courses per quarter
                </div>
                <div class="tuitionand-fees-item-name-desc">
                  15 hours of credit, 150 hours of instruction
                </div>

                <div class="tuitionand-fees-number">US $13,695</div>
              </div>

              <div class="tuitionand-fees-item bg-white">
                <div class="tuitionand-fees-item-name">
                  Part-time student, two courses per quarter
                </div>
                <div class="tuitionand-fees-item-name-desc">
                  10 hours of credit, 100 hours of instruction
                </div>

                <div class="tuitionand-fees-number">US $9,130</div>
              </div>

              <div class="tuitionand-fees-item bg-white">
                <div class="tuitionand-fees-item-name">
                  Less than part-time student, one course per quarter
                </div>
                <div class="tuitionand-fees-item-name-desc">
                  Five hours of credit, 50 hours of instruction
                </div>

                <div class="tuitionand-fees-number">US $4,565</div>
              </div>

              <div class="tuitionand-fees-item bg-white">
                <div class="tuitionand-fees-item-name">One credit hour</div>
                <div class="tuitionand-fees-number">US $913</div>
              </div>
            </div>
          </div>

          <div>
            <div class="tuitionand-fees-item bg-gary">
              <div class="tuitionand-fees-item-title">
                New student application and enrollment fees
              </div>
              <div class="tuitionand-fees-item-desc italic">Nonrefundable</div>
            </div>

            <div class="tuitionand-fees-item-list">
              <div class="tuitionand-fees-item bg-white">
                <div class="tuitionand-fees-item-name">
                  Online application fee
                </div>
                <div class="tuitionand-fees-number">US $100</div>
              </div>

              <div class="tuitionand-fees-item bg-white">
                <div class="tuitionand-fees-item-name">
                  One-time enrollment fee
                </div>

                <div class="tuitionand-fees-number">US $500</div>
              </div>
            </div>
          </div>

          <div>
            <div class="tuitionand-fees-item bg-gary">
              <div class="tuitionand-fees-item-title">
                Housing reservation fees for one academic year*
              </div>
            </div>

            <div class="tuitionand-fees-item-list">
              <div class="tuitionand-fees-item bg-white">
                <div class="tuitionand-fees-item-name">
                  New student housing reservation fee
                </div>

                <div class="tuitionand-fees-number">US $750</div>
              </div>

              <div class="tuitionand-fees-item bg-white">
                <div class="tuitionand-fees-item-name">
                  Continuing student housing reservation fee
                </div>
                <div class="tuitionand-fees-number">US $750</div>
              </div>
            </div>
          </div>

          <div>
            <div class="tuitionand-fees-item bg-gary">
              <div class="tuitionand-fees-item-title">
                Residence hall balance for one academic year*
              </div>
              <div class="tuitionand-fees-item-desc italic">
                Prices include $750 student housing reservation fee.
              </div>
            </div>

            <div class="tuitionand-fees-item-list">
              <div class="tuitionand-fees-item bg-white">
                <div class="tuitionand-fees-item-name weight">Savannah</div>
              </div>

              <div class="tuitionand-fees-item bg-white">
                <div class="tuitionand-fees-item-name">
                  Chatham, two bedroom suite, two residents per bedroom (4
                  residents total)
                </div>
                <div class="tuitionand-fees-number">US $10,905</div>
              </div>

              <div class="tuitionand-fees-item bg-white">
                <div class="tuitionand-fees-item-name">
                  The Hive, two bedroom suite, two residents per bedroom (4
                  residents total)
                </div>
                <div class="tuitionand-fees-number">US $10,905</div>
              </div>

              <div class="tuitionand-fees-item bg-white">
                <div class="tuitionand-fees-item-name">
                  Oglethorpe House, double occupancy bedroom (2 residents total)
                </div>
                <div class="tuitionand-fees-number">US $10,374</div>
              </div>

              <div class="tuitionand-fees-item bg-white">
                <div class="tuitionand-fees-item-name">
                  Montgomery House, two bedroom suite, two residents per bedroom
                  (4 residents total)
                </div>
                <div class="tuitionand-fees-number">US $10,905</div>
              </div>

              <div class="tuitionand-fees-item bg-white">
                <div class="tuitionand-fees-item-name">
                  Turner House, double occupancy bedroom (2 residents total)
                </div>
                <div class="tuitionand-fees-number">US $10,374</div>
              </div>

              <div class="tuitionand-fees-item bg-white">
                <div class="tuitionand-fees-item-name">
                  Turner House, triple occupancy bedroom (3 residents total)
                </div>
                <div class="tuitionand-fees-number">US $9,603</div>
              </div>

              <div class="tuitionand-fees-item bg-white">
                <div class="tuitionand-fees-item-name">
                  Boundary Village, private bedroom in four bedroom apartment (4
                  residents total)
                </div>
                <div class="tuitionand-fees-number">US $11,925</div>
              </div>

              <div class="tuitionand-fees-item bg-white">
                <div class="tuitionand-fees-item-name">
                  Barnard Village, private bedroom in four bedroom apartment (4
                  residents total)
                </div>
                <div class="tuitionand-fees-number">US $11,925</div>
              </div>

              <div class="tuitionand-fees-item bg-white">
                <div class="tuitionand-fees-item-name">
                  Sand at Victory Village, private bedroom in four bedroom
                  apartment (4 residents total)
                </div>
                <div class="tuitionand-fees-number">US $12,555</div>
              </div>

              <div class="tuitionand-fees-item bg-white">
                <div class="tuitionand-fees-item-name">
                  Sand at Victory Village, private bedroom in two bedroom
                  apartment (2 residents total)
                </div>
                <div class="tuitionand-fees-number">US $14,676</div>
              </div>

              <div class="tuitionand-fees-item bg-white">
                <div class="tuitionand-fees-item-name">
                  Sand at Victory Village, private apartment (1 resident total)
                </div>
                <div class="tuitionand-fees-number">US $16,878</div>
              </div>

              <div class="tuitionand-fees-item bg-white">
                <div class="tuitionand-fees-item-name">
                  Sail and Surf at Victory Village, two bedroom suite, two
                  residents per bedroom (4 residents total)
                </div>
                <div class="tuitionand-fees-number">US $10,905</div>
              </div>

              <div class="tuitionand-fees-item bg-white">
                <div class="tuitionand-fees-item-name">
                  Sail and Surf at Victory Village, one bedroom suite, two
                  residents per bedroom (2 residents total)
                </div>
                <div class="tuitionand-fees-number">US $11,925</div>
              </div>

              <div class="tuitionand-fees-item bg-white">
                <div class="tuitionand-fees-item-name">
                  Sail and Surf at Victory Village, private room (1 resident
                  total)
                </div>
                <div class="tuitionand-fees-number">US $12,555</div>
              </div>

              <div class="tuitionand-fees-item bg-white">
                <div class="tuitionand-fees-item-name">
                  River, two bedroom suite, two residents per bedroom (4
                  residents total)
                </div>
                <div class="tuitionand-fees-number">US $10,905</div>
              </div>

              <div class="tuitionand-fees-item bg-white">
                <div class="tuitionand-fees-item-name">
                  River, private room (1 resident total)
                </div>
                <div class="tuitionand-fees-number">US $12,555</div>
              </div>

              <div class="tuitionand-fees-item bg-white">
                <div class="tuitionand-fees-item-name weight">Atlanta</div>
              </div>

              <div class="tuitionand-fees-item bg-white">
                <div class="tuitionand-fees-item-name">
                  SCAD House, apartment, private bedroom in two bedroom
                  apartment (4 residents total)
                </div>
                <div class="tuitionand-fees-number">US $11,925</div>
              </div>

              <div class="tuitionand-fees-item bg-white">
                <div class="tuitionand-fees-item-name">
                  FORTY, apartment, two bedroom apartment, two residents per
                  bedroom (4 residents total)
                </div>
                <div class="tuitionand-fees-number">US $12,555</div>
              </div>

              <div class="tuitionand-fees-item bg-white">
                <div class="tuitionand-fees-item-name">
                  FORTY, two bedroom suite, two residents per bedroom (4
                  residents total)
                </div>
                <div class="tuitionand-fees-number">US $10,905</div>
              </div>

              <div class="tuitionand-fees-item bg-white">
                <div class="tuitionand-fees-item-name">
                  FORTY, four bedroom pod, two residents per bedroom (8
                  residents total)
                </div>
                <div class="tuitionand-fees-number">US $10,905</div>
              </div>

              <div class="tuitionand-fees-item bg-white">
                <div class="tuitionand-fees-item-name">
                  FORTY FOUR, two bedroom apartment, two residents per bedroom
                  (4 residents total)
                </div>
                <div class="tuitionand-fees-number">US $12,555</div>
              </div>

              <div class="tuitionand-fees-item bg-white">
                <div class="tuitionand-fees-item-name">
                  FORTY FOUR, two bedroom suite, two residents per bedroom (4
                  residents total)
                </div>
                <div class="tuitionand-fees-number">US $10,905</div>
              </div>

              <div class="tuitionand-fees-item bg-white">
                <div class="tuitionand-fees-item-name">
                  FORTY FOUR, double occupancy bedroom (2 residents total)
                </div>
                <div class="tuitionand-fees-number">US $10,374</div>
              </div>

              <div class="tuitionand-fees-item bg-white">
                <div class="tuitionand-fees-item-name">
                  FORTY FOUR, private room (1 resident total)
                </div>
                <div class="tuitionand-fees-number">US $12,555</div>
              </div>

              <div class="tuitionand-fees-item bg-white">
                <div class="tuitionand-fees-item-name">
                  FORTY FIVE, two bedroom suite, two residents per bedroom (4
                  residents total)
                </div>
                <div class="tuitionand-fees-number">US $10,905</div>
              </div>

              <div class="tuitionand-fees-item bg-white">
                <div class="tuitionand-fees-item-name">
                  FORTY FIVE, double occupancy bedroom (2 residents total)
                </div>
                <div class="tuitionand-fees-number">US $10,374</div>
              </div>
            </div>
          </div>

          <div class="tuitionand-fees-item-tips bg-white">
            <div class="">
              *Includes accommodations, furnishings, utilities, and local phone
              and internet services. Some variations exist in residence hall
              accommodations. Specifics are provided to students who request
              college housing. No guarantees may be made regarding specific
              housing requests/assignments. Housing is limited and assignments
              are confirmed beginning July 1 each year as quarterly payments and
              signed housing contracts are received.
            </div>
          </div>

          <div>
            <div class="tuitionand-fees-item bg-gary">
              <div class="tuitionand-fees-item-title">Summer housing</div>
              <div class="tuitionand-fees-item-desc italic">
                Includes nonrefundable $250 summer housing reservation fee
              </div>
            </div>

            <div class="tuitionand-fees-item-list">
              <div class="tuitionand-fees-item bg-white">
                <div class="tuitionand-fees-item-name">
                  Traditional style, double occupancy
                </div>
                <div class="tuitionand-fees-number">US $3,721</div>
              </div>

              <div class="tuitionand-fees-item bg-white">
                <div class="tuitionand-fees-item-name">
                  Apartment style, double occupancy
                </div>
                <div class="tuitionand-fees-number">US $4,146</div>
              </div>

              <div class="tuitionand-fees-item bg-white">
                <div class="tuitionand-fees-item-name">
                  Apartment style, private bedroom
                </div>
                <div class="tuitionand-fees-number">US $4,060</div>
              </div>
            </div>
          </div>

          <div>
            <div class="tuitionand-fees-item bg-gary">
              <div class="tuitionand-fees-item-title">
                Meal plan (quarterly)
              </div>
            </div>

            <div class="tuitionand-fees-item-list">
              <div class="tuitionand-fees-item bg-white">
                <div class="tuitionand-fees-item-name">
                  18 meals/week, $75 Dining dollars
                </div>
                <div class="tuitionand-fees-number">US $1,998</div>
              </div>

              <div class="tuitionand-fees-item bg-white">
                <div class="tuitionand-fees-item-name">
                  14 meals/week, $150 Dining dollars
                </div>
                <div class="tuitionand-fees-number">US $2,019</div>
              </div>

              <div class="tuitionand-fees-item bg-white">
                <div class="tuitionand-fees-item-name">
                  135 meals/quarter, $125 Dining dollars
                </div>
                <div class="tuitionand-fees-number">US $1,962</div>
              </div>

              <div class="tuitionand-fees-item bg-white">
                <div class="tuitionand-fees-item-name">
                  85 meals/quarter, $75 Dining dollars
                </div>
                <div class="tuitionand-fees-number">US $1,020</div>
              </div>

              <div class="tuitionand-fees-item bg-white">
                <div class="tuitionand-fees-item-name">
                  50 commuter, $100 Dining dollars
                </div>
                <div class="tuitionand-fees-number">US $681</div>
              </div>

              <div class="tuitionand-fees-item bg-white">
                <div class="tuitionand-fees-item-name">
                  25 commuter, $125 Dining dollars
                </div>
                <div class="tuitionand-fees-number">US $464</div>
              </div>
            </div>
          </div>

          <div>
            <div class="tuitionand-fees-item bg-gary">
              <div class="tuitionand-fees-item-title">
                English as a Second Language
              </div>
              <div class="tuitionand-fees-item-desc italic">
                10-week regular session
              </div>
            </div>

            <div class="tuitionand-fees-item-list">
              <div class="tuitionand-fees-item bg-white">
                <div class="tuitionand-fees-item-name">
                  Levels I, II, III and IV
                </div>
                <div class="tuitionand-fees-item-name-desc">20 ESL hours</div>

                <div class="tuitionand-fees-number">US $3,332</div>
              </div>

              <div class="tuitionand-fees-item bg-white">
                <div class="tuitionand-fees-item-name">Level V</div>
                <div class="tuitionand-fees-item-name-desc">15 ESL hours*</div>

                <div class="tuitionand-fees-number">US $2,499</div>
              </div>

              <div class="tuitionand-fees-item bg-white">
                <div class="tuitionand-fees-item-name">
                  Level VI undergraduate
                </div>
                <div class="tuitionand-fees-item-name-desc">10 ESL hours**</div>

                <div class="tuitionand-fees-number">US $1,666</div>
              </div>
            </div>

            <div class="tuitionand-fees-item-tips bg-white">
              <div class="">
                *ESL course fees only (students are also required to take an
                academic studio course and must pay tuition for academic course)
                <br />
                **ESL course fees only (students are also required to take
                English 123 plus an academic studio course and must pay tuition
                for academic courses)
              </div>
            </div>
          </div>

          <div>
            <div class="tuitionand-fees-item bg-gary">
              <div class="tuitionand-fees-item-title">
                English as a Second Language LEAP course
              </div>
              <div class="tuitionand-fees-item-desc italic">
                Five-week online session
              </div>
            </div>

            <div class="tuitionand-fees-item-list">
              <div class="tuitionand-fees-item bg-white">
                <div class="tuitionand-fees-item-name">
                  Learning English for Academic Purposes
                </div>
                <div class="tuitionand-fees-item-name-desc">Five ESL hours</div>

                <div class="tuitionand-fees-number">US $833</div>
              </div>
            </div>
          </div>

          <div>
            <div class="tuitionand-fees-item bg-gary">
              <div class="tuitionand-fees-item-title">
                Lacoste program expenses
              </div>
            </div>

            <div class="tuitionand-fees-item-list">
              <div class="tuitionand-fees-item bg-white">
                <div class="tuitionand-fees-item-name">
                  Room, meals, site visit fees
                </div>
                <div class="tuitionand-fees-item-name-desc">
                  Participants responsible for all personal expenses, including
                  meals when outside of Lacoste
                </div>

                <div class="tuitionand-fees-number">US $7,600</div>
              </div>

              <div class="tuitionand-fees-item bg-white">
                <div class="tuitionand-fees-item-name">Insurance fee</div>
                <div class="tuitionand-fees-item-name-desc">
                  All students commencing a study abroad program will
                  automatically be enrolled in the SCAD student insurance plan.
                  Cost for this coverage will be added to your bill each
                  quarter. Note that this policy is not meant to be your primary
                  insurance; it is study abroad insurance only. For more
                  information on the policy, email studyabroad@scad.edu.
                </div>

                <div class="tuitionand-fees-number">US $75</div>
              </div>

              <div class="tuitionand-fees-item bg-white">
                <div class="tuitionand-fees-item-name"><b>Lacoste fees</b></div>
                <div class="tuitionand-fees-item-name-desc2">
                  Participants are responsible for tuition, program fees, travel
                  expenses, and all personal expenses, including meals, during
                  weekend excursions. Because arrangements for air travel must
                  be made on the student's behalf well in advance of the
                  program, it is crucial that participants adhere to the
                  deadlines in the acceptance email. All deposits are
                  nonrefundable and nontransferable; cancellations and
                  withdrawals must be made in writing by emailing
                  <a href="mailto:studyabroad@scad.edu">studyabroad@scad.edu</a
                  >. Program aspects are subject to change without notice.
                  Travel scholarships in varying amounts are awarded to
                  qualified students who have been accepted to participate in
                  SCAD study abroad programs. For more information, email SCAD
                  Study Abroad at
                  <a href="mailto:studyabroad@scad.edu">studyabroad@scad.edu</a>
                  or call 912.525.5806.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <goTop></goTop>
    <custFooter></custFooter>
  </div>
</template>

<script>
import menuBar from "../../../../components/menuBar/index.vue";
import custFooter from "@/components/footer/Footer";
export default {
  metaInfo: {
    title: "SCAD", // set a title
  },
  name: "TuitionandFees",
  components: {
    menuBar,
    custFooter,
  },
  data() {
    return {
      mealPlans: true,
    };
  },
  created() {
    if (this.$route.query.t === "preview") {
      this.mealPlans = true;
    }
  },
};
</script>

<style scoped lang="less">
@import "../TuitionandFees.less";
</style>
