const params = [
  { programs: "Advertising Copywriting", value: "ADCO" },
  { programs: "Advertising Art Direction", value: "ARTD" },
  { programs: "Creative Technology", value: "ATEC" },
  // { programs: "Business of Beauty & Fragrance", value: "BEAU" },
  { programs: "Commercial Photography", value: "COMP" },
  { programs: "Documentary Photography", value: "DOCP" },
  { programs: "Fashion Marketing & Management", value: "FASM" },
  { programs: "Fine Art Photography", value: "FINE" },
  { programs: "Graphic Design", value: "GRDS" },
  { programs: "Sequential Art", value: "SEQA" },
  { programs: "Social Strategy and Management", value: "SOCL" },
  { programs: "Undecided", value: "UNDE" },
];

export default params;
