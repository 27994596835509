<template>
  <div>
    <!--<el-row :gutter="20">-->
    <!--  <el-col :span="8" v-for="(article, index) in articleList" :key="index">-->
    <!--    <articeItem :article="article"></articeItem>-->
    <!--  </el-col>-->
    <!--</el-row>-->

    <div class="article-swiper-container" :class="'swiper-container-' + id">
      <div class="swiper-wrapper">
        <div
          class="swiper-slide"
          v-for="(article, index) in articleList"
          :key="index"
        >
          <articeItem :article="article"></articeItem>
          <!--<div class="slide-inner">-->
          <!--  <img :src="banner" alt="" />-->
          <!--</div>-->
        </div>
      </div>

      <div class="swiper-pagination"></div>
    </div>
  </div>
</template>

<script>
import articeItem from "./ArticleItem";

import Swiper from "swiper/swiper-bundle.min.js";
import "swiper/swiper-bundle.min.css";
import { v4 } from "uuid";

export default {
  name: "ArticleList",
  components: {
    // eslint-disable-next-line vue/no-unused-components
    articeItem,
  },
  props: ["articleList"],
  data: function () {
    return {
      id: "",
      mySwiper: null,
    };
  },
  created() {
    this.id = v4();
  },
  mounted() {
    this.mySwiper = this.initSwiper(".swiper-container-" + this.id);
  },
  methods: {
    update() {},
    initSwiper: function (clazz) {
      var swiperOptions = {
        speed: 500,
        grabCursor: true,
        autoHeight: true,
        mousewheelControl: true,
        keyboardControl: true,
        slidesPerView: 1,
        spaceBetween: 20,
        observer: true,
        observeParents: true,
        watchSlidesProgress: true,
        pagination: {
          el: ".swiper-pagination",
        },
        breakpoints: {
          601: {
            //当屏幕宽度大于等于768
            slidesPerView: 2,
            spaceBetween: 20,
          },
          801: {
            //当屏幕宽度大于等于768
            slidesPerView: 3,
            spaceBetween: 20,
          },
        },
      };

      return new Swiper(clazz, swiperOptions);
    },
  },
};
</script>

<style scoped lang="less">
.article-swiper-container {
  padding-bottom: 10px;
  padding-right: 1px;
  box-sizing: border-box;
  position: relative;

  @media (max-width: 800px) {
    padding-bottom: 40px;
  }

  .swiper-slide {
    //border: 1px solid rgb(220, 220, 220);
  }
}
</style>

<style lang="less">
.article-swiper-container .swiper-pagination-bullet-active {
  background: #000 !important;
}
</style>
