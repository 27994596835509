<template>
  <div>
    <EnglishLanguageSupportCN
      v-if="$i18n.locale === 'zh'"
    ></EnglishLanguageSupportCN>
    <EnglishLanguageSupportEN
      v-if="$i18n.locale === 'en'"
    ></EnglishLanguageSupportEN>
  </div>
</template>

<script>
import EnglishLanguageSupportCN from "./EnglishLanguageSupport-CN";
import EnglishLanguageSupportEN from "./EnglishLanguageSupport-EN";
export default {
  metaInfo: {
    title: "SCAD", // set a title
  },
  name: "EnglishLanguageSupport",
  components: {
    EnglishLanguageSupportCN,
    EnglishLanguageSupportEN,
  },
  data: function () {
    return {};
  },
};
</script>
