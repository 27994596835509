<template>
  <div class="articleItem">
    <a :href="article.url" style="text-decoration: none; color: #333">
      <div class="coverImgBox">
        <div
          class="coverImg"
          :style="'background-image: url(' + article.img_url + ');'"
        ></div>
      </div>
      <div class="artcleInfo">
        <div class="title">{{ article.name }}</div>
        <div class="description">
          {{ article.desc }}
        </div>
      </div>
    </a>
  </div>
</template>

<script>
export default {
  name: "ArticleItem",
  data: function () {
    return {
      imgSrc: require("@/assets/images/scad_06.jpg"),
    };
  },
  props: ["article"],
  methods: {
    // convertImagePath(url) {
    //   // return require(url);
    // },
  },
};
</script>

<style scoped lang="less">
.articleItem {
  border: 1px solid #dcdcdc;
  @media (max-width: 600px) {
    border: 0;
  }

  .coverImgBox {
    width: 100%;
    height: 20vw;

    overflow: hidden;

    @media (min-width: 1600px) {
      height: 300px;
    }

    @media (max-width: 800px) {
      height: 30vw;
    }

    @media (max-width: 600px) {
      height: 60vw;
    }
    .coverImg {
      width: 100%;
      height: 100%;
      background-size: cover;
      background-position: center;
      transition: all 0.5s;

      &:hover {
        transform: scale(1.1);
      }
    }
  }

  .artcleInfo {
    padding: 2.5vw 0vw 2.5vw 2.1vw;

    @media (min-width: 1600px) {
      padding: 45px 0px 45px 35px;
    }
    @media (max-width: 600px) {
      text-align: center;
    }

    .title {
      font-weight: 800;
      font-size: 1.5vw;

      @media (min-width: 1600px) {
        font-size: 25px;
      }

      @media (max-width: 800px) {
        font-size: 2.2vw;
      }

      @media (max-width: 600px) {
        font-size: 16px;
      }
    }
    .description {
      font-weight: 800;
      color: #aaaaaa;
      margin-top: 10px;
      font-size: 1vw;

      @media (min-width: 1600px) {
        font-size: 17px;
      }

      @media (max-width: 800px) {
        font-size: 1.4vw;
      }

      @media (max-width: 600px) {
        font-size: 10px;
      }
    }
  }
}
</style>
