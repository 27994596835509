<template>
  <div>
    <div v-if="$i18n.locale === 'en'">
      <div class="grid-content content">
        <div class="title1">
          SCAD Savannah — the historic coastal south, brimming with inspiration
        </div>
        <div class="video">
          <!--<video-->
          <!--  src="https://scad-minisite.oss-cn-beijing.aliyuncs.com/video/Savannah/2021-22%20SCADSavannah.mp4"-->
          <!--  controls-->
          <!--  poster="https://scad-minisite.oss-cn-beijing.aliyuncs.com/video/Savannah/2021-22%20SCADSavannah.mp4?x-oss-process=video/snapshot,t_2000,m_fast"-->
          <!--&gt;</video>-->

          <video
            src="https://scad-minisite.oss-cn-beijing.aliyuncs.com/video/20231110/2023_24_SCAD_Savannah_Mandarin_Subs_V7.mp4"
            controls
            poster="https://scad-minisite.oss-cn-beijing.aliyuncs.com/video/20231110/2023_24_SCAD_Savannah_Mandarin_Subs_V7.mp4?x-oss-process=video/snapshot,t_2000,m_fast"
          ></video>
        </div>

        <div class="tips text">
          Nestled within a charming coastal locale, SCAD Savannah and its 100
          future-forward degree programs offer students a launchpad for
          creativity and innovation. SCAD students live and learn in the most
          distinctive built environment in higher education, among more than 70
          revitalized historic structures nestled throughout the city, including
          modern apartment- and suite-style residence halls and academic
          buildings with advanced technology. Creative adventure abounds for the
          next generation of design leaders in these storied spaces, which house
          classrooms, studios, and labs, as well as unparalleled resources like
          SCADpro, the university’s innovation and design studio, where top
          companies like Google, BMW, HP, Deloitte, and Target tap SCAD students
          to dream up their next big ideas.
        </div>
        <div class="line" style="margin-top: 1%"></div>
        <div class="padding-both">
          <img
            src="@/assets/images/locations/savanah_content_img_01.jpg?v=1"
            alt=""
          />
          <div class="title2">Explore our programs</div>
          <div class="text text-center">
            SCAD offers more than 100 degree programs. With the flexibility of
            our programs, you can begin your journey at one location and
            continue it at another. It's an incredible opportunity to explore
            different cultures and absorb a variety of artistic perspectives
            without interrupting your progress in completing your degree.
          </div>
        </div>
        <div class="line"></div>

        <el-row :gutter="30" class="padding-left">
          <el-col :xs="24" :md="12">
            <img
              src="@/assets/images/locations/savanah_content_img_02.jpg?v=1"
            />
          </el-col>
          <el-col :xs="24" :md="12">
            <div class="title3">Our buildings and facilities</div>
            <div class="text">
              SCAD students live and learn in the most distinctive built
              environment in higher education, among more than 70 revitalized
              historic structures nestled throughout the city, including modern
              apartment- and suite-style residence halls and academic buildings
              with advanced technology. Creative adventure abounds for the next
              generation of design leaders in these storied spaces, which house
              classrooms, studios, and labs, as well as unparalleled resources
              like SCADpro, the university’s innovation and design studio, where
              top companies like Google, BMW, HP, Deloitte, and Target tap SCAD
              students to dream up their next big ideas.
              <br /><br />
              SCAD students enjoy access to the university’s cutting-edge LED
              volume stage and 11-acre Hollywood-style backlot expansion — the
              largest and most comprehensive university film complex in the U.S.
              — which includes tree-lined streetscapes, a town square, a
              hospital façade, and a fabrication space for props, sets,
              costumes, and more.
            </div>
          </el-col>
        </el-row>
        <el-row :gutter="30" class="padding-left margin-top">
          <el-col :xs="24" :md="12">
            <img
              src="@/assets/images/locations/savanah_content_img_03.jpg?v=1"
            />
          </el-col>
          <el-col :xs="24" :md="12">
            <div class="title3">
              A home away from home for international students
            </div>
            <div class="text">
              Students from more than 100 countries find their home away from
              home at SCAD, where international student life is celebrated
              through a calendar stacked with cross-cultural events,
              exhibitions, and programming, as well as dedicated resources
              through the SCAD International Student Services Office (ISSO).
            </div>
          </el-col>
        </el-row>

        <div class="line"></div>

        <el-row :gutter="30" class="padding-left margin-top">
          <el-col :xs="24" :md="12">
            <div class="title3">Connect with us</div>
            <div class="text">
              Request more information today, and we'll send you all the details
              you need to begin your exciting journey at SCAD.
              <br /><br />
              <a
                target="_blank"
                href="https://app.jingsocial.com/microFrontend/forms/jsf-forms/details/qFjikR3MCzTGfMEbp4bRqh?lead_source_type=survey&lead_source_value=qFjikR3MCzTGfMEbp4bRqh&promotion_code=survey_qrcode&promotion_value=cdw9Dvn5cUBUn6GxM44mja&promotion_channel_id=tiVt8LccayrF2kbKxd9jja&global_promotion_channel_id=3ZH8PjSow9oxzhbzfrcXAX&global_promotion_sub_channel_id=n9RQk7YyKX6he32EtHzFce&appId=wxe14b2fd0105ac9dc"
                >Request Information
              </a>
            </div>
          </el-col>

          <el-col :xs="24" :md="12">
            <img
              src="@/assets/images/locations/savanah_content_img_04.jpg?v=1"
            />
          </el-col>
        </el-row>
      </div>
    </div>
    <div v-if="$i18n.locale === 'zh'">
      <div class="grid-content content">
        <div class="title1">
          SCAD萨凡纳校区——历史悠久，充满灵感的南部沿海地区
        </div>
        <div class="video">
          <!--<video-->
          <!--  src="https://scad-minisite.oss-cn-beijing.aliyuncs.com/video/Savannah/2021-22%20SCADSavannah.mp4"-->
          <!--  controls-->
          <!--  poster="https://scad-minisite.oss-cn-beijing.aliyuncs.com/video/Savannah/2021-22%20SCADSavannah.mp4?x-oss-process=video/snapshot,t_2000,m_fast"-->
          <!--&gt;</video>-->
          <video
            src="https://scad-minisite.oss-cn-beijing.aliyuncs.com/video/20231110/2023_24_SCAD_Savannah_Mandarin_Subs_V7.mp4"
            controls
            poster="https://scad-minisite.oss-cn-beijing.aliyuncs.com/video/20231110/2023_24_SCAD_Savannah_Mandarin_Subs_V7.mp4?x-oss-process=video/snapshot,t_2000,m_fast"
          ></video>
        </div>
        <div class="tips text">
          从广阔的海滩到蓬勃发展的艺术社区，再到美国最大的国家历史地标区，萨凡纳为你的创意事业提供了理想的起点。萨凡纳拥有世界上最受推崇的城市设计之一，以温暖的气候、南方的热情好客和文化丰富的城市文化提供学生优秀的学习环境，助其成长。
        </div>
        <div class="line" style="margin-top: 1%"></div>
        <div class="padding-both">
          <img
            src="@/assets/images/locations/savanah_content_img_01.jpg?v=1"
            alt=""
          />
          <div class="title2">探索我们的项目</div>
          <div class="text text-center">
            SCAD 提供 100
            多个学位课程。借助我们项目的灵活性，学生可以在一个校区开始旅程，并在另一个校区继续前行。这并不会阻滞学生完成学位的进程，而是为学生提供了去探索不同文化和吸收各种艺术观点的绝佳机会。
          </div>
        </div>
        <div class="line"></div>

        <el-row :gutter="30" class="padding-left">
          <el-col :xs="24" :md="12">
            <img
              src="@/assets/images/locations/savanah_content_img_02.jpg?v=1"
            />
          </el-col>
          <el-col :xs="24" :md="12">
            <div class="title3">我们的建筑和设施</div>
            <div class="text">
              SCAD萨凡纳校区是该大学最大的校区，拥有近 70
              个历史悠久且经过精心翻修的建筑，错综交织在整个城市中。每栋建筑都拥有丰富资源和尖端技术，你可以享受行业内的的专业人士也在使用的资源和工具。
            </div>
          </el-col>
        </el-row>
        <el-row :gutter="30" class="padding-left margin-top">
          <el-col :xs="24" :md="12">
            <img
              src="@/assets/images/locations/savanah_content_img_03.jpg?v=1"
            />
          </el-col>
          <el-col :xs="24" :md="12">
            <div class="title3">国际学生的海外之家</div>
            <div class="text">
              SCAD 欢迎来自全球 100
              多个国家/地区的学生，他们利用独特的资源，享受一生难忘的旅行。通过专门的国际学生服务办公室，SCAD
              提供全职学业顾问，提供过渡到大学环境时时所需的一切支持。
            </div>
          </el-col>
        </el-row>

        <div class="line"></div>

        <el-row :gutter="30" class="padding-left margin-top">
          <el-col :xs="24" :md="12">
            <div class="title3">联系我们</div>
            <div class="text">
              立即索取更多信息，我们将向你发送开始SCAD激动人心的旅程所需的详细信息。
              <br /><br />
              <a
                target="_blank"
                href="https://app.jingsocial.com/microFrontend/forms/jsf-forms/details/fjbXnGDs4SQNrs7uL6dph7?lead_source_type=survey&lead_source_value=fjbXnGDs4SQNrs7uL6dph7&promotion_code=survey_qrcode&promotion_value=ifzDy6ceWJuk3qCYFxrEzD&promotion_channel_id=EuQLxTKCfzLJgdmf8Z2DzD&global_promotion_channel_id=3ZH8PjSow9oxzhbzfrcXAX&global_promotion_sub_channel_id=n9RQk7YyKX6he32EtHzFce&appId=wxe14b2fd0105ac9dc"
                style="color: #000000"
                >了解更多
              </a>
            </div>
          </el-col>

          <el-col :xs="24" :md="12">
            <img
              src="@/assets/images/locations/savanah_content_img_04.jpg?v=1"
            />
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped lang="less">
@import "../Atlanta.less";
</style>
