<template>
  <div class="gotop-container" :class="showGoTop ? 'active' : ''">
    <span class="icon iconfont icon-fanhui" @click="onReturn"></span>
    <span class="icon iconfont icon-fanhuidingbu" @click="onGoTop"></span>
  </div>
</template>

<script>
export default {
  name: "goTop",
  data() {
    return {
      showGoTop: false,
    };
  },
  created() {},
  mounted() {
    var that = this;
    var scrollTop2 =
      document.documentElement.scrollTop || document.body.scrollTop;
    that.showGoTop = scrollTop2 > 500;
    window.onscroll = function () {
      //为了保证兼容性，这里取两个值，哪个有值取哪一个
      //scrollTop就是触发滚轮事件时滚轮的高度
      var scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      // console.log("滚动距离" + scrollTop);
      that.showGoTop = scrollTop > 500;
    };
  },
  destroyed() {
    window.onscroll = null;
  },
  methods: {
    onReturn() {
      window.history.back();
    },
    onGoTop() {
      var timer = setInterval(function () {
        if (document.documentElement.scrollTop !== 0) {
          document.documentElement.scrollTop -= 100;
        } else {
          clearInterval(timer);
        }
      }, 10);
    },
  },
};
</script>

<style scoped lang="less">
.gotop-container {
  position: fixed;
  bottom: 50px;
  right: 50px;
  z-index: 10;
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s;

  &.active {
    opacity: 1;
    pointer-events: inherit;
  }

  .iconfont {
    font-size: 40px;
    color: #8a8a8a;
    cursor: pointer;
    display: block;
  }
}
</style>
