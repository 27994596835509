import Vue from "vue";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";

import "../theme/index.css";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import i18n from "@/i18n/index";
import tool from "@/utils/tool.js";
import MetaInfo from "vue-meta-info";

import locale from "element-ui/lib/locale/lang/en";

// import VueWechatTitle from "vue-wechat-title";
// Vue.use(VueWechatTitle);

// 重置浏览器默认样式
import "normalize.css";
Vue.config.productionTip = false;

import goTop from "@/components/goTop/goTop";
Vue.use(goTop);
Vue.component("goTop", goTop);
Vue.use(MetaInfo);
Vue.use(ElementUI, { locale });
Vue.use(tool);

// 引用API文件
import api from "./api/index";

Vue.prototype.$api = api;

new Vue({
  router,
  i18n,
  render: (h) => h(App),
  mounted() {
    document.dispatchEvent(new Event("render-event"));
  },
}).$mount("#app");
