const params = [
  { programs: "Animation", value: "ANIM" },
  { programs: "Fashion", value: "FASH" },
  { programs: "Graphic Dsgn/Visual Experience", value: "GDVX" },
  { programs: "Luxury and Brand Management", value: "LXMT" },
  { programs: "Motion Media Design", value: "MOME" },
  { programs: "Photography", value: "PHOT" },
  { programs: "Painting", value: "PNTG" },
  { programs: "Writing", value: "WRIT" },
];

export default params;
