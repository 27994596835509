<template>
  <div>
    <InternationalCAPPCN v-if="$i18n.locale === 'zh'"></InternationalCAPPCN>
    <InternationalCAPPEN v-if="$i18n.locale === 'en'"></InternationalCAPPEN>
  </div>
</template>

<script>
import InternationalCAPPCN from "./InternationalCAPP-CN";
import InternationalCAPPEN from "./InternationalCAPP-EN";
export default {
  metaInfo: {
    title: "SCAD", // set a title
  },
  name: "InternationalCAPP",
  components: {
    InternationalCAPPCN,
    InternationalCAPPEN,
  },
  data: function () {
    return {};
  },
};
</script>

<style lang="css" src="./els-css/typography.gotham.css" scoped />
<style lang="less" scoped>
@import url(els-css/style.css);
@import url(els-css/global.small.768.css);
@import url(els-css/swarm_fullwidth.css);
@import url(els-css/svg.css);
@import url(els-css/display-modes.css);
@import url(els-css/life.css);

.faq-line {
  width: 70px;
  border-bottom: medium;
  border-bottom-color: #000000;
  border-bottom-style: solid;
}
.faq-answer {
  border-top: thin;
  border-top-color: #eaeaea;
  border-top-style: solid;
}

@media (min-width: 992px) {
  .reduce {
    font-size: 21px !important;
    line-height: 23px !important;
  }
}
/* Large screens */
@media (min-width: 1200px) {
  .reduce {
    font-size: 28px !important;
    line-height: 30px !important;
  }
}

@media (max-width: 800px) {
  .main-content {
    padding: 0;

    .row {
      margin: 0;
    }
  }
}
</style>
