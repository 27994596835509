<template>
  <div class="programs">
    <menuBar></menuBar>
    <div class="programs-header-container">
      <img
        class="banner"
        src="https://abcam.oss-cn-shanghai.aliyuncs.com/40b69202201191322236564.jpg"
      />
    </div>
    <div class="body-container">
      <div>
        <h1 class="title" v-text="lang === 'en' ? obj.name_en : obj.name">
          B.A. in visual communication
        </h1>
        <div class="area-container">
          {{ $t("programs.available_at") }}

          <span :class="item" v-for="(item, index) in areaList" :key="index"
            >- {{ $t("programs." + item) }}</span
          >
        </div>
        <p class="introduction" v-text="lang === 'en' ? obj.desc_en : obj.desc">
          As a student at SCAD, you will gain expertise in every facet of
          advertising: copywriting, art direction, digital production, and
          integrated brand marketing. As a result of this dynamic and creative
          approach, our students regularly take top honors in industry
          competitions, and our alumni continue to tell the stories of the
          world’s biggest brands. The collaborative environment of an art and
          design university provides the ideal conditions for perfecting the
          highly developed craft of innovative advertising.
        </p>

        <div
          class="content"
          v-html="lang === 'en' ? obj.content_en : obj.content"
        ></div>
      </div>
    </div>
    <goTop></goTop>
    <custFooter></custFooter>
  </div>
</template>

<script>
import menuBar from "../../components/menuBar/index.vue";
import custFooter from "@/components/footer/Footer";
import i18n from "@/i18n";
// import router from "@/router";
export default {
  name: "MissonVisionValue",
  metaInfo: {
    title: i18n.t("nav.academic_programs"), // set a title
  },
  components: {
    menuBar,
    custFooter,
  },
  data: function () {
    return {
      obj: {},
      lang: this.$i18n.locale,
      id: null,
      name: null,
      areaList: [],
    };
  },
  // watch:{
  //   lang:
  // },
  mounted() {
    this.id = this.$route.params["id"];
    this.name = this.$route.params["name"];

    var that = this;
    this.$api.get(
      "AcademicsPrograms/getProgramsInfo",
      {
        id: this.id,
      },
      (r) => {
        console.log(r);
        that.obj = r.data.data;
        that.areaList = r.data.data.area.split(";");
      }
    );
  },
};
</script>
<style lang="less" scoped>
@import "Programs.less";
</style>

<style>
.content img {
  max-width: 100% !important;
}
</style>
