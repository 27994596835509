<template>
  <a href="/WhyChooseSCAD">
    <div class="whyChooseScad">
      <img
        class="bgImg"
        src="@/assets/images/SAV_2019Fall_NumberNine_Interior_CC_040_JJ_v2.jpg"
        alt=""
      />
      <img
        class="bgMoImg"
        src="@/assets/images/index/mo/1.20210923SCAD_Mobile_04.jpg"
        alt=""
      />
      <div class="content">
        <div class="title">{{ $t("home.WHY CHOOSE SCAD") }}</div>
        <div class="description">
          {{ $t("home.SCAD is continually") }}
        </div>
      </div>
    </div>
  </a>
</template>

<script>
export default {
  name: "WhyChooseScad",
};
</script>

<style scoped lang="less">
.whyChooseScad {
  width: 100%;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0);
    transition: all 0.4s;
  }

  .bgImg {
    display: block;
    width: 100%;

    @media (max-width: 600px) {
      display: none;
    }
  }

  .bgMoImg {
    display: none;
    width: 100%;
    @media (max-width: 600px) {
      display: block;
    }
  }
  .content {
    position: absolute;
    top: 18%;
    left: 5%;
    color: #ffffff;
    width: 90%;
    .title {
      font-size: 3vw;
      font-weight: bold;
      letter-spacing: 0.3vw;
      position: relative;
      top: 6vw;
      transition: all 0.4s;

      @media (max-width: 800px) {
        font-size: 30px;
      }

      @media (max-width: 600px) {
        font-size: 25px;
      }
    }
    .description {
      font-size: 1.1vw;
      line-height: 2vw;
      letter-spacing: 0.1vw;
      margin-top: 3%;
      opacity: 0;
      transition: all 0s;

      @media (max-width: 800px) {
        font-size: 12px;
        line-height: 20px;
      }

      @media (max-width: 600px) {
        font-size: 10px;
        line-height: 20px;
      }
    }
  }

  &:hover {
    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.5);
    }
    .content {
      .title {
        top: 0;
      }
      .description {
        transition: all 0.4s;
        transition-delay: 0.4s;
        opacity: 1;
      }
    }
  }
}
</style>
