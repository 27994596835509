<template>
  <div>
    <menuBar></menuBar>
    <div class="banner">
      <img src="@/assets/images/misson_vision_value/banner.jpg" alt="" />
    </div>
    <div class="cust-container" v-if="$i18n.locale === 'en'">
      <h1 class="title1 text-center">Mission, vision, and values</h1>
      <h2 class="title2">SCAD mission</h2>
      <p class="text">
        SCAD prepares talented students for creative professions through engaged
        teaching and learning in a positively oriented university environment.
      </p>
      <img
        class="w-100 margin-top"
        src="@/assets/images/misson_vision_value/misson_vision_value_03_2.jpg"
      />

      <h2 class="title2">SCAD vision</h2>
      <p class="text">
        SCAD will be globally recognized as the preeminent source of knowledge
        in the disciplines we teach.
      </p>
      <img
        class="w-100 margin-top"
        src="@/assets/images/misson_vision_value/misson_vision_value_01_2.jpg"
      />
      <h2 class="title2">SCAD values</h2>
      <p class="text">
        Be Strategic. Research and measure to guide work and document results.
        <br />
        Be Innovative. Generate new ideas and relevant solutions.<br />
        Be Positive. Approach all endeavors with enthusiasm.<br />
        Be Collaborative. Embrace and act upon our collective genius.<br />
        Be Transformative. Create life-changing experiences.
      </p>
      <img
        class="w-100 margin-top"
        style="margin-bottom: 5%"
        src="@/assets/images/misson_vision_value/misson_vision_value_02_2.jpg"
      />
    </div>

    <div class="cust-container" v-if="$i18n.locale === 'zh'">
      <h1 class="title1 text-center">使命、愿景和价值观</h1>
      <h2 class="title2">SCAD使命</h2>
      <p class="text">
        SCAD通过提供积极正面的大学环境和丰富的教学资源给有才华的学生，为其开创未来创意职业做好准备。
      </p>
      <img
        class="w-100 margin-top"
        src="@/assets/images/misson_vision_value/misson_vision_value_03_2.jpg"
      />

      <h2 class="title2">SCAD愿景</h2>
      <p class="text">SCAD是全球公认的课程教学和卓越学术知识来源。</p>
      <img
        class="w-100 margin-top"
        src="@/assets/images/misson_vision_value/misson_vision_value_01_2.jpg"
      />
      <h2 class="title2">SCAD价值观</h2>
      <p class="text">
        战略性——研究和测量标准以指导未来工作和记录效果。
        <br />
        创新性——激励新想法和提供相关解决方案。<br />
        正面性——积极并富有热忱对待努力过程和结果。<br />
        合作性——集思广益，尊重集体才华。<br />
        变革性——创造改变生活的体验。
      </p>
      <img
        class="w-100 margin-top"
        style="margin-bottom: 5%"
        src="@/assets/images/misson_vision_value/misson_vision_value_02_2.jpg"
      />
    </div>

    <goTop></goTop>
    <custFooter></custFooter>
  </div>
</template>

<script>
import menuBar from "../../components/menuBar/index.vue";
import custFooter from "@/components/footer/Footer";
export default {
  name: "MissonVisionValue",
  metaInfo: {
    title: "SCAD", // set a title
  },
  components: {
    menuBar,
    custFooter,
  },
};
</script>

<style scoped lang="less">
@import "MissonVisionValue.less";
</style>
