const params = [
  { programs: "Animation", value: "ANIM" },
  { programs: "Creative Business Leadership", value: "CRBL" },
  { programs: "Design Management", value: "DMGT" },
  { programs: "Fashion", value: "FASH" },
  { programs: "Graphic Dsgn/Visual Experience", value: "GDVX" },
  { programs: "Illustration", value: "ILLU" },
  { programs: "Interior Design", value: "INDS" },
  { programs: "Luxury and Brand Management", value: "LXMT" },
  { programs: "Motion Media Design", value: "MOME" },
  { programs: "Photography", value: "PHOT" },
  { programs: "Preservation Design", value: "PRES" },
  { programs: "Service Design", value: "SERV" },
];

export default params;
