// 配置API接口地址
// 引用axios
// const axios = require('axios');
import axios from "axios";

// const root = "https://cnodejs.org/api/v1";
// const root = "http://scad.local:6888/v2/";
const root = "https://api.scadedu.cn/v2/";
// 自定义判断元素类型JS
function toType(obj) {
  return {}.toString
    .call(obj)
    .match(/\s([a-zA-Z]+)/)[1]
    .toLowerCase();
}
// 参数过滤函数
function filterNull(o) {
  // eslint-disable-next-line guard-for-in, no-restricted-syntax
  for (const key in o) {
    if (o[key] === null) {
      // eslint-disable-next-line no-param-reassign
      delete o[key];
    }
    if (toType(o[key]) === "string") {
      // eslint-disable-next-line no-param-reassign
      o[key] = o[key].trim();
    } else if (toType(o[key]) === "object") {
      // eslint-disable-next-line no-param-reassign
      o[key] = filterNull(o[key]);
    } else if (toType(o[key]) === "array") {
      // eslint-disable-next-line no-param-reassign
      o[key] = filterNull(o[key]);
    }
  }
  return o;
}
/*
  接口处理函数
  这个函数每个项目都是不一样的，我现在调整的是适用于
  https://cnodejs.org/api/v1 的接口，如果是其他接口
  需要根据接口的参数进行调整。参考说明文档地址：
  https://cnodejs.org/topic/5378720ed6e2d16149fa16bd
  主要是，不同的接口的成功标识和失败提示是不一致的。
  另外，不同的项目的处理方法也是不一致的，这里出错就是简单的alert
*/

function apiAxios(method, url, params, success, failure) {
  if (params) {
    // eslint-disable-next-line no-param-reassign
    params = filterNull(params);
  }
  axios({
    method,
    url,
    data: method === "POST" || method === "PUT" ? params : null,
    params: method === "GET" || method === "DELETE" ? params : null,
    baseURL: root,
    withCredentials: false,
  })
    .then((res) => {
      if (res.data.status === 200) {
        if (success) {
          success(res.data);
        }
      } else if (failure) {
        failure(res.data);
      } else {
        window.alert(`error: ${JSON.stringify(res.data)}`);
      }
    })
    .catch((err) => {
      const res = err.response;
      if (err) {
        window.alert(`api error, HTTP CODE: ${res.status}`);
      }
    });
}

// 返回在vue模板中的调用接口
export default {
  get(url, params, success, failure) {
    return apiAxios("GET", url, params, success, failure);
  },
  post(url, params, success, failure) {
    return apiAxios("POST", url, params, success, failure);
  },
  put(url, params, success, failure) {
    return apiAxios("PUT", url, params, success, failure);
  },
  delete(url, params, success, failure) {
    return apiAxios("DELETE", url, params, success, failure);
  },
};
