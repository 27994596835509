const params = [
  { programs: "Non-Degree Student Only", value: 0 },
  { programs: "3-D Tech for Fashion Design", value: "3DFD" },
  { programs: "Arts Administration", value: "AADM" },
  { programs: "Arts/Entertainment Management", value: "AAEM" },
  { programs: "Accessory Design", value: "ACCE" },
  { programs: "Copywriting", value: "ACOP" },
  { programs: "Acting for the Camera", value: "ACTI" },
  { programs: "Advertising (ACA)", value: "AD" },
  { programs: "Art Director", value: "ADAD" },
  { programs: "Advertising and Branding", value: "ADBR" },
  { programs: "Advertising Copywriting", value: "ADCO" },
  { programs: "Creative Technologist", value: "ADCT" },
  { programs: "Copywriter", value: "ADCW" },
  { programs: "Art Direction", value: "ADIR" },
  { programs: "Advertising", value: "ADVE" },
  { programs: "Advertising Photography", value: "ADVP" },
  { programs: "Computer Animation (ACA)", value: "AN" },
  { programs: "2D Animation", value: "ANI2" },
  { programs: "3D Character Animation", value: "ANI3" },
  { programs: "Animation", value: "ANIM" },
  { programs: "Storytelling/Concept Develop", value: "ANSC" },
  { programs: "Technical Animation", value: "ANTA" },
  { programs: "Art (ACA)", value: "AR" },
  { programs: "Architecture", value: "ARCH" },
  { programs: "Architectural History", value: "ARLH" },
  { programs: "Provisional Architecture", value: "ARPR" },
  { programs: "Art Concentration/Education", value: "ART" },
  { programs: "Advertising Art Direction", value: "ARTD" },
  { programs: "Art History", value: "ARTH" },
  { programs: "Immersive Reality", value: "ARVR" },
  { programs: "Creative Technology", value: "ATEC" },
  { programs: "Business Collaboration", value: "BCOL" },
  { programs: "Broadcast Design", value: "BCST" },
  { programs: "Business Desgn/Arts Leadership", value: "BDAL" },
  // { programs: "Business of Beauty & Fragrance", value: "BEAU" },
  { programs: "Book Arts", value: "BOOK" },
  { programs: "Branded Entertainment", value: "BREN" },
  { programs: "Bridal/Eveningwear Design", value: "BREV" },
  { programs: "British-American Studies", value: "BRIT" },
  { programs: "Built Environment", value: "BUIL" },
  { programs: "Business Management/Entreprene", value: "BUSI" },
  { programs: "Computer Art 2D Design Concen", value: "CA2D" },
  { programs: "Computer Art 3D Design Concen", value: "CA3D" },
  { programs: "Computer Art Game Devlpmnt Con", value: "CAGD" },
  { programs: "Computer Art Interactv Dsgn Co", value: "CAID" },
  { programs: "Computer Art Motion Graphics C", value: "CAMG" },
  { programs: "Casting", value: "CAST" },
  { programs: "Ceramics", value: "CERA" },
  { programs: "Character Technical Direction", value: "CHAR" },
  { programs: "Cinema Studies", value: "CINE" },
  { programs: "Computer Art", value: "CMPA" },
  { programs: "Commercial Photography", value: "COMP" },
  { programs: "Concept Art for Games", value: "CONA" },
  { programs: "Costume Concentration", value: "COST" },
  { programs: "Creative Business Leadership", value: "CRBL" },
  { programs: "Creative Writing", value: "CREA" },
  { programs: "Costume Design", value: "CSTM" },
  { programs: "Cultural Landscape", value: "CULT" },
  { programs: "Contemporary Writing", value: "CWRI" },
  { programs: "Digital Art (ACA)", value: "DA" },
  { programs: "Design for 3-D Action Figures", value: "DACT" },
  { programs: "Dance", value: "DANC" },
  { programs: "Decorative Arts", value: "DECA" },
  { programs: "Denimwear Design", value: "DENI" },
  { programs: "Digital Photography", value: "DIGP" },
  { programs: "Digital Multimedia (ACA)", value: "DM" },
  { programs: "Design Management", value: "DMGT" },
  { programs: "Documentary Photography", value: "DOCP" },
  { programs: "Drawing (ACA)", value: "DR" },
  { programs: "Drama Concentration/Education", value: "DRAM" },
  { programs: "Drawing", value: "DRAW" },
  { programs: "Design Concntrtn,MPRA", value: "DSGN" },
  { programs: "Dramatic Writing", value: "DWRI" },
  { programs: "Teaching Art", value: "EDUA" },
  { programs: "Education", value: "EDUC" },
  { programs: "Teaching Drama", value: "EDUD" },
  { programs: "Electronic Design", value: "ELDS" },
  { programs: "English Language Program", value: "ELP" },
  { programs: "Equestrian Studies", value: "EQST" },
  { programs: "ESL (SEVIS Only)", value: "ESL" },
  { programs: "Exhibition Design", value: "EXHI" },
  { programs: "Fashion", value: "FASH" },
  { programs: "Fashion Journalism", value: "FASJ" },
  { programs: "Fashion Marketing & Management", value: "FASM" },
  { programs: "Fashion Photography", value: "FASP" },
  { programs: "Fibers", value: "FIBR" },
  { programs: "Film and Television", value: "FILM" },
  { programs: "Fine Art Photography", value: "FINE" },
  { programs: "Fragrance Marketing/Management", value: "FRAM" },
  { programs: "Furniture Design", value: "FURN" },
  { programs: "Game Development", value: "GAME" },
  { programs: "Graphic Design (ACA)", value: "GD" },
  { programs: "Graphic Dsgn/Visual Experience", value: "GDVX" },
  { programs: "Gender Studies", value: "GEND" },
  { programs: "Game UX", value: "GMUX" },
  { programs: "Graphic Design", value: "GRDS" },
  { programs: "Historic Preservation", value: "HIPR" },
  { programs: "Hybrid-Learning", value: "HYBR" },
  { programs: "Interaction Design", value: "IACT" },
  { programs: "Industrial Design", value: "IDUS" },
  { programs: "Illustration (ACA)", value: "IL" },
  { programs: "Illustration Design", value: "ILDE" },
  { programs: "Animated Illu/Publication Dsgn", value: "ILLA" },
  { programs: "Concept Dsgn/Animation & Games", value: "ILLC" },
  { programs: "Illustration for Entertainment", value: "ILLE" },
  { programs: "Illustration for Surface Dsgn", value: "ILLS" },
  { programs: "Illustration", value: "ILLU" },
  { programs: "Interior Design", value: "INDS" },
  { programs: "Inclusive Fashion Design", value: "INFD" },
  { programs: "International Preservation", value: "INPR" },
  { programs: "Interactive Design", value: "INTR" },
  { programs: "Interactive Dsgn/Game Developm", value: "ITGM" },
  { programs: "Interactive Design", value: "IXDS" },
  { programs: "Jewelry and Objects", value: "JEOB" },
  { programs: "Jewelry", value: "JEWL" },
  { programs: "Kinetic Design", value: "KDES" },
  { programs: "Language and Cultural Studies", value: "LANG" },
  { programs: "Creative Business Leadership", value: "LEAD" },
  { programs: "Lighting Design", value: "LGHT" },
  { programs: "Lighting and Scenery Concentra", value: "LIGH" },
  { programs: "Limited Residency", value: "LMTD" },
  { programs: "Luxury & Fashion Management", value: "LXFM" },
  { programs: "Luxury and Brand Management", value: "LXMT" },
  { programs: "Marine Design", value: "MARI" },
  { programs: "Marketing Mobile/Interactive", value: "MARK" },
  { programs: "Menswear Design", value: "MENS" },
  { programs: "Management", value: "MGMT" },
  { programs: "Mobile and Interactive Design", value: "MOBI" },
  { programs: "Motion Media Design", value: "MOME" },
  { programs: "Performing Arts", value: "MPRA" },
  { programs: "Music Production", value: "MPRD" },
  { programs: "Metals & Jewelry", value: "MTJW" },
  { programs: "Music Composition", value: "MUCO" },
  { programs: "Museum Studies", value: "MUSM" },
  { programs: "Vocal Performance", value: "MUST" },
  { programs: "New Media Art", value: "NEWM" },
  { programs: "Package Design", value: "PACK" },
  { programs: "Theme Parks and Attractions", value: "PARK" },
  { programs: "Products", value: "PDCT" },
  { programs: "Performing Arts", value: "PERF" },
  { programs: "Photography (ACA)", value: "PH" },
  { programs: "Photography", value: "PHOT" },
  { programs: "Painting (ACA)", value: "PN" },
  { programs: "Painting", value: "PNTG" },
  { programs: "Portrait Arts", value: "PORT" },
  { programs: "Packaging and Print Media", value: "PPME" },
  { programs: "Printmaking (ACA)", value: "PR" },
  { programs: "Product Design", value: "PRDS" },
  { programs: "Preservation Design", value: "PRES" },
  { programs: "Previsualization", value: "PREV" },
  { programs: "Producing for Film and Media", value: "PRFM" },
  { programs: "Printmaking", value: "PRMK" },
  { programs: "Production Design", value: "PROD" },
  { programs: "Built Environment", value: "SBEV" },
  { programs: "Sculpture (ACA)", value: "SC" },
  { programs: "Scientific Illustration", value: "SCIL" },
  { programs: "Visual Communication", value: "SCOM" },
  { programs: "Sculpture", value: "SCPT" },
  { programs: "Sequential Art", value: "SEQA" },
  { programs: "Service Design", value: "SERV" },
  { programs: "Set Design and Art Direction", value: "SETD" },
  { programs: "Digital Media", value: "SFDM" },
  { programs: "Management", value: "SMGT" },
  { programs: "Sound Editing", value: "SNDE" },
  { programs: "Sound Design", value: "SNDS" },
  { programs: "Social Strategy and Management", value: "SOCL" },
  { programs: "Packaging and print media", value: "SPPM" },
  { programs: "Products", value: "SPRO" },
  { programs: "Storyboarding", value: "STOR" },
  { programs: "Sustainable Fashion Design", value: "SUSF" },
  { programs: "Design for Sustainability", value: "SUST" },
  { programs: "Technical Direction", value: "TECH" },
  { programs: "Television Producing", value: "TELE" },
  { programs: "Themed Entertainment Design", value: "THED" },
  { programs: "Typeface Design", value: "TYPE" },
  { programs: "Undecided", value: "UNDE" },
  { programs: "Urban Design", value: "URBA" },
  { programs: "User Experience (UX) Design", value: "UXDG" },
  { programs: "Digital Video (ACA)", value: "VI" },
  { programs: "Video/Film", value: "VIFM" },
  { programs: "Visual Effects", value: "VSFX" },
  { programs: "Writing", value: "WRIT" },
  { programs: "Broadcast Design/Motion Graphi", value: "ZBRO" },
  { programs: "Fiber Arts", value: "ZFIB" },
  { programs: "Media and Performing Arts", value: "ZMPA" },
  { programs: "Product/Industrial Design", value: "ZPIN" },
  { programs: "Video", value: "ZVID" },
  { programs: "Weaving/Textile Design", value: "ZWEA" },
];

export default params;
