const params = [
  { programs: "Advertising", value: "ADVE" },
  // { programs: "Business of Beauty & Fragrance", value: "BEAU" },
  { programs: "Fashion Marketing & Management", value: "FASM" },
  { programs: "Interactive Design and Game Development", value: "ITGM" },
  { programs: "Graphic Design", value: "GRDS" },
  { programs: "Photography", value: "PHOT" },
  { programs: "Sequential Art", value: "SEQA" },
  { programs: "Social Strategy and Management", value: "SOCL" },
  { programs: "Undecided", value: "UNDE" },
];

export default params;
