var validateStr = (rule, value, callback) => {
  //   callback(new Error("两次输入密码不一致!"));
  // var reg_tel = /^[0-9a-zA-Z!@#$%^&*_|., /\s?]+ $/; //11位手机号码正则
  var reg_tel = /^[0-9a-zA-Z.!@#$%^&\s?]+$/; //11位手机号码正则
  if (!reg_tel.test(value)) {
    // alert("请正确填写您的手机号码！");
    callback(new Error("Format error，allowed characters:'0-9A-Z!@#$%^&'"));
  } else {
    callback();
  }
};

var validatePhone = (rule, value, callback) => {
  var reg_tel =
    /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/; //11位手机号码正则

  if (!reg_tel.test(value)) {
    // alert("请正确填写您的手机号码！");
    callback(new Error("Please enter a valid phone number"));
  } else {
    callback();
  }
};

var validateEmail = (rule, value, callback) => {
  var reg_tel = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/; //11位手机号码正则

  if (!reg_tel.test(value)) {
    // alert("请正确填写您的手机号码！");
    callback(new Error("Please enter a valid email"));
  } else {
    callback();
  }
};

var validateconfirmemail = (rule, value, callback) => {
  console.log(rule);
  // if (this.formData.email != value) {
  //   // alert("请正确填写您的手机号码！");
  //   callback(new Error("please enter the correct Confirm email"));
  // } else {
  //   callback();
  // }
  callback();
};

const obj = {
  firstname: [
    {
      required: true,
      message: "Please enter",
      trigger: "blur",
    },
    { validator: validateStr, trigger: "blur" },
    // { min: 3, max: 5, message: "3 to 5 characters long", trigger: "blur" },
  ],
  familyname: [
    {
      required: true,
      message: "Please enter",
      trigger: "blur",
    },
    { validator: validateStr, trigger: "blur" },
  ],
  email: [
    {
      required: true,
      message: "Please enter",
      trigger: "blur",
    },
    { validator: validateEmail, trigger: "blur" },
    { validator: validateStr, trigger: "blur" },
  ],
  confirmemail: [
    {
      required: true,
      message: "Please enter",
      trigger: "blur",
    },
    { validator: validateEmail, trigger: "blur" },
    { validator: validateconfirmemail, trigger: "blur" },
    { validator: validateStr, trigger: "blur" },
  ],
  company: [
    {
      required: true,
      message: "Please enter",
      trigger: "blur",
    },
    { validator: validateStr, trigger: "blur" },
  ],
  dateofbirth: [
    {
      required: true,
      message: "Please choose",
      trigger: "change",
    },
  ],
  countryc: [
    {
      required: true,
      message: "please choose",
      trigger: "change",
    },
  ],
  countryofcitizenship: [
    {
      required: true,
      message: "please choose",
      trigger: "change",
    },
  ],
  streetaddress: [
    {
      required: true,
      message: "Please enter",
      trigger: "blur",
    },
    { validator: validateStr, trigger: "blur" },
  ],
  mobile: [
    {
      required: true,
      message: "Please enter",
      trigger: "blur",
    },
    { validator: validatePhone, trigger: "blur" },
    { validator: validateStr, trigger: "blur" },
  ],
  areyouauscitizen: [
    {
      required: true,
      message: "Please choose",
      trigger: "change",
    },
  ],
  intendedlocation: [
    {
      required: true,
      message: "Please choose",
      trigger: "change",
    },
  ],
  termofentry: [
    {
      required: true,
      message: "Please choose",
      trigger: "change",
    },
  ],
  enrollmentstatus: [
    {
      required: true,
      message: "Please choose",
      trigger: "change",
    },
  ],
  intendedprogramofstudentfirstchoice: [
    {
      required: true,
      message: "Please choose",
      trigger: "change",
    },
  ],
  intendedprogramofstudentsecondchoice: [
    {
      required: true,
      message: "Please choose",
      trigger: "change",
    },
  ],
  intendedprogramofstudentthirdchoice: [
    {
      required: true,
      message: "Please choose",
      trigger: "change",
    },
  ],
  campusdegree: [
    {
      required: true,
      message: "Please choose",
      trigger: "change",
    },
  ],
  nameofuniversityattended: [
    {
      required: true,
      message: "Please enter",
      trigger: "blur",
    },
    { validator: validateStr, trigger: "blur" },
  ],
  universitycity: [
    {
      required: true,
      message: "Please enter",
      trigger: "blur",
    },
    { validator: validateStr, trigger: "blur" },
  ],

  nameofsecondaryhomeorhighschool: [
    {
      required: true,
      message: "Please enter",
      trigger: "blur",
    },
    { validator: validateStr, trigger: "blur" },
  ],
  highschoolcity: [
    {
      required: true,
      message: "Please enter",
      trigger: "blur",
    },
    { validator: validateStr, trigger: "blur" },
  ],

  graduationmonthyearofhighschool: [
    {
      required: true,
      message: "Please choose",
      trigger: "change",
    },
  ],

  dateattendedfromuniversityonly: [
    {
      required: true,
      message: "Please choose",
      trigger: "change",
    },
  ],
  dateattendedtouniversityonly: [
    {
      required: true,
      message: "Please choose",
      trigger: "change",
    },
  ],
  degreeuniversityonly: [
    {
      required: true,
      message: "Please choose",
      trigger: "change",
    },
  ],
  dateofgraduationuniversityonly: [
    {
      required: true,
      message: "Please choose",
      trigger: "change",
    },
  ],
  probationandsuspendedexpelled: [
    {
      required: true,
      message: "Please choose",
      trigger: "change",
    },
  ],
  convicted: [
    {
      required: true,
      message: "Please choose",
      trigger: "change",
    },
  ],
  guardianrelationship: [
    {
      required: true,
      message: "Please choose",
      trigger: "change",
    },
  ],
  guardianemail: [
    {
      required: true,
      message: "Please enter",
      trigger: "blur",
    },
    { validator: validateEmail, trigger: "blur" },
    { validator: validateStr, trigger: "blur" },
  ],
  guardianfirstname: [
    {
      required: true,
      message: "Please enter",
      trigger: "blur",
    },
    { validator: validateStr, trigger: "blur" },
  ],
  guardianlastname: [
    {
      required: true,
      message: "Please enter",
      trigger: "blur",
    },
    { validator: validateStr, trigger: "blur" },
  ],
  guardianaddressline1: [
    {
      required: true,
      message: "Please enter",
      trigger: "blur",
    },
    { validator: validateStr, trigger: "blur" },
  ],
  guardianphone: [
    {
      required: true,
      message: "Please enter",
      trigger: "blur",
    },
    { validator: validatePhone, trigger: "blur" },
    { validator: validateStr, trigger: "blur" },
  ],
  contactconsent: [
    {
      required: true,
      message: "Please choose",
      trigger: "change",
    },
  ],
  scadrulesagreementyesorno: [
    {
      required: true,
      message: "Please choose",
      trigger: "change",
    },
  ],
  i_am_a: [
    {
      required: true,
      message: "Please choose",
      trigger: "change",
    },
  ],

  rfiiama: [
    {
      required: true,
      message: "Please choose",
      trigger: "change",
    },
  ],
  rfinameofhighschool: [
    {
      required: true,
      message: "Please enter",
      trigger: "blur",
    },
    { validator: validateStr, trigger: "blur" },
  ],
  rfiwheredidyougraduatefromcollege: [
    {
      required: true,
      message: "Please enter",
      trigger: "blur",
    },
    { validator: validateStr, trigger: "blur" },
  ],
  rfiwheredoyoucurrentlyattendcollege: [
    {
      required: true,
      message: "Please enter",
      trigger: "blur",
    },
    { validator: validateStr, trigger: "blur" },
  ],
  rfiwhatscadlocationinterestsyouthemost: [
    {
      required: true,
      message: "Please choose",
      trigger: "change",
    },
  ],

  rfiwhenwouldyouliketoenroll: [
    {
      required: true,
      message: "Please choose",
      trigger: "change",
    },
  ],

  rfiwhatsyourintendedlevelofstudy: [
    {
      required: true,
      message: "Please choose",
      trigger: "change",
    },
  ],

  rfiwhatareyouinterestedinstudying: [
    {
      required: true,
      message: "Please choose",
      trigger: "change",
    },
  ],

  rfiwouldyoulikeustosendinformation: [
    {
      required: true,
      message: "Please choose",
      trigger: "change",
    },
  ],

  rfiparentfirstname: [
    {
      required: true,
      message: "Please enter",
      trigger: "blur",
    },
    { validator: validateStr, trigger: "blur" },
  ],

  rfiparentlastname: [
    {
      required: true,
      message: "Please enter",
      trigger: "blur",
    },
    { validator: validateStr, trigger: "blur" },
  ],

  rfiparentemail: [
    {
      required: true,
      message: "Please enter",
      trigger: "blur",
    },
    { validator: validateStr, trigger: "blur" },
  ],

  rfiparentphonenumber: [
    {
      required: true,
      message: "Please enter",
      trigger: "blur",
    },
    { validator: validateStr, trigger: "blur" },
  ],
};

export default obj;
