const params = [
  { programs: "Accessory Design", value: "ACCE" },
  { programs: "Advertising", value: "ADVE" },
  { programs: "Animation", value: "ANIM" },
  { programs: "Architecture", value: "ARCH" },
  { programs: "Architectural History", value: "ARLH" },
  { programs: "Art History", value: "ARTH" },
  { programs: "Immersive Reality", value: "ARVR" },
  // { programs: "Business of Beauty & Fragrance", value: "BEAU" },
  { programs: "Dramatic Writing", value: "DWRI" },
  { programs: "Equestrian Studies", value: "EQST" },
  { programs: "Fashion", value: "FASH" },
  { programs: "Fashion Marketing & Management", value: "FASM" },
  { programs: "Fibers", value: "FIBR" },
  { programs: "Film and Television", value: "FILM" },
  { programs: "Furniture Design", value: "FURN" },
  { programs: "Graphic Design", value: "GRDS" },
  { programs: "Industrial Design", value: "IDUS" },
  { programs: "Illustration", value: "ILLU" },
  { programs: "Interior Design", value: "INDS" },
  { programs: "Interactive Design and Game Development", value: "ITGM" },
  { programs: "Jewelry", value: "JEWL" },
  { programs: "Motion Media Design", value: "MOME" },
  { programs: "Performing Arts", value: "MPRA" },
  { programs: "Photography", value: "PHOT" },
  { programs: "Painting", value: "PNTG" },
  { programs: "Preservation Design", value: "PRES" },
  { programs: "Production Design", value: "PROD" },
  { programs: "Sequential Art", value: "SEQA" },
  { programs: "Service Design", value: "SERV" },
  { programs: "Sound Design", value: "SNDS" },
  { programs: "Social Strategy and Management", value: "SOCL" },
  { programs: "Undecided", value: "UNDE" },
  { programs: "User Experience (UX) Design", value: "UXDG" },
  { programs: "Visual Effects", value: "VSFX" },
  { programs: "Writing", value: "WRIT" },
];

export default params;
