<template>
  <div class="cust-container">
    <menuBar></menuBar>
    <div>
      <div class="banner">
        <img src="@/assets/images/international_students/banner.jpg" />
        <div class="text" v-if="$i18n.locale === 'en'">
          INTERNATIONAL STUDENTS
        </div>
        <div class="text" v-if="$i18n.locale === 'zh'">国际学生</div>
      </div>

      <div class="content" v-if="$i18n.locale === 'en'">
        <div class="title">Working in the U.S.</div>
        <div class="desc">
          If you are searching for work, ISSO can help explain acceptable types
          of employment, special authorizations for certain jobs, and income tax
          requirements.
        </div>

        <div class="title1">On-campus employment</div>
        <div class="text1">
          All F-1 visa holders are automatically eligible to seek on-campus
          employment, but employment is not guaranteed. You can work a maximum
          of 20 hours per week in any department or SCAD office during the
          academic year. During break periods, you can work a maximum of 40
          hours per week. Learn more on MySCAD.
        </div>

        <div class="title1">Volunteering</div>
        <div class="text1">
          You may volunteer your time and skills in any non-paid position
          provided that position is not normally held by a paid worker. Non-paid
          work does not require any work authorization, but you must maintain
          full-time enrollment unless you are participating in a Curricular
          Practical Training internship.
        </div>
        <div class="title1">Employment outside of SCAD</div>
        <div class="text1">
          You must obtain U.S. work authorization for any position outside of
          SCAD. If you have an F-1 visa, you cannot apply for employment outside
          of SCAD until you have F-1 status for at least three quarters. or if
          you have studied for at least three consecutive quarters at SCAD.You
          cannot accept employment outside of SCAD without USCIS authorization.
        </div>
      </div>

      <div class="content" v-if="$i18n.locale === 'zh'">
        <div class="title">在美国工作</div>
        <div class="desc">
          如果您正在寻找工作，ISSO
          可以帮助解释可接受的就业类型、某些工作的特别授权和所得税要求。
        </div>

        <div class="title1">学生就业</div>
        <div class="text1">
          F-1
          签证持有者都有资格寻找学生工作，但不能保证就业。在学年期间，您每周最多可以在任何部门或
          SCAD 办公室工作 20 小时。在休息期间，您每周最多可以工作 40
          小时。可以在 MySCAD 上了解更多信息。
        </div>

        <div class="title1">志愿服务</div>
        <div class="text1">
          您可以在任何无薪职位上花自愿费您的时间和技能，前提是该职位通常不由有薪工人担任。无偿工作不需要任何工作授权。除非您要参加课程实践培训的实习，否则您必须保持全日制注册才能参加志愿者工作。
        </div>
        <div class="title1">SCAD 以外的就业</div>
        <div class="text1">
          对于 SCAD 以外的任何带薪职位，您必须获得美国工作授权。如果您持有 F-1
          签证，则至少在您拥有 F-1 身份至少三个学期之后，您才能申请 SCAD
          以外的工作。未经 USCIS 授权，您不能接受 SCAD 以外的工作。
        </div>
      </div>
    </div>

    <goTop></goTop>
    <custFooter></custFooter>
  </div>
</template>

<script>
import menuBar from "../../components/menuBar/index.vue";
import custFooter from "@/components/footer/Footer";
export default {
  metaInfo: {
    title: "SCAD", // set a title
  },
  name: "International1",
  components: {
    menuBar,
    custFooter,
  },
  data: function () {
    return {};
  },
};
</script>

<style lang="less" scoped>
@import url(International.less);
</style>
