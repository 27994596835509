import Vue from "vue";
import VueRouter from "vue-router";
// import Home from "../views/Home.vue";
import HomePage from "../views/homePage/HomePage.vue";
import Locations from "@/views/locations/Locations";
import MissonVisionValue from "@/views/missonVisionValue/MissonVisionValue";
import Faq from "@/views/faq/Faq";
import AcademicPrograms from "@/views/academicPrograms/AcademicPrograms";
import AdmissionEvents from "@/views/admissionEvents/AdmissionEvents";
import Academic from "@/views/academicPrograms/Academic";
import GraduateApplication from "@/views/form/GraduateApplication";
import Programs from "@/views/academicPrograms/Programs";
import TransferApplication from "@/views/form/TransferApplication";
import UndergraduateApplication from "@/views/form/UndergraduateApplication";
import RFI from "@/views/form/RFI";
import Application from "@/views/form/Application";
import InternationalStudents from "@/views/internationalStudents/InternationalStudents";
import International1 from "@/views/internationalStudents/International1";
import International2 from "@/views/internationalStudents/International2";
import International3 from "@/views/internationalStudents/International3";
import International4 from "@/views/internationalStudents/International4";
import International5 from "@/views/internationalStudents/International5";
import WhyChooseSCAD from "@/views/whyChooseSCAD/WhyChooseSCAD";
import Stem from "@/views/stem/Stem";
import EnglishLanguageSupport from "@/views/internationalStudents/EnglishLanguageSupport";
import InternationalCAPP from "@/views/internationalStudents/InternationalCAPP";
import TopIndividualProgramRankings from "@/views/highlightedAwards/TopIndividualProgramRankings";
import TopRankings from "@/views/highlightedAwards/TopRankings";
import AwardsSTEM from "@/views/highlightedAwards/AwardsSTEM";
import TFUnderGrad2023 from "@/views/faq/tuitionandFees/T-F-Under-Grad-2023/T-F-Under-Grad-2023";
import TFUnderGrad2022 from "@/views/faq/tuitionandFees/T-F-Under-Grad-2022/T-F-Under-Grad-2022";
import TFGrad2023 from "@/views/faq/tuitionandFees/T-F-Grad-2023/T-F-Grad-2023";
import TFGrad2022 from "@/views/faq/tuitionandFees/T-F-Grad-2022/T-F-Grad-2022";

import TFUnderGrad2024 from "@/views/faq/tuitionandFees/T-F-Under-Grad-2024/T-F-Under-Grad-2024";
import TFGrad2024 from "@/views/faq/tuitionandFees/T-F-Grad-2024/T-F-Grad-2024";

import TFUnderGrad2025 from "@/views/faq/tuitionandFees/T-F-Under-Grad-2025/T-F-Under-Grad-2025";
import TFGrad2025 from "@/views/faq/tuitionandFees/T-F-Grad-2025/T-F-Grad-2025";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "HomePage",
    component: HomePage,
  },
  {
    path: "/Locations/:name",
    name: "Locations",
    component: Locations,
  },

  {
    path: "/MissonVisionValue",
    name: "MissonVisionValue",
    component: MissonVisionValue,
  },
  {
    path: "/AcademicPrograms",
    name: "AcademicPrograms",
    component: AcademicPrograms,
  },
  {
    path: "/Programs/:id/:name",
    name: "Programs",
    component: Programs,
  },
  {
    path: "/Academic",
    name: "Academic",
    component: Academic,
  },
  {
    path: "/Faq",
    name: "Faq",
    component: Faq,
  },
  {
    path: "/T-F-Under-Grad-2023",
    name: "TFUnderGrad2023",
    component: TFUnderGrad2023,
  },

  {
    path: "/T-F-Under-Grad-2022",
    name: "TFUnderGrad2022",
    component: TFUnderGrad2022,
  },
  {
    path: "/T-F-Grad-2023",
    name: "T-F-Grad-2023",
    component: TFGrad2023,
  },

  {
    path: "/T-F-Grad-2022",
    name: "T-F-Grad-2022",
    component: TFGrad2022,
  },

  {
    path: "/T-F-Under-Grad-2024",
    name: "T-F-Under-Grad-2024",
    component: TFUnderGrad2024,
  },

  {
    path: "/T-F-Grad-2024",
    name: "T-F-Grad-2024",
    component: TFGrad2024,
  },

  {
    path: "/T-F-Under-Grad-2025",
    name: "T-F-Under-Grad-2025",
    component: TFUnderGrad2025,
  },

  {
    path: "/T-F-Grad-2025",
    name: "T-F-Grad-2025",
    component: TFGrad2025,
  },
  {
    path: "/AdmissionEvents",
    name: "AdmissionEvents",
    component: AdmissionEvents,
  },
  {
    path: "/GraduateApplication",
    name: "GraduateApplication",
    component: GraduateApplication,
  },
  {
    path: "/TransferApplication",
    name: "TransferApplication",
    component: TransferApplication,
  },
  {
    path: "/UndergraduateApplication",
    name: "UndergraduateApplication",
    component: UndergraduateApplication,
  },
  {
    path: "/rfi",
    name: "rfi",
    component: RFI,
  },
  {
    path: "/Application",
    name: "Application",
    component: Application,
  },
  {
    path: "/InternationalStudents",
    name: "InternationalStudents",
    component: InternationalStudents,
  },

  {
    path: "/International-1",
    name: "International-1",
    component: International1,
  },
  {
    path: "/Getting-started-at-SCAD",
    name: "International-2",
    component: International2,
  },
  {
    path: "/International-3",
    name: "International-3",
    component: International3,
  },
  {
    path: "/International-4",
    name: "International-4",
    component: International4,
  },
  {
    path: "/International-5",
    name: "International-5",
    component: International5,
  },
  {
    path: "/WhyChooseSCAD",
    name: "WhyChooseSCAD",
    component: WhyChooseSCAD,
  },
  {
    path: "/STEM",
    name: "STEM",
    component: Stem,
  },
  {
    path: "/englishLanguageSupport",
    name: "EnglishLanguageSupport",
    component: EnglishLanguageSupport,
  },
  {
    path: "/international-capp",
    name: "InternationalCAPP",
    component: InternationalCAPP,
  },
  {
    path: "/topIndividualProgramRankings",
    name: "TopIndividualProgramRankings",
    component: TopIndividualProgramRankings,
  },
  {
    path: "/topRankings",
    name: "TopRankings",
    component: TopRankings,
  },
  {
    path: "/awardsSTEM",
    name: "AwardsSTEM",
    component: AwardsSTEM,
  },
  // {
  //   path: "/about",
  //   name: "About",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/About.vue"),
  // },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
