<template>
  <div>
    <div v-if="$i18n.locale === 'en'">
      <div class="grid-content content">
        <div class="title1">SCAD Atlanta — a booming metropolitan hub</div>
        <div class="video">
          <!--<video-->
          <!--  src="https://scad-minisite.oss-cn-beijing.aliyuncs.com/video/Atlanta/2021-22SCADAtlanta.mp4"-->
          <!--  controls-->
          <!--  poster="https://scad-minisite.oss-cn-beijing.aliyuncs.com/video/Atlanta/2021-22SCADAtlanta.mp4?x-oss-process=video/snapshot,t_1000,m_fast"-->
          <!--&gt;</video>-->

          <video
            src="https://scad-minisite.oss-cn-beijing.aliyuncs.com/video/20231110/2023_24_SCAD_Atlanta_Mandarin_subs_V7.mp4"
            controls
            poster="https://scad-minisite.oss-cn-beijing.aliyuncs.com/video/20231110/2023_24_SCAD_Atlanta_Mandarin_subs_V7.mp4?x-oss-process=video/snapshot,t_1000,m_fast"
          ></video>
        </div>
        <div class="tips text">
          Atlanta’s mix of cosmopolitan style and ascendant commerce energizes
          every avenue of this soaring city, where a tapestry of buzzworthy
          startups, big-budget film studios, and multinational companies is
          woven among one of the most popular and visually appealing urban
          canopies in the world. The elite bastions of business and design that
          call Atlanta home — a roster that includes Porsche, CNN, The Coca-Cola
          Company, The Home Depot, Tyler Perry Studios, Delta Air Lines, and
          Microsoft — signal the city as a celebrated metropolis and translate
          to extraordinary internship and professional opportunities for SCAD
          students and alumni.
        </div>
        <div class="line" style="margin-top: 1%"></div>
        <div class="padding-both">
          <img
            src="@/assets/images/locations/atlanta_content_img_01_02.jpg?v=1"
            alt=""
          />
          <div class="title2">Immerse yourself in our diverse programs</div>
          <div class="text text-center">
            The university's fastest-growing location, SCAD Atlanta offers
            next-level technology and resources for every academic pursuit. The
            SCAD Digital Media Center rivals the city’s nearby film studios with
            its state-of-the-art LED volume stage and stellar production spaces
            for students in acting and film and television. Digital studios and
            postproduction spaces at FORTY FIVE and SCAD Atlanta's main building
            boost the work of animation, interactive design and game
            development, immersive reality, motion media design, and visual
            effects students, while digital labs and physical resources at FORTY
            FIVE and SCAD Studio support industrial design, sculpture, and user
            experience (UX) design students as they prepare to tap into the
            region's expansive job market.
          </div>
        </div>
        <div class="line"></div>

        <el-row class="padding-left" :gutter="30">
          <el-col :xs="24" :md="12">
            <img
              src="@/assets/images/locations/ATL_2023Winter_SCADday_CG_011_JJ_v1.jpg?v=1"
            />
          </el-col>
          <el-col :xs="24" :md="12">
            <div class="title3">Your space to grow</div>
            <div class="text">
              In Atlanta, students make their mark on the arts and innovation
              scene and contribute to the growth of the city’s top companies
              through SCADpro, the university’s boutique creative consultancy.
              Both students and alumni secure roles in front of the camera and
              behind the lens on acclaimed TV shows and blockbuster movies,
              including Marvel hits like Black Panther: Wakanda Forever,
              Hawkeye, and WandaVision, as well as Red Notice, Atlanta, Ozark,
              and more.
            </div>
          </el-col>
        </el-row>
        <el-row class="padding-left margin-top" :gutter="30">
          <el-col :xs="24" :md="12">
            <img
              src="@/assets/images/locations/atlanta_content_img_03_2.jpg?v=1"
            />
          </el-col>
          <el-col :xs="24" :md="12">
            <div class="title3">Hosting students from around the world</div>
            <div class="text">
              The university’s proximity to Hartsfield-Jackson Atlanta
              International Airport, the world’s busiest and most connected
              airport, make it an ideal location for international students and
              curious travelers alike, with more than 150 domestic and 70 global
              destinations among its daily departures. Energy, inspiration, and
              entertainment are just steps away in Atlanta’s chic shops, major
              venues, and 300-plus acres of green spaces, including Piedmont
              Park, Atlantic Station, Buckhead, and the famed Atlanta Beltline.
              At the university’s own SCAD FASH Museum of Fashion + Film,
              must-see exhibitions and exclusive screenings herald the future of
              style.
            </div>
          </el-col>
        </el-row>

        <div class="line"></div>

        <el-row class="padding-left margin-top" :gutter="30">
          <el-col :xs="24" :md="12">
            <div class="title3">Connect with us</div>
            <div class="text">
              Request more information today, and we'll send you all the details
              you need to begin your exciting journey at SCAD.
              <br /><br />
              <a
                target="_blank"
                href="https://app.jingsocial.com/microFrontend/forms/jsf-forms/details/qFjikR3MCzTGfMEbp4bRqh?lead_source_type=survey&lead_source_value=qFjikR3MCzTGfMEbp4bRqh&promotion_code=survey_qrcode&promotion_value=cdw9Dvn5cUBUn6GxM44mja&promotion_channel_id=tiVt8LccayrF2kbKxd9jja&global_promotion_channel_id=3ZH8PjSow9oxzhbzfrcXAX&global_promotion_sub_channel_id=n9RQk7YyKX6he32EtHzFce&appId=wxe14b2fd0105ac9dc"
                >Request Information
              </a>
            </div>
          </el-col>

          <el-col :xs="24" :md="12">
            <img
              src="@/assets/images/locations/atlanta_content_img_04.jpg?v=1"
            />
          </el-col>
        </el-row>
      </div>
    </div>

    <div v-if="$i18n.locale === 'zh'">
      <div class="grid-content content">
        <div class="title1">SCAD亚特兰大校区——蓬勃发展的大都市枢纽</div>
        <div class="video">
          <!--<video-->
          <!--  src="https://scad-minisite.oss-cn-beijing.aliyuncs.com/video/Atlanta/2021-22SCADAtlanta.mp4"-->
          <!--  controls-->
          <!--  poster="https://scad-minisite.oss-cn-beijing.aliyuncs.com/video/Atlanta/2021-22SCADAtlanta.mp4?x-oss-process=video/snapshot,t_1000,m_fast"-->
          <!--&gt;</video>-->

          <video
            src="https://scad-minisite.oss-cn-beijing.aliyuncs.com/video/20231110/2023_24_SCAD_Atlanta_Mandarin_subs_V7.mp4"
            controls
            poster="https://scad-minisite.oss-cn-beijing.aliyuncs.com/video/20231110/2023_24_SCAD_Atlanta_Mandarin_subs_V7.mp4?x-oss-process=video/snapshot,t_1000,m_fast"
          ></video>
        </div>
        <div class="tips text">
          在繁华的亚特兰大都市中心，SCAD的学生处于一个拥有财富 500
          强公司、蓬勃发展的艺术场景和无限的职业发展机会的环境。学生在追求创意事业的同时，可以充分利用遍布博物馆、公园、商店、音乐和专业运动场所的多元化城市环境。
        </div>
        <div class="line" style="margin-top: 1%"></div>
        <div class="padding-both">
          <img
            src="@/assets/images/locations/atlanta_content_img_01_02.jpg?v=1"
            alt=""
          />
          <div class="title2">沉浸在我们多样化的项目中</div>
          <div class="text text-center">
            SCAD 提供 100
            多个学位课程。借助我们项目的灵活性，学生可以在一个校区开始旅程，并在另一个校区继续前行，这并不会阻滞完成学位的进程，而是探索不同文化和吸收各种艺术观点的绝佳机会。
          </div>
        </div>
        <div class="line"></div>

        <el-row class="padding-left" :gutter="30">
          <el-col :xs="24" :md="12">
            <img
              src="@/assets/images/locations/ATL_2023Winter_SCADday_CG_011_JJ_v1.jpg?v=1"
            />
          </el-col>
          <el-col :xs="24" :md="12">
            <div class="title3">你的成长空间</div>
            <div class="text">
              作为 SCAD
              亚特兰大校区的学生，你将享受位于市中心的艺术和设计中心的所有便利设施。除了我们的图书馆、餐厅、健身中心、画廊和数字实验室外，学生还可以使用世界著名的雕塑工作室、文化艺术和写作中心以及广阔的数字媒体中心。无论你在
              SCAD 的哪个校区，你都将拥有所需的所有资源。
            </div>
          </el-col>
        </el-row>
        <el-row class="padding-left margin-top" :gutter="30">
          <el-col :xs="24" :md="12">
            <img
              src="@/assets/images/locations/atlanta_content_img_03_2.jpg?v=1"
            />
          </el-col>
          <el-col :xs="24" :md="12">
            <div class="title3">欢迎来自世界各地的学生</div>
            <div class="text">
              亚特兰大是世界上最繁忙的机场所在地——来自世界各地的 SCAD
              学生都充分利用了它。我们通过专门的国际学生服务办公室为多元化的学生群体提供支持，提供从学位咨询、入学培训和有趣校内活动的一切服务。
            </div>
          </el-col>
        </el-row>

        <div class="line"></div>

        <el-row class="padding-left margin-top" :gutter="30">
          <el-col :xs="24" :md="12">
            <div class="title3">联系我们</div>
            <div class="text">
              立即索取更多信息，我们将向你发送开始SCAD激动人心的旅程所需的详细信息。
              <br /><br />
              <a
                target="_blank"
                href="https://app.jingsocial.com/microFrontend/forms/jsf-forms/details/fjbXnGDs4SQNrs7uL6dph7?lead_source_type=survey&lead_source_value=fjbXnGDs4SQNrs7uL6dph7&promotion_code=survey_qrcode&promotion_value=ifzDy6ceWJuk3qCYFxrEzD&promotion_channel_id=EuQLxTKCfzLJgdmf8Z2DzD&global_promotion_channel_id=3ZH8PjSow9oxzhbzfrcXAX&global_promotion_sub_channel_id=n9RQk7YyKX6he32EtHzFce&appId=wxe14b2fd0105ac9dc"
                style="color: #000000"
                >了解更多</a
              >
            </div>
          </el-col>

          <el-col :xs="24" :md="12">
            <img
              src="@/assets/images/locations/atlanta_content_img_04.jpg?v=1"
            />
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped lang="less">
@import "../Atlanta.less";
</style>
