const params = [
  { programs: "Accessory Design", value: "ACCE" },
  { programs: "Advertising and Branding", value: "ADBR" },
  { programs: "Advertising Copywriting", value: "ADCO" },
  { programs: "2D Animation", value: "ANI2" },
  { programs: "3D Character Animation", value: "ANI3" },
  { programs: "Animation", value: "ANIM" },
  { programs: "Storytelling/Concept Develop", value: "ANSC" },
  { programs: "Technical Animation", value: "ANTA" },
  { programs: "Architecture", value: "ARCH" },
  { programs: "Architectural History", value: "ARLH" },
  { programs: "Advertising Art Direction", value: "ARTD" },
  { programs: "Art History", value: "ARTH" },
  { programs: "Immersive Reality", value: "ARVR" },
  // { programs: "Business of Beauty & Fragrance", value: "BEAU" },
  { programs: "Commercial Photography", value: "COMP" },
  { programs: "Costume Design", value: "CSTM" },
  { programs: "Documentary Photography", value: "DOCP" },
  { programs: "Dramatic Writing", value: "DWRI" },
  { programs: "Fashion", value: "FASH" },
  { programs: "Fashion Marketing & Management", value: "FASM" },
  { programs: "Fibers", value: "FIBR" },
  { programs: "Fine Art Photography", value: "FINE" },
  { programs: "Furniture Design", value: "FURN" },
  { programs: "Graphic Design", value: "GRDS" },
  { programs: "Industrial Design", value: "IDUS" },
  { programs: "Animated Illu/Publication Dsgn", value: "ILLA" },
  { programs: "Concept Dsgn/Animation & Games", value: "ILLC" },
  { programs: "Illustration for Entertainment", value: "ILLE" },
  { programs: "Illustration for Surface Dsgn", value: "ILLS" },
  { programs: "Interior Design", value: "INDS" },
  { programs: "Interactive Dsgn/Game Developm", value: "ITGM" },
  { programs: "Jewelry", value: "JEWL" },
  { programs: "Lighting Design", value: "LGHT" },
  { programs: "Motion Media Design", value: "MOME" },
  { programs: "Performing Arts", value: "MPRA" },
  { programs: "Theme Parks and Attractions", value: "PARK" },
  { programs: "Painting", value: "PNTG" },
  { programs: "Preservation Design", value: "PRES" },
  { programs: "Sequential Art", value: "SEQA" },
  { programs: "Service Design", value: "SERV" },
  { programs: "Set Design and Art Direction", value: "SETD" },
  { programs: "Sound Design", value: "SNDS" },
  { programs: "Social Strategy and Management", value: "SOCL" },
  { programs: "User Experience (UX) Design", value: "UXDG" },
  { programs: "Visual Effects", value: "VSFX" },
  { programs: "Writing", value: "WRIT" },
];

export default params;
