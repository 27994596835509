const params = [
  { programs: "Creative Business Leadership", value: "CRBL" },
  { programs: "Design Management", value: "DMGT" },
  { programs: "Fashion", value: "FASH" },
  { programs: "Graphic Design", value: "GRDS" },
  { programs: "Interior Design", value: "INDS" },
  { programs: "Interactive Design and Game Development", value: "ITGM" },
  { programs: "Luxury and Brand Management", value: "LXMT" },
  { programs: "Motion Media Design", value: "MOME" },
  { programs: "Photography", value: "PHOT" },
  { programs: "Painting", value: "PNTG" },
  { programs: "Preservation Design", value: "PRES" },
  { programs: "Service Design", value: "SERV" },
  { programs: "Undecided", value: "UNDE" },
  { programs: "Writing", value: "WRIT" },
];

export default params;
