<template>
  <div class="cust-container">
    <menuBar></menuBar>
    <div class="content section-life">
      <div class="row" style="margin-top: 50px">
        <div
          class="col-sm-8 main-content"
          style="
            margin: 0 auto;
            display: block;
            float: inherit;
            max-width: 1000px;
          "
        >
          <div class="row">
            <div class="inner-region">
              <div
                class="
                  panel-panel
                  row_1_left
                  col-sm-4 col-xs-offset-0 col-md-5 col-md-offset-0
                "
              >
                <div
                  class="
                    panel-pane
                    pane-entity-field pane-node-field-regional-images
                  "
                >
                  <div class="pane-content">
                    <div class="item">
                      <picture class="img-responsive">
                        <img
                          class="img-responsive"
                          src="https://www.scad.edu/sites/default/files/styles/swarm1x1_550/public/Events/ISSO-Plaza-Fiesta-Atlanta-2018-summer-DP_3800_JJ%281%29.jpg?itok=xeKrZTXl&timestamp=1550080166 1x"
                          alt="International Student Services Office plaza fiesta Atlanta"
                          title=""
                        />
                      </picture>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="inner-region">
              <div
                class="
                  panel-panel
                  row_1_center
                  col-sm-8 col-sm-offset-0 col-md-7 col-md-offset-0
                "
              >
                <div class="panel-pane pane-custom pane-7">
                  <div class="pane-content">
                    <h1 style="margin-top: 0">SCAD 文化和学术准备计划</h1>
                    <p>
                      借助 SCAD 文化和学术准备计划 (CAPP)
                      在您的美国大学生活之旅中寻找支持，CAPP 是由 SCAD
                      国际学生服务办公室和 SCAD
                      语言工作室为亚特兰大和萨凡纳的国际新生举办的为期四周的暑期预备课程。
                    </p>
                    <p>
                      <strong
                        >CAPP 将于 2022 年 8 月 3 日至 31 日期间举行。 </strong
                      >如需更多信息，请访问
                      <a href="mailto:isso@scad.edu">isso@scad.edu</a>.
                    </p>
                    <p>
                      <a
                        class="btn btn-stroke"
                        href="https://isso.scad.edu/index.cfm?FuseAction=Abroad.ViewLink&amp;Parent_ID=0&amp;Link_ID=F83876B7-EFC2-1731-B4F5F0A083B9611C"
                        target="_blank"
                        >立即申请</a
                      >
                    </p>
                    <p></p>
                    <p></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <div class="hr"></div>
            </div>
          </div>
          <div class="row">
            <div class="inner-region">
              <div
                class="
                  panel-panel
                  row_2_left
                  col-sm-12 col-xs-offset-0 col-md-12 col-md-offset-0
                "
              >
                <div class="panel-pane pane-node margin-top-sm-20">
                  <div class="pane-content">
                    <div class="borderless-block">
                      <div class="video-medium">
                        <div class="image">
                          <a href="javascript:void(0)" aria-label="Play Video">
                            <div
                              class="
                                field
                                field-name-field-thumbnail-image
                                field-type-image
                                field-label-hidden
                              "
                            >
                              <div class="field-items">
                                <div class="field-item even">
                                  <picture class="img-responsive">
                                    <img
                                      class="img-responsive"
                                      src="@/assets/images/internationalcapp/CAPP-2020-video.webp?itok=eQbV9vkq&amp;timestamp=1582039881"
                                      alt="Play SCAD CAPP video"
                                      title=""
                                    />
                                  </picture>
                                </div>
                              </div>
                            </div>
                            <span class="scadtv-icon scadtv-icon-play"></span>
                          </a>
                        </div>
                        <div class="video-element">
                          <div class="embed-responsive embed-responsive-16by9">
                            <iframe
                              src="//www.youtube.com/embed/bixEVUpW5iM?rel=0"
                              frameborder="0"
                              width="500px"
                              height="400px"
                              webkitallowfullscreen=""
                              mozallowfullscreen=""
                              allowfullscreen=""
                            ></iframe>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="panel-pane pane-custom pane-13">
                  <div class="pane-content" style="text-align: center">
                    <h2>加速您的成功</h2>
                    <p>
                      CAPP 旨在帮助有才能的学生在秋季学期开始前，顺利过渡到 SCAD
                      的学术、社会和文化生活中。
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="inner-region">
              <div
                class="
                  panel-panel
                  row_3_left
                  col-sm-6 col-sm-offset-0 col-md-4 col-md-offset-0
                "
              >
                <div
                  class="
                    panel-pane
                    pane-custom pane-15
                    margin-top-all-50 margin-bottom-xs-30 margin-bottom-sm-30
                  "
                >
                  <div class="pane-content">
                    <div
                      style="
                        background-color: #ffffff;
                        padding: 30px;
                        -webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
                        -moz-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
                        box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
                        border-radius: 3px;
                      "
                    >
                      <div
                        style="
                          border-top: 4px;
                          border-top-color: #cccccc;
                          border-top-style: solid;
                          padding-top: 30px;
                        "
                      >
                        <p
                          class="reduce"
                          style="
                            font-size: 28px;
                            line-height: 30px;
                            font-weight: 600;
                            margin-bottom: 0px;
                          "
                        >
                          适应美国生活
                        </p>
                      </div>
                    </div>
                    <p>&nbsp;</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="inner-region">
              <div
                class="
                  panel-panel
                  row_3_center
                  col-sm-6 col-sm-offset-0 col-md-4 col-md-offset-0
                "
              >
                <div
                  class="
                    panel-pane
                    pane-custom pane-14
                    margin-top-sm-50
                    margin-top-md-50
                    margin-top-lg-50
                    margin-bottom-xs-30
                    margin-bottom-sm-30
                  "
                >
                  <div class="pane-content">
                    <div
                      style="
                        background-color: #ffffff;
                        padding: 30px;
                        -webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
                        -moz-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
                        box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
                        border-radius: 3px;
                      "
                    >
                      <div
                        style="
                          border-top: 4px;
                          border-top-color: #cccccc;
                          border-top-style: solid;
                          padding-top: 30px;
                        "
                      >
                        <p
                          class="reduce"
                          style="
                            font-size: 28px;
                            line-height: 30px;
                            font-weight: 600;
                            margin-bottom: 0px;
                          "
                        >
                          认识新同学
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="inner-region">
              <div
                class="
                  margin-top-sm-50 margin-top-md-50 margin-top-lg-50
                  panel-panel
                  row_3_right
                  col-sm-6 col-sm-offset-3 col-md-4 col-md-offset-0
                "
              >
                <div
                  class="
                    panel-pane
                    pane-custom pane-16
                    margin-top-md-50 margin-top-lg-50 margin-bottom-xs-30
                  "
                >
                  <div class="pane-content">
                    <div
                      style="
                        background-color: #ffffff;
                        padding: 30px;
                        -webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
                        -moz-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
                        box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
                        border-radius: 3px;
                      "
                    >
                      <div
                        style="
                          border-top: 4px;
                          border-top-color: #cccccc;
                          border-top-style: solid;
                          padding-top: 30px;
                        "
                      >
                        <p
                          class="reduce"
                          style="
                            font-size: 28px;
                            line-height: 30px;
                            font-weight: 600;
                            margin-bottom: 0px;
                          "
                        >
                          做好学术准备
                        </p>
                      </div>
                    </div>
                    <p>&nbsp;</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <div class="hr"></div>
            </div>
          </div>
          <div class="row">
            <div class="inner-region">
              <div
                class="
                  panel-panel
                  row_9_left
                  col-sm-12 col-sm-offset-0 col-md-12 col-md-offset-0
                "
              >
                <div class="panel-pane pane-custom pane-17 margin-top-all-20">
                  <div class="pane-content">
                    <h2 class="rtecenter">SCAD CAPP 学员：</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="inner-region">
              <div
                class="
                  panel-panel
                  row_10_left
                  col-sm-4 col-sm-offset-0 col-md-4 col-md-offset-0
                "
              >
                <div
                  class="
                    panel-pane
                    pane-entity-field pane-node-field-regional-images
                    margin-bottom-all-20
                  "
                >
                  <div class="pane-content">
                    <div class="item">
                      <picture class="img-responsive">
                        <img
                          class="img-responsive"
                          src="@/assets/images/internationalcapp/ISSO-rafting-Atlanta-2018-summer-RP_27.webp?itok=xFbHJu3E&amp;timestamp=1549995354"
                          alt="International Student Services Office rafting"
                          title=""
                        />
                      </picture>
                    </div>
                  </div>
                </div>
                <div class="panel-pane pane-custom pane-2 margin-bottom-all-30">
                  <div class="pane-content">
                    <h3>探索并游览该地区</h3>
                    <p>
                      SCAD CAPP
                      的学生们会在美国东南部进行一日游，包括在萨凡纳附近的杰基尔岛进行海滩游，以及参观亚特兰大的景点等等。
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="inner-region">
              <div
                class="
                  panel-panel
                  row_10_center
                  col-sm-4 col-sm-offset-0 col-md-4 col-md-offset-0
                "
              >
                <div
                  class="
                    panel-pane
                    pane-entity-field pane-node-field-regional-images
                    margin-bottom-all-20
                  "
                >
                  <div class="pane-content">
                    <div class="item">
                      <picture class="img-responsive">
                        <img
                          class="img-responsive"
                          src="@/assets/images/internationalcapp/Faculty-candids-Menjie-Di-Fall-2014-JM_40.webp?itok=0MOdEEaD&amp;timestamp=1549994894"
                          alt="Mengjie Di faculty"
                          title=""
                        />
                      </picture>
                    </div>
                  </div>
                </div>
                <div class="panel-pane pane-custom pane-1 margin-bottom-all-30">
                  <div class="pane-content">
                    <h3>解决设计挑战</h3>
                    <p>
                      在这些基于团队的挑战中，SCAD CAPP
                      学生们一起合作设计新产品、数字应用程序或品牌标识。学生们在该大学的美国校区中了解对于
                      SCAD 教室的期望，并有机会练习英语演讲技巧。
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="inner-region">
              <div
                class="
                  panel-panel
                  row_10_right
                  col-sm-4 col-sm-offset-0 col-md-4 col-md-offset-0
                "
              >
                <div
                  class="
                    panel-pane
                    pane-entity-field pane-node-field-regional-images
                    margin-bottom-all-20
                  "
                >
                  <div class="pane-content">
                    <div class="item">
                      <picture class="img-responsive">
                        <img
                          class="img-responsive"
                          src="@/assets/images/internationalcapp/Norris-Hall-Fall-2014-CC_07_SN.webp?itok=UzZ3LMDT&amp;timestamp=1549994623"
                          alt="Norris Hall interior"
                          title=""
                        />
                      </picture>
                    </div>
                  </div>
                </div>
                <div class="panel-pane pane-custom pane-3 margin-bottom-all-30">
                  <div class="pane-content">
                    <h3>参与基础研讨会</h3>
                    <p>
                      向 SCAD CAPP 学生介绍 SCAD
                      基础学习和通识教育课程，探索对学术成功至关重要的概念、创意和视觉技能。研讨会专为学生们量身定制，为他们提供工作室环境和学习课程。
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="inner-region">
              <div
                class="
                  panel-panel
                  row_11_left
                  col-sm-4 col-sm-offset-0 col-md-4 col-md-offset-0
                "
              >
                <div
                  class="
                    panel-pane
                    pane-entity-field pane-node-field-regional-images
                    margin-bottom-all-20
                  "
                >
                  <div class="pane-content">
                    <div class="item">
                      <picture
                        title="Rising Stars Arts Festival"
                        class="img-responsive"
                      >
                        <img
                          class="img-responsive"
                          src="@/assets/images/internationalcapp/RisingStarsArtsFestival_06-29-2014_jr_MG_9366_SN.webp?itok=45gOqyjk&amp;timestamp=1549993068"
                          alt="Rising Stars Arts Festival"
                          title="Rising Stars Arts Festival"
                        />
                      </picture>
                    </div>
                  </div>
                </div>
                <div class="panel-pane pane-custom pane-5 margin-bottom-xs-30">
                  <div class="pane-content">
                    <h3>探索充满活力的 SCAD 校区</h3>
                    <p>
                      SCAD CAPP
                      学生适应亚特兰大或萨凡纳的大学和城市生活。在亚特兰大，学生们探索这所大学的中心区，财富
                      500
                      强公司、生机勃勃的文化景观以及蓬勃发展的艺术和媒体机构林立于四周。在萨凡纳，学生们可以探索繁荣发展的艺术社区、繁花似锦的广场以及附近的壮丽海滩。
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="inner-region">
              <div
                class="
                  panel-panel
                  row_11_center
                  col-sm-4 col-sm-offset-0 col-md-4 col-md-offset-0
                "
              >
                <div
                  class="
                    panel-pane
                    pane-entity-field pane-node-field-regional-images
                    margin-bottom-all-20
                  "
                >
                  <div class="pane-content">
                    <div class="item">
                      <picture class="img-responsive">
                        <img
                          class="img-responsive"
                          src="@/assets/images/internationalcapp/Montgomery-House-interior-2013-230_CC_SN.webp"
                          alt="Montgomery House interior"
                          title=""
                        />
                      </picture>
                    </div>
                  </div>
                </div>
                <div class="panel-pane pane-custom pane-4 margin-bottom-xs-30">
                  <div class="pane-content">
                    <h3>尽早搬进 SCAD 宿舍</h3>
                    <p>
                      SCAD CAPP 学生们能更早地融入 SCAD
                      学生生活。在亚特兰大，学生们居住在 SCAD
                      亚特兰大地最新宿舍楼
                      FORTY，宿舍楼里有公寓和套房式宿舍、学习空间、活动空间和用餐区。在萨凡纳，学生们住在
                      The
                      Hive，这是一个由宿舍楼组成的综合体，附近有餐厅、健身中心、游泳池等。每栋宿舍楼都配备齐全，并配有无线互联网。
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <div class="hr"></div>
            </div>
          </div>
          <div class="row">
            <div class="inner-region">
              <div class="panel-panel">
                <div class="panel-pane pane-custom pane-12 margin-top-all-20">
                  <div class="pane-content" style="text-align: center">
                    <h2 class="rtecenter">充分利用 CAPP 提供地所有服务</h2>
                    <p class="rtecenter">
                      CAPP 的总费用为 3, 950
                      美元，费用涵盖为期四周的学术和英语教学、每周 18
                      顿餐、周末远足、住宿费以及提前进入 SCAD 教学楼，访问 SCAD
                      资源。
                    </p>
                    <!--<p class="rtecenter">-->
                    <!--  View this year's schedules.<br />-->
                    <!--  <a-->
                    <!--    class="load-modal-window"-->
                    <!--    href="/life/orientation/you-arrive/international-capp/atlanta-schedule"-->
                    <!--    >Atlanta</a-->
                    <!--  >-->
                    <!--  |-->
                    <!--  <a-->
                    <!--    class="load-modal-window"-->
                    <!--    href="/life/orientation/you-arrive/international-capp/savannah-schedule"-->
                    <!--    >Savannah</a-->
                    <!--  >-->
                    <!--</p>-->
                    <p class="rtecenter">
                      招生名额有限。为了您能充分考虑该计划，请完成申请并支付 850
                      美元的押金。剩余的 3, 100 美元用于支付 SCAD CAPP
                      计划费用，付款截止日期为 2022 年 7 月 1 日，星期五。
                    </p>

                    <p class="rtecenter">
                      如需了解更多，请访问
                      <a href="https://scad.edu/scadcapp">scad.edu/scadcapp</a
                      >。
                      如果您有疑问或者想要了解关于如何报名的更多信息，请发送电子邮件至
                      <a href="mailto:isso@scad.edu">isso@scad.edu</a> 或者
                      <a href="mailto:issoatl@scad.edu">issoatl@scad.edu</a>。
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <div class="hr"></div>
            </div>
          </div>
          <div class="row">
            <div class="inner-region">
              <div
                class="
                  panel-panel
                  row_23_left
                  col-sm-12 col-sm-offset-0 col-md-12 col-md-offset-0
                "
              >
                <div
                  class="
                    panel-pane
                    pane-custom pane-8
                    margin-top-sm-20 margin-bottom-all-40
                  "
                >
                  <div class="pane-content">
                    <h2>常见问题</h2>
                    <p>
                      了解有关该计划的其他信息，包括付款截止日期、上课地点等。
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="inner-region">
              <div
                class="
                  panel-panel
                  row_24_left
                  col-sm-12 col-sm-offset-0 col-md-12 col-md-offset-0
                "
              >
                <div class="panel-pane pane-custom pane-10">
                  <div class="pane-content">
                    <div style="padding-bottom: 60px">
                      <h4 style="margin-bottom: 0px; padding-bottom: 0px">
                        SCAD CAPP 计划为时多久？
                      </h4>
                      <div class="faq-line">&nbsp;</div>
                      <div class="faq-answer">
                        <p style="padding-top: 25px">
                          SCAD CAPP 计划为时四周，在大学入学和秋季学期之前举行。
                        </p>
                      </div>
                    </div>
                    <div style="padding-bottom: 20px">
                      <h4 style="margin-bottom: 0px; padding-bottom: 0px">
                        我是否有资格申请 SCAD CAPP？
                      </h4>
                      <div class="faq-line">&nbsp;</div>
                      <div class="faq-answer">
                        <p style="padding-top: 25px">
                          该计划包含针对非母语英语 (ESL)
                          豁免和非豁免学生的学术内容。欢迎所有新生参加！
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-12">
              <div class="hr"></div>
            </div>
          </div>

          <div class="row">
            <div class="inner-region">
              <div
                class="
                  panel-panel
                  row_23_left
                  col-sm-12 col-sm-offset-0 col-md-12 col-md-offset-0
                "
              >
                <div
                  class="
                    panel-pane
                    pane-custom pane-8
                    margin-top-sm-20 margin-bottom-all-40
                  "
                >
                  <div class="pane-content">
                    <h2>亚特兰大 CAPP 安排</h2>
                    <div>
                      <p>星期一和星期二，2022 年 8 月 1 日和 2 日</p>
                      <p>
                        SCAD CAPP 参与者抵达亚特兰大，登记宿舍入住。SCAD
                        国际学生服务办公室将根据亚特兰大机场的要求，于美国东部时间上午
                        9 点至晚上 10:30
                        之间提供接送服务。学生可于美国东部时间上午 9 点至晚上
                        10:30 之间的任何时间入住 SCAD 宿舍。
                      </p>
                      <p>8 月 3 日</p>
                      <p>举办入学和迎新活动。</p>
                      <p>8 月 4 日至 30 日</p>
                      <p>
                        学生在周一至周四上午 9
                        点至中午参加学术和/或英语作为第二语言课程。下午举办文化活动、研讨会和本地游。远足安排在以下周末举行：
                      </p>
                      <ul>
                        <li>8 月 12 日至 14 日</li>
                        <li>8 月 19 日至 21 日</li>
                        <li>8 月 26 日至 28 日</li>
                      </ul>
                      <p>8 月 31 日，星期三</p>
                      <p>
                        SCAD CAPP
                        结束。所有搬至新宿舍楼或校外宿舍的学生都可以获得帮助。
                      </p>
                      <p>
                        更多详情将在计划临近时公布。计划日程可能会有所更改。
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="inner-region">
              <div
                class="
                  panel-panel
                  row_23_left
                  col-sm-12 col-sm-offset-0 col-md-12 col-md-offset-0
                "
              >
                <div
                  class="
                    panel-pane
                    pane-custom pane-8
                    margin-top-sm-20 margin-bottom-all-40
                  "
                >
                  <div class="pane-content">
                    <h2>萨凡纳 CAPP 安排</h2>
                    <div>
                      <p>星期一和星期二，2022 年 8 月 1 日和 2 日</p>
                      <p>
                        SCAD CAPP 参与者抵达萨凡纳，登记宿舍入住。SCAD
                        国际学生服务办公室将根据萨凡纳机场的要求，于美国东部时间上午
                        9 点至晚上 10:30
                        之间提供接送服务。学生可于美国东部时间上午 9 点至晚上
                        10:30 之间的任何时间入住 SCAD 宿舍。
                      </p>
                      <p>8 月 3 日</p>
                      <p>举办入学和迎新活动。</p>
                      <p>8 月 4 日至 30 日</p>
                      <p>
                        学生在周一至周四上午 9
                        点至中午参加学术和/或英语作为第二语言课程。下午举办文化活动、研讨会和本地游。远足安排在以下周末举行：
                      </p>
                      <ul>
                        <li>8 月 12 日至 14 日</li>
                        <li>8 月 19 日至 21 日</li>
                        <li>8 月 26 日至 28 日</li>
                      </ul>
                      <p>8 月 31 日，星期三</p>
                      <p>
                        SCAD CAPP
                        结束。所有搬至新宿舍楼或校外宿舍的学生都可以获得帮助。
                      </p>
                      <p>
                        更多详情将在计划临近时公布。计划日程可能会有所更改。
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <goTop></goTop>
    <custFooter></custFooter>
  </div>
</template>

<script>
import menuBar from "../../components/menuBar/index.vue";
import custFooter from "@/components/footer/Footer";
export default {
  metaInfo: {
    title: "SCAD", // set a title
  },
  name: "InternationalCAPP",
  components: {
    menuBar,
    custFooter,
  },
  data: function () {
    return {};
  },
};
</script>

<style lang="css" src="./els-css/typography.gotham.css" scoped />
<style lang="less" scoped>
@import url(els-css/style.css);
@import url(els-css/global.small.768.css);
@import url(els-css/swarm_fullwidth.css);
@import url(els-css/svg.css);
@import url(els-css/display-modes.css);
@import url(els-css/life.css);

.faq-line {
  width: 70px;
  border-bottom: medium;
  border-bottom-color: #000000;
  border-bottom-style: solid;
}
.faq-answer {
  border-top: thin;
  border-top-color: #eaeaea;
  border-top-style: solid;
}

@media (min-width: 992px) {
  .reduce {
    font-size: 21px !important;
    line-height: 23px !important;
  }
}
/* Large screens */
@media (min-width: 1200px) {
  .reduce {
    font-size: 28px !important;
    line-height: 30px !important;
  }
}

@media (max-width: 800px) {
  .main-content {
    padding: 0;

    .row {
      margin: 0;
    }
  }
}
</style>
