import { render, staticRenderFns } from "./InternationalCAPP.vue?vue&type=template&id=344f33d8&scoped=true&"
import script from "./InternationalCAPP.vue?vue&type=script&lang=js&"
export * from "./InternationalCAPP.vue?vue&type=script&lang=js&"
import style0 from "./els-css/typography.gotham.css?vue&type=style&index=0&id=344f33d8&lang=css&scoped=true&"
import style1 from "./InternationalCAPP.vue?vue&type=style&index=1&id=344f33d8&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "344f33d8",
  null
  
)

export default component.exports