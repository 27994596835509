<template>
  <div class="cust-container">
    <menuBar></menuBar>
    <div>
      <div class="banner">
        <img src="@/assets/images/stem/banner.jpg" />
      </div>

      <div class="content" v-if="$i18n.locale === 'en'">
        <div class="title">Study STEM at SCAD</div>
        <div class="desc">
          <ul>
            <li>
              SCAD programs are top ranked by Red Dot, Designintelligence, The
              Business of Fashion, The Hollywood Reporter, and the Rockies,
              among others.
            </li>
            <li>
              Students work on real-world solutions with Google, Disney, and BMW
              through SCADpro, the university's in-housecreative consultancy.
            </li>
            <li>
              99% of SCAD alumni are employed or pursuing further education and
              many work at companies like IBM, Facebook，Pixar, Instagram,
              Netflix, NASA, and more.↑
            </li>
          </ul>
        </div>
        <div class="line" style=""></div>

        <div class="title-list">
          <div class="title-row">
            <div>Advertising</div>
            <div>Architecture</div>
            <div>Design for sustainability</div>
            <div>Furniture design</div>
          </div>
          <div class="title-row">
            <div>Animation</div>
            <div>Creative business leadership</div>
            <div>Design management</div>
          </div>
          <div class="title-row">
            <div>Graphic design</div>
            <div>Industrial design</div>
            <div>Luxury and brand management</div>
          </div>
          <div class="title-row">
            <div>Immersive reality</div>
            <div>Interactive design and game development</div>
          </div>
          <div class="title-row">
            <div>Service design</div>
            <div>User experience (UX) design</div>
            <div>Visual effects</div>
          </div>
          <div class="title-row">
            <div>Service design</div>
            <div>User experience (UX) design</div>
          </div>
        </div>
      </div>

      <div class="content" v-if="$i18n.locale === 'zh'">
        <div class="title">在 SCAD 学习 STEM</div>
        <div class="desc">
          <ul>
            <li>
              SCAD 课程在各大专业平台排名中名列前茅，如Red
              Dot（红点奖）、DesignIntelligence（《DI》）、The Business of
              Fashion（时尚圣经）、The Hollywood Reporter（《好莱坞报道》）、the
              Rockies等等。
            </li>
            <li>
              学生们在大学内设的创意咨询工作室 SCADpro，与
              Google（谷歌）、Disney（迪士尼）和BMW（宝马）一起合作，为现实世界探寻解决方案。
            </li>
            <li>
              99%的SCAD校友都找到了理想的工作或选择继续深造，而且很大一部分都进入了世界
              500 强，比如IBM、Facebook、Pixar、 Instagram、Netflix、 NASA等。
            </li>
          </ul>
        </div>
        <div class="line" style=""></div>

        <div class="title-list">
          <div class="title-row">
            <div>广告</div>
            <div>建筑学</div>
            <div>可持续性设计</div>
            <div>家具设计</div>
          </div>
          <div class="title-row">
            <div>动画</div>
            <div>创意商业领导力</div>
            <div>设计管理</div>
          </div>
          <div class="title-row">
            <div>平面设计</div>
            <div>工业设计</div>
            <div>奢侈品与品牌管理</div>
          </div>
          <div class="title-row">
            <div>沉浸式现实</div>
            <div>互动设计与游戏开发</div>
          </div>
          <div class="title-row">
            <div>视觉效果</div>
            <div>服务设计</div>
            <div>用户体验（UX设计）</div>
          </div>
        </div>
      </div>
    </div>

    <goTop></goTop>
    <custFooter></custFooter>
  </div>
</template>

<script>
import menuBar from "../../components/menuBar/index.vue";
import custFooter from "@/components/footer/Footer";
export default {
  metaInfo: {
    title: "SCAD", // set a title
  },
  name: "WhyChooseSCAD",
  components: {
    menuBar,
    custFooter,
  },
  data: function () {
    return {
      showVideo: false,
    };
  },
};
</script>

<style lang="less" scoped>
@import url(Stem.less);
</style>
