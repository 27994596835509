<template>
  <div class="programs">
    <menuBar></menuBar>
    <div class="programs-header-container">
      <img
        class="banner"
        src="https://abcam.oss-cn-shanghai.aliyuncs.com/40b69202201191322236564.jpg"
      />
    </div>
    <div
      class="body-container"
      style="padding-bottom: 50px"
      v-if="$i18n.locale === 'zh'"
    >
      <div>
        <h1 class="title">其他高等排名</h1>

        <div class="content">
          <ul>
            <li>
              <p>#1 全球排名前 50 的创意媒体与娱乐的学院（2021 年新秀奖）</p>
            </li>
            <li>
              <p>#1 美国 10 所最佳艺术学校（2021 年Art & Object奖）</p>
            </li>
            <li>
              <p>
                #1 国际学生专业重点机构排名前 40 （2021 年连续三年被Open
                Doors评选）
              </p>
            </li>
            <li>
              <p>按地区划分的2022 年最佳大学（2021 年普林斯顿评论）</p>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div
      class="body-container"
      style="padding-bottom: 50px"
      v-if="$i18n.locale === 'en'"
    >
      <div>
        <h1 class="title">Top Rankings</h1>

        <div class="content">
          <ul>
            <li>
              <p>
                #1 Top 50 Creative Media & Entertainment Schools and Colleges in
                the World(The Rookies, 2021)
              </p>
            </li>
            <li>
              <p>#1 10 Best Art Schools in the U.S. (Art & Object, 2021)</p>
            </li>
            <li>
              <p>
                #1 Top 40 Specialty Focus Institutions Hosting International
                Students (Open Doors, three consecutive years, 2021)
              </p>
            </li>
            <li>
              <p>
                The 2022 Best Colleges: Region by Region(The Princeton Review,
                2021)
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <goTop></goTop>
    <custFooter></custFooter>
  </div>
</template>

<script>
import menuBar from "../../components/menuBar/index.vue";
import custFooter from "@/components/footer/Footer";
import i18n from "@/i18n";
// import router from "@/router";
export default {
  name: "TopRankings",
  metaInfo: {
    title: i18n.t("Top Rankings"), // set a title
  },
  components: {
    menuBar,
    custFooter,
  },
  data: function () {
    return {
      obj: {},
      lang: this.$i18n.locale,
      id: null,
      name: null,
      areaList: [],
    };
  },
  mounted() {},
};
</script>
<style lang="less" scoped>
@import "Programs.less";
</style>

<style>
.content img {
  max-width: 100% !important;
}
</style>
