<template>
  <div class="body">
    <formMenuBar></formMenuBar>
    <div class="header-container">
      <div class="title">Graduate Application</div>
      <div class="tips">
        Students who have graduated or anticipate graduating from a 4-year
        college or university. This also includes graduate certificates.
      </div>
    </div>

    <div class="content-container" id="content-container">
      <div class="form-nav-container">
        <div class="form-nav-list">
          <div
            class="form-nav-item"
            @click="formNavClick('Personal Information', 1)"
            :class="formIndex == 1 ? 'active' : ''"
          >
            <span>1</span>Personal Information
          </div>
          <div
            class="form-nav-item"
            @click="formNavClick('Campus / Curriculum Information', 2)"
            :class="formIndex == 2 ? 'active' : ''"
          >
            <span>2</span>Campus / Curriculum Information
          </div>
          <div
            class="form-nav-item"
            @click="formNavClick('Education History', 3)"
            :class="formIndex == 3 ? 'active' : ''"
          >
            <span>3</span>Education History
          </div>
          <div
            class="form-nav-item"
            @click="formNavClick('Guardian Information', 4)"
            :class="formIndex == 4 ? 'active' : ''"
          >
            <span>4</span>Guardian Information
          </div>
          <div
            class="form-nav-item"
            @click="formNavClick('Additional Information', 5)"
            :class="formIndex == 5 ? 'active' : ''"
          >
            <span>5</span>Additional Information
          </div>

          <div
            class="form-nav-item"
            @click="formNavClick('Summary', 6)"
            :class="formIndex == 6 ? 'active' : ''"
          >
            <span>6</span>Summary
          </div>

          <div
            class="form-nav-item"
            @click="formNavClick('Submit', 7)"
            :class="formIndex == 7 ? 'active' : ''"
          >
            <span>7</span>Submit
          </div>
        </div>
      </div>
      <div class="form-container">
        <div class="form-header-container" v-if="formTitle != ''">
          <div class="form-title">{{ formTitle }}</div>
          <div class="form-title-tips">Fields marked with * are required</div>
        </div>

        <el-form
          :rules="rules"
          ref="ruleForm1"
          :model="formData"
          v-if="formIndex === 1"
          class="form-list"
        >
          <div class="form-row form-row-col-2">
            <el-form-item class="form-item" prop="firstname">
              <div class="form-label">First name (Given name) *</div>
              <el-input v-model="formData.firstname"></el-input>
            </el-form-item>

            <el-form-item class="form-item" prop="familyname">
              <div class="form-label">Last name (Family name) *</div>
              <el-input v-model="formData.familyname"></el-input>
            </el-form-item>
          </div>

          <div class="form-row form-row-col-2">
            <el-form-item class="form-item" prop="email">
              <div class="form-label">Email address *</div>
              <el-input v-model="formData.email"></el-input>
            </el-form-item>

            <el-form-item class="form-item" prop="confirmemail">
              <div class="form-label">Confirm Email address *</div>
              <el-input v-model="formData.confirmemail"></el-input>
            </el-form-item>
          </div>

          <div class="form-row form-row-col-1">
            <el-form-item class="form-item" prop="company">
              <div class="form-label">School Name *</div>
              <el-input v-model="formData.company"></el-input>
            </el-form-item>
          </div>

          <div class="form-row form-row-col-1">
            <el-form-item class="form-item" prop="dateofbirth">
              <div class="form-label">Date of birth *</div>
              <el-date-picker
                :editable="false"
                value-format="yyyy-MM-dd"
                v-model="formData.dateofbirth"
                style="width: 100%"
                type="date"
                placeholder="Choose..."
              >
              </el-date-picker>
              <!--<div class="form-date">-->
              <!--  <el-select class="form-date-month" placeholder="Month">-->
              <!--    <el-option-->
              <!--      v-for="n in 12"-->
              <!--      :label="n"-->
              <!--      :value="n"-->
              <!--      :key="n"-->
              <!--    ></el-option>-->
              <!--  </el-select>-->
              <!--  <el-select class="form-date-day" placeholder="Day">-->
              <!--    <el-option label="1" value="1"></el-option>-->
              <!--  </el-select>-->
              <!--  <el-select class="form-date-year" placeholder="Year">-->
              <!--    <el-option label="2021" value="2021"></el-option>-->
              <!--    <el-option label="2021" value="2021"></el-option>-->
              <!--  </el-select>-->
              <!--</div>-->
            </el-form-item>
          </div>

          <div class="form-row form-row-col-1">
            <el-form-item class="form-item" prop="countryc">
              <div class="form-label">Country *</div>
              <el-select
                v-model="formData.countryc"
                class="w-100"
                placeholder="Choose..."
              >
                <el-option
                  v-for="item in countrys"
                  :label="item.name"
                  :value="item.code"
                  :key="item.code"
                ></el-option>
              </el-select>
            </el-form-item>
          </div>

          <div class="form-row form-row-col-1">
            <el-form-item class="form-item" prop="streetaddress">
              <div class="form-label">Street address *</div>
              <el-input
                v-model="formData.streetaddress"
                placeholder="Lane, road name, apartment, city"
              ></el-input>
            </el-form-item>
          </div>

          <div class="form-row form-row-col-1">
            <el-form-item class="form-item" prop="mobile">
              <div class="form-label">Phone number *</div>
              <el-input v-model="formData.mobile"></el-input>
            </el-form-item>
          </div>

          <div class="form-row form-row-col-1">
            <el-form-item class="form-item" prop="areyouauscitizen">
              <div class="form-label">Are you a U.S. citizen? *</div>
              <el-radio-group v-model="formData.areyouauscitizen">
                <el-radio label="TRUE" value="TRUE">Yes</el-radio>
                <el-radio label="FALSE" value="FALSE">No</el-radio>
              </el-radio-group>
            </el-form-item>
          </div>

          <div
            class="form-row form-row-col-1"
            v-if="formData.areyouauscitizen == 'FALSE'"
          >
            <el-form-item class="form-item" prop="countryofcitizenship">
              <div class="form-label">Country of citizenship *</div>
              <el-select
                v-model="formData.countryofcitizenship"
                class="w-100"
                placeholder="Choose..."
              >
                <el-option
                  v-for="item in countryOfCitizenship"
                  :label="item.name"
                  :value="item.name"
                  :key="item.name"
                ></el-option>
              </el-select>
            </el-form-item>
          </div>

          <!--<div class="form-row form-row-col-1">-->
          <!--  <div class="form-item">-->
          <!--    <div class="form-label">Visa type</div>-->
          <!--    <el-select class="w-100" placeholder="Choose...">-->
          <!--      <el-option label="Transfer" value="Transfer"></el-option>-->
          <!--    </el-select>-->
          <!--  </div>-->
          <!--</div>-->
        </el-form>

        <el-form
          :rules="rules"
          ref="ruleForm2"
          :model="formData"
          v-if="formIndex === 2"
          class="form-list"
        >
          <div class="form-row form-row-col-1">
            <el-form-item class="form-item" prop="intendedlocation">
              <div class="form-label">Intended Location *</div>
              <el-radio-group
                v-model="formData.intendedlocation"
                @change="intendedlocationChange"
              >
                <el-radio label="A" value="A">Atlanta</el-radio>
                <el-radio label="M" value="M">Savannah</el-radio>
                <el-radio label="O" value="O">SCADnow (online)</el-radio>
              </el-radio-group>
            </el-form-item>
          </div>

          <div class="form-row form-row-col-1">
            <el-form-item class="form-item" prop="termofentry">
              <div class="form-label">Term of Entry *</div>
              <el-select
                v-model="formData.termofentry"
                class="w-100"
                placeholder="Choose..."
              >
                <el-option
                  label="Winter 2023 (January)"
                  value="202320"
                ></el-option>
                <el-option
                  label="Spring 2023 (March)"
                  value="202330"
                ></el-option>
                <el-option
                  label="Summer 2023 (June)"
                  value="202340"
                ></el-option>
                <el-option
                  label="Fall 2024 (September)"
                  value="202410"
                ></el-option>
                <el-option
                  label="Winter 2024 (January)"
                  value="202420"
                ></el-option>
                <el-option
                  label="Spring 2024 (March)"
                  value="202430"
                ></el-option>
                <el-option
                  label="Summer 2024 (June)"
                  value="202440"
                ></el-option>
                <el-option
                  label="Fall 2025 (September)"
                  value="202510"
                ></el-option>
                <el-option
                  label="Winter 2025 (January)"
                  value="202520"
                ></el-option>
              </el-select>
            </el-form-item>
          </div>

          <div
            class="form-row form-row-col-1"
            v-if="intendedEnrollmentStatus.length > 0"
          >
            <el-form-item class="form-item" prop="enrollmentstatus">
              <div class="form-label">Enrollment Status *</div>

              <el-radio-group v-model="formData.enrollmentstatus">
                <el-radio
                  :label="item.enrollment_status_degree"
                  v-for="(item, index) in intendedEnrollmentStatus"
                  :key="index"
                ></el-radio>
              </el-radio-group>
            </el-form-item>
          </div>

          <div
            class="form-row form-row-col-1"
            v-if="
              formData.intendedlocation != '' && formData.enrollmentstatus != ''
            "
          >
            <el-form-item
              class="form-item"
              prop="intendedprogramofstudentfirstchoice"
            >
              <div class="form-label">
                Intended Program of student first choice *
              </div>

              <el-select
                v-model="formData.intendedprogramofstudentfirstchoice"
                class="w-100"
                placeholder="Choose..."
              >
                <el-option
                  v-for="item in intendedProgram"
                  :label="item.programs"
                  :value="item.programs_value"
                  :key="item.programs_value"
                ></el-option>
              </el-select>
            </el-form-item>
          </div>

          <div
            class="form-row form-row-col-1"
            v-if="
              formData.intendedlocation != '' && formData.enrollmentstatus != ''
            "
          >
            <el-form-item
              class="form-item"
              prop="intendedprogramofstudentfirstchoice"
            >
              <div class="form-label">
                Intended Program of student second choice *
              </div>

              <el-select
                v-model="formData.intendedprogramofstudentsecondchoice"
                class="w-100"
                placeholder="Choose..."
              >
                <el-option
                  v-for="item in intendedProgram"
                  :label="item.programs"
                  :value="item.programs_value"
                  :key="item.programs_value"
                ></el-option>
              </el-select>
            </el-form-item>
          </div>

          <div
            class="form-row form-row-col-1"
            v-if="
              formData.intendedlocation != '' && formData.enrollmentstatus != ''
            "
          >
            <el-form-item
              class="form-item"
              prop="intendedprogramofstudentfirstchoice"
            >
              <div class="form-label">
                Intended Program of student third choice *
              </div>

              <el-select
                v-model="formData.intendedprogramofstudentthirdchoice"
                class="w-100"
                placeholder="Choose..."
              >
                <el-option
                  v-for="item in intendedProgram"
                  :label="item.programs"
                  :value="item.programs_value"
                  :key="item.programs_value"
                ></el-option>
              </el-select>
            </el-form-item>
          </div>
        </el-form>

        <el-form
          :rules="rules"
          ref="ruleForm3"
          :model="formData"
          v-if="formIndex === 3"
          class="form-list"
        >
          <div class="form-row form-row-col-1">
            <el-form-item class="form-item" prop="nameofuniversityattended">
              <div class="form-label">Name of University attended *</div>
              <el-input v-model="formData.nameofuniversityattended"></el-input>
            </el-form-item>
          </div>

          <div class="form-row form-row-col-1">
            <el-form-item class="form-item" prop="universitycity">
              <div class="form-label">City *</div>
              <el-input v-model="formData.universitycity"></el-input>
            </el-form-item>
          </div>

          <div class="form-row form-row-col-1">
            <el-form-item
              class="form-item"
              prop="dateattendedfromuniversityonly"
            >
              <div class="form-label">Date attended from *</div>
              <el-date-picker
                :editable="false"
                value-format="yyyy-MM-dd"
                v-model="formData.dateattendedfromuniversityonly"
                style="width: 100%"
                type="date"
                placeholder="Choose..."
              >
              </el-date-picker>
            </el-form-item>
          </div>

          <div class="form-row form-row-col-1">
            <el-form-item class="form-item" prop="dateattendedtouniversityonly">
              <div class="form-label">Date attended to *</div>
              <el-date-picker
                :editable="false"
                value-format="yyyy-MM-dd"
                v-model="formData.dateattendedtouniversityonly"
                style="width: 100%"
                type="date"
                placeholder="Choose..."
              >
              </el-date-picker>
            </el-form-item>
          </div>

          <div class="form-row form-row-col-1">
            <el-form-item class="form-item" prop="degreeuniversityonly">
              <div class="form-label">Degree *</div>
              <el-select
                v-model="formData.degreeuniversityonly"
                class="w-100"
                placeholder="Choose..."
              >
                <el-option
                  label="Associate of Arts"
                  value="Associate of Arts"
                ></el-option>
                <el-option
                  label="Associate of Applied Science"
                  value="Associate of Applied Science"
                ></el-option>
                <el-option
                  label="Bachelor of Arts"
                  value="Bachelor of Arts"
                ></el-option>
                <el-option
                  label="Bachelor of Architecture"
                  value="Bachelor of Architecture"
                ></el-option>
                <el-option
                  label="Bachelor Business Administration"
                  value="Bachelor Business Administration"
                ></el-option>
                <el-option
                  label="Bachelor of Design"
                  value="Bachelor of Design"
                ></el-option>
                <el-option
                  label="Bachelor of Fine Arts"
                  value="Bachelor of Fine Arts"
                ></el-option>
                <el-option
                  label="Bachelor of Science"
                  value="Bachelor of Science"
                ></el-option>
                <el-option
                  label="Graduate Certificate"
                  value="Graduate Certificate"
                ></el-option>
                <el-option
                  label="Bachelor of Fine Arts"
                  value="Bachelor of Fine Arts"
                ></el-option>

                <el-option
                  label="Master of Arts in Teaching"
                  value="Master of Arts in Teaching"
                ></el-option>
                <el-option
                  label="Master Business Administration"
                  value="Master Business Administration"
                ></el-option>
                <el-option
                  label="Master of Design"
                  value="Master of Design"
                ></el-option>
                <el-option
                  label="Master of Education"
                  value="Master of Education"
                ></el-option>
                <el-option
                  label="Master of Fine Arts"
                  value="Master of Fine Arts"
                ></el-option>
                <el-option
                  label="Master of Fine Arts Architecture"
                  value="Master of Fine Arts Architecture"
                ></el-option>
                <el-option
                  label="Master of Science"
                  value="Master of Science"
                ></el-option>
                <el-option
                  label="Master of Urban Design"
                  value="Master of Urban Design"
                ></el-option>
                <el-option label="Doctorate" value="Doctorate"></el-option>
                <el-option
                  label="Undergraduate Certificate"
                  value="Undergraduate Certificate"
                ></el-option>

                <el-option label="Other" value="Other"></el-option>
              </el-select>
            </el-form-item>
          </div>

          <div class="form-row form-row-col-1">
            <el-form-item
              class="form-item"
              prop="dateofgraduationuniversityonly"
            >
              <div class="form-label">Date of graduation *</div>
              <!--<el-input></el-input>-->
              <el-date-picker
                :editable="false"
                value-format="yyyy-MM-dd"
                v-model="formData.dateofgraduationuniversityonly"
                style="width: 100%"
                type="date"
                placeholder="Choose..."
              >
              </el-date-picker>
            </el-form-item>
          </div>

          <div class="form-row form-row-col-1">
            <el-form-item
              class="form-item"
              prop="probationandsuspendedexpelled"
            >
              <div class="form-label">
                Have you ever been placed on probation, suspended, or expelled
                from an educational institute since the 9th grade? *
              </div>
              <el-select
                v-model="formData.probationandsuspendedexpelled"
                class="w-100"
                placeholder="Choose..."
              >
                <el-option label="No" value="FALSE"></el-option>
                <el-option label="Yes" value="TRUE"></el-option>
              </el-select>
            </el-form-item>
          </div>

          <div class="form-row form-row-col-1">
            <el-form-item class="form-item" prop="convicted">
              <div class="form-label">
                Have you ever been convicted of a felony or crime? *
              </div>
              <el-select
                v-model="formData.convicted"
                class="w-100"
                placeholder="Choose..."
              >
                <el-option label="No" value="FALSE"></el-option>
                <el-option label="Yes" value="TRUE"></el-option>
              </el-select>
            </el-form-item>
          </div>
        </el-form>

        <el-form
          :rules="rules"
          ref="ruleForm4"
          :model="formData"
          v-if="formIndex === 4"
          class="form-list"
        >
          <div class="form-row form-row-col-1">
            <el-form-item class="form-item" prop="guardianrelationship">
              <div class="form-label">Guardian relationship *</div>
              <el-select
                v-model="formData.guardianrelationship"
                class="w-100"
                placeholder="Choose..."
              >
                <el-option label="Mother" value="Mother"></el-option>
                <el-option label="Father" value="Father"></el-option>
                <el-option label="Aunt" value="Aunt"></el-option>
                <el-option label="Brother" value="Brother"></el-option>
                <el-option label="Child" value="Child"></el-option>
                <el-option label="Cousin" value="Cousin"></el-option>
                <el-option label="Friend" value="Friend"></el-option>
                <el-option label="Grandparent" value="Grandparent"></el-option>
                <el-option label="Guardian" value="Guardian"></el-option>
                <el-option label="Neighbor" value="Neighbor"></el-option>
                <el-option label="Nephew" value="Nephew"></el-option>
                <el-option label="Niece" value="Niece"></el-option>
                <el-option label="Relative" value="Relative"></el-option>
                <el-option
                  label="Significant Other"
                  value="Significant Other"
                ></el-option>
                <el-option label="Sister" value="Sister"></el-option>
                <el-option label="Spouse" value="Spouse"></el-option>
                <el-option label="Uncle" value="Uncle"></el-option>
              </el-select>
            </el-form-item>
          </div>

          <div class="form-row form-row-col-1">
            <el-form-item class="form-item" prop="guardianemail">
              <div class="form-label">Guardian email *</div>
              <el-input v-model="formData.guardianemail"></el-input>
            </el-form-item>
          </div>

          <div class="form-row form-row-col-2">
            <el-form-item class="form-item" prop="guardianfirstname">
              <div class="form-label">Guardian first name *</div>
              <el-input v-model="formData.guardianfirstname"></el-input>
            </el-form-item>

            <el-form-item class="form-item" prop="guardianlastname">
              <div class="form-label">Guardian last name *</div>
              <el-input v-model="formData.guardianlastname"></el-input>
            </el-form-item>
          </div>

          <div class="form-row form-row-col-1">
            <el-form-item class="form-item" prop="guardianaddressline1">
              <div class="form-label">Guardian address line 1 *</div>
              <el-input
                v-model="formData.guardianaddressline1"
                placeholder="Lane, road name, apartment, city"
              ></el-input>
            </el-form-item>
          </div>

          <div class="form-row form-row-col-1">
            <el-form-item class="form-item" prop="guardianphone">
              <div class="form-label">Primary phone number *</div>
              <el-input v-model="formData.guardianphone"></el-input>
            </el-form-item>
          </div>
        </el-form>

        <el-form
          :rules="rules"
          ref="ruleForm5"
          :model="formData"
          v-if="formIndex === 5"
          class="form-list"
        >
          <div class="form-row form-row-col-1">
            <el-form-item class="form-item" prop="contactconsent">
              <div class="form-label">
                I would like to stay up to date with the latest news about SCAD
                events, exhibitions, opportunities and exlusive content. Please
                contact me via:
              </div>
              <el-radio-group v-model="formData.contactconsent">
                <el-radio label="email">Email</el-radio>
                <el-radio label="phone">Phone</el-radio>
                <el-radio label="text"
                  >SMS/text message (Standard texting rates may apply)</el-radio
                >
              </el-radio-group>
            </el-form-item>
          </div>

          <div class="form-row form-row-col-1">
            <el-form-item class="form-item" prop="scadrulesagreementyesorno">
              <div class="form-label">
                If I am admitted and enroll at SCAD, I agree to abide by the
                rules and regulations of the Savannah College of Art and Design.
                *
              </div>
              <el-select
                v-model="formData.scadrulesagreementyesorno"
                class="w-100"
                placeholder="Choose..."
              >
                <el-option label="Yes" value="TRUE"></el-option>
                <el-option label="No" value="FALSE"></el-option>
              </el-select>
            </el-form-item>
          </div>

          <!--<div class="description">-->
          <!--  <div>-->
          <!--    By clicking the “Save and Continue” button, you acknowledge that-->
          <!--    you have read and understand the-->
          <!--    <a href="https://www.scad.edu/privacy">privacy policy</a>.-->
          <!--  </div>-->

          <!--  <div>-->
          <!--    By providing us with your mobile phone number, you are consenting-->
          <!--    to receiving texts from SCAD regarding admission policies,-->
          <!--    reminders and events. Individuals may opt-out of text messaging at-->
          <!--    any time.-->
          <!--  </div>-->
          <!--</div>-->
        </el-form>

        <el-form
          :rules="rules"
          ref="ruleForm6"
          :model="formData"
          v-if="formIndex === 6"
          class="form-list ruleForm6"
        >
          <div class="form-row form-row-col-1">
            <!--Personal Information-->
            <el-form-item class="form-item">
              <div class="form-label form-row-title">
                Personal Information
                <a class="edit" @click="formNavClick2(1)">Edit</a>
              </div>
              <div class="confirm-form-content-list">
                <div class="confirm-form-content-item">
                  <div class="confirm-form-content-name">
                    First name (Given name)
                  </div>
                  <div>{{ formData.firstname }}</div>
                </div>

                <div class="confirm-form-content-item">
                  <div class="confirm-form-content-name">
                    Last name (Family name)
                  </div>
                  <div>{{ formData.familyname }}</div>
                </div>

                <div class="confirm-form-content-item">
                  <div class="confirm-form-content-name">Email address</div>
                  <div>{{ formData.email }}</div>
                </div>

                <div class="confirm-form-content-item">
                  <div class="confirm-form-content-name">School Name</div>
                  <div>{{ formData.company }}</div>
                </div>

                <div class="confirm-form-content-item">
                  <div class="confirm-form-content-name">Date of birth</div>
                  <div>{{ formData.dateofbirth }}</div>
                </div>

                <div class="confirm-form-content-item">
                  <div class="confirm-form-content-name">Country</div>
                  <div>{{ formData.countryc }}</div>
                </div>

                <div class="confirm-form-content-item">
                  <div class="confirm-form-content-name">Street address</div>
                  <div>{{ formData.streetaddress }}</div>
                </div>

                <div class="confirm-form-content-item">
                  <div class="confirm-form-content-name">Phone number</div>
                  <div>{{ formData.mobile }}</div>
                </div>
                <div class="confirm-form-content-item">
                  <div class="confirm-form-content-name">
                    Are you a U.S. citizen?
                  </div>
                  <div>
                    {{ formData.areyouauscitizen == "TRUE" ? "Yes" : "No" }}
                  </div>
                </div>
              </div>
            </el-form-item>

            <!--Campus / Curriculum Information-->
            <el-form-item class="form-item">
              <div class="form-label form-row-title">
                Campus / Curriculum Information

                <a class="edit" @click="formNavClick2(2)">Edit</a>
              </div>
              <div class="confirm-form-content-list">
                <div class="confirm-form-content-item">
                  <div class="confirm-form-content-name">Intended Location</div>
                  <div v-if="formData.intendedlocation == 'A'">Atlanta</div>
                  <div v-if="formData.intendedlocation == 'M'">Savannah</div>
                  <div v-if="formData.intendedlocation == 'O'">
                    SCADnow (online)
                  </div>
                </div>

                <div class="confirm-form-content-item">
                  <div class="confirm-form-content-name">Term of Entry</div>
                  <div>{{ formData.termofentry }}</div>
                </div>

                <div class="confirm-form-content-item">
                  <div class="confirm-form-content-name">Enrollment Status</div>
                  <div
                    v-for="item in intendedEnrollmentStatus"
                    :key="item.enrollment_status_degree"
                  >
                    <div
                      v-if="
                        formData.enrollmentstatus ==
                        item.enrollment_status_degree
                      "
                    >
                      {{ item.enrollment_status_degree }}
                    </div>
                  </div>
                </div>

                <div class="confirm-form-content-item">
                  <div class="confirm-form-content-name">
                    Intended Program of student first choice
                  </div>
                  <div
                    v-for="item in intendedProgram"
                    :key="item.programs_value"
                  >
                    <div
                      v-if="
                        formData.intendedprogramofstudentfirstchoice ==
                        item.programs_value
                      "
                    >
                      {{ item.programs }}
                    </div>
                  </div>
                </div>

                <div class="confirm-form-content-item">
                  <div class="confirm-form-content-name">
                    Intended Program of student second choice
                  </div>
                  <div
                    v-for="item in intendedProgram"
                    :key="item.programs_value"
                  >
                    <div
                      v-if="
                        formData.intendedprogramofstudentsecondchoice ==
                        item.programs_value
                      "
                    >
                      {{ item.programs }}
                    </div>
                  </div>
                </div>

                <div class="confirm-form-content-item">
                  <div class="confirm-form-content-name">
                    Intended Program of student third choice
                  </div>
                  <div
                    v-for="item in intendedProgram"
                    :key="item.programs_value"
                  >
                    <div
                      v-if="
                        formData.intendedprogramofstudentthirdchoice ==
                        item.programs_value
                      "
                    >
                      {{ item.programs }}
                    </div>
                  </div>
                </div>
              </div>
            </el-form-item>

            <!--Education History-->
            <el-form-item class="form-item">
              <div class="form-label form-row-title">
                Education History
                <a class="edit" @click="formNavClick2(3)">Edit</a>
              </div>
              <div class="confirm-form-content-list">
                <div class="confirm-form-content-item">
                  <div class="confirm-form-content-name">
                    Name of University attended
                  </div>
                  <div>{{ formData.nameofuniversityattended }}</div>
                </div>

                <div class="confirm-form-content-item">
                  <div class="confirm-form-content-name">City</div>
                  <div>{{ formData.universitycity }}</div>
                </div>
                <div class="confirm-form-content-item">
                  <div class="confirm-form-content-name">
                    Date attended from
                  </div>
                  <div>{{ formData.dateattendedfromuniversityonly }}</div>
                </div>
                <div class="confirm-form-content-item">
                  <div class="confirm-form-content-name">Date attended to</div>
                  <div>{{ formData.dateattendedtouniversityonly }}</div>
                </div>
                <div class="confirm-form-content-item">
                  <div class="confirm-form-content-name">Degree</div>
                  <div>{{ formData.degreeuniversityonly }}</div>
                </div>
                <div class="confirm-form-content-item">
                  <div class="confirm-form-content-name">
                    Date of graduation
                  </div>
                  <div>{{ formData.dateofgraduationuniversityonly }}</div>
                </div>
                <div class="confirm-form-content-item">
                  <div class="confirm-form-content-name">
                    Have you ever been placed on probation, suspended, or
                    expelled from an educational institute since the 9th grade?
                  </div>
                  <div>
                    {{
                      formData.probationandsuspendedexpelled == "TRUE"
                        ? "Yes"
                        : "No"
                    }}
                  </div>
                </div>
                <div class="confirm-form-content-item">
                  <div class="confirm-form-content-name">
                    Have you ever been convicted of a felony or crime?
                  </div>
                  <div>{{ formData.convicted == "TRUE" ? "Yes" : "No" }}</div>
                </div>
              </div>
            </el-form-item>

            <!--Guardian Information-->
            <el-form-item class="form-item">
              <div class="form-label form-row-title">
                Guardian Information
                <a class="edit" @click="formNavClick2(4)">Edit</a>
              </div>
              <div class="confirm-form-content-list">
                <div class="confirm-form-content-item">
                  <div class="confirm-form-content-name">
                    Guardian relationship
                  </div>
                  <div>{{ formData.guardianrelationship }}</div>
                </div>

                <div class="confirm-form-content-item">
                  <div class="confirm-form-content-name">Guardian email</div>
                  <div>{{ formData.guardianemail }}</div>
                </div>
                <div class="confirm-form-content-item">
                  <div class="confirm-form-content-name">
                    Guardian first name
                  </div>
                  <div>{{ formData.guardianfirstname }}</div>
                </div>
                <div class="confirm-form-content-item">
                  <div class="confirm-form-content-name">
                    Guardian last name
                  </div>
                  <div>{{ formData.guardianlastname }}</div>
                </div>
                <div class="confirm-form-content-item">
                  <div class="confirm-form-content-name">
                    Guardian address line 1
                  </div>
                  <div>{{ formData.guardianaddressline1 }}</div>
                </div>
                <div class="confirm-form-content-item">
                  <div class="confirm-form-content-name">
                    Primary phone number
                  </div>
                  <div>{{ formData.guardianphone }}</div>
                </div>
              </div>
            </el-form-item>

            <!--Additional Information-->
            <el-form-item class="form-item">
              <div class="form-label form-row-title">
                Additional Information

                <a class="edit" @click="formNavClick2(5)">Edit</a>
              </div>
              <div class="confirm-form-content-list">
                <div class="confirm-form-content-item">
                  <div class="confirm-form-content-name">
                    I would like to stay up to date with the latest news about
                    SCAD events, exhibitions, opportunities and exlusive
                    content. Please contact me via:
                  </div>
                  <div v-if="formData.contactconsent == 'email'">Email</div>
                  <div v-if="formData.contactconsent == 'phone'">Phone</div>
                  <div v-if="formData.contactconsent == 'text'">
                    SMS/text message (Standard texting rates may apply)
                  </div>
                </div>

                <div class="confirm-form-content-item">
                  <div class="confirm-form-content-name">
                    If I am admitted and enroll at SCAD, I agree to abide by the
                    rules and regulations of the Savannah College of Art and
                    Design.
                  </div>
                  <div>
                    {{
                      formData.scadrulesagreementyesorno == "TRUE"
                        ? "Yes"
                        : "No"
                    }}
                  </div>
                </div>
              </div>
            </el-form-item>
          </div>
        </el-form>

        <el-form
          :rules="rules"
          ref="ruleForm5"
          :model="formData"
          v-if="formIndex === 7"
          class="form-list"
        >
          <div class="form-row form-row-col-1">
            <el-form-item class="form-item">
              <div class="form-label">
                If you have an Application Promo Code, please enter your code:
              </div>
              <el-input v-model="formData.promocode"></el-input>
            </el-form-item>
          </div>
          <div class="description" style="max-width: 800px">
            Your application is not complete until you have submitted the
            application, to submit your application, please click “Submit My
            Application”.
          </div>

          <div class="form-btns" style="max-width: 800px; margin-top: 80px">
            <el-button
              type="primary"
              :round="false"
              @click="submitClick()"
              :loading="saveStatus"
              >Submit My Application</el-button
            >
          </div>

          <div class="description" style="max-width: 800px">
            <div>
              <div style="font-weight: bold">Nondiscrimination Policy</div>
              <br />
              SCAD shall admit students of any gender, race, color, national or
              ethnic origin, and religion to all the rights, privileges,
              programs and activities generally accorded or made available to
              students at SCAD. SCAD shall not discriminate on the basis of
              gender, race, color, national or ethnic origin in the
              administration of its educational policies, admission policies,
              and athletic and other university-administered programs.
              <br /><br />

              SCAD shall make its nondiscrimination policies known to all
              segments of the general community served by the university, in
              accordance with applicable legal requirements.<br /><br />

              SCAD provides equal employment opportunities to all employees and
              applicants for employment without regard to sex, race, color,
              national origin, age, religion, veteran status, or status in any
              group protected by applicable laws. This policy applies to all
              terms and conditions of employment including hiring, placement,
              promotion, termination, transfer, leave of absence, compensation
              and training.SCAD expressly prohibits any unlawful discrimination
              or harassment that affects tangible job benefits, unreasonably
              interferes with an individual’s work performance, or creates an
              intimidating, hostile work environment. Violations of this policy
              may result in disciplinary action up to and including termination.
            </div>
          </div>
        </el-form>

        <div class="form-btns" v-if="formIndex != 7">
          <el-button
            type="primary"
            v-if="formIndex != 1"
            :round="false"
            @click="previousClick()"
            >Previous</el-button
          >
          <el-button
            type="primary"
            v-if="formIndex != 7"
            :round="false"
            @click="nextClick()"
            >Next</el-button
          >

          <!--<el-button-->
          <!--  type="primary"-->
          <!--  v-if="formIndex == 7"-->
          <!--  :round="false"-->
          <!--  @click="submitClick()"-->
          <!--  :loading="saveStatus"-->
          <!--  >Save and Continue</el-button-->
          <!--&gt;-->
        </div>
      </div>
    </div>

    <el-dialog
      title="Prompt"
      :visible.sync="dialogVisible"
      width="30%"
      @close="closeFun"
    >
      <span>Submitted successfully</span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false">OK</el-button>
      </span>
    </el-dialog>

    <el-dialog title="Prompt" :visible.sync="dialog2Visible" width="30%">
      <span>Please enter the correct promo code</span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialog2Visible = false">OK</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import formMenuBar from "@/views/form/formMenuBar";
import Programs_Atlanta_1 from "@/data/Graduate/Programs_Atlanta_1";
import Programs_Atlanta_2 from "@/data/Graduate/Programs_Atlanta_2";
import Programs_Atlanta_3 from "@/data/Graduate/Programs_Atlanta_3";
import Country from "@/data/Country";
import GraduateApplicationRules from "@/views/form/GraduateApplicationRules";
// import jingTrack from "@/utils/jingTrack";
import Citizenship from "@/data/Citizenship";
import Programs_Savannah_4 from "@/data/Graduate/Programs_Savannah_4";
import Programs_Savannah_1 from "@/data/Graduate/Programs_Savannah_1";
import Programs_Savannah_2 from "@/data/Graduate/Programs_Savannah_2";
import Programs_Savannah_3 from "@/data/Graduate/Programs_Savannah_3";
import Programs_eLearning_1 from "@/data/Graduate/Programs_eLearning_1";
import Programs_eLearning_2 from "@/data/Graduate/Programs_eLearning_2";
import Programs_eLearning_3 from "@/data/Graduate/Programs_eLearning_3";
export default {
  name: "GraduateApplication",
  metaInfo: {
    title: "Graduate Application", // set a title
  },
  components: {
    formMenuBar,
  },
  data() {
    return {
      dialogVisible: false,
      dialog2Visible: false,
      formTitle: "Personal Information",
      pageType: "Graduate",
      formIndex: 1,
      name: "",
      saveStatus: false,
      intendedEnrollmentStatus: [],
      intendedProgram: [],
      Programs_Atlanta_1: Programs_Atlanta_1,
      Programs_Atlanta_2: Programs_Atlanta_2,
      Programs_Atlanta_3: Programs_Atlanta_3,
      Programs_Savannah_1: Programs_Savannah_1,
      Programs_Savannah_2: Programs_Savannah_2,
      Programs_Savannah_3: Programs_Savannah_3,
      Programs_Savannah_4: Programs_Savannah_4,
      Programs_eLearning_1: Programs_eLearning_1,
      Programs_eLearning_2: Programs_eLearning_2,
      Programs_eLearning_3: Programs_eLearning_3,
      countrys: Country,
      rules: GraduateApplicationRules,
      countryOfCitizenship: Citizenship,
      formData: {
        promocode: "",
        applicationidentity: "GraduateApplication",
        firstname: "",
        familyname: "",
        email: "",
        confirmemail: "",
        company: "",
        leadstatus: "Open",
        dateofbirth: "",
        countryc: "",
        streetaddress: "",
        mobile: "",
        areyouauscitizen: null,
        countryofcitizenship: "",
        intendedlocation: "",
        termofentry: "",
        enrollmentstatus: "",
        intendedprogramofstudentfirstchoice: "",
        intendedprogramofstudentsecondchoice: "",
        intendedprogramofstudentthirdchoice: "",
        campusdegree: "",
        nameofuniversityattended: "",
        universitycity: "",
        dateattendedfromuniversityonly: "",
        dateattendedtouniversityonly: "",
        degreeuniversityonly: "",
        dateofgraduationuniversityonly: "",
        probationandsuspendedexpelled: "",
        convicted: "",
        guardianrelationship: "",
        guardianemail: "",
        guardianfirstname: "",
        guardianlastname: "",
        guardianaddressline1: "",
        guardianphone: "",
        contactconsent: "",
        scadrulesagreementyesorno: "",
      },
    };
  },
  watch: {
    "formData.intendedlocation": {
      handler(val) {
        if (val == "") {
          return;
        }
        console.log(val);
        this.getDegree(this.pageType, val);
      },
      immediate: true,
    },
    "formData.enrollmentstatus": {
      handler(val) {
        if (val == "") {
          return;
        }
        this.intendedProgram = [];
        this.formData.intendedprogramofstudentfirstchoice = "";
        this.formData.intendedprogramofstudentsecondchoice = "";
        this.formData.intendedprogramofstudentthirdchoice = "";
        console.log(val);
        this.getPrograms(this.pageType, this.formData.intendedlocation, val);
      },
      immediate: true,
    },
    formIndex() {
      if (this.formIndex == 1) {
        this.formTitle = "Personal Information";
      } else if (this.formIndex == 2) {
        this.formTitle = "Campus / Curriculum Information";
      } else if (this.formIndex == 3) {
        this.formTitle = "Education History";
      } else if (this.formIndex == 4) {
        this.formTitle = "Guardian Information";
      } else if (this.formIndex == 5) {
        this.formTitle = "Additional Information";
      } else if (this.formIndex == 6) {
        this.formTitle = "";
      } else if (this.formIndex == 7) {
        this.formTitle = "Submit";
      }
    },
  },
  mounted() {
    // this.formIndex = 7;
  },
  methods: {
    getDegree(enrollment_status, location) {
      var that = this;
      this.$api.post(
        "ApplicationForm/getDegree",
        {
          enrollment_status: enrollment_status,
          location: location,
        },
        (r) => {
          console.log(r);
          that.intendedEnrollmentStatus = r.data.data.list;
          // that.obj = r.data.data;
          // that.areaList = r.data.data.area.split(";");
        }
      );
    },

    getPrograms(enrollment_status, location, enrollment_status_degree) {
      console.log(enrollment_status, location, enrollment_status_degree);
      var that = this;
      this.$api.post(
        "ApplicationForm/getPrograms",
        {
          enrollment_status: enrollment_status,
          location: location,
          enrollment_status_degree: enrollment_status_degree,
        },
        (r) => {
          console.log(r);
          that.intendedProgram = r.data.data.list;
          // that.obj = r.data.data;
          // that.areaList = r.data.data.area.split(";");
        }
      );
    },
    intendedlocationChange() {
      this.formData.enrollmentstatus = "";
    },
    // eslint-disable-next-line no-unused-vars
    formNavClick(name, index) {
      // this.formTitle = name;
      // this.formIndex = index;
    },

    formNavClick2(index) {
      // this.formTitle = name;
      this.formIndex = index;
    },
    previousClick() {
      if (this.formIndex <= 1) {
        this.formIndex = 1;
        return;
      }
      this.formIndex--;
      document.getElementById("content-container").scrollIntoView();
    },
    nextClick() {
      let formName = "ruleForm" + this.formIndex;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.formIndex >= 7) {
            this.formIndex = 7;
            return;
          }
          this.formIndex++;
          document.getElementById("content-container").scrollIntoView();
          return false;
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    submitClick() {
      var that = this;

      if (that.formData.promocode == null || that.formData.promocode == "") {
        that.sumit();
      } else {
        this.$api.post(
          "AcademicsPrograms/verifyPromocode",
          {
            promocode: that.formData.promocode,
          },
          (r) => {
            if (r.data.data != null) {
              that.sumit();
              // console.log("提交2");
            } else {
              that.dialog2Visible = true;
            }
          }
        );
      }

      return;
      // let formName = "ruleForm" + this.formIndex;
      // this.$refs[formName].validate((valid) => {
      //   if (valid) {
      //     this.sumit();
      //   } else {
      //     console.log("error submit!!");
      //     return false;
      //   }
      // });
    },
    sumit() {
      var that = this;
      this.saveStatus = true;

      this.formData["mobile"] = "+86 " + this.formData["mobile"];
      this.formData["guardianphone"] = "+86 " + this.formData["guardianphone"];
      // jingTrack.trackToUser(this.formData);
      // setTimeout(function () {
      //   that.saveStatus = false;
      //   that.dialogVisible = true;
      // }, 1000);

      this.$api.post("AcademicsPrograms/postJING", this.formData, (r) => {
        console.log(r);
        // that.obj = r.data.data;
        // that.areaList = r.data.data.area.split(";");
        that.saveStatus = false;
        that.dialogVisible = true;
      });
    },
    closeFun() {
      window.location.reload();
    },
  },
};
</script>

<style lang="less">
.el-dialog__wrapper {
  .el-dialog {
    @media (max-width: 600px) {
      width: 85% !important;
    }
  }
}
</style>

<style scoped lang="less">
@import "GraduateApplication.less";
.body {
  padding: 20px 30px 0 30px;
  background: #fcf9f6;

  @media (max-width: 800px) {
    padding: 10px 10px 0 10px;
  }
}
</style>
